import React from "react"
import { functions } from "../index"
import {
  Button,
  Page,
  withData,
  withNotify,
  Icon,
  format,
  Dropdown,
  toPreview,
  Meter,
} from "../shared"
import { Route } from "react-router-dom"
import SettingsBillingEdit from "./SettingsBillingEdit"
import moment from "moment"

class SettingsBilling extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPortalLoading: false,
    }
    const user = this.props.getUser()
    this.currentOptions = {
      audio: parseInt(user.maxAudio / 1073741824) || 1,
      video: parseInt(user.maxVideo / 3600) || 5,
      email: parseInt(user.maxEmail) || 2500,
      transcribe: parseInt(user.maxTranscribe / 60 / 60) || 1,
    }
  }
  async redirectToStripe() {
    const user = this.props.getUser()
    if (!user.stripeCustomerId)
      return this.props.error("You need a plan to access the billing portal")
    try {
      this.setState({ isPortalLoading: true })
      const getStripePortalUrl = functions.httpsCallable("getStripePortalUrl")
      const { data } = await getStripePortalUrl()
      if (data && data.isSuccess && data.url) {
        window.location.href = data.url
      } else {
        this.setState({ isPortalLoading: false })
        this.props.error("Could not open billing", (data || {}).message || true)
      }
    } catch (e) {
      this.setState({ isPortalLoading: false })
      this.props.error("Could not open billing", true)
    }
  }
  render() {
    const gridTemplateColumns = "auto 140px 1fr"
    const user = this.props.getUser()
    const hasPlan = !!this.props.plan
    const resetDate = moment().add(1, "month").startOf("month").unix()
    const startedFromZero =
      this.currentOptions.audio === 5 && this.currentOptions.video === 5
    const isEnding = user.willBeDeletedOn > 0

    return (
      <Page isNarrow hasPadding icon="billing" title="Billing">
        <Route path="/settings/billing/edit" component={SettingsBillingEdit} />
        <div className="flex flex-col items-center p-10 pt-3">
          <div className="mb-2 flex items-center justify-center px-10 pr-12">
            <Icon
              icon={hasPlan ? (isEnding ? "hourglass" : "check") : "cross"}
              color={
                hasPlan ? (isEnding ? "orange-500" : "green-500") : "gray-500"
              }
              colorDark={
                hasPlan ? (isEnding ? "orange-400" : "green-400") : "white"
              }
              size={16}
              className="mr-3 -mt-px"
            />
            <h3>
              {hasPlan
                ? this.props.plan === "pro"
                  ? `${isEnding ? "Ending" : "Active"} Pro plan`
                  : `${isEnding ? "Ending" : "Active"} plan`
                : "No active plan"}
            </h3>
          </div>
          <p
            className={`mx-auto mb-5 max-w-sm text-center ${
              hasPlan ? "text-gray-500" : "mt-2"
            }`}
          >
            {hasPlan
              ? isEnding
                ? `Your plan will end on ${format(
                    "date",
                    user.willBeDeletedOn,
                    { month: "full", year: "not-current" },
                  )}.`
                : "Thank you for being a customer."
              : "You can only edit projects you are invited to. Add a plan to create and manage projects yourself."}
          </p>
          <div className="flex justify-center">
            {!hasPlan && (
              <Button primary to={toPreview("sign-up")} className="mr-2">
                Buy plan
              </Button>
            )}
            <Button
              disabled={!user.stripeCustomerId}
              isLoading={this.state.isPortalLoading}
              hasBorder
              onClick={() => this.redirectToStripe()}
            >
              Invoices & billing ↗
            </Button>
            {hasPlan && (
              <Dropdown
                width={268}
                isInfo
                button={<Button className="ml-2">Support</Button>}
              >
                <h6>Billing support</h6>
                <p className="mt-3 text-sm">
                  If you have any questions about billing, we are happy to help.
                </p>
                <p className="mt-3 text-sm">
                  Please visit the{" "}
                  <a
                    rel="noopener noreferrer"
                    className="link"
                    target="_blank"
                    href="//interviewkit.co/support"
                  >
                    Member Support
                  </a>{" "}
                  page for frequently asked questions, or email us at
                  support@interviewkit.co, and we'll get back to you quickly.
                </p>
              </Dropdown>
            )}
          </div>
        </div>
        {hasPlan && (
          <>
            <div className="flex items-center pl-6 pb-2">
              <h6 className="mr-auto">Storage</h6>
              <Button
                isSmall
                to="/settings/billing/edit"
                icon={
                  startedFromZero
                    ? "add"
                    : "Setting.InterfaceSettingSliderVertical"
                }
              >
                {startedFromZero ? "Add storage" : "Edit storage"}
              </Button>
            </div>
            <div className="rounded-2xl border">
              <div
                className="grid items-center py-3 px-5"
                style={{ gridTemplateColumns }}
              >
                <Icon
                  size={20}
                  icon="video"
                  bg="green-100"
                  color="green-500"
                  colorDark="green-400"
                  bgDark="green-900"
                />
                <h5 className="pl-5">Video</h5>
                <Meter
                  color="green"
                  isTime
                  digits={1}
                  current={user.meterVideo}
                  max={user.maxVideo}
                  after="hours stored"
                />
              </div>
              <div
                className="grid items-center border-t py-3 px-5"
                style={{ gridTemplateColumns }}
              >
                <Icon
                  size={20}
                  icon="audio"
                  bg="yellow-100"
                  color="yellow-500"
                  colorDark="yellow-400"
                  bgDark="yellow-900"
                />
                <h5 className="pl-5">Audio</h5>
                <Meter
                  color="yellow"
                  isSize
                  current={user.meterAudio}
                  max={user.maxAudio}
                  after="stored"
                />
              </div>
            </div>
            <div className="flex items-center pt-6 pl-6 pb-2">
              <h6 className="mr-auto">
                {this.props.plan === "pro" ? "Services" : "More"}
              </h6>
              <Dropdown
                arrowPosition="right"
                width={295}
                isInfo
                button={<Button isSmall icon="help" />}
              >
                <h6>How to upgrade</h6>
                {this.props.plan === "regular" && (
                  <p className="mt-3 text-sm">
                    You have a regular InterviewKit plan, limited to 10
                    interviews per month. To upgrade, please email us at
                    support@interviewkit.co.
                  </p>
                )}
                <p className="mt-3 text-sm">
                  If you need more transcription minutes, simply go ahead and
                  transcribe another file. A confirmation box will pop up with
                  the extra charge for the additional transcription minutes
                  ($0,12/minute), which will be charged from your credit card.
                  If this happens a lot, please contact us to discuss a custom
                  plan with more transcription minutes.
                </p>
                <p className="mt-3 text-sm">
                  If you need more emails, please email us at
                  support@interviewkit.co
                </p>
              </Dropdown>
            </div>
            <div className="rounded-2xl border">
              {this.props.plan === "regular" && (
                <div
                  className="grid items-center border-b py-3 px-5"
                  style={{ gridTemplateColumns }}
                >
                  <Icon
                    size={20}
                    icon="mic"
                    bg="pink-100"
                    bgDark="pink-900"
                    color="pink-500"
                    colorDark="pink-400"
                  />
                  <h5 className="pl-5">Interviews</h5>
                  <Meter
                    color="pink"
                    current={
                      this.props.interviews?.filter(
                        (x) =>
                          x.createdBy === this.props.userId &&
                          moment(x.createdOn, "X").isSameOrAfter(
                            moment().startOf("month"),
                          ),
                      ).length || 0
                    }
                    max={10}
                    after="interviews created this month"
                  />
                </div>
              )}
              <div
                className=" grid items-center py-3 px-5"
                style={{ gridTemplateColumns }}
              >
                <Icon
                  size={20}
                  icon="transcribe"
                  bg="orange-100"
                  bgDark="orange-900"
                  color="orange-500"
                  colorDark="orange-400"
                />
                <h5 className="pl-5">Transcribe</h5>
                <Meter
                  color="orange"
                  isTime
                  digits={1}
                  current={user.meterTranscribe}
                  max={user.maxTranscribe}
                  after="hours transcribed this month"
                />
              </div>
              <div
                className="grid items-center border-t py-3 px-5"
                style={{ gridTemplateColumns }}
              >
                <Icon
                  size={20}
                  icon="send"
                  bg="blue-100"
                  color="blue-500"
                  colorDark="blue-400"
                  bgDark="blue-900"
                />
                <h5 className="pl-5">Emails</h5>
                <Meter
                  color="blue"
                  current={user.meterEmail}
                  max={user.maxEmail}
                  after="emails sent this month"
                />
              </div>
            </div>
            <div className="flex py-4 px-8">
              <Icon
                icon="arrow-left"
                size={12}
                color="gray-500"
                style={{ marginTop: 2 }}
              />
              <p className="ml-3 text-sm text-gray-500">
                These meters will reset {moment(resetDate, "X").fromNow()} (on{" "}
                {format("date", resetDate, {
                  month: "full",
                  year: "not-current",
                })}
                ).
              </p>
            </div>
          </>
        )}

        <div className="mx-auto mt-8 mb-5 flex max-w-lg items-center justify-center">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-5 w-24 flex-none fill-current"
            viewBox="0 0 468 222.5"
          >
            <path
              d="M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3
		c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z
		 M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z"
            />
            <path
              d="M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3
		c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1
		c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z"
            />
            <polygon points="223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 	" />
            <rect x="223.8" y="69.3" width="25.1" height="87.5" />
            <path d="M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z" />
            <path
              d="M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135
		c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z"
            />
            <path
              d="M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6
		C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7
		c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z"
            />
          </svg>
          <p className="text-sm leading-normal">
            <span className="block font-medium">
              InterviewKit works with{" "}
              <a
                className="link"
                href="https://stripe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>{" "}
              to manage billing.
            </span>{" "}
            <span className="text-gray-500">
              You can update your card details, {isEnding ? "resume" : "cancel"}{" "}
              your plan and <br />
              view previous invoices by going to the{" "}
              <span className="link" onClick={() => this.redirectToStripe()}>
                billing portal
              </span>
              .
            </span>
          </p>
        </div>
      </Page>
    )
  }
}

SettingsBilling.propTypes = {}

export default withData(withNotify(SettingsBilling))
