import React from 'react';
import {PropTypes} from 'prop-types';
import {filterData, formatData, sortData} from "../functions";
import {withData} from "../data";
import Icon from "./Icon";
import ContextBoard from "./ContextBoard";
import {motion} from "framer-motion";

class Grid extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.setColsBound = this.setCols.bind(this);
        this.state = {
            cols: false,
            selectedIds: [],
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.setColsBound);
        this.setCols();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setColsBound);
    }
    setCols() {
        const width = this.wrapper.current.offsetWidth;
        const cols = Math.floor(width / this.props.minWidth);
        this.setState({cols});
    }
    render() {
        const singular = this.props.singular || 'item';
        const plural = this.props.plural || 'items';

        const sortBy = this.props.local[`${this.props.plural}SortBy`] || this.props.defaultSortBy || (this.props.fields ? this.props.fields[0].key : '');
        const sortDesc = this.props.local[`${this.props.plural}SortDesc`] || this.props.defaultSortDesc || false;

        let fields = this.props.fields.filter(x => x && !x.hidden && !(this.props.hideFields || []).includes(x.key)) || [];
        const data = filterData(this.props.searchQuery, this.props.hideFields, fields, this.props.data);
        if(this.props.sortLocal) {
            // sortLocal makes most sense when using a Table and Grid with the same data.
            const sortByField = (fields.find(x => x.key === sortBy) || {});
            data.sort((a,b) => sortData(a, b, sortByField, sortDesc));
        }
        const keyTitle = this.props.keyTitle;
        const keyImage = this.props.keyImage;
        const keyStarred = this.props.keyStarred || 'isStarred';
        const showImages = keyImage || this.props.image;

        return (
            <motion.div layout='position' ref={this.wrapper} className={`flex-1 ${this.props.className || ''}`}>
                <ContextBoard
                    canDrag
                    data={data}
                    desc={this.props.desc}
                    classNameChild={`${this.props.noPadding || this.props.groupBy ? 'pb-10' : 'pt-4 p-10'} select-none grid items-start grid-cols-${this.state.cols} gap-${this.props.gap}`}
                    singular={singular}
                    plural={plural}
                    cols={this.state.cols}
                    groupBy={this.props.groupBy}
                    groupByDesc={this.props.groupByDesc}
                    mosaicCols={this.props.isMosaic ? this.state.cols : false}
                    contextMenu={this.props.contextMenu}
                    to={this.props.to}
                    fields={this.props.fields}
                    disabled={this.props.disabled}
                    mapData={(dataObj, i, isSelected) => {
                        const title = formatData(dataObj, this.props.fields.find(x => x.key === keyTitle) || {}, true).val;
                        const image = keyImage ? dataObj[keyImage] : (typeof this.props.image === 'function' ? this.props.image(dataObj, i) : this.props.image);
                        const isStarred = dataObj[keyStarred];
                        let icon = this.props.icon;
                        if(typeof icon === 'function') icon = icon(dataObj);
                        // Note: below, we check if image is a string. We do so because image can be a boolean of "true"
                        // as well, which indicates that the image URL is loading but not finished yet (i.e. display a
                        // black background but don't load an image URL yet).
                        return (
                            <div key={dataObj.id} className={`${isSelected ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-800'} ${this.props.isMosaic ? '' : 'h-full'} flex flex-col cursor-default card row-span-12 tr overflow-hidden border border-light ${isStarred ? 'border-yellow-400 dark:border-yellow-500' : ''} rounded-lg`}>
                                {showImages ? <div className={`h-36 flex-none bg-cover bg-center ${image ? 'bg-gray-900' : 'bg-gray-50 dark:bg-gray-700'} flex items-center justify-center`} style={typeof image === 'string' ? {backgroundImage: `url(${image})`} : null} >
                                    {image ? null : (icon ? <Icon size={16} color='gray-400' icon={icon} /> : null)}
                                </div> : null}
                                <div className='p-4 pb-3 flex-1 flex flex-col'>
                                    {this.props.mapInner && this.props.mapInner(dataObj, i, isSelected)}
                                    {keyTitle && (this.props.isText ? <p>{title || 'Empty'}</p> : <h5 className={`truncate ${title ? '' : 'text-gray-400'}`}>{title || 'Untitled'}</h5>)}
                                    {((this.props.keyRows || []).length || this.props.Right) && (
                                        <div className={`flex items-center ${this.props.isText ? 'mt-auto pt-2' : 'pt-1'}`}>
                                            <div className='flex-1 overflow-hidden'>{(this.props.keyRows || []).map(keyRow => <p key={keyRow} className='sub truncate'>{formatData(dataObj, this.props.fields.find(x => x && x.key === keyRow)).val}</p>)}</div>
                                            {this.props.right ? <div className='ml-5'>{this.props.right(dataObj)}</div> : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }}
                />
            </motion.div>
        );
    }
}

Grid.propTypes = {
    minWidth: PropTypes.number,
    gap: PropTypes.number,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
};

Grid.defaultProps = {
    minWidth: 180,
    gap: 5,
    icon: 'Pictures.ImagePictureLandscape1',
    padding: 10,
};

export default withData(Grid);