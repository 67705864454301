import React from "react"
import { Page, LoginBox, withData, withNotify } from "../shared"
import queryString from "query-string"

class Login extends React.Component {
  componentDidMount() {
    this.redirect = queryString.parse(this.props.location.search).redirect
    if (!this.props.isDemo) this.props.history.push(this.redirect || "/")
  }
  render() {
    return (
      <Page noBar title="Login" isGray>
        <div className="flex h-full items-center justify-center">
          <LoginBox
            redirectTo={this.redirect}
            className="relative z-10 w-96 rounded-xl bg-white p-10 shadow-sm dark:bg-gray-800"
          />
        </div>
      </Page>
    )
  }
}

export default withNotify(withData(Login))
