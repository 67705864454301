import React from 'react';
import Button from '../Button';
import ButtonSwitch from '../ButtonSwitch';
import {withRouter} from 'react-router-dom';
import Toggle from '../Toggle';
import Page from '../Page';
import Radio from '../Radio';
import Tabs from '../Tabs';
import Tab from '../Tab';
import RadioItem from '../RadioItem';
import {withData} from "../../data";
import PropTypes from 'prop-types';
import Checkbox from "../Checkbox";
import {capitalize, toggleInArray, getColor} from "../../functions";

class PopoverView extends React.Component {
    constructor(props) {
        super(props);
        const filterLabelIds = (this.props.options.filterLabels || {}).value || [];
        this.state = {
            tab: filterLabelIds.length ? 'filter' : null,
            view: (this.props.options.view || {}).value,
            gridSortBy: (this.props.options.gridSortBy || {}).value,
            gridSortDesc: this.props.options.gridSortDesc || false,
            groupBy: (this.props.options.groupBy || {}).value,
            // Table options
            tableHideFields: (this.props.options.tableHideFields || {}).value || [],
            // Grid options
            gridSubtitle: (this.props.options.gridSubtitle || {}).value,
            gridPinStarred: (this.props.options.gridPinStarred || {}).value,
            gridAlign: (this.props.options.gridAlign || {}).value,
            // Calendar options
            calendarView: (this.props.options.calendarView || {}).value,
            calendarShow24Hours: this.props.options.calendarShow24Hours || false,
            calendarShowWeekends: this.props.options.calendarShowWeekends || false,
            calendarHideInterviewerNames: this.props.options.calendarHideInterviewerNames || [],
            calendarHideProjectIds: this.props.options.calendarHideProjectIds || [],
            // Filter options
            filterLabelIds,
        };
    }
    finalize() {
        this.props.onSubmit(this.state);
        this.props.history.push(this.props.backTo);
    }
    render() {
        const {options, position, offset, backTo} = this.props;
        const {view, tab} = this.state;
        const hasTabs = options.groupBy || options.gridSortBy || options.filterLabels;

        const visibleProjects = options.calendarProjects?.filter(project => !this.state.calendarHideProjectIds?.includes(project.id));

        return (
            <Page
                isPopup
                title='View'
                width='sm'
                icon='layers'
                position={position}
                offset={offset}
                style={{height: 560}}
                backTo={backTo}
                isFlexCol
                footerGrid={2}
                footerBorder
                footer={<>
                    <Button full onClick={this.finalize.bind(this)} primary>Apply</Button>
                    <Button full onClick={() => this.props.history.push(backTo)}>Cancel</Button>
                </>}
            >
                {hasTabs && (
                    <Tabs margin={8} marginSide={0} className='px-6'>
                        <Tab onClick={() => this.setState({tab: null})} isActive={!tab}>General</Tab>
                        {options.groupBy && <Tab onClick={() => this.setState({tab: 'group'})} isActive={tab === 'group'}>Group</Tab>}
                        {options.gridSortBy && <Tab disabled={view !== 'grid'} onClick={() => this.setState({tab: 'sort'})} isActive={tab === 'sort'}>Sort</Tab>}
                        {options.filterLabels && <Tab badge={this.state.filterLabelIds.length || null} onClick={() => this.setState({tab: 'filter'})} isActive={tab === 'filter'}>Filter</Tab>}
                    </Tabs>
                )}

                <div className={`flex-1 flex flex-col px-6 pb-6 ${!tab && options.view.options ? 'pt-2' : 'pt-4'} space-y-4 justify-start overflow-scroll`}>
                    {!tab && <>
                        {options.view.options && <ButtonSwitch
                            value={view}
                            className={`self-start ${hasTabs ? 'mt-2' : 'mt-0'} mb-2`}
                            onChange={view => this.setState({view})}
                            buttons={[
                                options.view.options.includes('table') && {
                                    text: 'Table',
                                    value: 'table',
                                    icon: 'TextFormatting.InterfaceTextFormattingListBullets',
                                },
                                options.view.options.includes('grid') && {
                                    text: 'Grid',
                                    value: 'grid',
                                    icon: 'Photos.ImagePhotoFour',
                                },
                            ]}
                        />}

                        {view === 'table' && <>
                            {options.tableHideFields && <div>
                                <h6 className='mb-2'>Visible fields</h6>
                                {options.tableHideFields.options.filter(x => x).map(field => {
                                    if(!field || field.hidden) return null;
                                    return (
                                        <Checkbox isSmall disabled={field.canHide === false} onChange={x => this.setState({tableHideFields: toggleInArray(field.key, this.state.tableHideFields, !x)})} isActive={!this.state.tableHideFields.includes(field.key)} key={field.key}>{capitalize(field.name || field.key)}</Checkbox>
                                    );
                                })}
                            </div>}
                        </>}

                        {view === 'grid' && <>
                            {(options.gridPinStarred || options.gridAlign) && <div>
                                {options.gridPinStarred && <Toggle isSmall defaultValue={this.state.gridPinStarred} onChange={x => this.setState({gridPinStarred: x})}>{options.gridPinStarred.name || 'Pin starred'}</Toggle>}
                                {options.gridAlign && <Toggle isSmall defaultValue={this.state.gridAlign} onChange={x => this.setState({gridAlign: x})}>{options.gridAlign.name || 'Align columns'}</Toggle>}
                            </div>}
                            {options.gridSubtitle && <div>
                                <h6 className='mb-2'>Subtitle</h6>
                                <Radio isSmall value={this.state.gridSubtitle} onChange={gridSubtitle => this.setState({gridSubtitle})}>
                                    {options.gridSubtitle.options.map(field => <RadioItem key={field.value} value={field.value}>{field.name}</RadioItem>)}
                                </Radio>
                            </div>}
                        </>}

                        {view === 'calendar' && <>
                            {options.calendarView && <div>
                                <h6 className='mb-2'>Period</h6>
                                <Radio isSmall value={this.state.calendarView} onChange={calendarView => this.setState({calendarView})}>
                                    {options.calendarView.options.map(field => <RadioItem key={field} value={field}>{capitalize(field)}</RadioItem>)}
                                </Radio>
                            </div>}
                            {options.calendarShow24Hours != null && (!options.calendarView || !['month', 'year'].includes(this.state.calendarView)) && <div>
                                <h6 className='mb-2'>Hours</h6>
                                <Radio isSmall value={this.state.calendarShow24Hours} onChange={calendarShow24Hours => this.setState({calendarShow24Hours})}>
                                    <RadioItem value={false}>Show work hours</RadioItem>
                                    <RadioItem value={true}>Show 24 hours</RadioItem>
                                </Radio>
                            </div>}
                            {options.calendarProjects?.length > 1 && <div>
                                <h6 className='mb-2'>Show projects</h6>
                                {options.calendarProjects.map(x => <Checkbox color={visibleProjects.length > 1 && x.color} isSmall onChange={y => this.setState({calendarHideProjectIds: toggleInArray(x.id, this.state.calendarHideProjectIds, !y)})} isActive={!(this.state.calendarHideProjectIds || []).includes(x.id)} classNameChildren='flex items-center' key={x.id}>{x.name}</Checkbox>)}
                            </div>}
                            {options.calendarInterviewerNames?.length > 1 && <div>
                                <h6 className='mb-2'>Show interviewers</h6>
                                {options.calendarInterviewerNames.map((x,i) => {
                                    const color = visibleProjects.length === 1 && getColor((i) + 6);
                                    return <Checkbox isSmall color={color} onChange={y => this.setState({calendarHideInterviewerNames: toggleInArray(x, this.state.calendarHideInterviewerNames, !y)})} isActive={!(this.state.calendarHideInterviewerNames || []).includes(x)} classNameChildren='flex items-center' key={x}>{x || (x === null ? 'Not assigned' : 'No name')}</Checkbox>
                                })}
                            </div>}
                            {options.calendarShowWeekends != null && <Toggle isSmall value={this.state.calendarShowWeekends} onChange={calendarShowWeekends => this.setState({calendarShowWeekends})}>Show weekends</Toggle>}
                        </>}
                    </>}

                    {tab === 'group' && options.groupBy && <div>
                        <h6 className='mb-2'>Group by</h6>
                        <Radio isSmall value={this.state.groupBy} onChange={groupBy => this.setState({groupBy})}>
                            {options.groupBy.options.filter(x => x).map(x => <RadioItem value={x.value} key={x.value}>{x.name || capitalize(x.value)}</RadioItem>)}
                        </Radio>
                    </div>}

                    {tab === 'sort' && view === 'grid' && (options.gridSortBy || options.gridSortDesc != null) && <>
                        {options.gridSortBy && <div>
                            <h6 className='mb-2'>Sort by</h6>
                            <Radio isSmall value={this.state.gridSortBy} onChange={gridSortBy => this.setState({gridSortBy})}>
                                {options.gridSortBy.options.filter(x => x).map(x => <RadioItem value={x.value} key={x.value}>{x.name || capitalize(x.value)}</RadioItem>)}
                            </Radio>
                        </div>}

                        {options.gridSortDesc != null && <div>
                            <h6 className='mb-2'>Order</h6>
                            <Radio isSmall value={this.state.gridSortDesc} onChange={gridSortDesc => this.setState({gridSortDesc})}>
                                <RadioItem value={false}>Ascending (A→Z)</RadioItem>
                                <RadioItem value={true}>Descending (Z→A)</RadioItem>
                            </Radio>
                        </div>}
                    </>}

                    {tab === 'filter' && (
                        (options.filterLabels || {}).options ? <div>
                            <div className='flex items-center -mt-1.5 mb-2'>
                                <h6 className='mr-auto'>Filter by label</h6>
                                <Button isSmall disabled={!(this.state.filterLabelIds || []).length} onClick={() => this.setState({filterLabelIds: []})}>Clear filters</Button>
                            </div>
                            {options.filterLabels.options.map(label => (
                                <Checkbox
                                    isSmall
                                    color={label.color}
                                    isActive={this.state.filterLabelIds.includes(label.id)}
                                    onChange={x => this.setState({filterLabelIds: toggleInArray(label.id, this.state.filterLabelIds, x)})}>
                                    {label.name}
                                </Checkbox>
                            ))}
                        </div> : <div className='text-gray-500 text-sm'>
                            Add labels to enable filtering.
                        </div>
                    )}
                </div>
            </Page>
        );
    }
}

PopoverView.propTypes = {
    options: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

PopoverView.defaultProps = {
    position: 'topRight',
};

export default withData(withRouter(PopoverView));