import React from "react"
import {
  Page,
  PopoverUpdatePassword,
  Sidebar,
  SidebarItem,
  withData,
} from "../shared"
import SettingsProfile from "./SettingsProfile"
import SettingsBilling from "./SettingsBilling"
import SettingsExport from "./SettingsExport"
import { Redirect, Route, Switch } from "react-router-dom"

class Settings extends React.Component {
  render() {
    const baseUrl = "/settings"
    return (
      <div className="two-pages-sm">
        <Page title="Settings" isFlexCol isSidebar hasPadding>
          <Sidebar isInline>
            <SidebarItem icon="user" to={`${baseUrl}/profile`}>
              Profile
            </SidebarItem>
            <SidebarItem icon="billing" to={`${baseUrl}/billing`}>
              Billing
            </SidebarItem>
            <SidebarItem icon="download" to={`${baseUrl}/export`}>
              Export
            </SidebarItem>
          </Sidebar>
        </Page>
        <Switch>
          <Route path="/settings/profile" component={SettingsProfile} />
          <Route path="/settings/billing" component={SettingsBilling} />
          <Route path="/settings/export" component={SettingsExport} />
          <Redirect from="/settings" exact to="/settings/profile" />
        </Switch>
        <Route
          path="/settings/profile/password"
          render={(props) => (
            <PopoverUpdatePassword {...props} backTo="/settings" />
          )}
        />
      </div>
    )
  }
}

export default withData(Settings)
