import React from 'react';
import Input from "../Input";
import Icon from "../Icon";
import {
    formatData,
    format,
    sortData, filterData, capitalize,
} from "../../functions";
import { withData } from "../../data";
import State from "../State";
import Shortcut from "../Shortcut";
import ContextBoard from "../ContextBoard";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";
import _ from "lodash";
import CheckboxV2 from '../CheckboxV2/CheckboxV2';
import styles from "./Table.module.scss";

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: this.props.local[`${this.props.plural}SortBy`] || this.props.defaultSortBy || false, // If no default, sort it the way we receive it.
            sortDesc: this.props.local[`${this.props.plural}SortDesc`] || this.props.defaultSortDesc || false,
            editId: false,
            editKey: false,
            hideIdsSearch: [],
            hideIdsFilter: [],
            selectedIds: [],
            editKeyVal: {},
            search: '',
            newFilterKey: false,
            newFilterComparison: false,
            newFilterValue: '',
            checkedObj: {},
        };
        this.fieldWidths = [];
        this.buttonWidths = [];
        this.data = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.props.isInlineEditable) return false;
        if (this.data.current && !this.data.current.contains(event.target)) {
            this.setState({ selectedIds: [] });
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const newState = {};
        // When exiting edit mode, remove selection
        if (prevProps.isInlineEditable && !this.props.isInlineEditable) {
            newState.selectedIds = [];
            newState.editKeyVal = {};
        }
        if (prevProps.local[`${this.props.plural}SortBy`] !== this.props.local[`${this.props.plural}SortBy`]) {
            if (this.props.local[`${this.props.plural}SortBy`] !== this.state.sortBy) {
                newState.sortBy = this.props.local[`${this.props.plural}SortBy`];
            }
        }
        if (prevProps.local[`${this.props.plural}SortDesc`] !== this.props.local[`${this.props.plural}SortDesc`]) {
            if (this.props.local[`${this.props.plural}SortDesc`] !== this.state.sortDesc) {
                newState.sortDesc = this.props.local[`${this.props.plural}SortDesc`];
            }
        }
        // Update state when any adjustments were made in this function
        if (Object.keys(newState).length) this.setState(newState);

        if (prevState.checkedObj !== this.state.checkedObj) {
            if (typeof this.props?.setSelectedInterviewIds === 'function') {
                this.props.setSelectedInterviewIds(_.map(_.pickBy(this.state.checkedObj, e => e), (value, key) => key));
            }
        }

        if (prevProps.projectId !== this.props.projectId) {
            this.setState({ selectedIds: [], checkedObj: {} });
        }
    }
    handleEdit() {
        const obj = {};
        for (const id of this.state.selectedIds) {
            obj[id] = { ...this.state.editKeyVal }
        }
        this.props.onUpdate(obj);
    }
    handleSort(key) {
        if (!this.props.canSort) return false;
        const isActiveSort = this.state.sortBy === key;
        const sortDesc = isActiveSort ? !this.state.sortDesc : false;
        this.setState({
            sortBy: key,
            sortDesc,
        });
        this.props.setLocal({
            [`${this.props.plural}SortBy`]: key,
            [`${this.props.plural}SortDesc`]: sortDesc,
        });
    }
    render() {
        const tableType = this.props?.tableType ?? null;
        const singular = this.props.singular || 'item';
        const plural = this.props.plural || 'items';
        let fields = this.props.fields.filter(x => x && !x.hidden && x.canShow !== false && !(this.props.hideFields || []).includes(x.key)) || [];
        const data = filterData(this.props.searchQuery, this.props.hideFields, fields, this.props.data);
        const sortByField = fields.find(x => x.key === this.state.sortBy);
        if (sortByField) data.sort((a, b) => sortData(a, b, sortByField, this.state.sortDesc));
        let gridTemplateColumns = fields.map((x, i) => {
            if (!!this.props.badges && i === fields.length - 1) return x.size && x.size.endsWith('fr') && parseFloat(x.size) > 1 ? x.size : 'minmax(280px, 1fr)'; // Override default pixels below for badges
            if (x.size) return x.size;
            else if (x.type === 'boolean' || x.type === 'time' || x.type === 'number') return '70px';
            else if (x.type === 'labels') return 'minmax(300px, 1fr)';
            else return '230px'; // Default
        }).map(x => x.endsWith('px') || x.includes('minmax') ? x : `minmax(150px, ${x})`).join(' ');

        const allChecked = _.size(_.filter(this.state.checkedObj, value => value)) === _.size(data);

        return (
            <motion.div layout='position' className={`flex-1 flex flex-col overflow-scroll items-start cursor-default select-none ${this.props.className || ''}`} style={this.props.style}>
                <div className={`grid flex-none tr sticky z-10 min-w-full gap-10 ${this.props.noPadding ? '' : 'pl-10'} ${this.props.noPadding ? '' : 'pr-10'} h-14 justify-between items-center`} style={{ gridTemplateColumns: gridTemplateColumns }}>
                    <div className={`border-b ${this.props.noPadding ? '' : 'px-4'} absolute bottom-0 inset-x-0 border-light`} />
                    {fields.map((field, colIndex) => {
                        const isActiveSort = this.state.sortBy === field.key;
                        const canSort = this.props.canSort !== false && field.canSort !== false;
                        if (!this.fieldWidths[colIndex]) this.fieldWidths[colIndex] = [];
                        this.fieldWidths[colIndex][0] = React.createRef();

                        if (field.type === "checkbox") {
                            return (
                                <CheckboxV2
                                    key={field.key}
                                    checked={allChecked}
                                    onChange={e => e.target.checked ? this.setState({ checkedObj: _.reduce(data, (a, v) => ({ ...a, [v.id]: true }), {}) }) : this.setState({ checkedObj: {} })}
                                />
                            )
                        }

                        return (
                            <div className={`whitespace-nowrap overflow-hidden ${field.type === 'labels' && field.align === 'right' ? 'pr-2' : ''} text-${field.align || 'left'}`} ref={x => x ? this.fieldWidths[colIndex][0] = x : null} key={field.key}>
                                <h6 onClick={() => canSort ? this.handleSort(field.key) : null} key={field.key} className={`inline-flex items-center ${canSort ? 'hover:text-gray-700 dark:hover:text-gray-400 cursor-pointer' : ''} select-none`} title={field.name || capitalize(field.key)}>
                                    <span className='truncate' style={{ paddingRight: (isActiveSort && canSort) || field.align === 'right' ? 0 : 17 }}>{field.name || capitalize(field.key)}</span>
                                    {isActiveSort && canSort && <Icon icon={this.state.sortDesc ? 'arrow-down' : 'arrow-up'} size={10} className='ml-2 relative' style={{ top: 1 }} />}
                                </h6>
                            </div>
                        );
                    })}
                </div>
                <div ref={this.data} className='flex-1 min-h-0 min-w-full'>
                    {data.length ?
                        <ContextBoard
                            allChecked={allChecked}
                            checkedObj={this.state.checkedObj}
                            ignoreDefaultActions={this.props.ignoreDefaultActions}
                            canDrag
                            data={data}
                            desc={this.props.desc}
                            className={`overflow-scroll relative ${this.props.noPaddingBottom ? '' : 'pb-32'} py-2 ${this.props.noPadding ? '' : 'px-6'}`}
                            singular={singular}
                            plural={plural}
                            contextMenu={this.props.contextMenu}
                            to={this.props.to}
                            disabled={this.props.isInlineEditable}
                            mapData={(dataItem, rowIndex, isSelected, selectItem, openItem) => {
                                const badges = typeof this.props.badges === 'function' ? this.props.badges(dataItem, rowIndex) : false;
                                const hasCheckbox = _.size(_.filter(fields, e => e?.type !== "checkbox")) > 0;

                                return <div
                                    key={dataItem.id}
                                    className={`justify-between relative items-center h-12 grid tr gap-10 rounded-lg mb-px ${isSelected ? 'bg-gray-100 dark:bg-gray-700' : ''} ${this.props.noPadding ? '' : 'px-4'} ${this.props.isInlineEditable ? `select-none` : ''} ${styles.onHoverRow}`}
                                    style={{ gridTemplateColumns }}
                                >
                                    {this.props.isNumbered && !this.props.image && <div style={{ left: '1rem' }} className={`absolute inset-y-0 text-gray-400 flex items-center mt-px dark:text-gray-500 ${hasCheckbox ? "pl-10" : ""}`}>{rowIndex + 1}</div>}
                                    {this.props.image && <div className={`w-8 h-8 ml-3 rounded-full absolute inset-y-0 my-auto bg-cover bg-center ${hasCheckbox ? "pl-10" : ""}`} style={{ backgroundImage: `url(${typeof this.props.image === 'function' ? this.props.image(dataItem) : dataItem[this.props.image]})` }} />}
                                    {_.map(fields, (field, colIndex) => {
                                        const type = field?.fieldType;
                                        const { rawVal, val } = formatData(dataItem, field, true);
                                        let content = val ? (field.type === 'labels' && rawVal.length === 0 ? '' : <>{field.before || ''}{val}{field.after || ''}</>) : (field.type === 'labels' ? '' : '—');
                                        if (field.type === "checkbox") {
                                            content = <CheckboxV2
                                                checked={isSelected}
                                                onChange={() => { this.setState({ checkedObj: { ...this.state.checkedObj, [dataItem.id]: !isSelected } }); selectItem(); }}
                                                style={{ zIndex: 1, position: "relative" }}
                                            />;
                                        }

                                        if (field.fieldType === "name" && tableType === "interview") {
                                            content = <span className={styles.onLinkHover} onClick={openItem}>{content}</span>;
                                        }
                                        const isTabular = ['currency', 'number', 'time'].includes(field.type);
                                        const isLastCol = colIndex === fields.length - 1;
                                        const padding = (this.props.isNumbered || this.props.isInlineEditable || this.props.image) && colIndex === 0 && type !== "checkbox" && !hasCheckbox;
                                        if (!this.fieldWidths[colIndex]) this.fieldWidths[colIndex] = [];
                                        this.fieldWidths[colIndex][rowIndex + 1] = React.createRef();
                                        let title = typeof field.title === "function" ? field.title(rawVal, dataItem) : field.title;
                                        const isEditing = this.state.editId === dataItem.id && this.state.editKey === field.key;
                                        const fieldDiv = <div title={title} ref={x => x ? this.fieldWidths[colIndex][rowIndex + 1] = x : null} key={`${field.key}-${dataItem.id}`} className={`flex-1 ${isEditing ? '' : 'truncate'} ${field.type === 'boolean' ? 'text-center' : ''} ${padding ? 'pl-10' : ''} ${isTabular ? 'tabular' : ''} ${field.align === 'right' ? 'text-right' : ''} ${val ? '' : 'text-gray-300 dark:text-gray-500'} ${type === "checkbox" ? "inline-flex items-center" : ""}`}>
                                            {isEditing ? (
                                                <div className='relative'>
                                                    <Shortcut alsoWorksWhenInputInFocus press='Escape' onPress={x => document.activeElement.blur()} />
                                                    <Shortcut alsoWorksWhenInputInFocus press='Enter' onPress={x => document.activeElement.blur()} />
                                                    <Input hasBorder format={field.format} autoFocus defaultValue={rawVal} className='absolute bg-white dark:bg-gray-800 shadow-lg inset-0' style={{ left: -15, right: -15, width: 'calc(100% + 30px)' }} type={field.type} onBlur={val => { this.props.onUpdate({ [dataItem.id]: { [field.key]: val } }); this.setState({ editId: false, editKey: false }) }} />
                                                </div>
                                            ) : (
                                                <span onClick={this.props.isInlineEditable ? () => this.setState({ editId: dataItem.id, editKey: field.key }) : null} className={field.className}>{content}</span>
                                            )}
                                        </div>;
                                        return !!badges && isLastCol ? (
                                            <div key={`${field.key}-${rowIndex}`} className='flex items-center'>
                                                {fieldDiv}
                                                <div className='ml-auto pl-2 overflow-hidden whitespace-nowrap flex space-x-2'>{badges}</div>
                                            </div>
                                        ) : fieldDiv;
                                    })}
                                </div>
                            }}
                        /> :
                        <State />
                    }
                </div>
                {data.length && this.props.showTotals && fields.some(x => x.type === 'number' || x.type === 'currency') ? (
                    <div className={`flex-none grid gap-10 min-w-full -mt-px pb-px sticky bg-white dark:bg-gray-800 h-12 ${this.props.noPadding ? '' : 'px-10'} justify-between`} style={{ gridTemplateColumns }}>
                        <div className={`absolute top-0 inset-x-0 border-t border-light`} />
                        {fields.map((field, colIndex) => {
                            const padding = (this.props.isNumbered || this.props.isInlineEditable || this.props.image) && colIndex === 0;
                            let sum = field.type === 'number' || field.type === 'currency' ? data.reduce((sum, obj) => sum + (field.calc ? field.calc(obj[field.key], obj) : obj[field.key]), 0) : null;
                            if (field.type === 'currency') sum = format('currency', sum);
                            else if (field.type === 'number' && !Number.isInteger(sum)) sum = sum.toFixed(1);
                            if (!sum || field.showTotals === false) sum = null;
                            return (
                                <div ref={x => x ? this.fieldWidths[colIndex][this.fieldWidths[colIndex].length] = x : null} key={field.key} className={`flex tabular truncate font-medium items-center ${padding ? 'pl-10' : ''}`}>
                                    <span className={`whitespace-nowrap w-full text-${field.align || 'left'}`}>{sum ? field.before : null}{sum}{sum ? field.after : null}</span>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </motion.div>
        )
    }
}

Table.propTypes = {
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
}

Table.defaultProps = {
    canSort: true,
}

export default withData(Table);