import React from "react"
import {
  Callout,
  Dropdown,
  format,
  DropdownItem,
  Sheet,
  updateObjectInArray,
  Input,
  InputGroup,
  Button,
  Page,
  withData,
  getSemiUniqueKey,
  Icon,
  deleteObjectInArray,
} from "../shared"
import { Link, Route } from "react-router-dom"
import { withNotify } from "../shared"
import { getInterviewDays } from "../functions"

const defaultTitles = {
  "single-choice": "Which of the following applies to you?",
  "multiple-choice": "Which of the following apply to you?",
  name: "What is your name?",
  email: "What is your email address?",
  age: "How old are you?",
  gender: "How do you identify?",
  phone: "What is your phone number?",
  education: "What is your highest completed education?",
  job: "What is your current job?",
  availability: "When are you available?",
  confirmation: "We received your application. Thank you!",
}

class ProjectFormContent extends React.Component {
  getFormQuestionsCopy() {
    const form = this.props.forms
      ? this.props.forms.find((x) => x.id === this.props.match.params.formId)
      : false
    return form.questions ? [...form.questions] : []
  }
  add(type) {
    const questions = this.getFormQuestionsCopy()
    questions.push({
      id: getSemiUniqueKey(),
      title: defaultTitles[type] || "",
      type,
    })
    this.props.update("forms", this.props.match.params.formId, { questions })
  }
  addAvailability() {
    this.props.addToArray(
      "projects",
      this.props.match.params.projectId,
      "availability",
      {
        date: "",
        from: "",
        until: "",
        id: getSemiUniqueKey(),
      },
    )
  }
  move(id, dir) {
    const questions = this.getFormQuestionsCopy()
    const index = questions.findIndex((x) => x.id === id)
    questions.splice(index + dir, 0, questions.splice(index, 1)[0]) // Reorder array from index to index + dir
    this.props.update("forms", this.props.match.params.formId, { questions })
  }
  update(id, obj) {
    const questions = this.getFormQuestionsCopy()
    const index = questions.findIndex((x) => x.id === id)
    questions[index] = { ...questions[index], ...obj }
    this.props.update("forms", this.props.match.params.formId, { questions })
  }
  delete(id) {
    const questions = this.getFormQuestionsCopy()
    const index = questions.findIndex((x) => x.id === id)
    questions.splice(index, 1)
    const obj = { questions }
    if (questions.length === 0) obj.isLive = false
    this.props.update("forms", this.props.match.params.formId, obj)
  }
  render() {
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const form = this.props.form
    const formQuestions = form.questions || []

    const types = formQuestions.map((x) => x.type)
    const fields = [
      {
        key: "type",
        name: "questions",
        type: "dropdown",
        canClear: false,
        options: [
          {
            key: "text",
            name: "Text field",
            icon: "text",
          },
          {
            key: "single-choice",
            name: "Single choice",
            icon: "check",
          },
          {
            key: "multiple-choice",
            name: "Multiple choice",
            icon: "check-double",
          },
          {
            key: "name",
            disabled: types.includes("name"),
            icon: "user",
          },
          {
            key: "email",
            disabled: types.includes("email"),
            icon: "at",
          },
          {
            key: "age",
            disabled: types.includes("age"),
            icon: "calendar",
          },
          {
            key: "gender",
            disabled: types.includes("gender"),
            icon: "gender",
          },
          {
            key: "phone",
            disabled: types.includes("phone"),
            icon: "phone",
          },
          {
            key: "education",
            disabled: types.includes("education"),
            icon: "education",
          },
          {
            key: "job",
            disabled: types.includes("job"),
            icon: "job",
          },
          {
            key: "availability",
            disabled: types.includes("availability"),
            icon: "clock",
          },
        ],
        size: "176px",
      },
      {
        key: "title",
        placeholder: "Add question...",
      },
    ]
    /* if(form.hasDesc) fields.push({
            key: 'desc',
            name: 'Description',
            placeholder: 'Add description...',
        }); */

    const questionWithError = formQuestions.find(
      (x) =>
        (x.type.endsWith("-choice") && !(x.options && x.options.length)) ||
        (x.type === "availability" &&
          (project.interviewers || []).map((x) => x.availability).flat()
            .length === 0),
    )
    const hasNoEmailField = !formQuestions.some((x) => x.type === "email")

    return (
      <>
        <Route
          path="/projects/:projectId/forms/:formId/questions/:questionId"
          render={(props) => {
            const question = formQuestions.find(
              (x) => x.id === props.match.params.questionId,
            )
            const options = question.options || []
            const backUrl = `/projects/${project.id}/forms/${form.id}`
            const update = (obj) =>
              this.props.update("forms", this.props.match.params.formId, {
                questions: updateObjectInArray(
                  props.match.params.questionId,
                  obj,
                  formQuestions,
                ),
              })
            return (
              <Page
                isPopup
                backTo={backUrl}
                noBar
                width="xl"
                height={500}
                hasPadding
                titleIsSmall
                footerGrid={2}
                footer={
                  <>
                    <Button
                      secondary
                      onClick={() => update({ options: [...options, ""] })}
                    >
                      Add option
                    </Button>
                    <Button full primary to={backUrl}>
                      Done
                    </Button>
                  </>
                }
              >
                <Input
                  className="-mt-5"
                  classNameInput="text-lg font-medium"
                  autosize
                  defaultValue={question.title}
                  onBlur={(title) => update({ title })}
                />
                <p className="mt-3 mb-4 text-gray-500">
                  Add options to this{" "}
                  {question.type === "multiple-choice"
                    ? "multiple choice"
                    : "single choice"}{" "}
                  question below. You can also press tab in the input field to
                  quickly add another option.
                </p>
                <Sheet
                  isNumbered
                  singular="option"
                  plural="options"
                  canReorder
                  data={options}
                  buttons={(x) => (
                    <Button
                      icon="cross"
                      isSmall
                      onClick={(e) =>
                        update({ options: deleteObjectInArray(x.id, options) })
                      }
                    />
                  )}
                  onCreate={() => update({ options: [...options, ""] })}
                  onUpdate={(options) => update({ options })}
                />
              </Page>
            )
          }}
        />
        <div className="p-10 pt-8">
          <div>
            <div className="flex h-10 items-center pb-3">
              <Icon icon="chat" size={14} className="mr-3" />
              <h5>Introduction</h5>
            </div>
            <Input
              hasBorder
              autosize
              defaultValue={form.desc}
              onBlur={(desc) => this.props.update("forms", form.id, { desc })}
            />
            <p className="sub mt-3">
              You can use{" "}
              <Link
                className="link"
                to={`/projects/${project.id}/forms/${form.id}/markdown`}
              >
                some markdown
              </Link>{" "}
              to format the introduction.
            </p>
          </div>
          <div className="mt-10">
            <div>
              <div className="flex h-10 items-center pb-3">
                <Icon icon="form" size={14} className="mr-3" />
                <h5 className="mr-auto">Questions</h5>
              </div>
              <Sheet
                noHead
                isNumbered
                canReorder
                canSort={false}
                singular="question"
                plural="questions"
                buttons={(question) => {
                  if (question.type.includes("-choice")) {
                    const options = question.options || []
                    return (
                      <Button
                        to={`/projects/${project.id}/forms/${form.id}/questions/${question.id}`}
                        isRed={options.length === 0}
                        primary={options.length === 0}
                        isSmall
                      >
                        {options.length} option{options.length === 1 ? "" : "s"}
                      </Button>
                    )
                  }
                  if (question.type === "availability") {
                    const days = getInterviewDays(project)
                    const button = (
                      <Button
                        isRed={days.length === 0}
                        primary={days.length === 0}
                        isSmall
                      >
                        {days.length} day{days.length === 1 ? "" : "s"}
                      </Button>
                    )
                    return (
                      <Dropdown
                        arrowPosition="right"
                        isInfo
                        width={220}
                        button={button}
                      >
                        <h6 className="mb-3">About availability</h6>
                        <p className="text-sm">
                          With the Availability field, respondents can supply
                          the times they are available on the interview day
                          {days.length === 1 ? "" : "s"}, which{" "}
                          {days.length === 1 ? "is" : "are"}:
                        </p>
                        <ul className="mt-3 text-sm">
                          {days.length ? (
                            days.map((day, i) => (
                              <li className={i > 0 ? "mt-2" : ""}>
                                {format("date", day, {
                                  day: "full",
                                  month: "full",
                                  year: "not-current",
                                })}
                              </li>
                            ))
                          ) : (
                            <li className="text-red-500">No availability</li>
                          )}
                        </ul>
                        <Button
                          full
                          secondary
                          className="mt-5"
                          to={`/projects/${project.id}/interviews/settings/interviewers`}
                        >
                          Adjust in Project Settings
                        </Button>
                      </Dropdown>
                    )
                  }
                }}
                fields={fields}
                onCreate={() =>
                  this.props.update("forms", this.props.form.id, {
                    questions: [
                      ...formQuestions,
                      {
                        id: getSemiUniqueKey(),
                        type: "text",
                        title: "",
                      },
                    ],
                  })
                }
                onUpdate={(questions) =>
                  this.props.update("forms", this.props.form.id, { questions })
                }
                data={formQuestions}
              />
              {questionWithError ? (
                <Callout isWarning className="mt-5">
                  The {questionWithError.type} field is missing options. Press
                  the red button to fix it.
                </Callout>
              ) : hasNoEmailField ? (
                <Callout className="mt-5">
                  You are not asking for an email address – this will make it
                  more difficult to contact the respondent later. Responses will
                  also not be automatically linked to interviews.
                </Callout>
              ) : null}
              <Dropdown
                approxHeight={400}
                button={
                  <Button secondary className="mt-5">
                    Add question
                  </Button>
                }
              >
                <DropdownItem
                  onClick={() => this.add("text")}
                  icon="form-input"
                >
                  Text field
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.add("single-choice")}
                  icon="check"
                >
                  Single choice
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.add("multiple-choice")}
                  icon="check-double"
                >
                  Multiple choice
                </DropdownItem>
                <DropdownItem
                  borderTop
                  disabled={types.includes("name")}
                  onClick={() => this.add("name")}
                  icon="user"
                >
                  Name
                </DropdownItem>
                <DropdownItem
                  disabled={types.includes("email")}
                  onClick={() => this.add("email")}
                  icon="at"
                >
                  Email
                </DropdownItem>
                <DropdownItem
                  disabled={types.includes("age")}
                  onClick={() => this.add("age")}
                  icon="calendar"
                >
                  Age
                </DropdownItem>
                <DropdownItem
                  disabled={types.includes("gender")}
                  onClick={() => this.add("gender")}
                  icon="gender"
                >
                  Gender
                </DropdownItem>
                <DropdownItem
                  disabled={types.includes("phone")}
                  onClick={() => this.add("phone")}
                  icon="phone"
                >
                  Phone
                </DropdownItem>
                <DropdownItem
                  disabled={types.includes("education")}
                  onClick={() => this.add("education")}
                  icon="education"
                >
                  Education
                </DropdownItem>
                <DropdownItem
                  disabled={types.includes("job")}
                  onClick={() => this.add("job")}
                  icon="job"
                >
                  Job
                </DropdownItem>
                <DropdownItem
                  disabled={types.includes("availability")}
                  onClick={() => this.add("availability")}
                  icon="clock"
                >
                  Availability
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
          <div className="mt-10">
            <div className="flex h-10 items-center pb-3">
              <Icon icon="like" size={14} className="mr-3" />
              <h5>Confirmation</h5>
            </div>
            <InputGroup
              hasBorder
              doc={form}
              onBlur={(data) =>
                this.props.update("forms", this.props.form.id, data)
              }
              beforeWidth={100}
              fields={[
                {
                  key: "confirmationMessage",
                  before: "Message",
                  placeholder: "We received your application. Thank you!",
                  autosize: true,
                },
                this.props.userId === form.createdBy && {
                  key: "emailCreatorOnSubmit",
                  type: "boolean",
                  before: "Notify me",
                },
              ]}
            />
            {this.props.userId === form.createdBy && (
              <p
                className={`sub tr mt-3 ${
                  form.emailCreatorOnSubmit ? "" : "opacity-0"
                }`}
              >
                You will receive an email when someone responds.
              </p>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withData(withNotify(ProjectFormContent))
