import React from "react"
import PropTypes from "prop-types"
import Icon from "./Icon"

class RadioItem extends React.Component {
  render() {
    return (
      <div
        title={this.props.title}
        onClick={() => this.props.onClick(this.props.value)}
        className={`select-none items-center ${
          this.props.isSmall
            ? `rounded py-2 px-3 hover:bg-${window.appColorBase}-500 hover:text-white active:bg-${window.appColorBase}-600`
            : "py-1"
        } flex ${
          this.props.disabled
            ? "pointer-events-none opacity-75"
            : "cursor-pointer"
        } ${this.props.className || ""}`}
      >
        <div
          className={`${
            this.props.isSmall
              ? "mr-3 h-4"
              : "mr-4 h-8 w-8 rounded-full border p-2"
          } flex flex-none items-center`}
          style={{ width: this.props.isSmall ? 12 : null }}
        >
          {this.props.isActive ? <Icon icon="check" size={12} /> : null}
        </div>
        <div
          className={`cursor-pointer ${
            this.props.isSmall ? "text-sm font-medium" : ""
          } ${this.props.classNameChildren || ""}`}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

RadioItem.propTypes = {
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.any.isRequired,
}

export default RadioItem
