import React from "react"
import {
  DropdownItem,
  DemoBox,
  Callout,
  format,
  Table,
  withData,
  Button,
  Dropdown,
  withNotify,
} from "../shared"
import moment from "moment"

class ProjectFormResponses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRefreshing: false,
    }
  }
  async unlinkAll(responses) {
    const obj = {}
    for (const response of responses) {
      const interview = this.props.interviews.find((x) =>
        (x.responses || []).includes(response.id),
      )
      const interviewResponses = [...interview.responses]
      interviewResponses.splice(interviewResponses.indexOf(response.id), 1)
      obj[interview.id] = { responses: interviewResponses }
    }
    await this.props.updateMultiple("interviews", obj)
    const isOne = responses.length === 1
    this.props.success(
      `Response${isOne ? "" : "s"} unlinked from interview${isOne ? "" : "s"}`,
    )
  }
  async turnAllIntoInterviews(responsesToConvert) {
    const isCreateDisabled =
      this.props.plan === "regular" &&
      responsesToConvert.length +
        this.props.interviews.filter(
          (x) =>
            x.createdBy === this.props.userId &&
            moment(x.createdOn, "X").isSameOrAfter(moment().startOf("month")),
        ).length >
        10
    if (isCreateDisabled)
      return this.props.error(
        "This exceeds 10 interviews this month",
        "Contact us to upgrade your plan.",
      )
    const interviews = this.props.interviews.filter(
      (x) => x.projectId === this.props.match.params.projectId,
    )
    const existing = responsesToConvert.filter(
      (x) => x.email && interviews.some((y) => y.email === x.email),
    )
    const existingIds = existing.map((x) => x.id)
    const notExisting = responsesToConvert.filter(
      (x) => !existingIds.includes(x.id),
    )
    const confirm = await this.props.openModal({
      title: `Turn ${responsesToConvert.length} unlinked response${
        responsesToConvert.length === 1 ? "" : "s"
      } into interview${responsesToConvert.length === 1 ? "" : "s"}?`,
      icon: "arrow-right",
      desc: `This will create ${notExisting.length} new interview${
        notExisting.length === 1 ? "" : "s"
      }${
        existing.length
          ? ` and add a response to ${existing.length} existing interview${
              existing.length === 1 ? "" : "s"
            }`
          : ""
      }. Responses that are already linked will not be changed.`,
      action: "Confirm",
    })
    if (confirm) {
      for (const response of existing) {
        const interview = interviews.find((x) => x.email === response.email)
        await this.props.update("interviews", interview.id, {
          name: interview.name || response.name || null,
          age: interview.age || response.age || null,
          gender: interview.gender || response.gender || null,
          email: interview.email || response.email || null,
          phone: interview.phone || response.phone || null,
          education: interview.education || response.education || null,
          job: interview.job || response.job || null,
          availability:
            interview.availability && interview.availability.length > 0
              ? interview.availability
              : response.availability || null,
          responses: [...(interview.responses || []), response.id],
        })
      }
      const arr = []
      for (const response of notExisting) {
        arr.push({
          name: response.name || null,
          age: response.age || null,
          gender: response.gender || null,
          email: response.email || null,
          phone: response.phone || null,
          education: response.education || null,
          job: response.job || null,
          availability: response.availability || null,
          responses: [response.id],
          projectId: this.props.match.params.projectId,
        })
      }
      if (arr.length) await this.props.createMultiple("interviews", arr)
      this.props.success(
        `Turned ${responsesToConvert.length} response${
          responsesToConvert.length === 1 ? "" : "s"
        } into interview${responsesToConvert.length === 1 ? "" : "s"}`,
      )
    }
  }
  render() {
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const form = this.props.form
    const responses = this.props.responses || []
    const responsesToConvert = responses.filter(
      (response) =>
        !this.props.interviews.some((interview) =>
          (interview.responses || []).includes(response.id),
        ),
    )
    const baseUrl = `/projects/${this.props.match.params.projectId}/forms/${this.props.match.params.formId}/responses`
    return (
      <div className="flex h-full flex-col overflow-hidden">
        {this.props.isDemo && this.props.session.isOnboarded && (
          <DemoBox
            step={3}
            back="/projects/demo-project/forms/demo-form"
            to="/projects/demo-project/interviews"
            isLeft
            primary="Looks like the responses are already coming in!"
            secondary={
              <>
                You can review all respondents here, and turn them into
                interviews with a click. If you want, you can check out the{" "}
                <a
                  target="_blank"
                  className="link"
                  href="//form.interviewkit.co/demo-form"
                  rel="noopener noreferrer"
                >
                  form here
                </a>{" "}
                – everything is customizable.
              </>
            }
          />
        )}
        <div className="px-10 pb-2 pt-6">
          {form.isLive && (
            <Callout isSuccess isPulse>
              Your form is live. Responses show up here in real time.
            </Callout>
          )}
          {!form.isLive && (
            <Callout isWarning isPulse>
              Your form is hidden. Go live to enable new responses.
            </Callout>
          )}
        </div>
        <Table
          singular="response"
          plural="responses"
          hideFields={this.props.hideFields}
          isNumbered
          defaultSortBy="createdOn"
          defaultSortDesc={true}
          showTotals={false}
          desc={(selectedIds) => {
            if (selectedIds.length === 1) {
              const response = responses.find((x) => x.id === selectedIds[0])
              return `Submitted on ${format("date", response.createdOn, {
                day: "full",
                month: "full",
              })} at ${format("time", response.createdOn)}.`
            } else if (selectedIds.length > 1) {
              return null
            }
          }}
          contextMenu={(selectedIds) => {
            if (selectedIds.length === 1) {
              const response = responses.find((x) => x.id === selectedIds[0])
              const interview = this.props.interviews.find((x) =>
                (x.responses || []).includes(response.id),
              )
              if (interview) {
                return (
                  <>
                    <DropdownItem
                      to={`/projects/${interview.projectId}/interviews/${interview.id}`}
                      icon="arrow-right"
                    >
                      Open interview
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.unlinkAll([response])}
                      icon="unlink"
                    >
                      Unlink from interview
                    </DropdownItem>
                  </>
                )
              } else {
                return (
                  <>
                    <DropdownItem
                      icon="arrow-right"
                      onClick={() => this.turnAllIntoInterviews([response])}
                    >
                      Turn into interview
                    </DropdownItem>
                    <DropdownItem
                      to={`${baseUrl}/link`}
                      toData={{ responseId: response.id }}
                      icon="link"
                    >
                      Link to existing interview
                    </DropdownItem>
                  </>
                )
              }
            } else if (selectedIds.length > 1) {
              const hasInterviews = selectedIds.some((id) =>
                this.props.interviews.some((x) =>
                  (x.responses || []).includes(id),
                ),
              )
              const hasNoInterviews = selectedIds.some(
                (id) =>
                  !this.props.interviews.some((x) =>
                    (x.responses || []).includes(id),
                  ),
              )
              const isMixed = hasInterviews && hasNoInterviews
              const selectedResponses = selectedIds.map((id) =>
                responses.find((x) => x.id === id),
              )
              if (!isMixed) {
                if (hasInterviews) {
                  return (
                    <>
                      <DropdownItem
                        onClick={() => this.unlinkAll(selectedResponses)}
                        icon="unlink"
                      >
                        Unlink from interviews
                      </DropdownItem>
                    </>
                  )
                } else if (hasNoInterviews) {
                  return (
                    <>
                      <DropdownItem
                        onClick={() =>
                          this.turnAllIntoInterviews(selectedResponses)
                        }
                        icon="arrow-right"
                      >
                        Turn into interviews
                      </DropdownItem>
                    </>
                  )
                }
              } else {
                return <div />
              }
            }
          }}
          to={`/projects/${project.id}/forms/${form.id}/responses/:id`}
          fields={this.props.fields}
          data={responses}
        />
        <div className="animate-open absolute bottom-5 mx-10 inline-flex items-center rounded-full border bg-white p-1 shadow-sm dark:bg-gray-800">
          <Button
            titleLeft
            className="flex-none"
            disabled={!responsesToConvert.length}
            onClick={() => this.turnAllIntoInterviews(responsesToConvert)}
            title={`Turn ${responsesToConvert.length} unlinked response${
              responsesToConvert.length === 1 ? "" : "s"
            } into interview${responsesToConvert.length === 1 ? "" : "s"}`}
            titleTop
            icon="arrow-right"
          />
          <Dropdown
            width={290}
            arrowPosition="center"
            isInfo
            button={<Button className="flex-none" titleTop icon="help" />}
          >
            <h6>About form responses</h6>
            <p className="mt-3 text-sm">
              Because you might not want to interview everyone that responds to
              your form, interviews and form responses are separated in
              InterviewKit.
            </p>
            <p className="mt-3 text-sm">
              To turn responses into interviews, right-click the ones you want
              to convert and click "Turn into interview". You can also turn all
              responses into interviews by clicking the plus icon on the left.
            </p>
            {form.isLive && (
              <p className="mt-3 text-sm">
                You don't need to refresh – responses will show up here in real
                time.
              </p>
            )}
          </Dropdown>
        </div>
      </div>
    )
  }
}

export default withData(withNotify(ProjectFormResponses))
