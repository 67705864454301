import React from 'react';

class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: false,
        };
    }
    render() {
        return (
            <div onMouseEnter={() => this.setState({isHover: true})} onMouseLeave={() => this.setState({isHover: false})} className={`pl-2 flex items-start ${this.props.title ? 'cursor-help' : ''} ${this.props.className || ''}`} style={this.props.style}>
                <div className={`${this.props.isGreen ? 'bg-green-400 dark:bg-green-600 border-green-200 dark:border-green-800' : (this.props.isGray ? 'bg-gray-400 dark:bg-gray-600 border-gray-200 dark:border-gray-800' : 'bg-red-400 dark:bg-red-600 border-red-200 dark:border-red-800')} w-2 h-2 mt-1.5 flex-none border-2 rounded-full mr-3`} />
                <div className='text-sm'>{this.props.children}</div>
                {(this.props.titleShowAlways || !this.props.children) && this.props.title && this.state.isHover && <div className={`animate-fade-in absolute pointer-events-none bg-gray-400 text-white dark:bg-gray-700 left-7 whitespace-nowrap rounded-lg py-2 px-3 font-medium text-sm`}>{this.props.title}</div>}
            </div>
        );
    }
}

export default Status;