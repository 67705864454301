import React from "react"
import { Icon, Page, toPreview } from "../shared"

function Check(props) {
  return (
    <div
      className={`-mx-3 mt-px flex h-9 items-center rounded-lg px-3 text-sm font-medium`}
    >
      <Icon color="green-500" icon="check" size={12} className="mr-3" />
      <p>{props.children}</p>
    </div>
  )
}

export default function BuyDetails() {
  const gridTemplateColumns = "50px 1fr"
  return (
    <Page
      isPopup
      hasPadding
      backTo={toPreview("sign-up")}
      width="4xl"
      noBar
      noHFull
      classNameChild="grid gap-12 items-start grid-cols-5"
      title="InterviewKit Features"
    >
      <div className="col-span-3">
        <h4>Includes all features</h4>
        <p className="mt-1.5 mb-8 text-gray-500">
          Get access to every feature InterviewKit has to offer, including any
          new features that we'll release in the future.
        </p>
        <div className="grid grid-cols-2">
          <div>
            <h6 className="mb-2">Interviews</h6>
            <Check>Manage interviews</Check>
            <Check>Auto-schedule interviews</Check>
            <Check>Auto transcribe</Check>
            <Check>Upload audio</Check>
            <Check>Upload HD video</Check>
            <Check>Label interviews</Check>
            <Check>Interview analytics</Check>
            <h6 className="mb-2 mt-7">Findings</h6>
            <Check>Manage findings</Check>
            <Check>Label findings</Check>
            <Check>Focus mode</Check>
            <Check>Present findings</Check>
            <h6 className="mt-7 mb-2">Collaboration</h6>
            <Check>Add others to projects</Check>
            <Check>Collaborate in real-time</Check>
          </div>
          <div>
            <h6 className="mb-2">Emails</h6>
            <Check>Send batch emails</Check>
            <Check>Link emails to interviews</Check>
            <Check>Schedule emails</Check>
            <Check>Email templates</Check>
            <Check>Email variables</Check>
            <Check>Delivery updates</Check>
            <h6 className="mb-2 mt-7">Forms</h6>
            <Check>Publish forms</Check>
            <Check>Customize designs</Check>
            <Check>Ask for availability</Check>
            <h6 className="mt-7 mb-2">More</h6>
            <Check>Quick human support</Check>
            <Check>Light & dark mode</Check>
            <Check>Import interviews & findings</Check>
            <Check>Export to CSV</Check>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <h4>Storage & services</h4>
        <p className="mt-1.5 mb-8 text-gray-500">
          Easily scale up later if you need more resources for your projects.
        </p>
        <div className="grid gap-8">
          <div className="flex items-start" style={{ gridTemplateColumns }}>
            <Icon
              size={14}
              icon="video"
              bg="green-100"
              color="green-500"
              colorDark="green-400"
              bgDark="green-900"
            />
            <div className="ml-3">
              <h6>Video</h6>
              <h5 className="my-2">5 hours included</h5>
              <p className="text-sm leading-normal text-gray-500">
                You can add video storage from $0.69 per hour uploaded.
              </p>
            </div>
          </div>
          <div className="flex items-start" style={{ gridTemplateColumns }}>
            <Icon
              size={14}
              icon="audio"
              bg="yellow-100"
              color="yellow-500"
              colorDark="yellow-400"
              bgDark="yellow-900"
            />
            <div className="ml-3">
              <h6>Audio</h6>
              <h5 className="my-2">5 GB included</h5>
              <p className="text-sm leading-normal text-gray-500">
                You can add audio storage from $0.49 per GB uploaded.
              </p>
            </div>
          </div>
          <div className="flex items-start" style={{ gridTemplateColumns }}>
            <Icon
              size={14}
              icon="transcribe"
              bg="orange-100"
              bgDark="orange-900"
              color="orange-500"
              colorDark="orange-400"
            />
            <div className="ml-3">
              <h6>Transcribe</h6>
              <h5 className="my-2">5 hours/mo. included</h5>
              <p className="text-sm leading-normal text-gray-500">
                You can add extra transcription minutes at $0.12 per minute.
              </p>
            </div>
          </div>
          <div className="flex items-start" style={{ gridTemplateColumns }}>
            <Icon
              size={14}
              icon="send"
              bg="blue-100"
              color="blue-500"
              colorDark="blue-400"
              bgDark="blue-900"
            />
            <div className="ml-3">
              <h6>Email</h6>
              <h5 className="my-2">2.500 emails/mo. included</h5>
              <p className="text-sm leading-normal text-gray-500">
                This should cover most use cases, but you can contact us to
                upgrade.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}
