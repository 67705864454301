import { Icon } from "../shared"
import React from "react"

export default function InputPlan({
  plan,
  isPlanOpen,
  setPlan,
  setPlanOpen,
  className,
}) {
  return (
    <div className={`relative select-none ${className || ""}`}>
      <div
        onClick={() => setPlanOpen(true)}
        className="flex cursor-pointer items-center rounded-lg border py-1.5 pl-2 pr-3"
      >
        <div className="mr-2 flex h-7 w-7 items-center justify-center rounded-full border-2 border-white bg-yellow-400 shadow-sm dark:border-gray-900">
          <Icon icon="mic" size={12} color="yellow-800" />
        </div>
        <div className="flex-1">
          <div className="mr-1 truncate">
            <span className="font-medium">InterviewKit</span>{" "}
            {plan === "pro" ? <span className="text-orange-500">Pro</span> : ""}
          </div>
          <div className="text-sm text-gray-500">
            Free for 7 days, then ${plan === "regular" ? 29 : 79}/month
          </div>
        </div>
        <Icon icon="chevron-down" size={10} color="gray-500" className="ml-2" />
      </div>
      {isPlanOpen && (
        <div
          className={`animate-pop absolute z-40 ${
            plan === "pro" ? "-bottom-16" : "-top-2"
          } -inset-x-2 overflow-hidden rounded-xl border bg-white shadow-sm dark:bg-gray-800`}
        >
          <div
            onClick={() => {
              setPlanOpen(false)
              setPlan("regular")
            }}
            className="flex cursor-pointer items-center px-4 py-3"
          >
            <div>
              <div className="text-sm">
                <span className="font-medium">InterviewKit</span>
              </div>
              <div className="mt-0.5 text-sm text-gray-500">
                10 interviews/month
              </div>
            </div>
            <div className="ml-auto text-lg leading-tight">$29</div>
            <div className="ml-0.5 -mb-0.5 text-sm text-gray-500">/month</div>
          </div>
          <div
            onClick={() => {
              setPlanOpen(false)
              setPlan("pro")
            }}
            className="flex cursor-pointer items-center border-t px-4 py-3"
          >
            <div>
              <div className="text-sm">
                <span className="font-medium">InterviewKit</span>{" "}
                <span className="text-orange-500">Pro</span>
              </div>
              <div className="mt-0.5 text-sm text-gray-500">
                Unlimited interviews
              </div>
            </div>
            <div className="ml-auto text-lg leading-tight">$79</div>
            <div className="ml-0.5 -mb-0.5 text-sm text-gray-500">/month</div>
          </div>
          <div
            onClick={() => {
              setPlanOpen(false)
              window.location.href = atob(
                "bWFpbHRvOnN1cHBvcnRAaW50ZXJ2aWV3a2l0LmNv",
              )
            }}
            className="relative flex cursor-pointer items-center border-t px-4 py-3"
          >
            <div>
              <div className="text-sm">
                <span className="font-medium">InterviewKit</span>{" "}
                <span className="text-blue-500 dark:text-blue-400">
                  Enterprise
                </span>
                <span className="ml-1 text-gray-500">↗</span>
              </div>
              <div className="mt-0.5 text-sm text-gray-500">
                Contact us for options
              </div>
            </div>
            <div className="ml-auto  text-lg leading-tight">$159</div>
            <div
              className="absolute text-sm text-gray-500"
              style={{ top: 15, right: 53 }}
            >
              +
            </div>
            <div className="ml-2 -mb-0.5 text-sm text-gray-500">/month</div>
          </div>
        </div>
      )}
    </div>
  )
}
