import React from 'react';
import PropTypes from "prop-types";
import DropdownItem from "../DropdownItem";
import {capitalize, copyToClipboard, formatData} from "../../functions";
import {withData} from "../../data";
import {withRouter} from "react-router-dom";
import {withNotify} from "../../notify";

class DropdownItemCopy extends React.Component {
    constructor(props) {
        super(props);
        this.defaultFormat = this.props.defaultFormat || this.createDefaultFormat();
        const selectedIds = this.props.selectedIds || [];
        this.state = {
            data: this.props.data.filter(x => selectedIds.includes(x.id)), // Turn prop into state to fix it turning empty when closing
        };
    }
    async finalize() {
        const formatKey = `${this.props.plural}CopyFormat`;
        const format = this.props.local[formatKey] == null ? this.defaultFormat : this.props.local[formatKey];
        const result = this.parseResult(format);
        try {
            await copyToClipboard(result, this.props.error);
            this.props.success(`Copied ${this.state.data.length} ${this.state.data.length === 1 ? (this.props.singular || 'item') : (this.props.plural || 'items')} to clipboard`);
        } catch(e) {
            // Error handled by copyToClipboard
        }
    }
    parseResult(format) {
        let strings = [];
        const variables = format.match(/\[(.*?)\]/g);
        for(const dataItem of this.state.data) {
            let str = format;
            if(variables) for(const variable of variables) {
                const varStr = variable.substring(1).substring(0, variable.length - 2).toLowerCase();
                const field = this.props.fields.find(x => x && (x.key.toLowerCase() === varStr || (x.name || '').toLowerCase() === varStr));
                if (!field) continue;
                str = str.split(variable).join(formatData(dataItem, field).val || `no ${varStr}`);
            }
            strings.push(str);
        }
        return strings.join("\n");
    }
    createDefaultFormat() {
        let fields = this.props.fields.filter(x => !x.hidden);
        let defaultFormat = '–';
        for(let i = 0; i < Math.min(3, fields.length); i++) {
            if(i > 0) defaultFormat += ', ';
            defaultFormat += ` [${capitalize(fields[i].name || fields[i].key)}]`;
        }
        return defaultFormat;
    }
    render() {
        return (
            <DropdownItem onClick={this.finalize.bind(this)}>Copy</DropdownItem>
        );
    }
}

DropdownItemCopy.propTypes = {
    plural: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
}

export default withRouter(withData(withNotify(DropdownItemCopy)));