import React from "react"
import PropTypes from "prop-types"
import Icon from "./Icon"

class State extends React.Component {
  render() {
    // If it has children, make it the app color, otherwise keep it gray.
    const hasChildren = this.props.title || this.props.children
    const color = hasChildren ? window.appColor : this.props.color || "gray-300"
    const colorDark = hasChildren
      ? window.appColor
      : this.props.colorDark || "gray-600"
    return (
      <div
        className={`flex h-full items-center justify-center ${this.props.className}`}
      >
        <div
          className="flex w-full flex-col items-center justify-center"
          style={{ maxWidth: this.props.maxWidth }}
        >
          {this.props.img && (
            <img
              alt=""
              src={this.props.img}
              className="mx-auto mb-2 w-full max-w-md"
            />
          )}
          {this.props.icon && !this.props.img ? (
            <Icon
              size={this.props.iconSize}
              icon={this.props.icon}
              color={color}
              colorDark={colorDark}
              bg={this.props.bg}
              bgDark={this.props.bgDark}
            />
          ) : null}
          {this.props.title ? (
            <h4 className="mt-5 mb-3 text-center leading-snug">
              {this.props.title}
            </h4>
          ) : null}
          {this.props.children}
        </div>
      </div>
    )
  }
}

State.propTypes = {
  color: PropTypes.string,
  colorDark: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
}

State.defaultProps = {
  icon: "zap",
}

export default State
