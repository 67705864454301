import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import firebase from "firebase/app"
import 'firebase/functions';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { NotifyProvider, DataProvider, isLocalhost, timestamp } from './shared';
import moment from "moment";
import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

// Sentry.init({
//     dsn: "https://9251a409ae514abf890b765594831ea4@o1141282.ingest.sentry.io/6199327",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 0.5,
// });

let functions;
if (isLocalhost()) {
    // const firebaseConfig = {
    //     apiKey: "-------------------",
    //     authDomain: "--------------",
    //     databaseURL: "https://demo-interviewkit.firebaseio.com",
    //     projectId: "demo-interviewkit",
    //     storageBucket: "---------------------",
    //     messagingSenderId: "--------------",
    //     appId: "----------------------",
    // };
    const firebaseConfig = {
        apiKey: "AIzaSyDmOv-Ulwx-z9BEUCXE0wfOx3aqSU43hlY",
        authDomain: "interviewkit-eu.firebaseapp.com",
        databaseURL: "https://interviewkit-eu.firebaseio.com",
        projectId: "interviewkit-eu",
        storageBucket: "interviewkit-eu.appspot.com",
        messagingSenderId: "354177028352",
        appId: "1:354177028352:web:ec4d812a64eb7f4ed1a3e5"
    };
    firebase.initializeApp(firebaseConfig);
    functions = firebase.app().functions('europe-west2');
    functions.useEmulator("localhost", 5001);
} else {
    const firebaseConfig = {
        apiKey: "AIzaSyDmOv-Ulwx-z9BEUCXE0wfOx3aqSU43hlY",
        authDomain: "interviewkit-eu.firebaseapp.com",
        databaseURL: "https://interviewkit-eu.firebaseio.com",
        projectId: "interviewkit-eu",
        storageBucket: "interviewkit-eu.appspot.com",
        messagingSenderId: "354177028352",
        appId: "1:354177028352:web:ec4d812a64eb7f4ed1a3e5"
    };
    firebase.initializeApp(firebaseConfig);
    functions = firebase.app().functions('europe-west2');
}

const transcriptTimes = [
    0,
    1.5,
    1.7,
    3,
    3.4,
    3.7,
    4.4,
    4.6,
    6.7,
    7.3,
    7.4,
    7.6,
    8.1,
    8.6,
    9.2,
    9.2,
    9.7,
    10.1,
    11.1,
    11.2,
    12,
    12.6,
    12.9,
    13,
    13.1,
    13.5,
    14.2,
    14.2,
    14.3,
    14.7,
    15,
    15,
    16.3,
    17.2,
    17.3,
    17.7,
    18,
    18.5,
    18.8,
    19.1,
    19.7,
    19.9,
    21.2,
    21.8,
    22.5,
    23,
    23.2,
    23.2,
    24,
    26,
    26.9,
    27.4,
    27.8,
    28.1,
    28.4,
    29.2,
    29.3,
    29.8,
    30.1,
    30.1,
    31.8,
    32.2,
    32.6,
    32.7,
    33.1,
    33.5,
    34.2,
    34.5,
    34.7,
    35,
    35.1,
    35.3,
    35.8,
    36.2,
    36.5,
    36.6,
    37.1,
    37.8,
    38.2,
    38.6,
    38.9,
    39.2,
    39.7,
    39.8,
    39.9,
    40.4,
    40.7,
    40.8,
    41.6,
    42.6,
    43,
    43.3,
    43.7,
    44.2,
    44.7,
    45.9,
    46,
    46.1,
    46.4,
    46.6,
    47.8,
    48.3,
    48.6,
    49.4,
    49.8,
    50.7,
    50.7,
    51.1,
    51.7,
    52.6,
    53.1,
    53.6,
    53.7,
    54.4,
    55.2,
    55.4,
    55.6,
    55.9,
    56.5,
    56.6,
    57.5,
    57.6,
    58.2,
    58.7,
    59,
    60.6,
    60.8,
    61.3,
    61.8,
    61.9,
    62.4,
    62.5,
    62.6,
    62.9,
    63.4,
    63.5,
    63.7,
    64,
    64.4,
    64.4,
    64.9,
    65.8,
    66,
    66.2,
    66.6,
    66.9,
    67.7,
    68,
    69.1,
    69.2,
    69.3,
    69.7,
    70.4,
    71.1,
    71.7,
    72.5,
    73.2,
    73.4,
    73.5,
    73.7,
    74.2,
    74.3,
    74.5,
    75,
    75,
    75.4,
    75.4,
    75.9,
    76.2,
    76.5,
    77,
    77.3,
    78.5,
    78.9,
    79.1,
    79.6,
    80.1,
    80.5,
    80.6,
    81.1,
    81.3,
    81.4,
    81.7,
    81.8,
    82
];
const transcript = [
    {
        "text": "Economics of coffee.",
        "entityRanges": [
            {
                "offset": 0,
                "key": 0,
                "length": 9
            }
        ]
    },
    {
        "entityRanges": [],
        "text": "From Wikipedia, the free encyclopedia."
    },
    {
        "entityRanges": [
            {
                "key": 1,
                "length": 22,
                "offset": 61
            },
            {
                "length": 65,
                "key": 2,
                "offset": 131
            }
        ],
        "text": "Coffee is an important commodity in a popular beverage ever. Over 2.25 billion cups of coffee are consumed in the world every day. Over 90% of coffee production takes place in developing countries while consumption happens, mainly in the industrialized economies."
    },
    {
        "text": "Worldwide, 25 million small producers rely on coffee for a living.",
        "entityRanges": [
            {
                "key": 3,
                "offset": 11,
                "length": 26
            }
        ]
    },
    {
        "text": "For instance, in Brazil alone. We're almost a third of all. The world's coffee is produced over 5 million. People are employed in the cultivation and harvesting over of over three billion coffee plants. It is a much more labor-intensive culture than alternative cultures of the same regions as sugar cane or cattle as it is not subject to Automation and requires constant attention. Coffee is also bought and sold as a commodity on the New York Board of Trade. This is where Coffee Futures Contract traded, which are a financial asset involving a standardized contract for the future sale or purchase of a unit of coffee at an agreed price. The world's largest to transfer point for coffee is the port of Hamburg, Germany.",
        "entityRanges": [
            {
                "key": 4,
                "length": 52,
                "offset": 150
            },
            {
                "key": 5,
                "length": 62,
                "offset": 398
            },
            {
                "key": 6,
                "offset": 475,
                "length": 23
            },
            {
                "offset": 519,
                "key": 7,
                "length": 121
            }
        ]
    }
];
const transcriptEntities = {
    "0": {
        "type": "FINDING",
        "id": "cDjNPHnHh70m1SKJRStS"
    },
    "1": {
        "id": "fI2Mh2Og4uGSIyehX0vH",
        "type": "FINDING"
    },
    "2": {
        "type": "FINDING",
        "id": "HpX9RRWcSc1HOg7UHSYG"
    },
    "3": {
        "id": "9FbPXcEE558HPWMrAaxM",
        "type": "FINDING"
    },
    "4": {
        "type": "FINDING",
        "id": "Ts6YsP5nDUxiaYXBP8rW"
    },
    "5": {
        "type": "FINDING",
        "id": "mvoojU6adT8BnvIunrac"
    },
    "6": {
        "type": "FINDING",
        "id": "jACQF8MZLW6b3co9H9O2"
    },
    "7": {
        "type": "FINDING",
        "id": "4f6s7pt0nnAnZ1q8rN2T"
    }
};

const canPlayOgg = () => {
    const audio = document.createElement('audio');
    const res = audio.canPlayType('audio/ogg' || 'ogg');
    audio.remove();
    return res;
}

ReactDOM.render((
    <BrowserRouter>
        <DataProvider
            storageKey='interviewkit'
            // saveLocally={isLocalhost()}
            demoLocal={{ isFindingsAligned: true, interviewsSortBy: 'date', findingsSortBy: 'interviewName', findingsGroupBy: 'labelIds' }}
            demoSession={{ findingsFilterLabelIds: ["label-3", "label-2", "label-1"] }}
            demoData={{
                usersPublic: [{ id: 'demo-user', name: 'Denise Demo', email: 'demo@example.com' }],
                usersPrivate: [{ id: 'demo-user', calendarShowWeekends: true, widgets: ['interviews-upcoming', 'schedule', 'label-with-delayed'] }],
                findings: [
                    {
                        "id": "4f6s7pt0nnAnZ1q8rN2T",
                        "labelIds": [
                            "kumt517p3g38s7h9346"
                        ],
                        "createdOn": 1633966152,
                        "interviewId": "demo-interview-3",
                        "text": "Mentions standardization again",
                        "time": 69.3,
                        "createdBy": "demo-user",
                        "projectId": "demo-project",
                    },
                    {
                        "id": "9FbPXcEE558HPWMrAaxM",
                        "time": 26.9,
                        "interviewId": "demo-interview-3",
                        "projectId": "demo-project",
                        "createdOn": 1633965951,
                        "labelIds": [
                            "kumt517p3g38s7h9346"
                        ],
                        "createdBy": "demo-user"
                    },
                    {
                        "id": "HpX9RRWcSc1HOg7UHSYG",
                        "time": 15,
                        "createdBy": "demo-user",
                        "labelIds": [
                            "kumt46scuqpl61jcrda"
                        ],
                        "projectId": "demo-project",
                        "createdOn": 1633965912,
                        "interviewId": "demo-interview-3",
                    },
                    {
                        "id": "Ts6YsP5nDUxiaYXBP8rW",
                        "interviewId": "demo-interview-3",
                        "createdOn": 1633966134,
                        "projectId": "demo-project",
                        "labelIds": [
                            "kumt46scuqpl61jcrda"
                        ],
                        "createdBy": "demo-user",
                        "time": 40.7
                    },
                    {
                        "id": "fI2Mh2Og4uGSIyehX0vH",
                        "text": "We should definitely mention this to show market size",
                        "time": 11.1,
                        "createdBy": "demo-user",
                        "projectId": "demo-project",
                        "interviewId": "demo-interview-3",
                        "labelIds": [
                            "kumt7addcoihan6pcj7"
                        ],
                        "createdOn": 1633966007
                    },
                    {
                        "id": "mvoojU6adT8BnvIunrac",
                        "projectId": "demo-project",
                        "time": 61.3,
                        "interviewId": "demo-interview-3",
                        "text": "Interesting how this is only in NYC.",
                        "createdBy": "demo-user",
                        "labelIds": [
                            "kumt7addcoihan6pcj7"
                        ],
                        "createdOn": 1633966242
                    },
                    {
                        id: 'demo-finding-1',
                        labelIds: ['label-1', 'label-4'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        interviewId: 'demo-interview-1',
                        isStarred: false,
                        projectId: 'demo-project',
                        text: 'First response: "a bit bitter at first, with a surprisingly sweet aftertaste"',
                    },
                    {
                        id: 'demo-finding-2',
                        labelIds: ['label-1', 'label-5'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        time: 660,
                        interviewId: 'demo-interview-2',
                        isStarred: true,
                        projectId: 'demo-project',
                        text: 'Wonders if these espresso cups fit her coffee machine at home → we should communicate this better',
                    },
                    {
                        id: 'demo-finding-3',
                        labelIds: ['label-1', 'label-5', 'label-6', 'label-pos'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        interviewId: 'demo-interview-6',
                        isStarred: false,
                        projectId: 'demo-project',
                        text: "Loves the packaging because of the doodles on it & it's sustainable",
                        time: 850,
                    },
                    {
                        id: 'demo-finding-4',
                        labelIds: ['label-1', 'label-4', 'label-neg'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        interviewId: 'demo-interview-1',
                        isStarred: false,
                        projectId: 'demo-project',
                        text: `"The aftertaste is pretty heavy. I would only drink this if I slept terrible and need an energy boost."`,
                    },
                    {
                        id: 'demo-finding-5',
                        labelIds: ['label-1', 'label-6'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        time: 315,
                        interviewId: 'demo-interview-2',
                        isStarred: true,
                        projectId: 'demo-project',
                        text: 'Unclear how to recycle the cups – do we pick it up when we deliver their next order?',
                    },
                    {
                        id: 'demo-finding-6',
                        labelIds: ['label-1', 'label-4', 'label-pos'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        interviewId: 'demo-interview-6',
                        isStarred: false,
                        projectId: 'demo-project',
                        text: '"I love it. It tastes just like that one espresso I had in Florence!"',
                        time: 752,
                    },
                    {
                        id: 'demo-finding-7',
                        labelIds: ['label-2', 'label-4', 'label-pos'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        interviewId: 'demo-interview-1',
                        isStarred: false,
                        projectId: 'demo-project',
                        text: '"I like this one better. I could drink three of these on a day."',
                    },
                    {
                        id: 'demo-finding-8',
                        labelIds: ['label-2', 'label-5'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        interviewId: 'demo-interview-2',
                        isStarred: false,
                        projectId: 'demo-project',
                        time: 141,
                        text: 'The caffeine content is unclear to her, maybe put this on the packaging more clearly?',
                    },
                    {
                        id: 'demo-finding-9',
                        labelIds: ['label-2', 'label-4', 'label-pos'],
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        interviewId: 'demo-interview-6',
                        isStarred: true,
                        projectId: 'demo-project',
                        text: `"It's like chocolate... but coffee! This makes me so happy."`,
                        time: 578,
                    },
                ],
                emails: [
                    {
                        id: 'demo-email-1',
                        projectId: 'demo-project',
                        createdBy: 'demo-user',
                        createdOn: moment().startOf('day').subtract(570, 'minutes').unix(),
                        sends: [
                            {
                                id: 'demo-sent-1',
                                status: 'opened',
                                to: { name: 'Emma Example', email: 'emma@example.com' },
                                date: moment().startOf('day').subtract(570, 'minutes').unix(),
                                subject: 'Interview tomorrow at 11:00',
                                message: "Hi Emma, \n\nThank you for registering for our coffee tasting tomorrow.\n\nBased on your availability, I scheduled our interview tomorrow at 11:00. You will receive an email with the link to the video call 15 minutes beforehand.\n\nIf you have any questions, let me know. Talk to you then!\n\n— Denise Demo",
                                isSent: true,
                            },
                            {
                                id: 'demo-sent-2',
                                status: 'opened',
                                to: { name: 'Feline Faux', email: 'f.faux@example.com' },
                                date: moment().startOf('day').subtract(570, 'minutes').unix(),
                                subject: 'Interview tomorrow at 12:30',
                                message: "Hi Feline, \n\nThank you for registering for our coffee tasting tomorrow.\n\nBased on your availability, I scheduled our interview tomorrow at 12:30. You will receive an email with the link to the video call 15 minutes beforehand.\n\nIf you have any questions, let me know. Talk to you then!\n\n— Denise Demo",
                                isSent: true,
                            }
                        ],
                    },
                    {
                        id: 'demo-email-2',
                        projectId: 'demo-project',
                        createdBy: 'demo-user',
                        createdOn: moment().set('hour', 9).set('minute', 30).unix(),
                        sends: [
                            {
                                id: 'demo-sent-1',
                                status: 'opened',
                                to: { name: 'Feline Faux', email: 'f.faux@example.com' },
                                date: moment().set('hour', 9).set('minute', 30).unix(),
                                subject: 'Thank you!',
                                message: "Hi Feline, \n\nI just wanted to thank you for your time today! Your feedback really helped us make our coffee better. As a thank you, you can use the discount code COFFEE25 on our website for $25 off.\n\nEnjoy and thanks again :)\n\n— Denise Demo",
                                isSent: true,
                            },
                        ],
                    },
                    {
                        id: 'demo-email-3',
                        projectId: 'demo-project',
                        createdBy: 'demo-user',
                        createdOn: moment().add(1, 'day').set('hour', 12).set('minute', 45).unix(),
                        isScheduled: true,
                        sends: [
                            {
                                id: 'demo-sent-1',
                                to: { name: 'Sam Specimen', email: 'sam.specimen@example.com' },
                                date: moment().add(1, 'day').set('hour', 12).set('minute', 45).unix(),
                                subject: 'Interview in 15 minutes',
                                message: <>Hi Sam, <br /><br />Our video interview is starting in 15 minutes. When you're ready, you can click this link to start the video call: <span className='link-color'>meet-48f39ks.example.com</span><br /><br />Talk to you in a bit!<br /><br />— Denise Demo</>,
                                isSent: false,
                            },
                        ],
                    }
                ],
                interviews: [
                    {
                        age: 35,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        email: 'timmytester@example.com',
                        gender: 'male',
                        duration: 60,
                        id: 'demo-interview-1',
                        name: 'Timmy Tester',
                        job: 'Mechanic at Cars & Co.',
                        date: timestamp({ time: '14:30' }),
                        projectId: 'demo-project',
                        labelIds: ['label-1'],
                        responses: ['demo-response-1'],
                        availability: [
                            { from: timestamp({ time: '10:00' }), until: timestamp({ time: '12:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '14:00' }), until: timestamp({ time: '17:30' }), id: 'demo-availability-2' },
                            { from: timestamp({ time: '9:00', addDays: 1 }), until: timestamp({ time: '11:00', addDays: 1 }), id: 'demo-availability-3' },
                        ],
                    },
                    {
                        age: 27,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        email: 'f.faux@example.com',
                        gender: 'female',
                        id: 'demo-interview-2',
                        name: 'Feline Faux',
                        job: 'Teacher at Apples & Pears Elementary',
                        date: timestamp({ time: '12:30' }),
                        labelIds: ['label-2'],
                        projectId: 'demo-project',
                        mediaType: 'video',
                        mediaUrl: 'https://upload.wikimedia.org/wikipedia/commons/8/8e/Beware%2C_Hot_Coffee%21_%28Documentaire%29.webm',
                        responses: ['demo-response-2'],
                        availability: [
                            { from: timestamp({ time: '12:00' }), until: timestamp({ time: '14:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '16:00', addDays: 1 }), until: timestamp({ time: '18:00', addDays: 1 }), id: 'demo-availability-2' },
                        ],
                    },
                    {
                        age: 24,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        email: 'emma@example.com',
                        gender: 'female',
                        id: 'demo-interview-3',
                        name: "Emma Example",
                        job: 'Senior Manager at Silly Spoons',
                        labelIds: ['label-1'],
                        mediaType: 'audio',
                        mediaUrl: canPlayOgg() ? 'https://upload.wikimedia.org/wikipedia/commons/8/81/Economics-of-coffee.ogg' : '/assets/demo-audio.mp3',
                        date: timestamp({ time: '11:00' }),
                        projectId: 'demo-project',
                        availability: [
                            { from: timestamp({ time: '11:00' }), until: timestamp({ time: '16:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '14:00', addDays: 1 }), until: timestamp({ time: '15:30', addDays: 1 }), id: 'demo-availability-2' },
                        ],
                        transcript,
                        transcriptEntities,
                        transcriptTimes,
                    },
                    {
                        age: 48,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        email: 'thomast@example.com',
                        gender: 'other',
                        id: 'demo-interview-4',
                        name: "Thomas Trial",
                        job: 'Sommelier at Terrific Tea',
                        labelIds: ['label-3'],
                        date: timestamp({ time: '9:15', addDays: 1 }),
                        projectId: 'demo-project',
                        availability: [
                            { from: timestamp({ time: '14:00' }), until: timestamp({ time: '16:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '9:00', addDays: 1 }), until: timestamp({ time: '11:00', addDays: 1 }), id: 'demo-availability-2' },
                            { from: timestamp({ time: '15:00', addDays: 1 }), until: timestamp({ time: '16:00', addDays: 1 }), id: 'demo-availability-3' },
                        ],
                    },
                    {
                        age: 61,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        duration: 60,
                        email: 'sam.specimen@example.com',
                        gender: 'male',
                        id: 'demo-interview-5',
                        name: "Sam Specimen",
                        job: 'Libarian at Books, Bagels & Beverages',
                        date: timestamp({ time: '13:00', addDays: 1 }),
                        projectId: 'demo-project',
                        availability: [
                            { from: timestamp({ time: '14:00' }), until: timestamp({ time: '16:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '12:00', addDays: 1 }), until: timestamp({ time: '16:00', addDays: 1 }), id: 'demo-availability-2' },
                        ],
                    },
                    {
                        age: 17,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        email: 'bob.blueprint@example.com',
                        gender: 'male',
                        id: 'demo-interview-6',
                        name: "Bob Blueprint",
                        labelIds: ['label-2', 'label-4'],
                        job: 'Intern at The Daily Dupe',
                        date: timestamp({ time: '15:30' }),
                        projectId: 'demo-project',
                        availability: [
                            { from: timestamp({ time: '9:00' }), until: timestamp({ time: '11:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '15:00' }), until: timestamp({ time: '17:00' }), id: 'demo-availability-2' },
                            { from: timestamp({ time: '9:00', addDays: 1 }), until: timestamp({ time: '11:00', addDays: 1 }), id: 'demo-availability-3' },
                            { from: timestamp({ time: '13:00', addDays: 1 }), until: timestamp({ time: '17:00', addDays: 1 }), id: 'demo-availability-4' },
                        ],
                    },
                    {
                        age: 41,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        email: 'isabelle.illustration@example.com',
                        gender: 'female',
                        id: 'demo-interview-7',
                        name: "Isabelle Illustration",
                        labelIds: [],
                        job: 'Musician',
                        date: null,
                        projectId: 'demo-project',
                        availability: [
                            { from: timestamp({ time: '10:30' }), until: timestamp({ time: '12:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '15:00' }), until: timestamp({ time: '17:15' }), id: 'demo-availability-2' },
                            { from: timestamp({ time: '10:00', addDays: 1 }), until: timestamp({ time: '14:00', addDays: 1 }), id: 'demo-availability-3' },
                            { from: timestamp({ time: '15:00', addDays: 1 }), until: timestamp({ time: '16:30', addDays: 1 }), id: 'demo-availability-4' },
                        ],
                    },
                    {
                        age: 23,
                        createdBy: 'demo-user',
                        createdOn: timestamp({ isRandRecent: true }),
                        email: 'mike.model@example.com',
                        gender: 'male',
                        id: 'demo-interview-8',
                        name: "Mike Model",
                        labelIds: [],
                        job: 'Editor at the Monthly Magazine',
                        date: null,
                        projectId: 'demo-project',
                        availability: [
                            { from: timestamp({ time: '14:00', addDays: 1 }), until: timestamp({ time: '15:00', addDays: 1 }), id: 'demo-availability-1' },
                        ],
                    },
                ],
                forms: [
                    {
                        id: "demo-form",
                        hideFields: ["other-0", "other-1", "job", "gender", "email", "education", "phone"],
                        emailCreatorOnSubmit: true,
                        createdOn: moment().subtract(1, 'days').set('hours', 9).set('minutes', 54).unix(),
                        duration: 45,
                        timeFormat: "H:mm",
                        questions: [
                            { title: "What is your name?", desc: "", type: "name", id: "1592220405592-5921" },
                            { type: "email", title: "Wat is your email address?", desc: "", id: "1592220406615-25929" },
                            { title: "How old are you?", type: "age", id: "1592220407525-78868" },
                            { type: "phone", title: "What is your phone number?", id: "1592220409332-44978" },
                            {
                                title: "How often do you drink coffee?",
                                options: ['Less than daily', 'About 1 cup a day', 'About 2 cups a day', '3 or more cups a day'],
                                id: "1592220411356-28043",
                                type: "single-choice"
                            },
                            {
                                title: "What types of coffee do you drink?",
                                options: ['Espresso', 'Doppio', 'Lungo', 'Americano', 'Cappuccino', 'Latte Macchiato'],
                                id: "1592220411356-28044",
                                type: "multiple-choice"
                            },
                            { type: "availability", title: "When are you available?", id: "1592220427190-43810" }
                        ],
                        dateFormat: "D M Y",
                        image: 'https://images.unsplash.com/photo-1500353391678-d7b57979d6d2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=60',
                        projectId: "demo-project",
                        confirmationMessage: "We have received your response!",
                        createdBy: "demo-user",
                        accentColor: "yellow",
                        isLive: true,
                        desc: "We have produced some new coffees and can't wait to share them with you! ☕️\n\nIn a 30-minute coffee tasting, we will ask you to taste our different types of coffee and rate them on a few characteristics.\n\nIf you'd like to participate, please fill in this short form. We will get back to you within 24 hours to confirm the date and time of your tasting.\n\nThanks in advance!\n\n**Denise Demo**\n*Barista at Lungo Forte Inc.*",
                        title: "Taste our coffees!",
                        dates: [
                            moment().unix(),
                            moment().add(1, 'day').unix(),
                        ],
                        hasDesc: false,
                    }
                ],
                formResponses: [
                    {
                        id: "demo-response-1",
                        email: "timmytester@example.com",
                        gender: "male",
                        formId: "demo-form",
                        questions: [
                            {
                                answer: 'About 2 cups a day',
                                title: "How often do you drink coffee?",
                                id: "1592220411356-28043",
                                type: "single-choice",
                                options: ['Less than daily', 'About 1 cup a day', 'About 2 cups a day', '3 or more cups a day'],
                            },
                            {
                                title: "What types of coffee do you drink?",
                                answer: ['Americano'],
                                id: "1592220411356-28044",
                                type: "multiple-choice",
                                options: ['Espresso', 'Doppio', 'Lungo', 'Americano', 'Cappuccino', 'Latte Macchiato'],
                            },
                        ],
                        name: "Timmy Tester",
                        createdOn: moment().subtract(1, 'day').set('hours', 10).set('minutes', 15).unix(),
                        age: 35,
                        availability: [
                            { from: timestamp({ time: '10:00' }), until: timestamp({ time: '12:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '14:00' }), until: timestamp({ time: '17:30' }), id: 'demo-availability-2' },
                            { from: timestamp({ time: '9:00', addDays: 1 }), until: timestamp({ time: '11:00', addDays: 1 }), id: 'demo-availability-3' },
                        ],
                        projectId: "demo-project",
                        phone: "31612345678"
                    },
                    {
                        id: "demo-response-2",
                        email: "f.faux@example.com",
                        gender: "female",
                        formId: "demo-form",
                        questions: [
                            {
                                answer: 'About 1 cup a day',
                                title: "How often do you drink coffee?",
                                id: "1592220411356-28043",
                                type: "single-choice",
                                options: ['Less than daily', 'About 1 cup a day', 'About 2 cups a day', '3 or more cups a day'],
                            },
                            {
                                title: "What types of coffee do you drink?",
                                answer: ['Cappuccino'],
                                id: "1592220411356-28044",
                                type: "multiple-choice",
                                options: ['Espresso', 'Doppio', 'Lungo', 'Americano', 'Cappuccino', 'Latte Macchiato'],
                            },
                        ],
                        name: "Feline Faux",
                        createdOn: moment().subtract(1, 'day').set('hours', 13).set('minutes', 42).unix(),
                        age: 27,
                        availability: [
                            { from: timestamp({ time: '12:00' }), until: timestamp({ time: '14:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '16:00', addDays: 1 }), until: timestamp({ time: '18:00', addDays: 1 }), id: 'demo-availability-2' },
                        ],
                        projectId: "demo-project",
                        phone: "31612345678"
                    },
                    {
                        id: "demo-response-3",
                        email: "samantha.sim@example.com",
                        gender: "female",
                        formId: "demo-form",
                        questions: [
                            {
                                answer: 'Less than daily',
                                title: "How often do you drink coffee?",
                                id: "1592220411356-28043",
                                type: "single-choice",
                                options: ['Less than daily', 'About 1 cup a day', 'About 2 cups a day', '3 or more cups a day'],
                            },
                            {
                                answer: ['Lungo'],
                                title: "What types of coffee do you drink?",
                                id: "1592220411356-28044",
                                type: "multiple-choice",
                                options: ['Espresso', 'Doppio', 'Lungo', 'Americano', 'Cappuccino', 'Latte Macchiato'],
                            },
                        ],
                        name: "Samantha Sim",
                        createdOn: moment().subtract(1, 'day').set('hours', 12).set('minutes', 18).unix(),
                        age: 41,
                        availability: [
                            { from: timestamp({ time: '9:00' }), until: timestamp({ time: '12:00' }), id: 'demo-availability-1' },
                            { from: timestamp({ time: '14:00' }), until: timestamp({ time: '18:00' }), id: 'demo-availability-2' },
                            { from: timestamp({ time: '10:00', addDays: 1 }), until: timestamp({ time: '12:00', addDays: 1 }), id: 'demo-availability-3' },
                        ],
                        projectId: "demo-project",
                        phone: "31612345678"
                    },
                ],
                projects: [{
                    createdBy: 'demo-user',
                    memberIds: ['demo-user'],
                    emailTemplates: [
                        { id: 'demo-template', subject: 'Interview at [dateshort]', message: "Hi [firstname],\n\nThank you for applying to our coffee tasting! Based on your availability, I scheduled you on [date] at [time]. \n\nIf you have any questions, feel free to reply. See you then!\n\nDemo Denise\nResearcher, Lungo Forte" },
                    ],
                    interviewers: [
                        {
                            name: 'Iva Interviewer',
                            availability: [
                                { from: timestamp({ time: '9:00' }), until: timestamp({ time: '18:00' }), id: 'demo-availability-1' },
                                { from: timestamp({ time: '9:00', addDays: 1 }), until: timestamp({ time: '18:00', addDays: 1 }), id: 'demo-availability-2' },
                            ],
                        }
                    ],
                    id: 'demo-project',
                    createdOn: timestamp({ isRandRecent: true }),
                    findingLabels: [
                        { id: 'label-1', name: 'Espresso tasting' },
                        { id: 'label-2', name: 'Lungo tasting' },
                        { id: 'label-3', name: 'Cappuccino tasting' },
                        { id: 'label-4', name: 'Taste', color: 'pink' },
                        { id: 'label-5', name: 'Packaging', color: 'yellow' },
                        { id: 'label-6', name: 'Environment', color: 'sky' },
                        { id: 'label-pos', name: 'Positive', color: 'green' },
                        { id: 'label-neg', name: 'Negative', color: 'red' },
                        {
                            "id": "kumt46scuqpl61jcrda",
                            "name": "Production",
                            "color": "amber"
                        },
                        {
                            "name": "Economics",
                            "id": "kumt517p3g38s7h9346",
                            "color": "sky"
                        },
                        {
                            "id": "kumt7addcoihan6pcj7",
                            "color": "lime",
                            "name": "Consumption"
                        }
                    ],
                    findingsPinStarred: true,
                    interviewLabels: [
                        { color: 'sky', id: 'label-1', name: 'In person' },
                        { color: 'yellow', id: 'label-2', name: 'Video call' },
                        { color: 'pink', id: 'label-3', name: 'Audio call' },
                        { color: 'orange', id: 'label-4', name: 'Delayed' },
                    ],
                    name: 'Coffee Tasting',
                    duration: 45,
                }],
            }}
            pushEditsInCollections={['interviews', 'findings', 'emails', 'forms', 'formResponses', 'projects']}
            needsData={(userId, projectIds) => {
                const needsData = [];
                // Get index
                needsData.push({ collection: 'index', id: 'public' });
                // Get personal data
                needsData.push({ collection: 'usersPublic', id: userId });
                needsData.push({ collection: 'usersPrivate', id: userId });
                // Get projects
                if (projectIds.length) {
                    needsData.push({ collection: 'projects', ids: projectIds });
                    needsData.push({ collection: 'interviews', where: ['projectId', 'in', projectIds] });
                    needsData.push({ collection: 'emails', where: ['projectId', 'in', projectIds] });
                    needsData.push({ collection: 'findings', where: ['projectId', 'in', projectIds] });
                    needsData.push({ collection: 'forms', where: ['projectId', 'in', projectIds] });
                    needsData.push({ collection: 'formResponses', where: ['projectId', 'in', projectIds] });
                }
                return needsData;
            }}
        >
            <NotifyProvider defaultError='Please try again or contact us via support@interviewkit.co.'>
                <App />
            </NotifyProvider>
        </DataProvider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { functions };