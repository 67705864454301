import React from "react"
import {
  Button,
  deleteObjectInArray,
  getSemiUniqueKey,
  Page,
  Sheet,
  withData,
  withNotify,
} from "../shared"

class ProjectFindingsLabels extends React.Component {
  addLabel() {
    this.props.addToArray(
      "projects",
      this.props.match.params.projectId,
      "findingLabels",
      {
        id: getSemiUniqueKey(),
        name: "",
      },
    )
  }
  render() {
    const project = (this.props.projects || []).find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const labels = project.findingLabels || []

    return (
      <Page
        title="Edit labels"
        isPopup
        icon="label"
        backTo={this.props.backTo}
        hasPadding
        style={{ minHeight: "min(560px, 90vh)" }}
        width="md"
        footerGrid={2}
        footer={
          <>
            <Button secondary onClick={this.addLabel.bind(this)}>
              Add label
            </Button>
            <Button primary to={this.props.backTo}>
              Done
            </Button>
          </>
        }
      >
        <p className="mb-5 text-gray-500">
          Use labels to organize and code findings. You can add labels for
          topics, audience types, or whatever works for your project.
        </p>
        <Sheet
          noHead
          canReorder
          fields={[
            {
              key: "color",
              type: "color",
              size: "auto",
              noWeights: true,
              hasAll: true,
            },
            {
              key: "name",
              placeholder: "New label",
            },
          ]}
          onCreate={this.addLabel.bind(this)} // add row to array
          onUpdate={(findingLabels) =>
            this.props.update("projects", project.id, { findingLabels })
          }
          buttons={(label) => (
            <Button
              icon="cross"
              isSmall
              onClick={(e) =>
                this.props.update("projects", project.id, {
                  findingLabels: deleteObjectInArray(label.id, labels),
                })
              }
            />
          )}
          data={labels}
        />
      </Page>
    )
  }
}

export default withData(withNotify(ProjectFindingsLabels))
