import React from 'react';
import InputGroup from "../InputGroup";
import moment from "moment";
import PropTypes from 'prop-types';

class DropdownDateFormat extends React.Component {
    render() {
        const m = moment();
        return (
            <InputGroup
                hideBefore
                noPadding
                canClear={false}
                className={`p ${this.props.className || ''}`}
                onBlur={this.props.onBlur}
                doc={this.props.doc}
                fields={[
                    {key: 'dateLocale', type: 'dropdown', defaultValue: 'en', options: [
                        {key: 'en', name: 'English'},
                        {key: 'es', name: 'Spanish'},
                        {key: 'fr', name: 'French'},
                        {key: 'nl', name: 'Dutch'},
                        {key: 'de', name: 'German'},
                    ]},
                    {key: 'dateFormat', type: 'dropdown', defaultValue: window.appDateFormat || 'D M Y', options: [
                        {key: 'D M Y', name: m.format('D MMMM YYYY')},
                        {key: 'M D, Y', name: m.format('MMMM D, YYYY')},
                    ]},
                    {key: 'timeFormat', type: 'dropdown', defaultValue: window.appTimeFormat || 'H:mm', options: [
                        {key: 'H:mm', name: '17:00'},
                        {key: 'h:mm a', name: '5:00 pm'},
                    ]},
                ]}
            />
        );
    }
}

DropdownDateFormat.propTypes = {
    doc: PropTypes.object.isRequired,
    onBlur: PropTypes.func.isRequired,
};

export default DropdownDateFormat;