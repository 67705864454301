import React from "react"
import Button from "../Button"
import Input from "../Input"
import Page from "../Page"
import PropTypes from "prop-types"
import { withData } from "../../data"
import { withRouter } from "react-router-dom"
import { capitalize, copyToClipboard, formatData } from "../../functions"
import { withNotify } from "../../notify"
import Dropdown from "../Dropdown"
import DropdownItem from "../DropdownItem"

class PopoverCopyAs extends React.Component {
  constructor(props) {
    super(props)
    this.defaultFormat = this.props.defaultFormat || this.createDefaultFormat()
    const selectedIds =
      (this.props.location.state
        ? this.props.location.state.selectedIds
        : false) || []
    this.state = {
      data: this.props.data.filter((x) => selectedIds.includes(x.id)), // Turn prop into state to fix it turning empty when closing
    }
  }
  createDefaultFormat() {
    let fields = this.props.fields.filter((x) => !x.hidden)
    let defaultFormat = ""
    for (let i = 0; i < Math.min(3, fields.length); i++) {
      if (i > 0) defaultFormat += ", "
      defaultFormat += `[${capitalize(fields[i].name || fields[i].key)}]`
    }
    return defaultFormat
  }
  async finalize(result) {
    try {
      await copyToClipboard(result, this.props.error)
      this.props.history.push(this.props.backTo)
      this.props.success(
        `Copied ${this.state.data.length} ${this.props.plural || "items"
        } to clipboard`,
      )
    } catch (e) {
      // Error handled by copyToClipboard
    }
  }
  parseResult(format) {
    let strings = []
    const variables = format.match(/\[(.*?)\]/g)
    for (const dataItem of this.state.data) {
      let str = format
      if (variables)
        for (const variable of variables) {
          const varStr = variable
            .substring(1)
            .substring(0, variable.length - 2)
            .toLowerCase()
          const field = this.props.fields.find(
            (x) =>
              x &&
              (x.key.toLowerCase() === varStr ||
                (x.name || "").toLowerCase() === varStr),
          )
          if (!field) continue
          str = str
            .split(variable)
            .join(formatData(dataItem, field).val || `no ${varStr}`)
        }
      strings.push(str)
    }
    return {
      html: strings.map((x, i) => (
        <div key={`line-${i}`} className="mb-2">
          {x}
        </div>
      )),
      text: strings.join("\n"),
    }
  }
  render() {
    const singular = this.props.singular || "item"
    const plural = this.props.plural || "items"
    let fields = this.props.fields.filter((x) => x && !x.hidden && !x.hideInVarList)
    const data = this.state.data
    if (!data || data.length === 0) this.props.history.push(this.props.backTo)
    const formatKey = `${this.props.plural}CopyFormat`
    const format =
      this.props.local[formatKey] == null
        ? this.defaultFormat
        : this.props.local[formatKey]
    const result = this.parseResult(format)
    return (
      <Page
        isPopup
        backTo={this.props.backTo}
        title={`Copy ${data.length} ${data.length === 1 ? singular : plural
          } as...`}
        position={this.props.position}
        offset={this.props.offset}
        width="xl"
        hasPadding
        footerGrid={2}
        footer={
          <>
            <Button
              shortcut="Enter"
              disabled={!format}
              onClick={() => this.finalize(result.text)}
              primary
            >
              Copy
            </Button>
            <Button to={this.props.backTo} secondary>
              Cancel
            </Button>
          </>
        }
      >
        <div className="flex h-full flex-col">
          <h6 className="mb-1">Text format</h6>
          <div className="flex">
            <Input
              autosize
              className="mr-5 flex-1"
              value={format}
              onChange={(format) =>
                this.props.setLocal({ [formatKey]: format })
              }
            />
            <Dropdown button={<Button title="Variables" icon="add" />}>
              <h6 className="pf">Insert variable</h6>
              {fields.map((field) => {
                const name = capitalize(field.name || field.key)
                const hasSpace = !format.endsWith("(") && !format.endsWith(" ")
                return (
                  <DropdownItem
                    key={field.key}
                    onClick={() => {
                      const newFormat =
                        format + `${hasSpace ? " " : ""}[${name}]`
                      this.props.setLocal({ [formatKey]: newFormat })
                    }}
                  >
                    {name}
                  </DropdownItem>
                )
              })}
            </Dropdown>
            <Button
              title="Reset"
              icon="arrow-left"
              onClick={() =>
                this.props.setLocal({ [formatKey]: this.defaultFormat })
              }
            />
          </div>
          <h6 className="mb-3 mt-6">Preview</h6>
          <div
            className="flex-1 overflow-scroll rounded-lg bg-gray-100 p-4 pb-2 pt-3 leading-snug dark:bg-gray-700"
            style={{ maxHeight: 300 }}
          >
            {result.html}
          </div>
        </div>
      </Page>
    )
  }
}

PopoverCopyAs.propTypes = {
  singular: PropTypes.string.isRequired,
  plural: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

PopoverCopyAs.defaultProps = {
  position: "center",
}

export default withData(withRouter(withNotify(PopoverCopyAs)))
