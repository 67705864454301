import React from "react"
import {
  Button,
  DemoBox,
  DropdownItemCopy,
  DropdownItemInvite,
  DropdownItem,
  Grid,
  Labels,
  Input,
  ColorPicker,
  getSemiUniqueKey,
  Icon,
  Page,
  withData,
  withNotify,
  toLowerWithDashes,
  Checkbox,
  PopoverInvite,
  PopoverView,
  PopoverCopyAs,
  State,
  downloadFile,
  ButtonInvite,
} from "../shared"
import { Route, Switch } from "react-router-dom"
import {
  getFindingFields,
  getQuoteFromTranscript,
  sortFindings,
  getInterviewUpdateObjOnFindingDelete,
} from "../functions"
import ProjectFinding from "./ProjectFinding"
import ProjectFindingsLabels from "./ProjectFindingsLabels"
import ProjectFindingsPresent from "./ProjectFindingsPresent"
import { functions } from "../index"
import ProjectSettings from "./ProjectSettings"

class ProjectFindings extends React.Component {
  exportToCSV() {
    const esc = (str) => {
      if (typeof str === "string") {
        return str?.split(";").join(",") || ""
      } else {
        return str || ""
      }
    }
    const project =
      (this.props.projects || []).find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}

    let csvContent = "data:text/csv;charset=utf-8,"
    // Add head
    csvContent += "Finding;Quote in transcript;Starred;Interviewee;Labels\n"
    csvContent += this.props.findings
      .filter((x) => x.projectId === this.props.match.params.projectId)
      .map((x) => {
        const interview =
          this.props.interviews.find((y) => y.id === x.interviewId) || {}
        const sentence = getQuoteFromTranscript(
          interview.transcript,
          interview.transcriptEntities,
          x,
        )
        const labels = x.labelIds
          ? x.labelIds
              .map((labelId) =>
                project.findingLabels.find((x) => x.id === labelId),
              )
              .filter((x) => x)
              .map((x) => esc(x.name))
              .join(", ")
          : ""
        return `${esc(x.text)};${esc(sentence)};${
          x.isStarred ? "Yes" : ""
        };${esc(interview.name)};${labels}`
      })
      .join("\n")
    downloadFile(
      `findings-${toLowerWithDashes(project.name || "untitled")}.csv`,
      csvContent,
    )
  }
  render() {
    const project =
      (this.props.projects || []).find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}
    const interviews = (this.props.interviews || [])
      .filter((x) => x.projectId === this.props.match.params.projectId)
      .map((x) => ({ ...x, duration: x.duration || project.duration || 60 }))
    const existingFilters = (
      this.props.session.findingsFilterLabelIds || []
    ).filter((id) => (project.findingLabels || []).some((y) => y.id === id)) // Filter out any labels that doesn't exist anymore.
    let findings = (this.props.findings || [])
      .filter((x) => {
        if (x.projectId !== this.props.match.params.projectId) return false
        return (
          !existingFilters.length ||
          (x.labelIds || []).some((id) => existingFilters.includes(id))
        )
      })
      .sort((a, b) => a.createdOn - b.createdOn)
      .map((finding) => ({
        ...finding,
        labelIds:
          finding.labelIds
            ?.filter((labelId) =>
              project.findingLabels?.some((label) => label.id === labelId),
            )
            .sort((a, b) => {
              // Sort labelIds of each finding by order of project.findingLabels so that grouping goes right.
              const catIndexA = project.findingLabels.findIndex(
                (x) => x.id === a,
              )
              const catIndexB = project.findingLabels.findIndex(
                (x) => x.id === b,
              )
              if (catIndexA < catIndexB) return -1
              if (catIndexA > catIndexB) return 1
              return 0
            }) || null,
      }))
    const isFiltered = existingFilters.length > 0
    const labels = project.findingLabels || []

    let presentFindings = [...findings]
    if (this.props.session.presentOnlyKey)
      presentFindings = presentFindings.filter((x) => x.isStarred)
    if (this.props.session.presentLabel === "none")
      presentFindings = presentFindings.filter(
        (x) => !x.labelIds || !x.labelIds.length,
      )
    else if (
      this.props.session.presentLabel &&
      this.props.session.presentLabel !== "all"
    )
      presentFindings = presentFindings.filter((x) =>
        (x.labelIds || []).includes(this.props.session.presentLabel),
      )

    const fields = getFindingFields(interviews, labels)

    const groupBy = this.props.local.findingsGroupBy || false
    const sortBy = this.props.local.findingsSortBy || "createdOn"
    const sortDesc = this.props.local.findingsSortDesc || false
    const pinStarred = this.props.local.isFindingsStarredPinned

    sortFindings(
      findings,
      interviews,
      project,
      groupBy,
      sortBy,
      sortDesc,
      pinStarred,
    )

    // Note: we sort presentFindings here, not findings, in preparation for the presentation.
    sortFindings(
      presentFindings,
      interviews,
      project,
      "labelIds",
      "time",
      false,
      true,
    )

    const isFinding =
      this.props.match.params.findingId &&
      !["invite", "present", "settings", "labels", "copy-as", "view"].includes(
        this.props.match.params.findingId,
      )
    const baseUrl = `/projects/${project.id}/findings${
      isFinding ? `/${this.props.match.params.findingId}` : ""
    }`
    const isEmpty = findings.length === 0

    const settingFields = [
      {
        key: "presentNames",
        type: "dropdown",
        defaultValue: "first",
        hideBefore: "true",
        options: [
          { key: "full", name: "Show full names" },
          { key: "first", name: "Only first names" },
          { key: "none", name: "Hide names" },
        ],
        canClear: false,
      },
    ]
    let hasLabels = [
      ...new Set(
        presentFindings
          .map((x) => {
            return x?.labelIds?.filter((x) =>
              project.findingLabels?.includes(x),
            )[0]
          })
          .flat(),
      ),
    ].filter((x) => x).length
    if (
      hasLabels === 1 &&
      !presentFindings.some((x) => {
        if (!x?.labelIds) return false
        const existingLabels = x.labelIds.filter((x) =>
          project.findingLabels?.includes(x),
        )
        return existingLabels.length === 0
      })
    )
      hasLabels = false
    if (this.props.session.presentLabel === "all" && hasLabels)
      settingFields.push({
        key: "presentChapters",
        type: "boolean",
        defaultValue: true,
        before: "Label chapters",
      })

    return (
      <Page
        title={`${findings.length} finding${findings.length === 1 ? "" : "s"}`}
        isFlexCol
        searchDisabled={isEmpty}
        required={{ project }}
        moreWidth={200}
        filteredTo={isFiltered && `${baseUrl}/view`}
        onFilterClear={() =>
          this.props.setSession({ findingsFilterLabelIds: [] })
        }
        barRight={
          <>
            <ButtonInvite
              project={project}
              to={`/projects/${project.id}/findings/invite`}
            />
          </>
        }
        more={
          <>
            <DropdownItem
              icon="layers"
              to={`/projects/${project.id}/findings/view`}
            >
              View
            </DropdownItem>
            <DropdownItem icon="label" to={`${baseUrl}/labels`}>
              Edit labels
            </DropdownItem>
            <DropdownItem
              icon="video"
              to={`/projects/${project.id}/findings/present`}
            >
              Present
            </DropdownItem>
            <DropdownItem
              borderTop
              icon="download"
              disabled={this.props.isDemo}
              onClick={this.exportToCSV.bind(this)}
            >
              Export findings (CSV)
            </DropdownItem>
            <DropdownItemInvite
              borderTop
              project={project}
              to={`/projects/${project.id}/findings/invite`}
            />
            <DropdownItem
              disabled={this.props.isDemo}
              icon="settings"
              to={`${baseUrl}/settings`}
            >
              Project settings
            </DropdownItem>
          </>
        }
      >
        {this.props.isDemo &&
          this.props.session.isOnboarded &&
          !this.props.location.pathname.includes("present") && (
            <DemoBox
              step={8}
              to="/projects/demo-project/findings/present"
              back="/projects/demo-project/interviews/demo-interview-3/findings"
              primary="Then, we can see all findings here."
              secondary="Labels, stars and groups help you organize your results – for now, and for future reference. They are linked to their audio or video, so you can watch each finding back."
            />
          )}
        <Switch>
          <Route
            path="/projects/:projectId/findings/invite"
            render={(props) => (
              <PopoverInvite
                {...props}
                backTo={baseUrl}
                project={project}
                inviteFunc={functions.httpsCallable("inviteMember")}
                revokeFunc={functions.httpsCallable("revokeMember")}
              />
            )}
          />
          <Route
            path="/projects/:projectId/findings/present"
            render={(props) => (
              <ProjectFindingsPresent
                {...props}
                backTo={baseUrl}
                interviews={interviews}
                allLabels={labels}
                presentNames={"first"}
                hasChapters={!!hasLabels}
                project={project}
                findings={presentFindings}
              />
            )}
          />
          <Route
            path="/projects/:projectId/findings/settings"
            render={(props) => (
              <ProjectSettings {...props} project={project} backTo={baseUrl} />
            )}
          />
          <Route path="/projects/:projectId/findings/labels" />
          <Route path="/projects/:projectId/findings/copy-as" />
          <Route
            path="/projects/:projectId/findings/view"
            render={(props) => (
              <PopoverView
                {...props}
                backTo={baseUrl}
                onSubmit={(x) => {
                  this.props.setLocal({
                    isFindingsStarredPinned: x.gridPinStarred,
                    isFindingsAligned: x.gridAlign,
                    findingsGroupBy: x.groupBy,
                    findingsSortBy: x.gridSortBy,
                    findingsSortDesc: x.gridSortDesc,
                  })
                  this.props.setSession({
                    findingsFilterLabelIds: x.filterLabelIds,
                  })
                }}
                options={{
                  view: { value: "grid" },
                  filterLabels: {
                    value: existingFilters,
                    options: project.findingLabels,
                  },
                  gridPinStarred: {
                    name: "Pin key findings",
                    value: this.props.local.isFindingsStarredPinned,
                  },
                  gridAlign: {
                    value: this.props.local.isFindingsAligned !== false,
                  },
                  groupBy: {
                    value: groupBy,
                    options: [
                      { value: false, name: "None" },
                      project.findingLabels &&
                        project.findingLabels.length && {
                          value: "labelIds",
                          name: "Label",
                        },
                      { value: "interviewName", name: "Interviewee" },
                    ],
                  },
                  gridSortBy: {
                    value: sortBy,
                    options: [
                      { value: "createdOn", name: "Date created" },
                      { value: "time", name: "Time in interview" },
                      { value: "interviewName", name: "Interviewee" },
                    ],
                  },
                  gridSortDesc: sortDesc,
                }}
              />
            )}
          />
          <Route
            path="/projects/:projectId/findings/:findingId"
            component={ProjectFinding}
          />
        </Switch>
        <Route
          path="/projects/:projectId/findings/:findingId?/copy-as"
          exact
          render={(props) => (
            <PopoverCopyAs
              backTo={baseUrl}
              defaultFormat="[Finding] — [Name]"
              {...props}
              fields={fields}
              data={findings}
              singular="finding"
              plural="findings"
            />
          )}
        />
        <Route
          path="/projects/:projectId/findings/:findingId?/labels"
          exact
          render={(props) => (
            <ProjectFindingsLabels backTo={baseUrl} {...props} />
          )}
        />

        {!isEmpty ? (
          <Grid
            minWidth={290}
            singular="finding"
            plural="findings"
            groupBy={groupBy}
            keyRows={["interviewNameAge"]}
            contextMenu={(selectedIds) => {
              if (selectedIds.length === 1) {
                const finding =
                  findings.find((x) => x.id === selectedIds[0]) || {}
                return (
                  <>
                    <DropdownItemCopy
                      defaultFormat="[Finding] — [Name]"
                      singular="finding"
                      plural="findings"
                      fields={fields}
                      data={findings}
                      selectedIds={selectedIds}
                    />
                    <DropdownItem
                      to={`${baseUrl}/copy-as`}
                      toData={{ selectedIds }}
                    >
                      Copy as
                    </DropdownItem>
                    <DropdownItem
                      borderTop
                      onClick={() =>
                        this.props.update("findings", finding.id, {
                          isStarred: !finding.isStarred,
                        })
                      }
                      iconFill={finding.isStarred}
                      iconColor={finding.isStarred ? "yellow-400" : null}
                      iconColorDark={finding.isStarred ? "yellow-500" : null}
                      icon="star"
                    >
                      {finding.isStarred ? "Unmark" : "Mark"} as key finding
                    </DropdownItem>
                    <div className="mt-1 border-t pt-1">
                      {project.findingLabels &&
                        project.findingLabels.length &&
                        project.findingLabels.map((label) => (
                          <Checkbox
                            isSmall
                            key={label.id}
                            isActive={(finding.labelIds || []).includes(
                              label.id,
                            )}
                            color={label.color}
                            onChange={() => {
                              this.props.toggleInArray(
                                "findings",
                                finding.id,
                                "labelIds",
                                label.id,
                              )
                            }}
                          >
                            {label.name}
                          </Checkbox>
                        ))}
                      <DropdownItem
                        icon="add"
                        onClick={async () => {
                          let name, color
                          const confirm = await this.props.openModal({
                            title: "New label",
                            desc: `Apply to 1 finding.`,
                            icon: "label",
                            action: "Add",
                            child: (
                              <Input
                                hasBorder
                                onChange={(x) => (name = x)}
                                autoFocus
                                placeholder="Name..."
                                button={
                                  <ColorPicker
                                    onChange={(x) => (color = x)}
                                    noWeights
                                    hasAll
                                  />
                                }
                              />
                            ),
                          })
                          if (confirm && name) {
                            const id = getSemiUniqueKey()
                            this.props.addToArray(
                              "projects",
                              this.props.match.params.projectId,
                              "findingLabels",
                              {
                                id,
                                name,
                                color: color || null,
                              },
                            )
                            this.props.addToArray(
                              "findings",
                              finding.id,
                              "labelIds",
                              id,
                            )
                          }
                        }}
                      >
                        New label
                      </DropdownItem>
                    </div>
                    <DropdownItem
                      disabled={this.props.isDemo}
                      borderTop
                      onClick={(e) => {
                        const interview = interviews.find(
                          (x) => x.id === finding.interviewId,
                        )
                        const updateObj = getInterviewUpdateObjOnFindingDelete(
                          interview,
                          finding.id,
                        )
                        if (updateObj)
                          this.props.update(
                            "interviews",
                            interview.id,
                            updateObj,
                          )
                        this.props.delete("findings", finding.id)
                      }}
                      isRed
                      icon="delete"
                    >
                      Delete
                    </DropdownItem>
                  </>
                )
              } else if (selectedIds.length > 1) {
                const selectedFindings = findings.filter((x) =>
                  selectedIds.includes(x.id),
                )
                const allAreStarred = selectedFindings.every((x) => x.isStarred)
                return (
                  <>
                    <DropdownItemCopy
                      defaultFormat="[text] — [interviewNameAge]"
                      plural="findings"
                      fields={fields}
                      data={findings}
                      selectedIds={selectedIds}
                    />
                    <DropdownItem
                      to={`${baseUrl}/copy-as`}
                      toData={{ selectedIds }}
                    >
                      Copy as
                    </DropdownItem>
                    <DropdownItem
                      borderTop
                      onClick={() =>
                        this.props.updateMultiple("findings", selectedIds, {
                          isStarred: !allAreStarred,
                        })
                      }
                      iconFill={allAreStarred}
                      iconColor={allAreStarred ? "yellow-400" : null}
                      iconColorDark={allAreStarred ? "yellow-500" : null}
                      icon="star"
                    >
                      {allAreStarred ? "Unmark" : "Mark"} as key findings
                    </DropdownItem>
                    <div className="mt-1 border-t pt-1">
                      {project.findingLabels && project.findingLabels.length ? (
                        project.findingLabels.map((label) => (
                          <Checkbox
                            isSmall
                            key={label.id}
                            isActive={selectedFindings.every((x) =>
                              (x.labelIds || []).includes(label.id),
                            )}
                            color={label.color}
                            onChange={() => {
                              const obj = {}
                              for (const selectedId of selectedIds) {
                                const finding = selectedFindings.find(
                                  (x) => x.id === selectedId,
                                )
                                const labelIds = [...(finding.labelIds || [])]
                                const removeLabel = selectedFindings.every(
                                  (x) => (x.labelIds || []).includes(label.id),
                                )
                                if (removeLabel) {
                                  const index = labelIds.indexOf(label.id)
                                  if (index > -1) labelIds.splice(index, 1)
                                } else if (!labelIds.includes(label.id)) {
                                  labelIds.push(label.id)
                                }
                                obj[selectedId] = { labelIds }
                              }
                              this.props.updateMultiple("findings", obj)
                            }}
                          >
                            {label.name}
                          </Checkbox>
                        ))
                      ) : (
                        <DropdownItem disabled>No labels</DropdownItem>
                      )}
                      <DropdownItem
                        icon="add"
                        onClick={async () => {
                          let name, color
                          const confirm = await this.props.openModal({
                            title: "New label",
                            desc: `Apply to ${findings.length} findings.`,
                            icon: "label",
                            action: "Add",
                            child: (
                              <Input
                                hasBorder
                                onChange={(x) => (name = x)}
                                autoFocus
                                placeholder="Name..."
                                button={
                                  <ColorPicker
                                    onChange={(x) => (color = x)}
                                    noWeights
                                    hasAll
                                  />
                                }
                              />
                            ),
                          })
                          if (confirm && name) {
                            const id = getSemiUniqueKey()
                            this.props.addToArray(
                              "projects",
                              this.props.match.params.projectId,
                              "findingLabels",
                              {
                                id,
                                name,
                                color: color || null,
                              },
                            )
                            for (const finding of findings) {
                              this.props.addToArray(
                                "findings",
                                finding.id,
                                "labelIds",
                                id,
                              )
                            }
                          }
                        }}
                      >
                        New label
                      </DropdownItem>
                    </div>
                    <DropdownItem
                      borderTop
                      onClick={(e) =>
                        this.props.deleteMultiple(
                          "findings",
                          selectedIds,
                          this.props.warn,
                          "findings",
                        )
                      }
                      isRed
                      icon="delete"
                    >
                      Delete
                    </DropdownItem>
                  </>
                )
              }
            }}
            isText
            isMosaic={this.props.local.isFindingsAligned === false}
            right={(finding) => {
              const interview =
                interviews.find((x) => x.id === finding.interviewId) || {}
              return (
                <>
                  {finding.time && interview.mediaUrl ? (
                    <Icon
                      size={12}
                      className={finding.isStarred ? "mr-3" : ""}
                      color="gray-400"
                      icon={interview.mediaType === "audio" ? "audio" : "video"}
                    />
                  ) : null}
                  {finding.isStarred ? (
                    <Icon
                      size={14}
                      fill
                      color="yellow-400"
                      colorDark="yellow-500"
                      icon="star"
                    />
                  ) : null}
                </>
              )
            }}
            to={`/projects/${project.id}/findings/:id`}
            fields={fields}
            data={findings}
            mapInner={(finding) => {
              const interview =
                interviews.find((x) => x.id === finding.interviewId) || {}
              const sentence = getQuoteFromTranscript(
                interview.transcript,
                interview.transcriptEntities,
                finding,
                true,
              )
              const shortSentence =
                sentence && sentence.length > 70
                  ? `${sentence.substring(
                      0,
                      sentence[69] === " " ? 69 : 70,
                    )}..."`
                  : sentence
              return (
                <div className="leading-normal">
                  <Labels
                    className="mb-2"
                    isSmall
                    labels={project.findingLabels}
                    labelIds={finding.labelIds}
                  />
                  {sentence && finding.text && (
                    <div className="sub mb-2">{shortSentence}</div>
                  )}
                  <div
                    className={`mb-1 ${
                      !finding.text && !sentence
                        ? "text-gray-400 dark:text-gray-600"
                        : ""
                    }`}
                  >
                    {finding.text || sentence || "Empty finding"}
                  </div>
                </div>
              )
            }}
          />
        ) : isFiltered ? (
          <State
            full
            title="No findings match this filter."
            maxWidth={380}
            icon="filter"
          >
            <Button hasBorder className="mt-2" to={`${baseUrl}/view`}>
              Change filters
            </Button>
          </State>
        ) : (
          <State full title="No findings" maxWidth={380} icon="star">
            <p className="text-center">
              Open an interview and click the Findings tab to add one. Then come
              back here to see them all together.
            </p>
          </State>
        )}
      </Page>
    )
  }
}

export default withData(withNotify(ProjectFindings))
