import React from "react"
import {
  Button,
  Dropdown,
  Input,
  List,
  Page,
  DemoBox,
  withData,
  withNotify,
} from "../shared"
import { Route } from "react-router-dom"
import ProjectForm from "./ProjectForm"
import { getSemiUniqueKey, State } from "../shared"
import ProjectFormResponse from "./ProjectFormResponse"
import PopoverLinkResponse from "./popovers/PopoverLinkResponse"

class ProjectForms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newFormTitle: "",
    }
  }
  componentDidMount() {
    this.openFirst()
    // Preload pictures in Design tab
    const pictures = [
      "https://images.unsplash.com/photo-1508614999368-9260051292e5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      "https://images.unsplash.com/photo-1557683311-eac922347aa1?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      "https://images.unsplash.com/photo-1569521569926-8bb185c2b5d8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60",
      "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
    ]
    pictures.forEach((picture) => {
      const img = new Image()
      img.src = picture
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.projectId !== this.props.match.params.projectId
    ) {
      this.openFirst()
    }
  }
  openFirst() {
    const forms = (this.props.forms || [])
      .filter((x) => x.projectId === this.props.match.params.projectId)
      .sort((a, b) => a.createdOn - b.createdOn)
    if (!this.props.match.params.formId && forms.length > 0) {
      const form = forms.find((x) => x.id === forms[0].id)
      const hasResponses = this.props.formResponses.some(
        (x) => x.formId === form.id,
      )
      const goToResponses = form.isLive && !this.props.isDemo && hasResponses
      this.props.history.replace(
        `/projects/${this.props.match.params.projectId}/forms/${forms[0].id}${
          goToResponses ? "/responses" : ""
        }`,
        this.props.location.state,
      )
    }
  }
  async handleSubmit(e) {
    e.preventDefault()
    if (this.props.isDemo) return false
    const formId = await this.props.create("forms", {
      confirmationMessage: "We received your application. Thank you!",
      desc: "Please answer the following questions to submit your application.",
      emailCreatorOnSubmit: true,
      projectId: this.props.match.params.projectId,
      image: null,
      isLive: false,
      questions: [
        { id: getSemiUniqueKey(), title: "What is your name?", type: "name" },
        {
          id: getSemiUniqueKey(),
          title: "What is your email address?",
          type: "email",
        },
      ],
      title: this.state.newFormTitle,
    })
    this.props.history.push(
      `/projects/${this.props.match.params.projectId}/forms/${formId}`,
    )
  }
  render() {
    const project =
      (this.props.projects || []).find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}
    const forms = (this.props.forms || [])
      .filter((x) => x.projectId === project.id)
      .sort((a, b) => b.createdOn - a.createdOn)

    const fields = [
      { key: "title" },
      { key: "desc" },
      {
        key: "responses",
        calc: (_, form) => {
          const x = (this.props.formResponses || []).filter(
            (x) => x.formId === form.id,
          )
          return `${
            !x || x.length === 0
              ? "No responses"
              : x.length === 1
              ? "1 response"
              : `${x.length} responses`
          }`
        },
      },
      { key: "isLive", calc: (isLive) => (isLive ? "Live" : "") },
    ]

    const isResponses = window.location.pathname.includes("/responses")
    const isDesign = window.location.pathname.includes("/design")
    const isForm = !isResponses && !isDesign
    const slug = isForm ? "" : isResponses ? "/responses" : "/design"

    return (
      <div className="two-pages">
        <Page
          title={`${forms.length} form${forms.length === 1 ? "" : "s"}`}
          required={{ project }}
          searchDisabled={forms.length === 0}
          hasPadding
          isSidebar
          barRight={
            <Dropdown
              onSubmit={this.handleSubmit.bind(this)}
              width={260}
              button={
                <Button
                  icon="add"
                  disabled={this.props.isDemo}
                  title="New form"
                />
              }
            >
              <Input
                autoClose={false}
                placeholder="Enter form title..."
                hasBorder
                autoFocus
                onChange={(x) => this.setState({ newFormTitle: x })}
              />
              <Button
                autoClose={false}
                type="submit"
                className="mt-2 w-full"
                primary
              >
                Create form
              </Button>
            </Dropdown>
          }
        >
          {!isResponses &&
            this.props.isDemo &&
            this.props.session.isOnboarded && (
              <DemoBox
                step={2}
                back="/"
                isLeft
                to="/projects/demo-project/forms/demo-form/responses"
                primary="To show you the app, let's pretend we organize a coffee tasting."
                secondary="First, we create a sign-up form for people to register. Let's ask people for their favorite coffees and their availability, which helps us later."
              />
            )}
          <List
            className="-mt-4"
            singular="form"
            keyPrimary="title"
            keyTertiary="responses"
            keyRight="isLive"
            fields={fields}
            to={`/projects/${project.id}/forms/:id${slug}`}
            data={forms}
          />
        </Page>
        {!!Object.keys(project).length && (
          <>
            <Route
              path="/projects/:projectId/forms/:formId/responses/:responseId"
              component={ProjectFormResponse}
            />
            <Route
              path="/projects/:projectId/forms/:formId/responses/:responseId?/link"
              component={PopoverLinkResponse}
            />
            {forms.length === 0 && (
              <Route
                path="/projects/:projectId/forms"
                exact
                render={(props) => (
                  <State icon="form" className="pt-12" title="No forms">
                    <p className="mb-5 max-w-sm text-center">
                      Create, design and share optimized forms to let people
                      apply for interviews.
                    </p>
                    <Dropdown
                      arrowPosition="center"
                      onSubmit={this.handleSubmit.bind(this)}
                      width={260}
                      button={<Button primary>Create form</Button>}
                    >
                      <Input
                        autoClose={false}
                        placeholder="Enter form title..."
                        hasBorder
                        autoFocus
                        onChange={(x) => this.setState({ newFormTitle: x })}
                      />
                      <Button
                        autoClose={false}
                        type="submit"
                        className="mt-2 w-full"
                        primary
                      >
                        Create form
                      </Button>
                    </Dropdown>
                  </State>
                )}
              />
            )}
            <Route
              path="/projects/:projectId/forms/:formId"
              render={(props) => (
                <ProjectForm {...props} filteredForms={forms} />
              )}
            />
            <Route
              path="/projects/:projectId/forms/:formId/markdown"
              render={(props) => (
                <Page
                  title="Markdown"
                  isPopup
                  backTo={`/projects/${project.id}/forms/${props.match.params.formId}`}
                  hasPadding
                  noBar
                  width="md"
                >
                  <h4 className="mb-3">How to use markdown</h4>
                  <p className="text-gray-500">
                    You can use some markdown to format your form's introduction
                    text. This is how it works:
                  </p>
                  <h6 className="mt-6 mb-2">Bold</h6>
                  <code className="text-sm">
                    To make text bold, wrap it in two **asterisks**.
                  </code>
                  <h6 className="mt-6 mb-2">Italic</h6>
                  <code className="text-sm">
                    To make text italic, wrap it in one *asterisk*.
                  </code>
                  <h6 className="mt-6 mb-2">Link</h6>
                  <code className="text-sm">
                    Create a link like this: click [here](https://ivkt.co) to
                    visit InterviewKit.
                  </code>
                  <Button
                    secondary
                    className="mt-6 w-full"
                    to={`/projects/${project.id}/forms/${this.props.match.params.formId}`}
                  >
                    Done
                  </Button>
                </Page>
              )}
            />
          </>
        )}
      </div>
    )
  }
}

export default withData(withNotify(ProjectForms))
