import React from "react"
import Button from "../Button"
import Dropdown from "../Dropdown"
import Icon from "../Icon"
import Input from "../Input"
import Page from "../Page"
import { isEmail, sortUserIds, fName } from "../../functions"
import { withData } from "../../data"
import { withNotify } from "../../notify"
import { withShare } from "../../share"
import PropTypes from "prop-types"
import Avatar from "../Avatar"

class PopoverInvite extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      isRevokingUserId: false,
      isInviting: false,
    }
  }
  async onInvite(e) {
    e.preventDefault()
    if (this.props.isDemo) return false
    if (this.props.project.memberIds.length >= 5) {
      return this.props.error(
        "You cannot add more than 5 people.",
        "Feel free to contact us if you need help with a special use case.",
      )
    }
    if (isEmail(this.state.email)) {
      this.setState({ isInviting: true })
      const projectId = this.props.project.id
      const email = this.state.email
      try {
        const { data } = await this.props.inviteFunc({ projectId, email })
        if (data && data.isSuccess) {
          await this.props.fetch({
            collection: "projects",
            id: projectId,
            force: true,
          }) // Refresh to get new memberIds
          await this.props.startSendingPings() // If this user wasn't sending pings, start now
          await this.props.startListeners() // If this user wasn't listening, start now
          this.props.success(`${email} added!`)
          this.setState({ email: "", isInviting: false })
        } else {
          this.props.error(
            `Could not add ${email}`,
            (data || {}).message || "Please try again or contact us.",
          )
          this.setState({ isInviting: false })
        }
      } catch (e) {
        this.props.error(
          `Could not add ${email}`,
          "Please try again or contact us.",
        )
        this.setState({ isInviting: false })
      }
    } else {
      this.props.error("Please enter a valid email address.")
    }
  }
  async onRevoke(user) {
    const confirm = await this.props.warn(
      `Remove ${fName(user.name) || "this user"} from this project?`,
      `No data will be lost. You can add ${
        fName(user.name) || "this user"
      } again later if you want to.`,
      "Remove",
    )
    if (confirm) {
      try {
        this.setState({ isRevokingUserId: user.id })
        const { data } = await this.props.revokeFunc({
          userId: user.id,
          projectId: this.props.project.id,
        })
        if (data.isSuccess) {
          await this.props.fetch({
            collection: "projects",
            id: this.props.project.id,
            force: true,
          })
          this.props.success(
            `${user.name || "User"} is removed from this project.`,
          )
        } else {
          this.props.error("Could not remove user from project.", data.message)
        }
      } catch (e) {
        this.props.error("Could not remove user from project.", e.message)
      } finally {
        this.setState({ isRevokingUserId: false })
      }
    }
  }
  async revokeMyself() {
    const ownerName = this.props.usersPublic.find(
      (x) => x.id === this.props.project.createdBy,
    )?.name
    const confirm = await this.props.warn(
      `Remove yourself from this project?`,
      `You will lose access to this project, including any data you provided. To get access again, you will have to ask ${
        ownerName || "the project owner"
      } to re-invite you.`,
      "Remove myself",
    )
    if (confirm) {
      try {
        this.setState({ isRevokingUserId: this.props.userId })
        const { data } = await this.props.revokeFunc({
          userId: this.props.userId,
          projectId: this.props.project.id,
        })
        if (data.isSuccess) {
          await this.props.delete(
            "projects",
            this.props.project.id,
            false,
            false,
            false,
            true,
          ) // Delete project only locally
          this.props.success(`You have removed yourself from the project.`)
          this.props.history.push("/")
        } else {
          this.props.error("Could not remove you from project.", data.message)
          this.setState({ isRevokingUserId: false })
        }
      } catch (e) {
        this.props.error("Could not remove you from project.", e.message)
        this.setState({ isRevokingUserId: false })
      }
    }
  }
  render() {
    const { project, onlineIds } = this.props
    const memberIds = project.memberIds || []
    const isShared = memberIds.length > 1
    const iAmOwner = project.createdBy === this.props.userId
    const userIds = sortUserIds(
      memberIds,
      onlineIds,
      this.props.userId,
      project.createdBy,
    )
    const ownerName = this.props.usersPublic.find(
      (x) => x.id === project.createdBy,
    )?.name

    return (
      <Page
        isPopup
        title={
          isShared ? `${memberIds.length} people have access` : "Share project"
        }
        width="lg"
        backTo={this.props.backTo}
        noBar
        hasPadding
      >
        <div className="flex flex-col items-center">
          <Icon
            icon={isShared ? "users" : "user-add"}
            color="yellow-800"
            bg="yellow-200"
          />
          <h3 className="mt-5">
            {isShared
              ? `${memberIds.length} people have access`
              : "Share project"}
          </h3>
          <div className={`mx-auto mt-4 max-w-md text-center text-gray-500`}>
            {iAmOwner ? (
              <>
                <span className="text-lg leading-relaxed">
                  People you invite to “
                  {this.props.project.name || "New project"}” with be able to
                  view and edit everything.
                </span>{" "}
                <Dropdown
                  width={280}
                  isInfo
                  button={
                    <span className="link-color text-lg font-medium leading-relaxed">
                      Learn more
                    </span>
                  }
                >
                  <h6 className="mb-3 text-left">About collaboration</h6>
                  <p className="text-left text-sm">
                    Easily add people you want to collaborate with, no matter if
                    they already have an InterviewKit account or not.
                  </p>
                  <p className="mt-3 text-left text-sm">
                    People you invite to this project will be able to view, edit
                    and use everything. Any media they upload will be added to
                    your storage usage. Invited people will not be able to
                    invite others, nor archive or delete the project.
                  </p>
                  <p className="mt-3 text-left text-sm">
                    You can add up to 5 people to collaborate with. If you want
                    to share your interviews or findings, we recommend using the
                    Copy feature or create a presentation from the Findings
                    page.
                  </p>
                </Dropdown>
              </>
            ) : (
              <span className="text-lg leading-relaxed">
                Only{" "}
                {ownerName
                  ? `${ownerName}, the project owner,`
                  : "the project owner"}{" "}
                can invite others to "{this.props.project.name || "New project"}
                ".
              </span>
            )}
          </div>
        </div>
        {isShared && (
          <div className="mt-6 mb-2">
            {userIds.map((userId, i) => {
              const user =
                this.props.usersPublic.find((x) => x.id === userId) || {}
              return (
                <div
                  key={userId}
                  className={`group my-1 flex items-center rounded-2xl bg-gray-100 p-4 dark:bg-gray-700`}
                >
                  <Avatar
                    i={i}
                    className="mr-3"
                    isOnline={onlineIds.includes(userId)}
                    id={userId}
                  />
                  <p
                    className=" mr-auto truncate"
                    title={user.name ? user.email : null}
                  >
                    {user.name || user.email || "Unknown user"}
                    {userId === project.createdBy ? (
                      iAmOwner ? (
                        <span className="text-gray-500"> · You</span>
                      ) : (
                        <span className="text-gray-500"> · Owner</span>
                      )
                    ) : userId === this.props.userId ? (
                      <span className="text-gray-500"> · You</span>
                    ) : null}
                  </p>
                  {iAmOwner && userId !== this.props.userId && (
                    <Button
                      isLoading={this.state.isRevokingUserId === user.id}
                      onClick={() => this.onRevoke(user)}
                      isSmall
                      title="Revoke access"
                      className={`${
                        this.state.isRevokingUserId === user.id
                          ? ""
                          : "invisible group-hover:visible"
                      } flex-none`}
                      icon="cross"
                    />
                  )}
                  {onlineIds.includes(userId) && (
                    <div className="mx-3.5 mt-px h-2 w-2 rounded bg-green-500" />
                  )}
                </div>
              )
            })}
            {!iAmOwner && (
              <Button
                hasBorder
                onClick={() => this.revokeMyself()}
                isLoading={this.state.isRevokingUserId === this.props.userId}
                className="mt-4"
                full
              >
                Remove myself from this project
              </Button>
            )}
          </div>
        )}
        {iAmOwner && (
          <form
            className="mt-8 flex items-center space-x-3 pb-2"
            onSubmit={this.onInvite.bind(this)}
          >
            <Input
              readOnly={this.state.isInviting}
              autoFocus={!isShared}
              hasBorder
              placeholder="eddy@example.com"
              className="flex-1"
              value={this.state.email}
              onChange={(email) => this.setState({ email })}
            />
            <Button
              type="submit"
              primary
              isLoading={this.state.isInviting}
              disabled={this.props.isDemo}
            >
              Invite
            </Button>
          </form>
        )}
      </Page>
    )
  }
}

PopoverInvite.propTypes = {
  project: PropTypes.object.isRequired,
  backTo: PropTypes.string.isRequired,
  inviteFunc: PropTypes.func.isRequired,
  revokeFunc: PropTypes.func.isRequired,
}

export default withData(withNotify(withShare(PopoverInvite)))
