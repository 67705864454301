import React from "react"
import {
  Tabs,
  Tab,
  Input,
  Icon,
  Page,
  format,
  DropdownItem,
  copyToClipboard,
  withNotify,
  withData,
  downloadFile,
  toLowerWithDashes,
} from "../shared"
import { Route, Switch } from "react-router-dom"
import ProjectFormContent from "./ProjectFormContent"
import ProjectFormDesign from "./ProjectFormDesign"
import ProjectFormResponses from "./ProjectFormResponses"
import { PopoverView } from "../shared"
import { getInterviewDays } from "../functions"

class ProjectForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSyncing: false,
    }
  }
  exportToCSV() {
    const esc = (str) => {
      if (typeof str === "string") {
        return str?.split(";").join(",") || ""
      } else {
        return str || ""
      }
    }
    const form = this.props.filteredForms.find(
      (x) => x.id === this.props.match.params.formId,
    )
    const responses = this.props.formResponses.filter(
      (x) => x.formId === form.id,
    )
    let csvContent = "data:text/csv;charset=utf-8,"
    // Add head
    const csvHead = [
      "Form",
      "Name",
      "Age",
      "Gender",
      "Email",
      "Phone",
      "Education",
      "Job",
      "Availability",
      "Submitted on",
    ]
    responses.forEach((x) => {
      // Add titles of other questions to head
      if (x.questions)
        for (const question of x.questions) {
          const index = csvHead.indexOf(esc(question.title))
          if (index === -1) csvHead.push(esc(question.title))
        }
    })
    csvContent += csvHead.join(";") + "\n"
    // Add body
    csvContent += responses
      .map((x) => {
        const availability = x.availability
          ?.map?.(
            (x) =>
              `${format("date", x.from)} from ${format(
                "time",
                x.from,
              )} until ${format("time", x.until)}`,
          )
          .join(", ")
        const fields = [
          `${esc(form.title)}`,
          `${esc(x.name)}`,
          `${x.age || ""}`,
          `${x.gender || ""}`,
          `${esc(x.email)}`,
          `${esc(x.phone)}`,
          `${esc(x.education)}`,
          `${esc(x.job)}`,
          availability || "",
          `${
            x.createdOn
              ? format("date", x.createdOn, { includeTime: true })
              : ""
          }`,
        ]
        while (fields.length < csvHead.length) {
          fields.push("")
        }
        if (x.questions)
          for (const question of x.questions) {
            const answer = Array.isArray(question.answer)
              ? question.answer.join(", ")
              : question.answer
            const index = csvHead.indexOf(esc(question.title))
            if (index > -1) {
              // not really necessary, index should always be bigger than -1
              fields[index] = answer
            }
          }
        return fields.join(";")
      })
      .join("\n")
    downloadFile(
      `form-responses-${toLowerWithDashes(form.title || "untitled")}.csv`,
      csvContent,
    )
  }
  render() {
    const form =
      this.props.filteredForms.find(
        (x) => x.id === this.props.match.params.formId,
      ) || {}
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const baseUrl = `/projects/${this.props.match.params.projectId}/forms/${form.id}`
    const responses = this.props.formResponses
      .filter((x) => x.formId === form.id)
      .map((x) => {
        const obj = { ...x }
        if (x.questions) {
          let i = 0
          for (const question of x.questions) {
            obj[`other-${i}`] = question.answer
            i++
          }
        }
        return obj
      })

    const fields = [
      { key: "name", canHide: false },
      { key: "age", type: "number", size: "62px" },
      { key: "gender", size: "84px" },
      // {key: 'email', type: 'email'},
      { key: "phone", type: "tel", size: "0.7fr" },
      { key: "education" },
      { key: "job" },
    ]
    const others = (form.questions || []).filter(
      (x) => x.type.includes("-choice") || x.type === "text",
    )
    others.forEach((other, i) => {
      fields.push({
        key: `other-${i}`,
        type: other.type === "multiple-choice" ? "array" : "text",
        name: other.title || "Unnamed question",
      })
    })

    fields.push({
      key: "createdOn",
      name: "Submitted",
      type: "date",
      format: { includeTime: true },
    })
    fields.push({
      key: "isInterview",
      canHide: false,
      name: "Interview",
      size: "80px",
      type: "boolean",
      calc: (_, x) =>
        this.props.interviews.some((y) => (y.responses || []).includes(x.id)),
    })

    let hideFields = form.hideFields
    if (!hideFields)
      hideFields = fields
        .map((x) =>
          ["name", "createdOn", "isInterview"].includes(x.key) ? null : x.key,
        )
        .filter((x) => x) // Hide all but name and submitted

    const isResponses = window.location.pathname.includes("/responses")
    const createdBy =
      (this.props.usersPublic.find((x) => x.id === form.createdBy) || {})
        .name || "a deleted user"

    return (
      <Page
        isFlexCol
        title={form.title || "New form"}
        titleIsSmall
        required={{ form }}
        titleOnUpdate={(title) =>
          this.props.update("forms", form.id, { title })
        }
        barRight={
          <>
            {form.isLive && (
              <div
                onClick={async () => {
                  await copyToClipboard(`https://form.ivkt.co/${form.id}`)
                  this.props.success("Copied form link")
                }}
                className={`tr mr-4 ${form.isLive ? "" : "opacity-0"}`}
              >
                <div className="pushable-sm flex items-center">
                  <Icon
                    icon="link"
                    size={12}
                    color="indigo-500"
                    className="mr-2"
                  />
                  <div className="truncate text-sm text-gray-500 focus:text-indigo-500">
                    form.ivkt.co/{form.id}
                  </div>
                </div>
              </div>
            )}
            <Input
              type="boolean"
              className="w-32 rounded-full"
              hasBorder
              before="Live"
              beforeWidth={54}
              value={form.isLive}
              onChange={(isLive) => {
                if (project.isArchived) {
                  this.props.error(
                    "Cannot publish form of archived project",
                    "Please restore your project first.",
                  )
                  return false
                }
                const data = { isLive }
                data.dates = getInterviewDays(project)
                data.duration = project.duration || 60
                data.dateFormat = window.appDateFormat || "D M Y"
                data.timeFormat = window.appTimeFormat || "H:mm"
                this.props.update("forms", form.id, data)
              }}
            />
          </>
        }
        moreWidth={220}
        more={
          <>
            {isResponses ? (
              <>
                <DropdownItem
                  icon="layers"
                  to={`${this.props.location.pathname}/view`}
                >
                  View
                </DropdownItem>
                <DropdownItem
                  disabled={this.props.isDemo}
                  icon="download"
                  onClick={this.exportToCSV.bind(this)}
                >
                  Export responses (CSV)
                </DropdownItem>
              </>
            ) : null}
            <DropdownItem
              extLink={`https://form.ivkt.co/${form.id}`}
              disabled={!form.isLive}
              borderTop={isResponses}
              icon="upload"
            >
              View form
            </DropdownItem>
            {!form.isLive && (
              <p className="sub ml-9 mb-2">Go live to view your form.</p>
            )}
            <DropdownItem
              disabled={!form.isLive}
              onClick={() => {
                try {
                  const link = `https://form.ivkt.co/${form.id}`
                  copyToClipboard(link, this.props.error)
                  this.props.success("Copied form link")
                } catch (e) {}
              }}
              icon="link"
            >
              Copy link
            </DropdownItem>
            <DropdownItem
              disabled={this.props.isDemo}
              onClick={async (e) => {
                try {
                  await this.props.delete(
                    "forms",
                    form.id,
                    this.props.warn,
                    undefined,
                    {
                      desc: "Deleting this form and its responses is permanent and cannot be undone.",
                    },
                  )
                  this.props.history.push(
                    `/projects/${this.props.match.params.projectId}/forms`,
                  )
                } catch (e) {
                  this.props.error("Could not delete form", e)
                }
              }}
              isRed
              icon="delete"
            >
              Delete form
            </DropdownItem>
            <p className="sub p mt-2 mb-1 border-t pt-3">
              Form created on{" "}
              {format("date", form.createdOn, { month: "full" })} at{" "}
              {format("time", form.createdOn)} by {createdBy}.
            </p>
          </>
        }
      >
        <Tabs marginSide={0} margin={8} className="px-10">
          <Tab to={baseUrl} exact>
            Content
          </Tab>
          <Tab to={`${baseUrl}/design`}>Design</Tab>
          <Tab to={`${baseUrl}/responses`} badge={responses.length}>
            Responses
          </Tab>
        </Tabs>
        <Route
          path="/projects/:projectId/forms/:formId/responses/view"
          render={(props) => (
            <PopoverView
              {...props}
              backTo={`/projects/${this.props.match.params.projectId}/forms/${form.id}/responses`}
              onSubmit={({ tableHideFields }) =>
                this.props.update("forms", form.id, {
                  hideFields: tableHideFields,
                })
              }
              options={{
                view: { value: "table" },
                tableHideFields: {
                  value: hideFields,
                  options: fields,
                },
              }}
            />
          )}
        />
        <div className="h-full overflow-scroll">
          <Switch>
            <Route
              path={[
                "/projects/:projectId/forms/:formId",
                "/projects/:projectId/forms/:formId/markdown",
                "/projects/:projectId/forms/:formId/questions/:questionId",
              ]}
              exact
              render={(props) => <ProjectFormContent {...props} form={form} />}
            />
            <Route
              path="/projects/:projectId/forms/:formId/design"
              render={(props) => <ProjectFormDesign {...props} form={form} />}
            />
            <Route
              path="/projects/:projectId/forms/:formId/responses"
              render={(props) => (
                <ProjectFormResponses
                  {...props}
                  form={form}
                  hideFields={hideFields}
                  fields={fields}
                  responses={responses}
                />
              )}
            />
          </Switch>
        </div>
      </Page>
    )
  }
}

export default withNotify(withData(ProjectForm))
