import React from "react"
import {
  Button,
  Page,
  DropdownItem,
  withData,
  withNotify,
  InputGroup,
  Avatar,
  SettingsDateFormat,
  Callout,
} from "../shared"
import { Route } from "react-router-dom"
import modeAuto from "../assets/mode-auto.png"
import modeLight from "../assets/mode-light.png"
import modeDark from "../assets/mode-dark.png"
import moment from "moment"
import SettingsProfileDelete from "./SettingsProfileDelete"

class SettingsProfile extends React.Component {
  signOut() {
    this.props.history.push("/logged-out")
    window.setTimeout(() => {
      this.props.signOut()
    }, 200)
  }
  setDisplayMode(displayMode) {
    if (
      displayMode === "dark" ||
      (!displayMode &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
    this.props.update("usersPrivate", this.props.userId, { displayMode })
  }
  render() {
    const user = this.props.getUser() || {}
    return (
      <Page
        isNarrow
        hasPadding
        icon="user"
        title="Profile"
        barRight={
          <Button
            onClick={() => this.signOut()}
            icon="logout"
            title="Log out"
          />
        }
        more={
          <>
            <DropdownItem extLink="//interviewkit.co/privacy">
              Privacy
            </DropdownItem>
            <DropdownItem extLink="//interviewkit.co/terms">
              Terms of Service
            </DropdownItem>
          </>
        }
      >
        <Route
          path="/settings/profile/delete"
          component={SettingsProfileDelete}
        />
        <div className="flex items-center">
          <div className="flex-1">
            <h5 className="mb-4">Personal details</h5>
            <InputGroup
              hasBorder
              doc={user}
              onBlur={(data) =>
                this.props.update("usersPublic", this.props.userId, data)
              }
              beforeWidth={80}
              fields={[{ key: "name" }, { key: "email", disabled: true }]}
            />
            <p className="sub mt-3">
              To change your email address, please email us at
              support@interviewkit.co
            </p>
          </div>
          <Avatar
            size={28}
            id={this.props.userId}
            canUpload
            className="ml-10"
          />
        </div>
        <div className="mt-12">
          <h5>Display mode</h5>
          <p className="mb-5 mt-1 text-gray-500">
            Adjust how InterviewKit looks.
          </p>
          <div className="flex justify-between">
            <div style={{ maxWidth: 150 }}>
              <img
                onClick={() => this.setDisplayMode(null)}
                alt=""
                className={`pushable-sm cursor-pointer rounded-lg border ring-indigo-500 ${
                  user.displayMode == null ? "ring-2" : ""
                }`}
                src={modeAuto}
              />
              <div className="mt-3 text-center text-sm font-medium">
                Automatic
              </div>
            </div>
            <div style={{ maxWidth: 150 }}>
              <img
                onClick={() => this.setDisplayMode("light")}
                alt=""
                className={`pushable-sm cursor-pointer rounded-lg border ring-indigo-500 ${
                  user.displayMode === "light" ? "ring-2" : ""
                }`}
                src={modeLight}
              />
              <div className="mt-3 text-center text-sm font-medium">Light</div>
            </div>
            <div style={{ maxWidth: 150 }}>
              <img
                onClick={() => this.setDisplayMode("dark")}
                alt=""
                className={`pushable-sm cursor-pointer rounded-lg border ring-indigo-500 ${
                  user.displayMode === "dark" ? "ring-2" : ""
                }`}
                src={modeDark}
              />
              <div className="mt-3 text-center text-sm font-medium">Dark</div>
            </div>
          </div>
          <Callout className="mt-5">
            Automatic will toggle light and dark mode based on your OS settings.
          </Callout>
        </div>
        <div className="mt-12">
          <h5>Date & time</h5>
          <p className="mb-4 mt-1 text-gray-500">
            Adjust how dates and times should be formatted.
          </p>
          <SettingsDateFormat beforeWidth={80} />
          <p className="sub mt-4">
            Dates and times are always in the timezone of your device, which is{" "}
            {moment().format("Z")} GMT.
          </p>
        </div>
        <div className="mt-12 flex space-x-5">
          <Button icon="lock" hasBorder to="/settings/profile/password">
            Change password
          </Button>
          <Button isRed to="/settings/profile/delete">
            Delete account
          </Button>
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(SettingsProfile))
