import React from 'react';
import PropTypes from "prop-types";
import Icon from "./Icon";
import Loader from "./Loader";
import { withRouter } from "react-router-dom";
import Shortcut from "./Shortcut";
import { formatShortcut } from "../functions";
import Dropdown from "./Dropdown";

class Button extends React.Component {
    constructor(props) {
        super(props);
        this.ref = this.props?.innerRef ? this.props.innerRef : React.createRef();
        this.state = {
            isHover: false,
        };
    }
    componentDidMount() {
        this.width = this.ref.current.offsetWidth;
        const { childRef } = this.props;
        if (childRef) childRef(this);
    }
    componentWillUnmount() {
        const { childRef } = this.props;
        if (childRef) childRef(undefined);
    }
    handleClick(e) {
        if (this.props.disabled) return false;
        this.setState({ isHover: false }); // Fix situation where a dropdown opens below button, but title still shows since you're still hovered over the button.
        if (this.props.extLink) {
            window.open(this.props.extLink, '_blank').focus();
        } else if (this.props.to) {
            this.props.history.push(this.props.to, this.props.toData);
        }
        if (!!this.props.onClick) this.props.onClick(e);
    }
    offsetWidth() {
        return this.ref.current.offsetWidth();
    }
    render() {
        const h = this.props.isSmall ? 8 : 10;
        const classArr = [`appearance-none z-20 focus:outline-none outline-none relative whitespace-nowrap select-none font-medium inline-flex items-center ${this.props.alignLeft ? '' : 'justify-center'} px-${this.props.isSmall ? 3 : 4} h-${h} ${this.props.endIcon ? "flex-row-reverse" : ""}`];

        if (this.props.isSmall && !this.props.more) classArr.push('rounded-md text-sm');
        if (!this.props.isSmall && !this.props.more) classArr.push('rounded-full');
        if (!this.props.primary && !this.props.isWhite && this.props.isRed) classArr.push('text-red-500 dark:text-red-500');
        if (!this.props.isRed && !this.props.primary && !this.props.isWhite) classArr.push(`text-${this.props.color || 'gray-800'} dark:text-${this.props.colorDark || this.props.color || 'gray-200'}`);
        if (this.props.disabled) classArr.push('opacity-50');
        if (this.props.disabled || this.props.isLoading) classArr.push('pointer-events-none');

        if (!this.props.primary && !this.props.isWhite && !this.props.selected && !this.props.secondary && !this.props.noHover) classArr.push(`hover:bg-${this.props.hoverColor || 'gray-100'} dark:hover:bg-${this.props.hoverColorDark || 'gray-700'}`);
        if (!this.props.isRed && this.props.primary) classArr.push(`bg-${this.props.color || window.appColor} dark:bg-${this.props.colorDark || this.props.color || window.appColor}`);
        if (this.props.primary) classArr.push(`text-white`);
        if (this.props.isWhite) classArr.push(`bg-white text-${this.props.color || window.appColor} dark:text-${this.props.color || window.appColor} hover:bg-white dark:hover:bg-white`);
        if (!this.props.isRed && this.props.primary && this.props.selected) classArr.push(`bg-${this.props.selectedColor || window.appColorDarker} dark:bg-${this.props.selectedColorDark || window.appColorDarker}`);
        if (this.props.isRed && this.props.primary) classArr.push(`bg-red-500`);
        if (this.props.isRed && this.props.primary && this.props.selected) classArr.push(`bg-red-600`);
        if (this.props.secondary) classArr.push('bg-gray-100 dark:bg-gray-700');
        if (!this.props.primary && !this.props.isWhite && this.props.selected) classArr.push(`text-${this.props.color} dark:text-${this.props.colorDark || this.props.color} bg-${this.props.selectedColor || 'gray-200'} dark:bg-${this.props.selectedColorDark || 'gray-600'}`); // Selected state for secondary and naked buttons
        if (!this.props.disabled) classArr.push('pushable-sm');
        if (this.props.hasBorder) classArr.push('border');
        const className = classArr.join(' ');
        const loader = <div className={`absolute inset-0 flex items-center tr justify-center transform ${this.props.isLoading ? 'scale-100 opacity-1' : 'opacity-0 scale-90'}`}><Loader color={this.props.primary ? 'white' : (this.props.isRed ? 'red' : '')} className='-mt-px' size={24} /></div>;
        const titleText = this.props.title == null ? (this.props.icon === 'help' ? 'Help' : (this.props.icon === 'more' ? 'More' : '')) : `${this.props.title}${this.props.extLink ? ' ↗' : ''}`;
        const title = titleText && (this.props.shortcut || this.props.titleShortcut) ? `${titleText} (${formatShortcut(this.props.shortcut || this.props.titleShortcut)})` : titleText;
        const button = (
            <button onMouseEnter={() => this.setState({ isHover: true })} onMouseLeave={() => this.setState({ isHover: false })} onKeyDown={e => e.preventDefault()} type={this.props.type} ref={this.ref} onClick={this.handleClick.bind(this)} className={`${className} ${this.props.full ? 'flex-1 w-full' : ''} ${this.props.more ? '' : (this.props.className || '')}`} style={{ minWidth: this.props.isSmall ? '2rem' : '2.5rem', ...this.props.style }}>
                {this.props.icon ? <Icon size={this.props.isSmall ? 12 : 16} color={this.props.iconColor} fill={this.props.iconFill} colorDark={this.props.iconColorDark} icon={this.props.icon} className={`${this.props.children ? (this.props.isSmall ? `m${this.props.endIcon ? "l" : "r"}-2` : `m${this.props.endIcon ? "l" : "r"}-3`) : ''} ${this.props.isSpinning ? 'animate-spin' : ''} ${this.props.isLoading ? 'opacity-0' : ''} -mt-px`} style={{ animationDirection: 'reverse' }} /> : null}
                {this.props.isLoading && this.props.children ? <span className='opacity-0'>{this.props.children}</span> : this.props.children}
                {this.props.isLoading ? loader : null}
                {(this.props.titleShowAlways || !this.props.children) && titleText && this.state.isHover && <div className={`animate-open absolute ${this.props.titleTop ? '-top-11' : '-bottom-11'} pointer-events-none bg-gray-600 text-white dark:bg-gray-900 ${this.props.titleLeft ? 'left-0' : ''} ${this.props.titleRight ? 'right-0' : ''} rounded-lg py-2 px-3 font-medium text-sm`}>{title}</div>}
                {this.props.shortcut ? <Shortcut prevent={this.props.prevent} disabled={this.props.disabled} alsoWorksWhenInputInFocus={this.props.alsoWorksWhenInputInFocus} press={this.props.shortcut} onPress={this.handleClick.bind(this)} /> : null}
            </button>
        );

        const classNameArrMore = [...classArr, 'pl-5 pr-6'];
        const classNameMore = classNameArrMore.join(' ');

        return !this.props.more ? button : (
            <div className={`rounded-full relative inline-flex overflow-hidden ${this.props.className || ''}`}>
                {button}
                {!!this.props.more ? (
                    <Dropdown offset={-186} arrowPosition='right' width={this.props.moreWidth} approxHeight={this.props.approxHeight} button={<div className={`${classNameMore} ml-px`}>
                        <Icon size={this.props.isSmall ? 12 : 14} icon='chevron-down' className={`${this.props.isLoading ? 'opacity-0' : ''} -mx-1 mt-px`} />
                    </div>}>
                        {this.props.more}
                    </Dropdown>
                ) : null}
            </div>
        );
    }
}

Button.propTypes = {
    alsoWorksWhenInputInFocus: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    full: PropTypes.bool,
    fill: PropTypes.bool,
    hasBorder: PropTypes.bool,
    icon: PropTypes.string,
    extLink: PropTypes.string,
    isLoading: PropTypes.bool,
    isSmall: PropTypes.bool,
    isRed: PropTypes.bool,
    onClick: PropTypes.func,
    primary: PropTypes.bool,
    selected: PropTypes.bool,
    shortcut: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    to: PropTypes.string,
    endIcon: PropTypes.bool,
};

Button.defaultProps = {
    type: 'button',
};

export default withRouter(Button);