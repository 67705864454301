import React from "react"
import { Redirect, Route, Switch, Link, withRouter } from "react-router-dom"
import {
  withData,
  withNotify,
  toPreview,
  Previews,
  Button,
  Preview,
  SidebarItemUser,
  isLocalhost,
  ShareProvider,
  Callout,
  Root,
  Error,
  Icon,
  Sidebar,
  SidebarItem,
  SidebarGroup,
  SidebarWrap,
} from "./shared"
import queryString from "query-string"
import moment from "moment"

// Routes
import Action from "./routes/Action"
import Login from "./routes/Login"
import Today from "./routes/Today"
import New from "./routes/New"
import Buy from "./routes/Buy"
import Archive from "./routes/Archive"
import ProjectInterviews from "./routes/ProjectInterviews"
import ProjectFindings from "./routes/ProjectFindings"
import ProjectEmails from "./routes/ProjectEmails"
import ProjectForms from "./routes/ProjectForms"
import Account from "./routes/Settings"
import Schedule from "./routes/Schedule"
import PopoverOnboarding from "./routes/popovers/PopoverOnboarding"
import Setup from "./routes/Setup"
import LoggedOut from "./routes/LoggedOut"
import Find from "./routes/Find"
import BuyDetails from "./routes/BuyDetails"

class App extends React.Component {
  constructor(props) {
    super(props)
    // Skip the login popover if url contains ?demo=1
    this.isLocalhost = isLocalhost()
    const isDemo = queryString.parse(props.location.search).demo
    const isError = queryString.parse(props.location.search).err
    const plan = queryString.parse(props.location.search).plan
    const preview = queryString.parse(props.location.search).p
    if (isDemo && plan) {
      this.props.setSession({ isOnboarded: true, buyPlan: plan })
    } else if (isDemo) {
      this.props.setSession({ isOnboarded: true })
    } else if (plan) {
      this.props.setSession({ buyPlan: plan })
    }
    if (isError) {
      this.props.error(
        "InterviewKit crashed. The error has been reported.",
        "Please contact us if this issue persists.",
      )
    }
    if (isDemo || plan || isError) {
      if (preview) {
        props.history.replace(`${this.props.pathname || ""}?p=${preview}`)
      } else {
        props.history.replace(this.props.pathname)
      }
    }
  }
  componentDidMount() {
    if (!this.isLocalhost) {
      // Handle runtime errors
      window.onerror = async function (message) {
        if (message.includes("ResizeObserver loop limit exceeded")) {
          // Error can safely be ignored, see: https://stackoverflow.com/a/50387233
          return
        }
        window.localStorage.clear()
        window.sessionStorage.clear()
        window.location.href = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}/?err=1`
      }
    }
    window.onkeydown = function (e) {
      if (e.key === "Shift") window.appShiftKey = true
    }
    window.onkeyup = function (e) {
      if (e.key === "Shift") window.appShiftKey = false
    }
  }
  render() {
    const activeProjectId = this.props.location.pathname.startsWith(
      "/projects/",
    )
      ? this.props.location.pathname.split("/")[2]
      : false
    const activeProject = activeProjectId
      ? (this.props.projects || []).find((x) => x.id === activeProjectId) || {}
      : false
    const user = this.props.getUser()

    return (
      <Root
        name="InterviewKit"
        desc="InterviewKit is the all-in-one interview tool, that lets you interview better and save hours of time."
        img="https://interviewkit.co/assets/img.png"
        color="indigo-500"
        colorHex="#6366F1"
      >
        <SidebarWrap>
          <Sidebar
            logo={
              <div className="mt-3 mr-3 flex h-12 w-12 items-center justify-center rounded-full border-2 border-white bg-yellow-400 shadow-sm dark:border-gray-900">
                <Icon
                  icon="mic"
                  size={20}
                  className="mt-px"
                  color="yellow-800"
                />
              </div>
            }
          >
            <SidebarItem icon="home" to="/" alsoActive={["/widgets"]} exact>
              Today
            </SidebarItem>
            {!!this.props.plan && (
              <SidebarItem icon="calendar" to="/schedule" exact>
                Schedule
              </SidebarItem>
            )}
            {(this.props.projects || [])
              .sort((a, b) => {
                // Sort projects by creation date
                if (
                  a.createdBy === this.props.userId &&
                  b.createdBy !== this.props.userId
                )
                  return -1
                else if (
                  a.createdBy !== this.props.userId &&
                  b.createdBy === this.props.userId
                )
                  return 1
                else return a.createdOn
              })
              .filter((x) => !x.isArchived)
              .map((project) => {
                return (
                  <SidebarGroup
                    key={project.id}
                    title={project.name || "New project"}
                    iconTitle="Shared with me"
                    icon={project.createdBy !== this.props.userId && "share"}
                  >
                    <SidebarItem
                      icon="mic"
                      to={`/projects/${project.id}/interviews`}
                    >
                      Interviews
                    </SidebarItem>
                    <SidebarItem
                      icon="star"
                      to={`/projects/${project.id}/findings`}
                    >
                      Findings
                    </SidebarItem>
                    <SidebarItem
                      icon="send"
                      to={`/projects/${project.id}/emails`}
                    >
                      Emails
                    </SidebarItem>
                    <SidebarItem
                      icon="form"
                      to={`/projects/${project.id}/forms`}
                    >
                      Forms
                    </SidebarItem>
                  </SidebarGroup>
                )
              })}
            {activeProject.isArchived ? (
              <SidebarGroup
                key={activeProject.id}
                icon="archive"
                iconTitle="Archived"
                title={`${activeProject.name || "New project"}`}
              >
                <SidebarItem
                  icon="mic"
                  to={`/projects/${activeProject.id}/interviews`}
                >
                  Interviews
                </SidebarItem>
                <SidebarItem
                  icon="star"
                  to={`/projects/${activeProject.id}/findings`}
                >
                  Findings
                </SidebarItem>
                <SidebarItem
                  icon="send"
                  to={`/projects/${activeProject.id}/emails`}
                >
                  Emails
                </SidebarItem>
                <SidebarItem
                  icon="form"
                  to={`/projects/${activeProject.id}/forms`}
                >
                  Forms
                </SidebarItem>
              </SidebarGroup>
            ) : null}
            {!!this.props.plan && (
              <SidebarItem
                className="mt-4"
                icon="add"
                disabled={this.props.isDemo}
                to={toPreview("new-project")}
                exact
              >
                New project
              </SidebarItem>
            )}
            {this.props.isDemo && (
              <SidebarItem icon="arrow-right" to={toPreview("sign-up")}>
                Sign up
              </SidebarItem>
            )}
            {this.props.session.isDemoClosed && this.props.isDemo && (
              <div className="animate-up mt-4 border-t p-4">
                <h6 className="mb-1 text-indigo-500 dark:text-indigo-400">
                  Feel free to look around.
                </h6>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Please note that your data will not be saved and some features
                  are locked without signing up.
                </p>
                <Button
                  to={toPreview("sign-up")}
                  isSmall
                  className="mt-3 mb-1.5"
                  primary
                  disabled={this.props.location.search?.includes("sign-up")}
                  full
                >
                  Sign up
                </Button>
                <Button
                  onClick={() => this.props.setSession({ isDemoClosed: false })}
                  to="/"
                  isSmall
                  hasBorder
                  full
                >
                  Restart tour
                </Button>
              </div>
            )}
            {(this.props.projects || []).filter((x) => x.isArchived).length ? (
              <SidebarItem icon="archive" to={toPreview("archive")}>
                Archive
              </SidebarItem>
            ) : null}
            {user.willBeDeletedOn && (
              <Callout isWarning className="mt-6">
                Important: your projects will be deleted{" "}
                {moment(user.willBeDeletedOn, "X").fromNow()}. Please make sure
                to{" "}
                <Link className="link-orange" to="/settings/export">
                  export your data
                </Link>{" "}
                in time.
              </Callout>
            )}
            <SidebarItem
              icon="search"
              to={toPreview("find")}
              className="mt-auto"
              shortcut="cmd-f"
            >
              Find
            </SidebarItem>
            {this.props.isDemo ? (
              <SidebarItem icon="user" to="/login">
                Log in
              </SidebarItem>
            ) : (
              <SidebarItemUser to="/settings" />
            )}
          </Sidebar>

          <Previews>
            {!this.props.isDemo && !!this.props.plan && (
              <Preview
                value="new-project"
                render={(props) => <New {...props} />}
              />
            )}
            {!this.props.isDemo && !!this.props.plan && (
              <Preview
                value="archive"
                render={(props) => <Archive {...props} />}
              />
            )}
            {(this.props.isDemo || !this.props.plan) && (
              <Preview value="sign-up" render={(props) => <Buy {...props} />} />
            )}
            {(this.props.isDemo || !this.props.plan) && (
              <Preview
                value="sign-up-details"
                render={(props) => <BuyDetails {...props} />}
              />
            )}
            <Preview value="find" render={(props) => <Find {...props} />} />
          </Previews>

          <Switch>
            <Route path={["/", "/setup", "/widgets"]} exact component={Today} />
            <Route path="/schedule" component={Schedule} />
            <Route path="/login" component={Login} />
            <Route
              path="/projects/:projectId"
              render={(props) => {
                const project = (this.props.projects || []).find(
                  (x) => x.id === props.match.params.projectId,
                )
                return (
                  <ShareProvider project={project}>
                    <Switch>
                      <Route
                        path="/projects/:projectId/interviews/:interviewId?"
                        component={ProjectInterviews}
                      />
                      <Route
                        path="/projects/:projectId/findings/:findingId?"
                        component={ProjectFindings}
                      />
                      <Route
                        path="/projects/:projectId/emails/:emailId?"
                        component={ProjectEmails}
                      />
                      <Route
                        path="/projects/:projectId/forms/:formId?"
                        component={ProjectForms}
                      />
                      <Redirect
                        from="/projects/:projectId"
                        exact
                        to={`/projects/${props.match.params.projectId}/interviews`}
                      />
                    </Switch>
                  </ShareProvider>
                )
              }}
            />
            {!this.props.isDemo && (
              <Route path="/settings" component={Account} />
            )}
            <Route path="/logged-out" component={LoggedOut} />
            <Route path="/action" component={Action} />
            <Route render={(props) => <Error {...props} type="404" />} />
          </Switch>
          <Route path="/setup" component={Setup} />
          {this.props.isDemo &&
            !this.props.session.isOnboarded &&
            !this.props.location.pathname.startsWith("/login") &&
            !this.props.location.search?.includes("sign-up") &&
            !this.props.location.pathname.startsWith("/logged-out") &&
            !this.props.location.pathname.startsWith("/setup") &&
            !this.props.location.pathname.startsWith("/action") && (
              <PopoverOnboarding />
            )}
        </SidebarWrap>
      </Root>
    )
  }
}

export default withData(withNotify(withRouter(App)))
