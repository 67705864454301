import React from "react"
import {
  withData,
  State,
  Button,
  Shortcut,
  Dropdown,
  Icon,
  fName,
  List,
  format,
} from "../shared"
import { capitalize } from "../shared"
import moment from "moment"

class ProjectInterviewEmails extends React.Component {
  render() {
    const interview = this.props.interview
    const emails = (this.props.emails || [])
      .filter(
        (x) =>
          x.projectId === this.props.project.id &&
          x.sends.map((send) => send.to.email).includes(interview.email),
      )
      .sort((a, b) => b.createdOn - a.createdOn)
    const firstName = fName(interview.name)

    const fields = [
      { key: "toStr" },
      { key: "toNames" },
      { key: "subject" },
      { key: "message" },
      {
        key: "date",
        calc: (date, send) => {
          const sends = this.props.emails
            .find((x) => x.id === send.id)
            .sends.sort((a, b) => b.scheduledAt - a.scheduledAt)
          if (sends.isCancelled) return "Aborted"
          // Any email that is still (partially) scheduled should display "Scheduled"
          if (
            sends.length &&
            moment(sends[sends.length - 1].date, "X").isAfter(
              moment(),
              "minute",
            )
          )
            return "Scheduled"
          // Otherwise, just display the relative date.
          const day = format("date", date, {
            year: "not-current",
            isRelative: true,
          })
          return day === "today" ? format("time", date) : capitalize(day)
        },
      },
    ]

    return (
      <div className="animate-up flex h-full flex-col overflow-scroll p-10 pt-4">
        <Shortcut
          press="Escape"
          onPress={() =>
            this.props.history.push(`/projects/${this.props.project.id}`)
          }
        />
        {emails.length ? (
          <>
            <div className="flex items-center pt-1">
              <Icon size={16} className="mr-3" icon="send" />
              <h5>
                {emails.length} email{emails.length === 1 ? "" : "s"} sent
              </h5>
              <Button
                title="Compose"
                className="ml-auto"
                icon="add"
                disabled={!interview.email || this.props.disabled}
                to={`/projects/${this.props.project.id}/emails/new`}
                toData={{ toIds: [interview.id] }}
              />
              <Dropdown width={200} isInfo button={<Button icon="help" />}>
                <h6>Help</h6>
                <p className="mt-2 text-sm">
                  These are the emails you sent to {firstName}. Any replies from{" "}
                  {firstName} will go straight to your email address,{" "}
                  {this.props.userEmail}.
                </p>
              </Dropdown>
            </div>
            <div className="scroll mt-4 mb-5 flex-1">
              <List
                hasBorder
                disabled={this.props.disabled}
                keyPrimary="subject"
                keyTertiary="message"
                keyRight="date"
                fields={fields}
                to={`/projects/${this.props.project.id}/emails/:id`}
                data={emails.map((email) => ({
                  ...email.sends[0],
                  isCancelled: email.isCancelled,
                  id: email.id,
                }))}
              />
            </div>
          </>
        ) : (
          <State title="No emails sent" icon="send">
            <p className="mx-auto max-w-xs px-4 text-center">
              When you send an email to {firstName}, it will show up right here.
            </p>
            <Button
              className="mt-5"
              secondary
              to={`/projects/${this.props.project.id}/emails/new`}
              toData={{ toIds: [interview.id] }}
            >
              Compose
            </Button>
          </State>
        )}
      </div>
    )
  }
}

export default withData(ProjectInterviewEmails)
