import React from 'react';
import Dropzone from 'react-dropzone';
import Icon from "./Icon";
import {checkIfDark} from "../functions";

class FileDrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEntered: false,
        };
        this.dropzone = React.createRef();
    }
    openFileDialog() {
        if(this.dropzone) this.dropzone.open();
    }
    render() {
        return (
            <Dropzone
                ref={this.dropzone}
                accept={this.props.accept}
                multiple={this.props.multiple}
                disabled={this.props.disabled}
                noClick={!this.props.canClick}
                onDragEnter={() => this.setState({isEntered: true})}
                onDragLeave={() => this.setState({isEntered: false})}
                onDrop={files => {
                    if(!this.props.multiple && files.length > 1) return this.setState({isEntered: false});;
                    this.props.onChange(this.props.multiple ? files : files[0]);
                    this.setState({isEntered: false});
                }}>
                {({getRootProps, getInputProps}) => (
                    <>
                        <input {...getInputProps()} />
                        <div className={`relative focus:outline-none ${this.props.fullScreen ? 'h-full overflow-hidden' : 'rounded-lg'} ${this.props.className || ''}`} {...getRootProps()}>
                            {this.props.children ? <>
                                {this.props.children}
                                {this.state.isEntered ? <div className={`absolute inset-0 z-10 flex items-center justify-center flex-col`} style={{background: checkIfDark() ? 'rgba(31,41,55,0.5)' : 'rgba(255,255,255,0.5)'}}>
                                    <Icon icon={this.props.icon} />
                                    <h5 className='mt-4'>{this.props.title}</h5>
                                </div> : null}
                            </> : (
                                <div className={`${this.state.isEntered ? 'bg-gray-100 dark:bg-gray-700' : ''} py-16 h-full w-full flex items-center justify-center select-none ${this.props.fullScreen ? 'h-full' : 'rounded-lg'} ${this.props.classNameChild || ''}`}>
                                    <div className={`${this.state.isEntered ? 'opacity-50' : ''} flex items-center justify-center flex-col`}>
                                        <Icon icon={this.props.icon} />
                                        <h5 className='mt-4'>{this.props.title}</h5>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Dropzone>
        );
    }
}

FileDrop.defaultProps = {
    title: 'Drop file here...',
    icon: 'Clouds.ProgrammingCloudDownload',
};

export default FileDrop;