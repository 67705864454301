import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Icon from "./Icon"
import { formatShortcut } from "../functions"

class DropdownItem extends React.Component {
  handleClick(e) {
    if (this.props.extLink) {
      window.open(this.props.extLink)
    } else if (this.props.to) {
      this.props.history.push(this.props.to, this.props.toData)
    }
    if (this.props.onClick) {
      // onClick is also added by <Dropdown /> to make autoClose work.
      this.props.onClick(e)
    }
  }
  render() {
    const text =
      this.props.to && this.props.children ? (
        <>{this.props.children}...</>
      ) : (
        this.props.children
      )
    const color = this.props.isRed ? "red-500" : window.appColor
    return (
      <>
        {this.props.borderTop ? <div className="mt-1 border-t pt-1" /> : null}
        <div
          onClick={this.handleClick.bind(this)}
          className={`whitespace-nowrap px-3 py-2 ${this.props.isRed ? "text-red-600 dark:text-red-500" : ""
            } text-sm ${this.props.isHover ? `bg-${color} text-white` : ""} ${this.props.isActive ? `bg-${color} text-white` : ""
            } ${this.props.isActive || this.props.isHover
              ? ""
              : `hover:bg-${color} hover:text-white dark:hover:text-white active:bg-${color}`
            } ${this.props.disabled
              ? "pointer-events-none opacity-60"
              : "cursor-pointer"
            } select-none rounded ${this.props.className || ""}`}
        >
          <div className="flex w-full items-center">
            {this.props.icon ? (
              <Icon
                size={12}
                icon={this.props.icon}
                fill={this.props.iconFill}
                color={this.props.iconColor}
                colorDark={this.props.iconColorDark}
                className="mr-3"
              />
            ) : null}
            <div className="w-full flex-1">
              <div className="flex w-full items-center">
                <div
                  className={`mr-auto flex-none truncate font-medium ${this.props.color ? "mr-2" : ""
                    }`}
                >
                  {text}
                  {this.props.extLink && " ↗"}
                </div>
                {this.props.color && (
                  <div
                    className={`ml-auto inline-block h-2 w-2 rounded-full ring-1 ring-white dark:ring-gray-800 bg-${this.props.color}-500`}
                  />
                )}
                {this.props.sub != null ? (
                  <div className="ml-2 truncate font-medium opacity-75">
                    {this.props.sub}
                  </div>
                ) : this.props.shortcut ? (
                  <div
                    className="ml-2 flex-none text-center font-medium opacity-75"
                    style={{ minWidth: 9 }}
                  >
                    {formatShortcut(this.props.shortcut)}
                  </div>
                ) : null}
              </div>
              {this.props.desc ? (
                <p className="mt-1 truncate text-xs opacity-75">
                  {this.props.desc}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}

DropdownItem.propTypes = {
  className: PropTypes.string,
  autoClose: PropTypes.bool,
  exact: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
}

DropdownItem.defaultProps = {
  autoClose: true,
}

export default withRouter(DropdownItem)
