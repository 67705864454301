import React from 'react';
import PropTypes from "prop-types";
import {withData} from '../data';

class SidebarWrap extends React.Component {
    render() {
        return (
            <div className={`overflow-hidden grid overflow-hidden flex-1 h-full flex w-full ${this.props.className || ''}`} style={{gridTemplateColumns: '240px 1fr'}}>
                {this.props.children}
            </div>
        );
    }
}

SidebarWrap.propTypes = {
    className: PropTypes.string,
};

export default withData(SidebarWrap);