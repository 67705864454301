import React from "react"
import {
  Page,
  Sheet,
  ButtonSwitch,
  withData,
  Icon,
  withNotify,
} from "../shared"

class Widgets extends React.Component {
  render() {
    const user = this.props.getUser()
    const numWidgets = user.widgetDisplay === "compact" ? 6 : 3
    return (
      <Page
        isPopup
        backTo="/"
        hasDone
        width="lg"
        hasPadding
        title="Today settings"
      >
        <h6 className="mb-2">View</h6>
        <ButtonSwitch
          value={user.widgetDisplay || null}
          onChange={(widgetDisplay) =>
            this.props.update("usersPrivate", user.id, { widgetDisplay })
          }
          buttons={[
            { value: null, text: "Simple" },
            { value: "compact", text: "Compact" },
          ]}
        />
        <h6 className="mb-2 mt-6">Widgets</h6>
        <p className="mb-5 text-gray-500">
          Reorder widgets to your preference. The first {numWidgets} widgets
          will be shown on your dashboard.
        </p>
        <Sheet
          noHead
          readOnly
          isNumbered={numWidgets}
          buttons={(dataItem) => (
            <div className="mr-4 mt-2">
              <Icon icon={dataItem.icon} size={14} />
            </div>
          )}
          canReorder
          fields={[{ key: "name" }]}
          data={this.props.widgets.map((widget) => ({
            ...widget,
            name: widget.editName || widget.name,
          }))}
          onUpdate={(widgets) =>
            this.props.update("usersPrivate", user.id, {
              widgets: widgets.slice(0, 6).map((x) => x.id),
            })
          }
        />
      </Page>
    )
  }
}

Widgets.propTypes = {}

export default withData(withNotify(Widgets))
