import React from "react"
import {
  Callout,
  State,
  DropdownItem,
  DemoBox,
  InputGroup,
  Toggle,
  Button,
  Page,
  Icon,
  Dropdown,
  fName,
  Input,
  format,
  withData,
  withNotify,
  isLocalhost,
} from "../../shared"
import { Link } from "react-router-dom"
import moment from "moment"
import { isScheduledOutsideAvailability, checkOverlap } from "../../functions"
// eslint-disable-next-line import/no-webpack-loader-syntax
import AutoscheduleWorker from "worker-loader!../../workers/autoschedule.worker.js"

class PopoverSchedule extends React.Component {
  constructor(props) {
    super(props)
    this.debug = isLocalhost()
    this.state = {
      isLoading: true,
      isOnlyWithAvailability: false,
      obj: {},
      OBJ_AUTO: {},
      date: "",
      time: "",
    }
  }
  componentWillUnmount() {
    if (this.autoschedule) this.autoschedule.terminate()
  }
  componentDidMount() {
    const selectedIds = this.props.location.state
      ? this.props.location.state.selectedIds || []
      : []
    if (selectedIds.length === 0)
      return this.props.history.push(
        `/projects/${this.props.match.params.projectId}/interviews`,
      )
    const interviews = this.props.interviews.filter((x) =>
      (selectedIds || []).includes(x.id),
    )
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    this.autoSchedule(project, interviews)
  }
  autoSchedule(project, interviews, updateObj = {}) {
    this.setState({ isLoading: true })
    this.autoschedule = new AutoscheduleWorker()
    const allInterviews = this.props.interviews
    const { isOnlyWithAvailability } = this.state
    this.autoschedule.postMessage({
      project,
      interviews,
      allInterviews,
      updateObj,
      isOnlyWithAvailability,
    })
    this.autoschedule.onmessage = async ({ data }) => {
      await new Promise((res) => setTimeout(res, 800))
      let obj = data
      const OBJ_AUTO = JSON.parse(JSON.stringify(obj))
      this.setState({ obj, OBJ_AUTO, isLoading: false }) // OBJ_AUTO is a copy that should not be altered outside of autoSchedule
      this.autoschedule.terminate()
      this.autoschedule = null
    }
    this.autoschedule.onerror = (e) => {
      this.setState({ isLoading: false, error: e.message })
      this.autoschedule.terminate()
      this.autoschedule = null
    }
  }
  finalize(interviewsToSchedule, interviewsToClear) {
    const obj = {}
    for (const interviewId of Object.keys(this.state.obj)) {
      const val = this.state.obj[interviewId]
      obj[interviewId] = {
        date: val.date || null,
        interviewerId: val.interviewerId || null,
      }
    }
    this.props.updateMultiple("interviews", obj)
    if (!this.props.local.interviewsSortBy)
      this.props.setLocal({ interviewsSortBy: "date" })
    if (interviewsToSchedule === 0 && interviewsToClear > 0)
      this.props.success(
        `Dates cleared from ${interviewsToClear} interview${
          interviewsToClear === 1 ? "" : "s"
        }`,
      )
    else if (interviewsToClear === 0 && interviewsToSchedule > 0)
      this.props.success(
        `Dates added to ${interviewsToSchedule} interview${
          interviewsToSchedule === 1 ? "" : "s"
        }`,
      )
    else
      this.props.success(
        `Dates added to ${interviewsToSchedule} interview${
          interviewsToSchedule === 1 ? "" : "s"
        } and cleared from ${interviewsToClear} interview${
          interviewsToClear === 1 ? "" : "s"
        }`,
      )
    this.props.history.push(
      `/projects/${this.props.match.params.projectId}/interviews`,
    )
  }
  render() {
    let selectedIds = this.props.location.state
      ? this.props.location.state.selectedIds || []
      : []
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    if (this.state.isOnlyWithAvailability)
      selectedIds = selectedIds.filter((id) => {
        const interview = this.props.interviews.find((x) => x.id === id)
        return interview?.availability?.length > 0
      })
    const interviews = this.props.interviews
      .filter((x) => selectedIds.includes(x.id))
      .sort((intA, intB) => {
        const a = this.state.OBJ_AUTO[intA.id] || {}
        const b = this.state.OBJ_AUTO[intB.id] || {}
        if (a.date && !b.date) return -1
        if (!a.date && b.date) return 1
        if (a.date < b.date) return -1
        if (a.date > b.date) return 1
        return 0
      })
    const otherInterviewsThatAreScheduled = this.props.interviews.filter(
      (x) =>
        x.date &&
        !selectedIds.includes(x.id) &&
        (!project.scheduleAllowOverlap || x.projectId === project.id),
    )
    let isError = false
    let title
    const hasAvailability =
      project.interviewers &&
      project.interviewers.length > 0 &&
      project.interviewers[0].availability &&
      project.interviewers[0].availability.filter((x) => x.from && x.until)
        .length > 0
    const objArr = Object.keys(this.state.obj).map((id) => ({
      id,
      ...this.state.obj[id],
    }))
    const objAutoArr = Object.values(this.state.OBJ_AUTO)
    const successAmount = objAutoArr.filter((x) => x.date).length
    const backTo = `/projects/${project.id}/interviews`
    if (successAmount > 0) {
      title = `${successAmount} ${
        successAmount === selectedIds.length
          ? ""
          : `out of ${selectedIds.length}`
      } interview${successAmount > 1 ? "s" : ""} can be scheduled.`
    } else if (!hasAvailability) {
      isError = true
      title = (
        <span>
          Add{" "}
          <span
            className="link-orange"
            onClick={() =>
              this.props.history.push(`${backTo}/settings/interviewers`)
            }
          >
            interviewers
          </span>{" "}
          to enable automatic scheduling.
        </span>
      )
    } else {
      isError = true
      title = "No interviews can be scheduled."
    }
    const amountToSchedule = objArr.filter((x) => x.date).length
    const toClear = objArr.filter((x) => {
      if (x.date) return false
      const interview = this.props.interviews.find((y) => y.id === x.id)
      return interview.date
    })
    const amountToClear = toClear.length
    const interviewers = project.interviewers || []
    const hasMultipleInterviewers = interviewers.length > 1
    const gridTemplateColumns = `19px 1fr ${
      hasMultipleInterviewers ? "150px" : ""
    } 200px 33px`
    const dateFormat = {
      day: "short",
      includeTime: true,
      isRelative: !this.props.local.scheduleAbsoluteDates,
      capitalize: true,
      year: "not-current",
    }
    const someEdited =
      JSON.stringify(this.state.obj) !== JSON.stringify(this.state.OBJ_AUTO)
    const someWithoutAvailability = interviews.some(
      (x) => !x.availability || x.availability.length === 0,
    )
    const hasBreaks = project.scheduleBreaks || project.scheduleLunchBreak
    return (
      <Page
        isPopup
        isLoading={this.state.isLoading}
        title={`Schedule ${selectedIds.length} ${
          selectedIds.length === 1 ? "interview" : "interviews"
        }`}
        position={this.props.position}
        width={hasMultipleInterviewers ? "5xl" : "3xl"}
        backTo={backTo}
        showError={this.state.error}
        icon="clock"
        height={700}
        moreWidth={260}
        barRight={
          <>
            <Button
              to={`${backTo}/settings/interviewers`}
              disabled={this.props.isDemo}
              icon="edit"
              title={`Edit interviewers (${
                (project.interviewers || []).length
              })`}
            />
            <Dropdown
              isInfo
              arrowPosition="right"
              width={260}
              button={<Button icon="help" />}
            >
              <h6>About scheduling</h6>
              {!hasAvailability && (
                <p className="mt-3 text-sm font-medium">
                  Add interviewers in Project Settings to enable automatic
                  scheduling.
                </p>
              )}
              <p className="mt-2 text-sm">
                Autoschedule will suggest the optimal time for each interview,
                matching their availability with the availability of the
                interviewer.
              </p>
              <p className="mt-2 text-sm">
                To override scheduling suggestions, you can always edit dates
                and times manually.
              </p>
              <p className="mt-2 text-sm">
                Note: interviews will be scheduled no earlier than the next hour
                (
                {moment()
                  .startOf("hour")
                  .add(1, "hour")
                  .format(window.appTimeFormat || "H:mm")}
                ).
              </p>
            </Dropdown>
          </>
        }
        more={
          <>
            <DropdownItem
              disabled={!someEdited}
              icon="arrow-left"
              onClick={() => this.autoSchedule(project, interviews)}
            >
              Undo all manual edits
            </DropdownItem>
            <DropdownItem
              icon="coffee"
              onClick={async () => {
                let {
                  scheduleBreaks,
                  scheduleBreakDuration,
                  scheduleLunchBreak,
                  scheduleLunchBreakDuration,
                  scheduleLunchBreakTime,
                } = project
                const confirm = await this.props.openModal({
                  icon: "coffee",
                  action: "Set breaks",
                  title: `${hasBreaks ? "Edit" : "Add"} interview breaks`,
                  child: (
                    <div>
                      <Toggle
                        defaultValue={scheduleBreaks}
                        onChange={(x) => (scheduleBreaks = x)}
                      >
                        Break between interviews
                      </Toggle>
                      <Input
                        className="mt-2 mb-6"
                        defaultValue={scheduleBreakDuration}
                        onBlur={(x) => (scheduleBreakDuration = x)}
                        hasBorder
                        type="number"
                        placeholder="15"
                        after="minutes"
                      />
                      <Toggle
                        defaultValue={scheduleLunchBreak}
                        onChange={(x) => (scheduleLunchBreak = x)}
                      >
                        Lunch break
                      </Toggle>
                      <InputGroup
                        hasBorder
                        className="mt-2 mb-2"
                        doc={project}
                        beforeWidth={34}
                        onBlur={(x) => {
                          scheduleLunchBreakTime = x.scheduleLunchBreakTime
                          scheduleLunchBreakDuration =
                            x.scheduleLunchBreakDuration
                        }}
                        fields={[
                          {
                            key: "scheduleLunchBreakTime",
                            positionTop: true,
                            before: "At",
                            placeholder: "12:00",
                            type: "time",
                          },
                          {
                            key: "scheduleLunchBreakDuration",
                            before: "For",
                            after: "minutes",
                            placeholder: 60,
                            type: "number",
                          },
                        ]}
                      />
                    </div>
                  ),
                })
                if (confirm) {
                  let somethingChanged =
                    scheduleBreaks !== project.scheduleBreaks ||
                    scheduleLunchBreak !== project.scheduleLunchBreak
                  if (!somethingChanged && scheduleBreaks) {
                    somethingChanged =
                      scheduleBreakDuration !== project.scheduleBreakDuration
                  }
                  if (!somethingChanged && scheduleLunchBreak) {
                    somethingChanged =
                      scheduleLunchBreakDuration !==
                        project.scheduleLunchBreakDuration ||
                      scheduleLunchBreakTime !== project.scheduleLunchBreakTime
                  }
                  if (somethingChanged) {
                    const obj = {
                      scheduleBreaks: scheduleBreaks || false,
                      scheduleBreakDuration: scheduleBreakDuration || null,
                      scheduleLunchBreak: scheduleLunchBreak || false,
                      scheduleLunchBreakDuration:
                        scheduleLunchBreakDuration || null,
                      scheduleLunchBreakTime: scheduleLunchBreakTime || null,
                    }
                    this.props.update("projects", project.id, obj)
                    this.autoSchedule(project, interviews, obj)
                  }
                }
              }}
            >
              {hasBreaks ? "Edit" : "Add"} interview breaks
            </DropdownItem>
            {hasBreaks && (
              <p className="sub p mb-4 pt-2">
                You set{" "}
                {project.scheduleBreaks &&
                  `a ${
                    project.scheduleBreakDuration || 15
                  }-minute break between every interview`}
                {project.scheduleBreaks &&
                  project.scheduleLunchBreak &&
                  " and "}
                {project.scheduleLunchBreak &&
                  `a ${
                    project.scheduleLunchBreakDuration || 60
                  }-minute lunch break at ${format(
                    "time",
                    project.scheduleLunchBreakTime || "12:00:00",
                  )}`}
                .
              </p>
            )}
            <Toggle
              borderTop
              defaultValue={this.props.local.scheduleAbsoluteDates}
              onChange={(x) => {
                this.props.setLocal({ scheduleAbsoluteDates: x })
              }}
            >
              Show absolute dates
            </Toggle>
            <Toggle
              defaultValue={project.scheduleAllowOverlap}
              onChange={(x) => {
                this.props.update("projects", project.id, {
                  scheduleAllowOverlap: x,
                })
                this.autoSchedule(project, interviews, {
                  scheduleAllowOverlap: x,
                })
              }}
            >
              Allow project overlap
            </Toggle>
            <p className="sub p mb-1 pt-3">
              Interviews will {project.scheduleAllowOverlap ? "" : "not"} be
              scheduled during interviews from other projects.
            </p>
          </>
        }
        hasPadding
        footerGrid={2}
        footer={
          <>
            <Button
              shortcut="Enter"
              onClick={() => this.finalize(amountToSchedule, amountToClear)}
              disabled={amountToSchedule === 0 && amountToClear === 0}
              primary
            >
              Set interview dates
            </Button>
            <Button secondary to={backTo}>
              Cancel
            </Button>
          </>
        }
      >
        {this.props.isDemo && this.props.session.isOnboarded && (
          <DemoBox
            step={5}
            back="/projects/demo-project/interviews"
            to={`/projects/${project.id}/emails/new`}
            toData={{
              toIds: ["demo-interview-4", "demo-interview-5"],
              subject: "Interview at [dateshort]",
              message:
                "Hi [firstname],\n\nThank you for applying to our coffee tasting! Based on your availability, I scheduled you on [date] at [time]. \n\nIf you have any questions, feel free to reply. See you then!\n\nDemo Denise\nResearcher, Lungo Forte",
            }}
            isRight
            primary="InterviewKit can schedule these interviews for us."
            secondary="Feel free to play around with the settings: you can add coffee and lunch breaks, work with multiple interviewers or manage overlapping projects."
          />
        )}

        {interviews.length > 0 ? (
          <>
            <Callout
              className="animate-up"
              isSuccess={selectedIds.length === successAmount}
              isWarning={isError}
            >
              {title}{" "}
              {`You can always ${
                successAmount > 0 ? "change" : "add"
              } dates manually.`}
            </Callout>
            {hasAvailability &&
              !this.state.isOnlyWithAvailability &&
              someWithoutAvailability && (
                <Callout className="animate-up mt-2">
                  Some interviewees have no availability. They will be scheduled
                  within the full availability of the interviewer
                  {hasMultipleInterviewers ? "s" : ""}. You can also{" "}
                  <span
                    onClick={() =>
                      this.setState({ isOnlyWithAvailability: true }, () =>
                        this.autoSchedule(project, interviews),
                      )
                    }
                    className="cursor-pointer"
                    style={{ borderBottom: "1px solid #D5D5D5" }}
                  >
                    only schedule interviews with availability
                  </span>
                  .
                </Callout>
              )}
            <div
              className="animate-up grid gap-5 pt-7"
              style={{ gridTemplateColumns }}
            >
              <h6 className="col-span-2">Interviewee</h6>
              {hasMultipleInterviewers && <h6>Interviewer</h6>}
              <h6>New date</h6>
            </div>
            <div className="animate-up pt-3 pb-4">
              {interviews.map((interview, i) => {
                const interviewObj = this.state.obj[interview.id] || {}
                const INTERVIEWOBJ_AUTO =
                  this.state.OBJ_AUTO[interview.id] || {}
                const objArr = Object.keys(this.state.obj).map((id) => ({
                  id,
                  ...this.state.obj[id],
                }))
                const isScheduled = !!interviewObj.date
                const IS_AUTO_SCHEDULED = !!INTERVIEWOBJ_AUTO.date
                const wasScheduled = !!interview.date
                const isManualDate =
                  isScheduled && interviewObj.date !== INTERVIEWOBJ_AUTO.date
                const isManualInterviewer =
                  isScheduled &&
                  interviewObj.interviewerId !== INTERVIEWOBJ_AUTO.interviewerId
                const isDateManuallyCleared = !isScheduled && IS_AUTO_SCHEDULED
                const isInterviewerManuallyCleared =
                  !interviewObj.interviewerId && IS_AUTO_SCHEDULED
                const duration = interview.duration || project.duration || 60
                const interviewer =
                  hasAvailability &&
                  (project.interviewers.length === 1
                    ? project.interviewers[0]
                    : project.interviewers.find(
                        (x) => x.id === interviewObj.interviewerId,
                      ))
                const interviewerAvailability =
                  interviewer &&
                  (interviewer.availability || []).filter(
                    (x) => x.from && x.until,
                  )
                const interviewAvailability = (
                  interview.availability || []
                ).filter((x) => x.from && x.until)
                const isScheduledOutsideInterviewerAvailability =
                  (isManualDate || isManualInterviewer) &&
                  isScheduledOutsideAvailability(
                    interviewObj,
                    duration,
                    interviewerAvailability,
                  )
                const isScheduledOutsideInterviewAvailability =
                  (isManualDate || isManualInterviewer) &&
                  isScheduledOutsideAvailability(
                    interviewObj,
                    duration,
                    interviewAvailability,
                  )
                const start = isScheduled
                  ? moment(interviewObj.date, "X").format("HH:mm")
                  : false
                const end = isScheduled
                  ? moment(interviewObj.date, "X")
                      .add(
                        interview.duration || project.duration || 60,
                        "minutes",
                      )
                      .format("HH:mm")
                  : false
                let overlapsWith =
                  isScheduled &&
                  otherInterviewsThatAreScheduled.find((x) => {
                    // Interviews outside of this schedule
                    if (x.id === interview.id) return false
                    if (
                      !x.date ||
                      !moment(x.date, "X").isSame(
                        moment(interviewObj.date, "X"),
                        "day",
                      )
                    )
                      return false
                    const xStart = moment(x.date, "X").format("HH:mm")
                    const xEnd = moment(x.date, "X")
                      .add(x.duration, "minutes")
                      .format("HH:mm")
                    // Now only interviews on same day with a time remain.
                    return checkOverlap([
                      [start, end],
                      [xStart, xEnd],
                    ])
                  })
                if (!overlapsWith && isScheduled)
                  overlapsWith = objArr.find((x) => {
                    // Interviews in this schedule
                    if (x.id === interview.id) return false
                    if (
                      !x.date ||
                      !moment(x.date, "X").isSame(
                        moment(interviewObj.date, "X"),
                        "day",
                      )
                    )
                      return false
                    const xStart = moment(x.date, "X").format("HH:mm")
                    const xEndTime = moment(x.date, "X")
                      .add(
                        interviews.find((y) => y.id === x.id)?.duration ||
                          project.duration ||
                          60,
                        "minutes",
                      )
                      .unix()
                    const xEnd = moment(xEndTime, "X").format("HH:mm")
                    // Now only interviews on same day with a time remain.
                    return checkOverlap([
                      [start, end],
                      [xStart, xEnd],
                    ])
                  })
                const updateObj = (key, val) => {
                  interviewObj[key] = val || ""
                  this.setState({
                    obj: { ...this.state.obj, [interview.id]: interviewObj },
                  })
                }
                const doClear = toClear.find((x) => x.id === interview.id)
                const doNothing = !isScheduled && !doClear
                let isRed = false
                let desc = ""
                if (overlapsWith) {
                  const overlapsWithName = overlapsWith
                    ? this.props.interviews.find(
                        (x) => x.id === overlapsWith.id,
                      ).name || "Unnamed"
                    : false
                  desc = `Overlaps with ${overlapsWithName} (${format(
                    "date",
                    overlapsWith.date,
                    { ...dateFormat, capitalize: false },
                  )})`
                  isRed = true
                } else if (
                  isScheduledOutsideInterviewerAvailability &&
                  isScheduledOutsideInterviewAvailability
                ) {
                  desc = `Scheduled outside ${fName(interview.name, {
                    withS: true,
                  })} and interviewer's availability`
                  isRed = true
                } else if (isScheduledOutsideInterviewerAvailability) {
                  desc = `Scheduled outside interviewer's availability`
                  isRed = true
                } else if (isScheduledOutsideInterviewAvailability) {
                  desc = `Scheduled outside ${fName(interview.name, {
                    withS: true,
                  })} availability`
                  isRed = true
                } else if (
                  wasScheduled &&
                  isScheduled &&
                  interview.date === interviewObj.date
                ) {
                  desc = `Already scheduled at this time`
                } else if (wasScheduled && isScheduled) {
                  desc = `Rescheduled from ${format("date", interview.date, {
                    ...dateFormat,
                    capitalize: false,
                  })}`
                } else if (
                  interviewObj.error &&
                  hasAvailability &&
                  !isScheduled
                ) {
                  // Check for availability.length because desc will redundantly say "cannot schedule" for all if no interviewer availability.
                  desc = interviewObj.error
                  if (doClear) desc += " → clear schedule"
                }
                const isEditedDate =
                  (isManualDate && IS_AUTO_SCHEDULED) || isDateManuallyCleared
                const isEditedInterviewer =
                  (isManualInterviewer && IS_AUTO_SCHEDULED) ||
                  isInterviewerManuallyCleared
                const isEditedInState =
                  (this.state.date &&
                    this.state.date !==
                      moment(interviewObj.date, "X").startOf("day").unix()) ||
                  (this.state.time &&
                    this.state.time !==
                      moment(interviewObj.date, "X").format("HH:mm:ss")) ||
                  (this.state.interviewerId &&
                    this.state.interviewerId !== interviewObj.interviewerId)
                let isUpdateDisabled =
                  !isEditedInState ||
                  !(this.state.date || interviewObj.date) ||
                  (!this.state.time && !interviewObj.date)

                const availabilityTitle =
                  `${desc ? `${desc} \n\n` : ""}Availability of ${
                    interview.name
                  }: \n` +
                  (interviewAvailability
                    .map(
                      (x) =>
                        `• ${format("date", x.from, {
                          day: "short",
                          capitalize: true,
                          isRelative: !this.props.local.scheduleAbsoluteDates,
                          year: "not-current",
                        })} — ${format("time", x.from)} to ${format(
                          "time",
                          x.until,
                        )}`,
                    )
                    .join("\n") ||
                    "No availability provided – assuming availability is equal to that of the interviewer.")
                return (
                  <div
                    key={interview.id}
                    className={`grid border-b pl-2 ${
                      i === 0 ? "border-t" : ""
                    } items-center gap-5 h-${
                      interviewAvailability.length ? 16 : 20
                    }`}
                    style={{ gridTemplateColumns }}
                  >
                    <div className="mr-2 mt-px flex items-center justify-center">
                      {
                        <Icon
                          icon={
                            isRed
                              ? "warning"
                              : isScheduled
                              ? "Validation.InterfaceValidationCheck"
                              : doNothing
                              ? "AddRemoveDelete.InterfaceRemove1"
                              : "AddRemoveDelete.InterfaceDelete1"
                          }
                          size={!isRed ? 12 : 14}
                          color={`${
                            isRed
                              ? "orange-500"
                              : isScheduled
                              ? "green-500"
                              : "gray-400"
                          }`}
                        />
                      }
                    </div>
                    <div className="overflow-hidden" title={availabilityTitle}>
                      <Link
                        to={`/projects/${project.id}/interviews/${interview.id}/availability`}
                        className="truncate dark:text-white"
                      >
                        {interview.name}
                      </Link>
                      <div
                        className={`truncate text-sm ${
                          isRed ? "text-orange-500" : "text-gray-500"
                        }`}
                      >
                        {desc}
                      </div>
                      {!interviewAvailability.length && (
                        <div className="truncate text-sm text-orange-500">
                          No availability provided
                        </div>
                      )}
                    </div>
                    {hasMultipleInterviewers && (
                      <div
                        className={`truncate leading-normal ${
                          interviewObj.interviewerId
                            ? isEditedInterviewer
                              ? "text-indigo-500"
                              : ""
                            : "text-gray-500"
                        }`}
                      >
                        {interviewObj.interviewerId
                          ? (
                              interviewers.find(
                                (x) => x.id === interviewObj.interviewerId,
                              ) || {}
                            ).name || "Unnamed"
                          : "None"}
                      </div>
                    )}
                    <div
                      className={`truncate leading-normal ${
                        isEditedDate ? "text-indigo-500" : ""
                      } ${interviewObj.date ? "" : "text-gray-500"}`}
                    >
                      {format("date", interviewObj.date, dateFormat) ||
                        "Not scheduled"}
                    </div>
                    <Dropdown
                      arrowPosition="center"
                      width={270}
                      approxHeight={hasMultipleInterviewers ? 270 : 250}
                      button={
                        <Button
                          isSmall
                          title={interviewObj.date ? "Reschedule" : "Schedule"}
                          icon="edit"
                        />
                      }
                    >
                      <DropdownItem
                        disabled={!isEditedDate}
                        onClick={() => {
                          updateObj("date", INTERVIEWOBJ_AUTO.date)
                          updateObj(
                            "interviewerId",
                            INTERVIEWOBJ_AUTO.interviewerId,
                          )
                        }}
                        isSmall
                        icon="arrow-left"
                      >
                        Undo manual edits
                      </DropdownItem>
                      <DropdownItem
                        disabled={!(interviewObj.date || interview.duration)}
                        onClick={() => {
                          updateObj("date", "")
                          updateObj("interviewerId", null)
                        }}
                        icon="delete"
                      >
                        Clear
                      </DropdownItem>
                      <h6 className="px-3 pb-1 pt-4">
                        {interviewObj.date ? "Reschedule" : "Schedule"} manually
                      </h6>
                      <InputGroup
                        className="p mb-3"
                        beforeWidth={hasMultipleInterviewers ? 80 : 60}
                        doc={{
                          date: this.state.date || interviewObj.date,
                          time: this.state.time || interviewObj.date,
                          duration: interview.duration,
                          interviewerId: interviewObj.interviewerId,
                        }}
                        onBlur={(data) => this.setState(data)}
                        fields={[
                          {
                            key: "date",
                            type: "date",
                            placeholder: "Set date...",
                            format: { day: "short" },
                            button: this.state.date ? (
                              <Button
                                isSmall
                                onClick={() => this.setState({ date: "" })}
                                icon="cross"
                              />
                            ) : (
                              interviewObj.date && (
                                <Button
                                  isSmall
                                  onClick={() =>
                                    this.setState({ date: interviewObj.date })
                                  }
                                  icon="arrow-left"
                                />
                              )
                            ),
                          },
                          {
                            key: "time",
                            type: "time",
                            placeholder: "Set time...",
                            button: this.state.time && (
                              <Button
                                isSmall
                                onClick={() => this.setState({ time: "" })}
                                icon="cross"
                              />
                            ),
                          },
                          hasMultipleInterviewers && {
                            key: "interviewerId",
                            before: "Interviewer",
                            type: "dropdown",
                            canClear: false,
                            options: interviewers.map((x) => ({
                              name: x.name || "Unnamed",
                              key: x.id,
                            })),
                          },
                        ]}
                      />
                      <Button
                        primary
                        disabled={isUpdateDisabled}
                        full
                        onClick={() => {
                          const time =
                            this.state.time ||
                            moment(interviewObj.date, "X").format("HH:mm:ss")
                          updateObj(
                            "date",
                            moment(this.state.date || interviewObj.date, "X")
                              .hours(time.split(":")[0])
                              .minutes(time.split(":")[1])
                              .startOf("minute")
                              .unix(),
                          )
                          if (
                            hasMultipleInterviewers &&
                            this.state.interviewerId
                          )
                            updateObj("interviewerId", this.state.interviewerId)
                          this.setState({
                            date: "",
                            time: "",
                            interviewerId: null,
                          })
                        }}
                      >
                        Done
                      </Button>
                    </Dropdown>
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <State icon="clock" title="No interviews to schedule" />
        )}
      </Page>
    )
  }
}

export default withData(withNotify(PopoverSchedule))
