import React from "react"
import { withRouter } from "react-router-dom"
import { withData } from "../data"
import { AnimateSharedLayout } from "framer-motion"
import { stripCharacters } from "../functions"

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openLayer: null,
    }
  }
  render() {
    // p-3 cannot be on outer div since Columns can't fully hide Sidebar that way.
    // width should be 240 so that Columns can animate it without text wrapping.
    return (
      <AnimateSharedLayout>
        <div
          className={`${
            this.props.isInline
              ? ""
              : "border-light-dark h-full overflow-hidden border-r bg-gray-50 dark:bg-gray-900"
          } ${this.props.className || ""}`}
        >
          <div
            className={`h-full overflow-scroll ${
              this.props.isInline ? "" : "flex flex-col p-3 pt-0"
            }`}
          >
            {this.props.logo || this.props.right ? (
              <div className="ml-3 mb-4 flex h-16 flex-none items-center">
                {this.props.logo
                  ? React.cloneElement(this.props.logo, {
                      className: `${
                        this.props.logo.props.className || ""
                      } pushable-sm cursor-pointer mr-auto`,
                      onClick: () => this.props.history.push("/"),
                    })
                  : null}
                {this.props.right}
              </div>
            ) : null}
            {React.Children.map(this.props.children, (child) => {
              if (!child) return null
              if (typeof child.type === "function") {
                const title =
                  child.props.children &&
                  (child.props.children[0].props || {}).children
                const key = title ? encodeURI(stripCharacters(title)) : null
                return React.cloneElement(child, {
                  isInline: this.props.isInline,
                  isLayered: this.props.isLayered,
                  onOpen: () => {
                    if (!key) return this.setState({ openLayer: null })
                    this.setState({ openLayer: key })
                  },
                  isOpen: key && key === this.state.openLayer,
                  iconColor: child.props.iconColor || this.props.iconColor,
                  iconColorDark:
                    child.props.iconColorDark || this.props.iconColorDark,
                })
              } else {
                return child
              }
            })}
          </div>
        </div>
      </AnimateSharedLayout>
    )
  }
}

export default withRouter(withData(Sidebar))
