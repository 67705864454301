import React from "react"
import {
  InputGroup,
  Callout,
  Input,
  withNotify,
  getSemiUniqueKey,
  ColorPicker,
  Status,
  Dropdown,
  capitalize,
  format,
  DropdownItem,
  Button,
  withData,
  Labels,
} from "../shared"
import { checkSchedulingIssue } from "../functions"
import { Link } from "react-router-dom"
import moment from "moment"

class ProjectInterviewOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: "",
      time: "",
      duration: props.interview.duration,
      interviewerId: props.interview.interviewerId,
    }
  }
  render() {
    const { project, interview, isFocus, isVideoShowing } = this.props

    const duration = interview.duration || project.duration || 60
    const { schedulingIssue, schedulingIssueMessageLong } =
      checkSchedulingIssue(
        interview,
        project.duration || 60,
        project,
        this.props.interviews,
      )
    const isDone = moment(interview.date, "X")
      .add(duration, "minutes")
      .isBefore(moment())
    const sameEmail =
      interview.email &&
      this.props.interviews.find(
        (x) => x.email === interview.email && x.id !== interview.id,
      )
    const color =
      interview.date && !isDone
        ? schedulingIssue
          ? "orange"
          : "green"
        : "gray"
    const newDate =
      this.state.date && this.state.time
        ? moment(this.state.date, "X")
          .hours(this.state.time.split(":")[0])
          .minutes(this.state.time.split(":")[1])
          .startOf("minute")
          .unix()
        : false
    const somethingChanged =
      (interview.duration || null) !== (this.state.duration || null) ||
      (interview.interviewerId || null) !==
      (this.state.interviewerId || null) ||
      (interview.date || false) !== newDate
    const hasMultipleInterviewers = (project.interviewers || []).length > 1

    return (
      <div
        className={`animate-up overflow-scroll px-10 ${isFocus ? "" : " pt-7 pb-2"
          }`}
      >
        {sameEmail && (
          <Callout isWarning className="mb-6">
            Email address is also used by{" "}
            <Link
              className="link-orange"
              to={`/projects/${project.id}/interviews/${sameEmail.id}`}
            >
              {sameEmail.name || "New interview"}
            </Link>
            .
          </Callout>
        )}
        <div
          className={`flex ${isFocus ? "-mx-10 px-10" : "px-6"
            } mb-5 items-center justify-between bg-${color}-50 dark:bg-${color}-${color === "gray" ? 700 : 900
            } mt-1 rounded-2xl py-5`}
        >
          <div>
            <h6
              className={`mb-2 text-${color}-${color === "gray" ? 500 : 600
                } dark:text-${color}-${color === "gray" ? 500 : 400}`}
            >
              Date
            </h6>
            <h4
              className={isDone ? "text-gray-500" : ""}
              title={format("date", interview.date, { year: false })}
            >
              {capitalize(
                format("date", interview.date, {
                  year: false,
                  isRelative: true,
                }),
              ) || <span className="opacity-40">Not scheduled</span>}
            </h4>
          </div>
          {interview.date && (
            <div>
              <h6
                className={`mb-2 text-${color}-${color === "gray" ? 500 : 600
                  } dark:text-${color}-${color === "gray" ? 500 : 400}`}
              >
                Time
              </h6>
              <h4 className={isDone ? "text-gray-500" : ""}>
                {format("time", interview.date)}
              </h4>
            </div>
          )}
          {hasMultipleInterviewers ? (
            <div>
              <h6
                className={`mb-2 text-${color}-${color === "gray" ? 500 : 600
                  } dark:text-${color}-${color === "gray" ? 500 : 400}`}
              >
                Interviewer
              </h6>
              <h4 className={isDone ? "text-gray-500" : ""}>
                {(
                  project.interviewers.find(
                    (x) => x.id === interview.interviewerId,
                  ) || {}
                ).name || <span className="opacity-40">None</span>}
              </h4>
            </div>
          ) : (
            <div>
              <h6
                className={`mb-2 text-${color}-${color === "gray" ? 500 : 600
                  } dark:text-${color}-${color === "gray" ? 500 : 400}`}
              >
                Duration
              </h6>
              <h4 className={isDone ? "text-gray-500" : ""}>{duration} min.</h4>
            </div>
          )}
          {!(isVideoShowing || isFocus) && (
            <Dropdown
              arrowPosition="center"
              buttonWidth={90}
              width={280}
              approxHeight={250}
              button={
                <Button
                  className="ml-5"
                  hoverColor={`${color}-100`}
                  hoverColorDark={`${color}-800`}
                  selectedColor={`${color}-200`}
                  selectedColorDark={`${color}-700`}
                  color={`${color}-600`}
                  colorDark={`${color}-500`}
                  title={interview.date ? "Reschedule" : "Schedule"}
                >
                  {interview.date ? "Edit" : "Add"}
                </Button>
              }
            >
              <DropdownItem
                to={`/projects/${project.id}/interviews/schedule`}
                toData={{ selectedIds: [interview.id] }}
                icon="clock"
              >
                Open in schedule window
              </DropdownItem>
              <DropdownItem
                disabled={!(interview.date || interview.duration)}
                onClick={() =>
                  this.props.update("interviews", interview.id, {
                    date: null,
                    time: null,
                    duration: null,
                  })
                }
                icon="delete"
              >
                Clear
              </DropdownItem>
              {color === "gray" && !isDone ? null : (
                <>
                  <h6 className="px-3 pb-2 pt-4">Schedule status</h6>
                  <Status
                    isGray={isDone}
                    isGreen={!schedulingIssueMessageLong && !isDone}
                    className="pl-4"
                  >
                    {isDone
                      ? "Interview finished"
                      : schedulingIssueMessageLong || "No scheduling issues"}
                  </Status>
                </>
              )}
              <h6 className="px-3 pb-1 pt-5">
                {interview.date ? "Reschedule" : "Schedule"} manually
              </h6>
              <InputGroup
                className="p mb-3"
                beforeWidth={hasMultipleInterviewers ? 80 : 70}
                doc={{
                  date: this.state.date,
                  time: this.state.time,
                  interviewerId: this.state.interviewerId,
                  duration: this.state.duration,
                }}
                onBlur={(data) => this.setState(data)}
                fields={[
                  {
                    key: "date",
                    type: "date",
                    placeholder: "Set date...",
                    format: { day: "short" },
                    button: this.state.date ? (
                      <Button
                        isSmall
                        onClick={() => this.setState({ date: "" })}
                        icon="cross"
                      />
                    ) : (
                      interview.date && (
                        <Button
                          isSmall
                          disabled={!interview.date}
                          onClick={() =>
                            this.setState({ date: interview.date })
                          }
                          icon="arrow-left"
                        />
                      )
                    ),
                  },
                  {
                    key: "time",
                    type: "time",
                    placeholder: "Set time...",
                    button: this.state.time && (
                      <Button
                        isSmall
                        onClick={() => this.setState({ time: "" })}
                        icon="cross"
                      />
                    ),
                  },
                  {
                    key: "duration",
                    type: "number",
                    after: "minutes",
                    placeholder: project.duration || 60,
                  },
                  hasMultipleInterviewers && {
                    key: "interviewerId",
                    before: "Interviewer",
                    type: "dropdown",
                    options: project.interviewers.map((x) => ({
                      name: x.name || "Unnamed",
                      key: x.id,
                    })),
                  },
                ]}
              />
              <Button
                primary
                full
                disabled={!somethingChanged || !newDate}
                onClick={() => {
                  const obj = {}
                  if (newDate) obj.date = newDate
                  obj.duration = this.state.duration || null
                  obj.interviewerId = this.state.interviewerId || null
                  if (Object.keys(obj).length) {
                    this.props.update("interviews", interview.id, obj)
                    this.setState({ date: "", time: "" })
                  }
                }}
              >
                Confirm
              </Button>
            </Dropdown>
          )}
        </div>
        <div
          className={isVideoShowing || isFocus ? null : "grid"}
          style={{ gridTemplateColumns: "1fr 200px" }}
        >
          <InputGroup
            beforeWidth={86}
            doc={interview}
            onBlur={(data) =>
              this.props.update("interviews", interview.id, data)
            }
            fields={[
              { key: "email", type: "email", classNameInput: "pr-4" },
              { key: "phone", type: "tel", classNameInput: "pr-4" },
            ]}
          />
          <InputGroup
            beforeWidth={isVideoShowing || isFocus ? 86 : 70}
            doc={interview}
            onBlur={(data) =>
              this.props.update("interviews", interview.id, data)
            }
            fields={[
              { key: "age", type: "number" },
              {
                key: "gender",
                type: "dropdown",
                options: ["male", "female", "other"],
              },
            ]}
          />
        </div>
        <InputGroup
          beforeWidth={86}
          doc={interview}
          onBlur={(data) => this.props.update("interviews", interview.id, data)}
          fields={[
            { key: "education", autosize: true },
            { key: "job", autosize: true },
            { key: "notes", rows: 3, autosize: true },
          ]}
        />

        <Labels
          onNew={async () => {
            let name, color
            const confirm = await this.props.openModal({
              title: "New label",
              desc: `Apply to the interview with ${interview.name || "Unnamed"
                }.`,
              icon: "label",
              action: "Add",
              child: (
                <Input
                  hasBorder
                  onChange={(x) => (name = x)}
                  autoFocus
                  placeholder="Name..."
                  button={
                    <ColorPicker
                      onChange={(x) => (color = x)}
                      noWeights
                      hasAll
                    />
                  }
                />
              ),
            })
            if (confirm && name) {
              const id = getSemiUniqueKey()
              this.props.addToArray(
                "projects",
                this.props.match.params.projectId,
                "interviewLabels",
                {
                  id,
                  name,
                  color: color || null,
                },
              )
              this.props.addToArray("interviews", interview.id, "labelIds", id)
            }
          }}
          className="mt-6"
          toEdit={
            isFocus
              ? null
              : `/projects/${project.id}/interviews/${interview.id}/labels`
          }
          labels={project.interviewLabels}
          labelIds={interview.labelIds}
          onChange={(labelIds) =>
            this.props.update("interviews", interview.id, { labelIds })
          }
        />
      </div>
    )
  }
}

export default withData(withNotify(ProjectInterviewOverview))
