import React from "react"
import { capitalize, format } from "./shared"
import moment from "moment"
import { Link } from "react-router-dom"

export function liveFormObj(obj, projectId, forms) {
  let liveForms = forms.filter((x) => x.isLive)
  if (projectId) liveForms = liveForms.filter((x) => x.projectId === projectId)
  if (liveForms.length) {
    const x = {}
    for (const form of liveForms) {
      x[form.id] = obj
    }
    return x
  }
  return false
}

export function simplifyUrl(url) {
  if (!url) return null
  try {
    const x = new URL(url)
    return `${x.protocol}//${x.hostname}${x.pathname}`
  } catch (e) {
    return null // Not a valid URL.
  }
}

export function sortSends(a, b) {
  const x = a?.date - b?.date
  if (x) return x
  const y = a?.to?.name.localeCompare(b?.to?.name)
  if (y) return y
  return a?.to?.email.localeCompare(b?.to?.email)
}

export function getInterviewUpdateObjOnFindingDelete(interview, findingId) {
  if (interview?.transcript && interview?.transcriptEntities) {
    const key = Object.keys(interview.transcriptEntities).find(
      (key) => interview.transcriptEntities[key].id === findingId,
    )
    if (key != null) {
      const newTranscriptEntities = { ...interview.transcriptEntities }
      delete newTranscriptEntities[key]

      const transcript = []
      for (const block of interview.transcript) {
        const newBlock = { ...block }
        const index = newBlock.entityRanges?.findIndex(
          (x) => x.key === parseInt(key),
        )
        if (newBlock.entityRanges && index > -1) {
          newBlock.entityRanges.splice(index, 1)
        }
        transcript.push(newBlock)
      }
      return { transcript, transcriptEntities: newTranscriptEntities }
    }
  }
  return null
}

export function sortFindings(
  findings,
  interviews,
  project,
  groupBy,
  sortBy,
  sortDesc,
  pinStarred,
) {
  return findings.sort((a, b) => {
    // Sort by group first
    if (groupBy) {
      if (groupBy === "labelIds" && project.findingLabels) {
        const catIndexA = a.labelIds
          ? project.findingLabels.findIndex((x) => x.id === a.labelIds[0])
          : false
        const catIndexB = b.labelIds
          ? project.findingLabels.findIndex((x) => x.id === b.labelIds[0])
          : false
        if (catIndexA < catIndexB) return -1
        if (catIndexA > catIndexB) return 1
      } else if (groupBy === "interviewName") {
        const interviewA = interviews.find((x) => x.id === a.interviewId) || {}
        const interviewB = interviews.find((x) => x.id === b.interviewId) || {}
        const x = sortDesc
          ? (interviewB.name || "").localeCompare(interviewA.name || "")
          : (interviewA.name || "").localeCompare(interviewB.name || "")
        if (x !== 0) return x
      }
    }
    if (pinStarred) {
      // This is unrelated to sortDesc because should always be on top
      if (a.isStarred && !b.isStarred) return -1
      if (!a.isStarred && b.isStarred) return 1
    }
    if (sortBy === "createdOn")
      return sortDesc ? b.createdOn - a.createdOn : a.createdOn - b.createdOn
    if (sortBy === "time") return sortDesc ? b.time - a.time : a.time - b.time
    if (sortBy === "interviewName") {
      const interviewA = interviews.find((x) => x.id === a.interviewId) || {}
      const interviewB = interviews.find((x) => x.id === b.interviewId) || {}
      return sortDesc
        ? (interviewB.name || "").localeCompare(interviewA.name || "")
        : (interviewA.name || "").localeCompare(interviewB.name || "")
    }
    return 0
  })
}

export function getQuoteFromTranscript(
  transcript,
  transcriptEntities,
  finding,
  wrapInQuotes = false,
) {
  if (!transcript || !transcriptEntities || !finding) return null
  let strArr = []
  for (const key in transcriptEntities) {
    if (transcriptEntities[key].id === finding.id) {
      for (const block of transcript) {
        const range = block.entityRanges.find((x) => x.key === parseInt(key))
        if (range) {
          strArr.push(
            block.text.substring(range.offset, range.offset + range.length),
          )
        }
      }
    }
  }
  const str = strArr.join(" ")
  return str ? (wrapInQuotes ? `"${str}"` : str) : null
}

export function getFindingFields(interviews = [], findingLabels = []) {
  return [
    {
      key: "text",
      name: "finding",
      calc: (text, finding) => {
        if (text) return text
        // Finding might contain only quoted text. If that's the case, return that in quotes:
        const interview = interviews.find((x) => x.id === finding.interviewId)
        return getQuoteFromTranscript(
          interview?.transcript,
          interview?.transcriptEntities,
          finding,
          true,
        )
      },
    },
    {
      key: "labelIds",
      name: "labels",
      calc: (labelIds) => {
        if (!labelIds) return ""
        const firstLabel = findingLabels.find((x) => x.id === labelIds[0])
        return firstLabel ? firstLabel.name : ""
      },
    },
    {
      key: "interviewName",
      name: "name",
      calc: (_, finding) => {
        const interview = interviews.find((x) => x.id === finding.interviewId)
        return interview ? interview.name : ""
      },
    },
    {
      key: "interviewAge",
      name: "age",
      calc: (_, finding) => {
        const interview = interviews.find((x) => x.id === finding.interviewId)
        return interview ? interview.age : ""
      },
    },
    {
      key: "interviewNameAge",
      hidden: true,
      calc: (_, finding) => {
        const interview = interviews.find((x) => x.id === finding.interviewId)
        return interview
          ? `${interview.name || "Unnamed"}${interview.age ? ` (${interview.age})` : ""
          }`
          : null
      },
    },
    {
      key: "createdOn",
      name: "created on",
      calc: (x) => {
        const date = format("date", x, { isRelative: true, year: false })
        if (!date) return null
        return date === "today" || date === "tomorrow" || date === "yesterday"
          ? `${capitalize(date)} ${date === "today" ? format("time", x) : ""}`
          : date
      },
    },
  ]
}

export function guessTranscriptTimestamp(transcriptTimes, i) {
  let prevTime,
    prevIndex = i,
    nextTime,
    nextIndex = i
  while (prevIndex >= 0) {
    prevIndex--
    if (transcriptTimes[prevIndex] != null) {
      prevTime = transcriptTimes[prevIndex]
      break
    }
  }
  while (nextIndex < transcriptTimes.length) {
    nextIndex++
    if (transcriptTimes[nextIndex] != null) {
      nextTime = transcriptTimes[nextIndex]
      break
    }
  }
  if (nextTime != null && prevTime != null) {
    const stepsBack = i - prevIndex
    const stepsForward = nextIndex - i
    const stepsToGuess = stepsBack + stepsForward - 1
    return parseFloat(
      (
        prevTime +
        ((nextTime - prevTime) / (stepsToGuess + 1)) * stepsBack
      ).toFixed(1),
    )
  }

  return null
}

export function getFormResponseFields(forms = []) {
  return [
    { key: "name" },
    {
      key: "formName",
      calc: (_, x) => forms.find((form) => form.id === x.formId).title,
    },
    {
      key: "createdOn",
      calc: (x) => {
        const date = format("date", x, { isRelative: true, year: false })
        if (!date) return null
        return date === "today" || date === "tomorrow" || date === "yesterday"
          ? `${capitalize(date)} at ${format("time", x)}`
          : date
      },
    },
  ]
}

export function combineLabels(projects = []) {
  const combinedLabels = []
  for (const project of projects) {
    if (project.interviewLabels && project.interviewLabels.length) {
      for (const label of project.interviewLabels) {
        if (
          !combinedLabels
            .map((x) => x.name.toLowerCase())
            .includes(label.name.toLowerCase())
        ) {
          combinedLabels.push(label)
        }
      }
    }
  }
  return combinedLabels
}

export function getInterviewFields(
  interviewLabels = [],
  findings = [],
  projects = [],
  includeEmail,
  includeInterviewer = false,
) {
  return [
    {
      key: "checkbox",
      size: "40px",
      type: "checkbox",
      canSort: false,
      hideInVarList: true,
    },
    {
      key: "name",
      edit: false,
      canHide: false,
      size: "236px",
      className: "font-medium",
      fieldType: "name",
    },
    {
      key: "date",
      type: "date",
      size: "194px",
      format: {
        isRelative: true,
        day: "short",
        year: "not-current",
        capitalize: true,
        includeTime: true,
      },
    },
    { key: "age", type: "number", size: "60px" },
    {
      key: "gender",
      size: "90px",
      type: "dropdown",
      options: ["male", "female", "other"],
    },
    includeEmail && { key: "email", type: "email" },
    { key: "phone", type: "tel", size: "130px" },
    { key: "education", size: "1.3fr" },
    { key: "createdOn", hidden: true },
    {
      key: "interviewerId",
      name: "Interviewer",
      size: "150px",
      hidden: !includeInterviewer,
      calc: (id, x) =>
        (
          (
            (projects.find((y) => y.id === x.projectId) || {}).interviewers ||
            []
          ).find((x) => x.id === id) || {}
        ).name,
    },
    { key: "job", size: "1.3fr" },
    { key: "notes" },
    { key: "duration", type: "number", after: " min.", size: "64px" },
    {
      key: "labelIds",
      type: "labels",
      name: "Labels",
      canSort: false,
      labels: interviewLabels,
      align: "right",
    },
    {
      key: "numFindings",
      hidden: true,
      calc: (x, interview) => {
        const fi = findings.filter((x) => x.interviewId === interview.id)
        const f = fi.length
        const str =
          f === 0 ? "No findings" : f === 1 ? "1 finding" : `${f} findings`
        const key = fi.filter((x) => x.isStarred).length
        return str + (key > 0 ? ` (${key} key)` : "")
      },
    },
    {
      key: "ageGender",
      hidden: true,
      calc: (_, x) =>
        `${capitalize(x.gender) || "No gender"} (${x.age || "no age"})`,
    },
    {
      key: "dateTime",
      hidden: true,
      calc: (_, x) =>
        x.date
          ? `${format("date", x.date, {
            month: "full",
            year: "not-current",
          })} at ${format("time", x.date)}`
          : "Not scheduled",
    },
    {
      key: "dateTimeRelative",
      hidden: true,
      calc: (_, x) => {
        if (x.date) {
          const date = format("date", x.date, { isRelative: true, year: false })
          if (!date) return null
          return date === "today" || date === "tomorrow" || date === "yesterday"
            ? `${capitalize(date)} ${date === "today" ? format("time", x.date) : ""
            }`
            : date
        }
      },
    },
    {
      key: "projectName",
      hidden: true,
      calc: (_, x) =>
        (projects.find((y) => y.id === x.projectId) || {}).name || "No project",
    },
  ]
}

export function checkOverlap(timeSegments) {
  if (timeSegments.length === 1) return false

  timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1[0].localeCompare(timeSegment2[0]),
  )

  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i][1]
    const nextStartTime = timeSegments[i + 1][0]

    if (currentEndTime > nextStartTime) {
      return true
    }
  }

  return false
}

export function getInterviewDays(project) {
  const allDates = (project.interviewers || [])
    .map((interviewer) =>
      (interviewer.availability || []).map((x) =>
        moment(x.from, "X").startOf("day").unix(),
      ),
    )
    .flat()
  return [...new Set(allDates)].filter((x) => x).sort()
}

export function checkSchedulingIssue(
  interview,
  defaultDuration,
  project,
  allInterviews,
) {
  if (!interview.date || (project.interviewers || []).length === 0)
    return { scheduledInterview: false }
  const interviewer =
    project.interviewers &&
    (project.interviewers.length === 1
      ? project.interviewers[0]
      : project.interviewers.find((x) => x.id === interview.interviewerId))
  if (!interview.interviewerId && (project.interviewers || []).length > 1)
    return {
      schedulingIssue: "NO_INTERVIEWER_ASSIGNED",
      schedulingIssueMessage: "No interviewer assigned",
      schedulingIssueMessageLong: (
        <>This interview has no interviewer assigned to it.</>
      ),
    }
  if (
    interview.interviewerId &&
    project.interviewers &&
    project.interviewers.length > 1 &&
    !interviewer
  )
    return {
      schedulingIssue: "NO_INTERVIEWER",
      schedulingIssueMessage: "Interviewer does not exist anymore",
      schedulingIssueMessageLong: (
        <>This interview is linked to an interviewer that has been deleted.</>
      ),
    }
  const interviewerAvailability =
    interviewer &&
    (interviewer.availability || []).filter((x) => x.from && x.until)
  const isScheduledOutsideInterviewerAvailability =
    isScheduledOutsideAvailability(
      interview,
      defaultDuration,
      interviewerAvailability,
    )
  if (isScheduledOutsideInterviewerAvailability)
    return {
      schedulingIssue: "INTERVIEWER",
      schedulingIssueMessage: "Scheduled outside interviewer availability",
      schedulingIssueMessageLong: (
        <>
          This interview is scheduled outside of the availability of the
          interviewer.
        </>
      ),
    }
  const isScheduledOutsideInterviewAvailability =
    interview.availability?.length > 0 &&
    isScheduledOutsideAvailability(
      interview,
      defaultDuration,
      interview.availability,
    )
  if (isScheduledOutsideInterviewAvailability)
    return {
      schedulingIssue: "INTERVIEWEE",
      schedulingIssueMessage: "Scheduled outside interviewee availability",
      schedulingIssueMessageLong: (
        <>
          This interview is scheduled outside of the availability of{" "}
          {interview.name}.
        </>
      ),
    }
  const interviewsAlreadyScheduled = allInterviews.filter(
    (x) =>
      x.date &&
      x.id !== interview.id &&
      (!project.scheduleAllowOverlap || x.projectId === project.id),
  )
  for (const scheduledInterview of interviewsAlreadyScheduled) {
    if (
      moment(scheduledInterview.date, "X").isSame(
        moment(interview.date, "X"),
        "day",
      )
    ) {
      const from1 = moment(interview.date, "X").format("HH:mm")
      const until1 = moment(interview.date, "X")
        .add(interview.duration || defaultDuration, "minutes")
        .format("HH:mm")
      const from2 = moment(scheduledInterview.date, "X").format("HH:mm")
      const until2 = moment(scheduledInterview.date, "X")
        .add(scheduledInterview.duration || defaultDuration, "minutes")
        .format("HH:mm")
      const isOverlap = checkOverlap([
        [from1, until1],
        [from2, until2],
      ])
      if (isOverlap) {
        return {
          schedulingIssue: "OVERLAP",
          overlapsWithId: scheduledInterview.id,
          schedulingIssueMessage: `Overlaps with ${scheduledInterview.name}`,
          schedulingIssueMessageLong: (
            <>
              This interview overlaps with{" "}
              <Link
                className="link"
                to={`/projects/${scheduledInterview.projectId}/interviews/${scheduledInterview.id}`}
              >
                {scheduledInterview.name}
              </Link>
              .
            </>
          ),
        }
      }
    }
  }
  return { schedulingIssue: false }
}

export function isScheduledOutsideAvailability(
  interview,
  defaultDuration,
  availability,
) {
  if (!interview.date || !availability || !availability.length) return false
  for (const { from, until } of availability) {
    const mStartAvailability = moment(from, "X").startOf("minute")
    const mStartInterview = moment(interview.date, "X").startOf("minute")
    const mEndAvailability = moment(until, "X").startOf("minute")
    const mEndInterview = moment(interview.date, "X")
      .startOf("minute")
      .add(interview.duration || defaultDuration, "minutes")
    if (
      mStartAvailability.isSameOrBefore(mStartInterview) &&
      mEndAvailability.isSameOrAfter(mEndInterview)
    ) {
      return false
    }
  }
  return true
}
