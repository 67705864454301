import React from "react"
import Icon from "./Icon"
import Input from "./Input"
import Button from "./Button"
import { withNotify } from "../notify"
import firebase from "firebase/app"
import "firebase/auth"
import { withRouter } from "react-router-dom"
import { isEmail } from "../functions"

class LoginBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      isLoading: false,
    }
  }
  async doLogin(username, password) {
    // this wrapper is necessary to be able to catch errors
    // see: https://github.com/firebase/firebase-js-sdk/issues/1881#issuecomment-510189685
    return new Promise((resolve, reject) => {
      return firebase
        .auth()
        .signInWithEmailAndPassword(username, password)
        .then((user) => resolve(user))
        .catch((err) => reject(err))
    })
  }
  doForgot(email) {
    // this wrapper is necessary to be able to catch errors
    // see: https://github.com/firebase/firebase-js-sdk/issues/1881#issuecomment-510189685
    return new Promise((resolve, reject) => {
      return firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  }
  async login(e) {
    e.preventDefault()
    try {
      if (!this.state.email) {
        return this.props.error("Please enter your email address")
      }
      if (!isEmail(this.state.email)) {
        return this.props.error("Please enter a valid email address")
      }
      if (!this.state.password) {
        return this.props.error("Please enter your password")
      }
      this.setState({ isLoading: true })
      await this.doLogin(this.state.email, this.state.password)
      this.props.history.push(this.props.redirectTo || "/")
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        return this.props.error("Email address not found")
      } else if (
        err.code === "auth/invalid-password" ||
        err.code === "auth/wrong-password"
      ) {
        this.props.error(
          "Incorrect password",
          "Please try again or reset your password below.",
        )
      } else {
        this.props.error("Could not login", err.message)
      }
      this.setState({ isLoading: false })
    }
  }
  forgot() {
    if (!this.state.email) {
      return this.props.error("Please enter your email address")
    }
    this.doForgot(this.state.email)
      .then(() => {
        this.props.success(
          "Reset instructions sent",
          "Please check your inbox. This may take up to 5 minutes.",
        )
      })
      .catch((err) => {
        if (err.code === "auth/user-not-found") {
          return this.props.error(
            "Unknown email address",
            "We could not find an account with that email address.",
          )
        } else {
          return this.props.error("Could not reset password", err.message)
        }
      })
  }
  render() {
    return (
      <div
        className={`${
          this.props.fullScreen
            ? "flex h-full items-center justify-center"
            : null
        } ${this.props.className || ""}`}
      >
        <form
          onSubmit={this.login.bind(this)}
          className={`w-full select-none ${
            this.props.maxWidth ? `max-w-${this.props.maxWidth}` : ""
          } ${this.state.isLoading ? "pointer-events-none" : ""}`}
        >
          {!this.props.hideTitle && (
            <>
              <div className="mb-4 flex flex-col items-center">
                <Icon icon="lock" color={window.appColor} />
              </div>
              <h3 className={`mb-10 text-center font-bold`}>Welcome back</h3>
            </>
          )}
          <h6 className="mb-2">Email</h6>
          <Input
            autoFocus
            placeholder="eddy@example.com"
            hasBorder
            value={this.state.email}
            onChange={(email) => this.setState({ email })}
          />
          <h6 className="mt-6 mb-2">Password</h6>
          <Input
            hasBorder
            type="password"
            placeholder="••••••••••••"
            value={this.state.password}
            onChange={(password) => this.setState({ password })}
          />
          <Button
            type="submit"
            primary
            loading={this.state.isLoading}
            className="mt-8 w-full"
          >
            Sign in
          </Button>
          <p
            onClick={this.forgot.bind(this)}
            className="sub link-color mt-5 block text-center"
          >
            Forgot password?
          </p>
        </form>
      </div>
    )
  }
}

export default withNotify(withRouter(LoginBox))
