import React from "react"
import { Button, Page, withData, withNotify } from "../shared"
import moment from "moment"

class LoggedOut extends React.Component {
  render() {
    return (
      <Page isFullScreen title="Logged out" noBar>
        {this.props.isDemo ? (
          <div className="animate-open-top flex h-screen flex-col items-center justify-center">
            <div
              className={`text-${window.appColor}`}
              style={{ fontFamily: "down", fontSize: 150 }}
            >
              bye!
            </div>
            <p className="mt-12 mb-6 text-center text-2xl leading-relaxed">
              You are now logged out. <br />
              Enjoy the rest of your {moment().format("dddd")}.
            </p>
            <Button to="/">Close</Button>
          </div>
        ) : null}
      </Page>
    )
  }
}

export default withData(withNotify(LoggedOut))
