import React, { useState } from "react"
import {
  ContextBoard,
  Checkbox,
  ColorPicker,
  DropdownItemCopy,
  getSemiUniqueKey,
  Label,
  Labels,
  secondsToTime,
  Icon,
  State,
  Input,
  fName,
  withNotify,
  withData,
  DropdownItem,
} from "../shared"
import { motion } from "framer-motion"
import { withRouter } from "react-router-dom"
import {
  getInterviewUpdateObjOnFindingDelete,
  getQuoteFromTranscript,
} from "../functions"

function FindingInput({ mediaSeconds, handleCreate, isFocus }) {
  const [value, setValue] = useState("")
  const [seconds, setSeconds] = useState(0)
  const [registerTime, setRegisterTime] = useState(true)
  return (
    <motion.div
      layout="position"
      className="flex flex-none items-end p-10 pt-0"
    >
      <Input
        hasBorder
        button={
          mediaSeconds >= 1 && (
            <div
              onClick={() => setRegisterTime(!registerTime)}
              className={`cursor-pointer select-none rounded-md py-1 px-2 text-sm ${
                registerTime
                  ? `${seconds ? "bg-indigo-500 text-white" : ""}`
                  : "text-gray-500 line-through"
              } tabular bg-gray-100 font-medium dark:bg-gray-700`}
            >
              {secondsToTime(seconds || mediaSeconds)}
            </div>
          )
        }
        value={value}
        onChange={(value) => {
          setValue(value)
          setSeconds(value ? seconds || mediaSeconds : 0)
        }}
        autosize
        onEnter={async (val, e) => {
          const isCreated = await handleCreate(val, seconds, registerTime, e)
          if (isCreated) {
            setValue("")
            setSeconds(0)
          }
        }}
        className={`flex-1 ${isFocus ? "relative top-3 mr-80" : ""}`}
        placeholder="Add a finding..."
      />
    </motion.div>
  )
}

class ProjectInterviewFindingsList extends React.Component {
  async editFinding(finding) {
    const { transcript, transcriptEntities } = this.props
    let text = finding.text,
      time = finding.time
    const sentence = getQuoteFromTranscript(
      transcript,
      transcriptEntities,
      finding,
      true,
    )
    const confirm = await this.props.openModal({
      title: "Edit finding",
      icon: "edit",
      action: "Edit",
      child: (
        <div>
          <p className="mb-3 text-gray-500">{sentence}</p>
          <Input
            hasBorder
            defaultValue={text}
            onChange={(x) => (text = x)}
            autoFocus
            rows={3}
            autosize
            placeholder="Type finding..."
          />
          {this.props.hasMedia && (
            <Input
              hasBorder
              defaultValue={time}
              before="At"
              after="seconds"
              className="mt-2"
              onChange={(x) => (time = x)}
              placeholder="0"
            />
          )}
        </div>
      ),
    })
    if (confirm) {
      this.props.update("findings", finding.id, {
        text: text || null,
        time: time || null,
      })
    }
  }
  handleCreate(value, seconds, registerTime, e) {
    if (e && e.preventDefault) e.preventDefault()
    const lines = value.split("\n").filter((x) => x)
    const arr = []
    for (const line of lines) {
      let finding = line
      let isStarred = false
      if (finding.startsWith("*")) {
        isStarred = true
        finding = finding.substring(1).trim()
      } else if (finding.endsWith("*")) {
        isStarred = true
        finding = finding.substring(0, finding.length - 1).trim()
      }
      const obj = {
        interviewId: this.props.match.params.interviewId,
        isStarred,
        projectId: this.props.match.params.projectId,
        text: finding,
      }
      const time = parseInt(seconds) || 0
      if (time && time >= 1 && lines.length === 1 && registerTime)
        obj.time = time // Don't add time when pasting multiple findings.
      arr.push(obj)
    }
    try {
      this.props.createMultiple("findings", arr)
      return true
    } catch (e) {
      this.props.error("Could not create findings")
      return false
    }
  }
  render() {
    const {
      labels,
      isFocus,
      hasMedia,
      fields,
      mediaSeconds,
      name,
      findings,
      transcript,
      baseUrl,
      transcriptEntities,
    } = this.props

    return (
      <>
        {findings.length ? (
          <ContextBoard
            data={findings}
            singular="finding"
            plural="findings"
            className="flex-1 px-6 pt-5"
            contextMenu={(selectedIds) => {
              if (selectedIds.length === 1) {
                const finding =
                  findings.find((x) => x.id === selectedIds[0]) || {}
                const canPlay = hasMedia && (finding.time || finding.time === 0)
                return (
                  <>
                    <DropdownItemCopy
                      defaultFormat="[Finding] — [Name]"
                      singular="finding"
                      plural="findings"
                      fields={fields}
                      data={findings}
                      selectedIds={selectedIds}
                    />
                    <DropdownItem
                      to={`${baseUrl}/copy-as`}
                      toData={{ selectedIds }}
                    >
                      Copy as
                    </DropdownItem>
                    {labels && labels.length ? (
                      labels.map((label, i) => (
                        <Checkbox
                          borderTop={i === 0}
                          isSmall
                          key={label.id}
                          isActive={(finding.labelIds || []).includes(label.id)}
                          color={label.color}
                          onChange={() => {
                            this.props.toggleInArray(
                              "findings",
                              finding.id,
                              "labelIds",
                              label.id,
                            )
                          }}
                        >
                          {label.name}
                        </Checkbox>
                      ))
                    ) : (
                      <DropdownItem disabled borderTop>
                        No labels
                      </DropdownItem>
                    )}
                    <DropdownItem
                      icon="add"
                      onClick={async () => {
                        const selectedText = getQuoteFromTranscript(
                          transcript,
                          transcriptEntities,
                          finding,
                        )
                        let name, color
                        const confirm = await this.props.openModal({
                          title: "New label",
                          desc: selectedText && `Apply to "${selectedText}".`,
                          icon: "label",
                          action: "Add",
                          child: (
                            <Input
                              hasBorder
                              onChange={(x) => (name = x)}
                              autoFocus
                              placeholder="Name..."
                              button={
                                <ColorPicker
                                  onChange={(x) => (color = x)}
                                  noWeights
                                  hasAll
                                />
                              }
                            />
                          ),
                        })
                        if (confirm && name) {
                          const id = getSemiUniqueKey()
                          this.props.addToArray(
                            "projects",
                            this.props.match.params.projectId,
                            "findingLabels",
                            {
                              id,
                              name,
                              color: color || null,
                            },
                          )
                          this.props.addToArray(
                            "findings",
                            finding.id,
                            "labelIds",
                            id,
                          )
                        }
                      }}
                    >
                      New label
                    </DropdownItem>
                    {canPlay && (
                      <DropdownItem
                        borderTop
                        onClick={() => this.props.setTime(finding.time)}
                        icon="play"
                      >
                        Play
                      </DropdownItem>
                    )}
                    <DropdownItem
                      borderTop={!canPlay}
                      icon="edit"
                      onClick={() =>
                        this.editFinding(
                          finding,
                          transcript,
                          transcriptEntities,
                        )
                      }
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.update("findings", finding.id, {
                          isStarred: !finding.isStarred,
                        })
                      }
                      iconFill={finding.isStarred}
                      iconColor={finding.isStarred ? "yellow-400" : null}
                      iconColorDark={finding.isStarred ? "yellow-500" : null}
                      icon="star"
                    >
                      {finding.isStarred ? "Unmark" : "Mark"} as key finding
                    </DropdownItem>
                    <DropdownItem
                      icon="arrow-right"
                      to={`/projects/${this.props.match.params.projectId}/findings/${finding.id}`}
                    >
                      View in Findings
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        const interview = this.props.interviews.find(
                          (x) => x.id === finding.interviewId,
                        )
                        const updateObj = getInterviewUpdateObjOnFindingDelete(
                          interview,
                          finding.id,
                        )
                        if (updateObj)
                          this.props.update(
                            "interviews",
                            interview.id,
                            updateObj,
                          )
                        this.props.delete("findings", finding.id)
                      }}
                      isRed
                      icon="delete"
                    >
                      Delete
                    </DropdownItem>
                  </>
                )
              } else if (selectedIds.length > 1) {
                const selectedFindings = findings.filter((x) =>
                  selectedIds.includes(x.id),
                )
                const allAreStarred = selectedFindings.every((x) => x.isStarred)
                return (
                  <>
                    <DropdownItemCopy
                      defaultFormat="[Finding] — [Name]"
                      singular="finding"
                      plural="findings"
                      fields={fields}
                      data={findings}
                      selectedIds={selectedIds}
                    />
                    <DropdownItem
                      to={`${baseUrl}/copy-as`}
                      toData={{ selectedIds }}
                    >
                      Copy as
                    </DropdownItem>
                    {labels && labels.length ? (
                      labels.map((label, i) => (
                        <Checkbox
                          borderTop={i === 0}
                          isSmall
                          key={label.id}
                          isActive={selectedFindings.every((x) =>
                            (x.labelIds || []).includes(label.id),
                          )}
                          color={label.color}
                          onChange={() => {
                            const obj = {}
                            for (const selectedId of selectedIds) {
                              const finding = selectedFindings.find(
                                (x) => x.id === selectedId,
                              )
                              const labelIds = [...(finding.labelIds || [])]
                              const removeLabel = selectedFindings.every((x) =>
                                (x.labelIds || []).includes(label.id),
                              )
                              if (removeLabel) {
                                const index = labelIds.indexOf(label.id)
                                if (index > -1) labelIds.splice(index, 1)
                              } else if (!labelIds.includes(label.id)) {
                                labelIds.push(label.id)
                              }
                              obj[selectedId] = { labelIds }
                            }
                            this.props.updateMultiple("findings", obj)
                          }}
                        >
                          {label.name}
                        </Checkbox>
                      ))
                    ) : (
                      <DropdownItem disabled borderTop>
                        No labels
                      </DropdownItem>
                    )}
                    <DropdownItem
                      borderTop
                      onClick={() =>
                        this.props.updateMultiple("findings", selectedIds, {
                          isStarred: !allAreStarred,
                        })
                      }
                      iconFill={allAreStarred}
                      iconColor={allAreStarred ? "yellow-400" : null}
                      iconColorDark={allAreStarred ? "yellow-500" : null}
                      icon="star"
                    >
                      {allAreStarred ? "Unmark" : "Mark"} as key findings
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) =>
                        this.props.deleteMultiple(
                          "findings",
                          selectedIds,
                          this.props.warn,
                          "findings",
                        )
                      }
                      isRed
                      icon="delete"
                    >
                      Delete
                    </DropdownItem>
                  </>
                )
              }
            }}
            paddingBelow={6}
            mapData={(finding, i, isSelected, selectedIds) => {
              const showTime = finding.time && hasMedia
              const sentence = getQuoteFromTranscript(
                transcript,
                transcriptEntities,
                finding,
                true,
              )
              // Below in setTime(), we subtract 3 because the fragment will have likely started before typing.
              return (
                <motion.div
                  layout="position"
                  key={finding.id}
                  className={`${
                    i === findings.length - 1 ? "mb-8" : ""
                  } mt-px flex items-start gap-4 rounded-lg px-4 py-2 leading-normal ${
                    isSelected ? "bg-gray-100 dark:bg-gray-700" : null
                  }`}
                >
                  {showTime && (
                    <Label
                      color="gray"
                      isSmall
                      onClick={() =>
                        this.props.setTime(Math.max(0, finding.time - 3))
                      }
                      className="tabular"
                    >
                      {secondsToTime(finding.time)}
                    </Label>
                  )}
                  <div className="flex-1 pr-3">
                    {sentence && finding.text && (
                      <div className="sub">{sentence}</div>
                    )}
                    <div
                      className={`${
                        !finding.text && !sentence
                          ? "text-gray-400 dark:text-gray-600"
                          : ""
                      }`}
                    >
                      {finding.text || sentence || "Empty finding"}
                    </div>
                    <Labels
                      className="mt-1"
                      isSmall
                      labels={labels}
                      labelIds={finding.labelIds}
                    />
                  </div>
                  {finding.isStarred ? (
                    <Icon
                      size={14}
                      fill
                      color="yellow-400"
                      colorDark="yellow-500"
                      className="mt-1"
                      icon="star"
                    />
                  ) : null}
                </motion.div>
              )
            }}
          ></ContextBoard>
        ) : (
          <State
            className="flex-1 p-10 pt-6 pb-0"
            title="No findings"
            icon="star"
          >
            <p className="mx-auto max-w-xs text-center">
              Add findings to organize what you learned from the interview with{" "}
              {fName(name)}.
            </p>
          </State>
        )}
        <FindingInput
          isFocus={isFocus}
          mediaSeconds={mediaSeconds}
          handleCreate={this.handleCreate.bind(this)}
        />
      </>
    )
  }
}

ProjectInterviewFindingsList.propTypes = {}

export default withRouter(withNotify(withData(ProjectInterviewFindingsList)))
