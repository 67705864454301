import React from 'react';
import InputGroup from "../InputGroup";
import {withData} from "../../data";
import moment from 'moment';

class SettingsDateFormat extends React.Component {
    render() {
        const user = this.props.getUser();
        if(!user) return 'Locale settings could not be loaded.';
        const m = moment();
        return (
            <InputGroup
                hasBorder
                doc={user}
                onBlur={data => this.props.update('usersPrivate', this.props.userId, data)}
                beforeWidth={this.props.beforeWidth || 80}
                canClear={false}
                fields={[
                    {key: 'dateFormat', type: 'dropdown', before: 'Date', options: [{key: 'D M Y', name: m.format('D MMMM YYYY')}, {key: 'M D, Y', name: m.format('MMMM D, YYYY')}], defaultValue: 'D M Y'},
                    {key: 'timeFormat', type: 'dropdown', before: 'Time', options: [{key: 'H:mm', name: '17:00'}, {key: 'h:mm a', name: '5:00 pm'}], defaultValue: 'H:mm'},
                ]}
            />
        );
    }
}

export default withData(SettingsDateFormat);