import React from 'react';

class Toggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
        };
    }
    render() {
        const value = this.props.value === undefined ? this.state.value : this.props.value;
        return (
            <div className={`${this.props.borderTop ? 'border-t border-light pt-3 mt-3' : ''} flex items-center ${this.props.isSmall ? `font-medium text-sm ${this.props.isDropdownPadding ? 'px-3' : ''}` : ''} py-1 ${this.props.className || ''}`}>
                {this.props.children ? <div className='mr-4 text-gray-500'>{this.props.children}</div> : null}
                <div onClick={() => {
                    if(this.props.value === undefined) this.setState({value: !value});
                    if(this.props.onChange) this.props.onChange(!value);
                }} className={`${this.props.children ? 'ml-auto' : ''} ${this.props.disabled || this.props.readOnly ? `${this.props.disabled ? 'opacity-75' : ''} pointer-events-none` : ''}`}>
                    <div title={this.props.title} className={`${value ? 'bg-green-500' : 'bg-gray-300 dark:bg-gray-700'} cursor-pointer border border-transparent tr rounded-full h-6 w-10 relative`}>
                        <div className='bg-white rounded-full w-6 h-6 shadow -mt-px tr -ml-px absolute' style={{left: value ? 14 : 0}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Toggle;