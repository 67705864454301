import React from 'react';
import 'chart.js';
import {format, checkIfDark} from "../functions";
import PropTypes from 'prop-types';
import {Line, Bar, HorizontalBar, Doughnut, Polar} from 'react-chartjs-2';

class Chart extends React.Component {
    label(tooltipItem, data) {
        const label = this.props.type === 'doughnut' || this.props.type === 'polar' ? data.labels[tooltipItem.index] : data.datasets[tooltipItem.datasetIndex].label;
        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        let str = `${this.displayColors ? ' ' : ''}${label ? `${label} – ` : ''}`;
        if(this.props.format === 'number') str += format('number', data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
        if(this.props.format === 'currency') str += this.props.currencySymbol + format('currency', value);
        else str += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        if(this.props.singular && value === 1) str += ` ${this.props.singular}`;
        if(this.props.plural && value !== 1) str += ` ${this.props.plural}`;
        if(this.props.showPercentages) {
            const total = data.datasets[tooltipItem.datasetIndex].data.reduce((sum, x) => sum + x, 0);
            str += ` (${Math.round(value / total * 100) || 0}%)`;
        }
        return str;
    }
    render() {
        const isDark = checkIfDark();
        const bgColor = isDark ? '#1F2937' : '#FFFFFF';
        const data = {
            datasets: this.props.datasets.map(dataset => ({
                pointRadius: 0,
                pointBorderWidth: 0,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 3,
                backgroundColor: bgColor,
                hoverBackgroundColor: this.props.type === 'doughnut' ? dataset.backgroundColor : undefined,
                borderColor: this.props.type === 'doughnut' ? bgColor : 'transparent',
                borderWidth: this.props.type === 'line' || this.props.type === 'doughnut' ? 3 : 0,
                hoverBorderWidth: this.props.type === 'line' ? 3 : 0,
                hoverBorderColor: dataset.borderColor,
                ...dataset,
            })),
            labels: this.props.labels,
        };

        this.displayColors = this.props.showColors == null ? this.props.type !== 'line' : this.props.showColors;

        const sort = this.props.sort || (this.props.type === 'line' ? 'desc' : false);
        const itemSort = typeof sort === 'function' ? sort : (sort === 'desc' ? (a,b) => b.value - a.value : (sort === 'asc' ? (a,b) => a.value - b.value : false));

        const borderColor = isDark ? '#374151' : '#E3E8EF';
        const textGrayColor = isDark ? '#A0AEC0' : '#6B7280';

        const polarOptions = {
            scale: {
                gridLines: {
                    color: borderColor,
                },
                ticks: {
                    fontColor: textGrayColor,
                    backdropColor: bgColor,
                },
            },
            tooltips: {
                mode: 'nearest',
                itemSort,
                intersect: false,
                displayColors: this.displayColors,
                callbacks: {
                    label: this.label.bind(this),
                },
                ...this.props.tooltips,
            },
            ...this.props.options,
        }

        /*

                ticks,
                angleLines,
                gridLines,
         */

        const lineBarOptions = {
            hover: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: textGrayColor,
                        beginAtZero: this.props.startAtZero,
                        padding: 10,
                        fontFamily: 'Inter, --apple-system, Helvetica Neue, Helvetica, Roboto, sans-serif',
                    },
                    gridLines: {
                        drawBorder: false,
                        drawTicks: false,
                        zeroLineColor: this.props.showXBorder ? (this.props.showXSide ? borderColor : 'transparent') : 'transparent',
                        color: this.props.showXBorder ? (this.props.showXSide ? borderColor : [...new Array(data.datasets[0].data.length - (this.props.type === 'line' ? 1 : 0)).fill(borderColor), 'transparent']) : 'transparent',
                    },
                    display: this.props.showXAxis,
                }],
                yAxes: [{
                    ticks: {
                        fontColor: textGrayColor,
                        beginAtZero: this.props.startAtZero,
                        padding: 10,
                        fontFamily: 'Inter, --apple-system, Helvetica Neue, Helvetica, Roboto, sans-serif',
                    },
                    gridLines: {
                        drawBorder: false,
                        drawTicks: false,
                        zeroLineColor: this.props.showYBorder ? (this.props.showYSide ? borderColor : 'transparent') : 'transparent',
                        color: this.props.showYBorder ? (this.props.showYSide ? borderColor : [...new Array(data.datasets[0].data.length - (this.props.type === 'line' ? 1 : 0)).fill(borderColor), 'transparent']) : 'transparent',
                    },
                    display: this.props.showYAxis,
                }],
            },
            tooltips: {
                mode: 'index',
                itemSort,
                intersect: false,
                displayColors: this.displayColors,
                callbacks: {
                    label: this.label.bind(this),
                },
                ...this.props.tooltips,
            },
            ...this.props.options,
        };

        const doughnutOptions = {
            tooltips: {
                intersect: !this.props.isNearest,
                displayColors: this.displayColors,
                callbacks: {
                    label: this.label.bind(this),
                },
            },
            ...this.props.options,
        };

        let chart;
        if(this.props.type === 'line') chart = <Line data={data} options={lineBarOptions} />;
        else if(this.props.type === 'doughnut') chart = <Doughnut data={data} options={doughnutOptions} />;
        else if(this.props.type === 'bar') chart = <Bar data={data} options={lineBarOptions} />;
        else if(this.props.type === 'horizontalBar') chart = <HorizontalBar data={data} options={lineBarOptions} />;
        else if(this.props.type === 'polar') chart = <Polar data={data} options={polarOptions} />;

        return chart ? <div className={`animate-up h-full w-full relative ${this.props.className || ''}`}>
            {chart}
        </div> : null;
    }
}

Chart.propTypes = {
    className: PropTypes.string,
    datasets: PropTypes.array,
    format: PropTypes.string,
    isNearest: PropTypes.bool,
    labels: PropTypes.array,
    options: PropTypes.object,
    sort: PropTypes.any,
    showAxis: PropTypes.bool,
    showColors: PropTypes.bool,
    startAtZero: PropTypes.bool,
    type: PropTypes.string,
}

Chart.defaultProps = {
    currencySymbol: '$',
}

export default Chart;