import React from "react"
import { Route } from "react-router-dom"
import {
  InputGroup,
  Input,
  Icon,
  getSemiUniqueKey,
  Page,
  withData,
  Dropdown,
  State,
  withNotify,
  Button,
  format,
  Tabs,
  Tab,
} from "../shared"
import AvailabilitySheet from "../components/AvailabilitySheet"
import moment from "moment"
import { getInterviewDays, liveFormObj } from "../functions"
import { functions } from "../index"

class ProjectSettings extends React.Component {
  constructor(props) {
    super(props)
    if ((this.props.project || {}).createdBy)
      this.props.fetch({
        collection: "usersPublic",
        id: this.props.project.createdBy,
      })
    this.state = {
      isArchiveLoading: false,
    }
  }
  async archiveOrRestoreProject() {
    const project =
      (this.props.projects || []).find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}

    if (project.isArchived) {
      try {
        this.setState({ isArchiveLoading: true })
        const unarchiveProject = functions.httpsCallable("unarchiveProject")
        const { data } = await unarchiveProject({ projectId: project.id })
        if (data && data.isSuccess) {
          await this.props.fetch({
            collection: "projects",
            id: project.id,
            force: true,
          })
          this.setState({ isArchiveLoading: false })
          this.props.success("Project restored")
        } else {
          this.props.error("Could not restore project", data.message)
          this.setState({ isArchiveLoading: false })
        }
      } catch (e) {
        this.props.error("Could not restore project", e.message)
        this.setState({ isArchiveLoading: false })
      }
    } else {
      const numGuests = Math.max(0, (project.memberIds || []).length - 1)
      const liveForms = this.props.forms.filter(
        (x) => x.projectId === project.id && x.isLive,
      )
      const numLiveForms = liveForms.length
      const lines = [
        "You can restore an archived project at any time from the Archive tab in the sidebar.",
      ]
      if (numGuests > 0)
        lines.push(
          `The ${numGuests > 1 ? `${numGuests} ` : ""}guest${
            numGuests === 1 ? "" : "s"
          } of this project will be removed.`,
        )
      if (numLiveForms > 0)
        lines.push(
          `The ${numLiveForms > 1 ? `${numLiveForms} ` : ""}form${
            numLiveForms === 1 ? "" : "s"
          } that ${
            numLiveForms === 1 ? "is" : "are"
          } currently live will be unpublished.`,
        )
      const confirm = await this.props.openModal({
        title: "Archive this project?",
        desc: lines.join(" "),
        action: "Archive",
        icon: "archive",
      })
      if (confirm) {
        try {
          this.setState({ isArchiveLoading: true })
          const archiveProject = functions.httpsCallable("archiveProject")
          const { data } = await archiveProject({ projectId: project.id })
          if (data && data.isSuccess) {
            await this.props.fetch({
              collection: "projects",
              id: project.id,
              force: true,
            })
            for (const liveForm of liveForms) {
              // Update the live indicator of each form that was live before archiving.
              await this.props.fetch({
                collection: "forms",
                id: liveForm.id,
                force: true,
              })
            }
            this.props.history.push("/")
            this.props.success("Project archived")
          } else {
            this.props.error("Could not archive project", data.message)
          }
        } catch (e) {
          this.props.error("Could not archive project", e.message)
        } finally {
          this.setState({ isArchiveLoading: false })
        }
      }
    }
  }
  async deleteProject() {
    const confirm = await this.props.warn(
      "Are you sure to delete this project?",
      "All interviews, video's, forms and other data will be removed forever.",
      "Delete project",
    )
    if (confirm) {
      try {
        await this.props.delete("projects", this.props.match.params.projectId)
        this.props.success("Project deleted")
        this.props.history.push("/")
      } catch (e) {
        this.props.error("Could not delete project", e)
      }
    }
  }
  maybeUpdateLiveForms(project, interviewer, availability = false) {
    // Update live forms associated with this
    const oldDates = getInterviewDays(project)
    const allDates = (project.interviewers || [])
      .map((thisInterviewer) => {
        const thisAvailability =
          thisInterviewer.id === interviewer.id
            ? availability || []
            : thisInterviewer.availability || []
        return thisAvailability.map((x) =>
          moment(x.from, "X").startOf("day").unix(),
        )
      })
      .flat()
    const dates = [...new Set(allDates)].filter((x) => x).sort()
    if (JSON.stringify(oldDates) !== JSON.stringify(dates)) {
      const obj = liveFormObj({ dates }, project.id, this.props.forms)
      if (obj) {
        const numOfForms = Object.keys(obj).length
        if (numOfForms === 1) {
          const form = this.props.forms.find(
            (x) => x.id === Object.keys(obj)[0],
          )
          this.props.success(
            "Live form updated to match new availability",
            form.title,
          )
        } else {
          this.props.success(
            `${numOfForms} live forms updated to match new availability`,
          )
        }
        this.props.updateMultiple("forms", obj)
      }
    }
  }
  render() {
    const project = this.props.project
    const backUrl = this.props.backTo
    const createdBy =
      (this.props.usersPublic.find((x) => x.id === project.createdBy) || {})
        .name || "a deleted user"
    const hasInterviewers =
      project.interviewers && !!project.interviewers.length
    const isOwner = project.createdBy === this.props.userId
    const isMultiple = project.interviewers?.length > 1
    if (this.props.isDemo) return null
    return (
      <Page
        isPopup
        title="Project settings"
        width="xl"
        icon="settings"
        isFlexCol
        style={{ height: 560 }}
        backTo={backUrl}
        hasDone
      >
        <Tabs marginSide={0} margin={8} className="px-10">
          <Tab to={`${backUrl}/settings`} exact>
            General
          </Tab>
          <Tab to={`${backUrl}/settings/interviewers`}>Interviewers</Tab>
        </Tabs>
        <Route
          path="/projects/:projectId/:collection/settings"
          exact
          render={(props) => (
            <div className="flex flex-1 flex-col p-10 pt-6">
              <InputGroup
                beforeWidth={80}
                onBlur={(data) =>
                  this.props.update("projects", project.id, data)
                }
                doc={project}
                fields={[
                  {
                    key: "name",
                    before: "Name",
                    placeholder: "New project",
                    disabled: !isOwner,
                  },
                  {
                    key: "duration",
                    before: "Duration",
                    type: "number",
                    placeholder: "60",
                    min: 5,
                    max: 360,
                    after: "minutes per interview",
                  },
                ]}
              />
              <p className="sub mt-3">
                This is the default interview duration. You can always adjust
                the duration of individual interviews.
              </p>
              <div className="mt-5 flex">
                <Icon
                  icon="info"
                  size={12}
                  color="gray-500"
                  className="mt-1 mr-3"
                />
                <p className="sub">
                  This project was created on{" "}
                  {format("date", project.createdOn, { month: "full" })} at{" "}
                  {format("time", project.createdOn)} by {createdBy}.
                </p>
              </div>
              {isOwner && (
                <div className="mt-auto flex space-x-5 pt-6">
                  <Button
                    isLoading={this.state.isArchiveLoading}
                    hasBorder
                    full
                    onClick={() => this.archiveOrRestoreProject()}
                  >
                    {project.isArchived ? "Restore" : "Archive"} project
                  </Button>
                  <Button full isRed onClick={() => this.deleteProject()}>
                    Delete project
                  </Button>
                </div>
              )}
            </div>
          )}
        />
        <Route
          path="/projects/:projectId/:collection/settings/interviewers"
          exact
          render={(props) => (
            <div className="flex-1 overflow-scroll pb-10">
              {hasInterviewers ? (
                <>
                  <div className="px-10 pt-9 pb-6">
                    <div className="flex">
                      <Icon
                        icon={isMultiple ? "users" : "user"}
                        size={16}
                        className="mr-3"
                      />
                      <div>
                        <h5>
                          {isMultiple
                            ? `${project.interviewers.length} interviewers`
                            : "1 interviewer"}
                        </h5>
                        <div className="sub mt-2 w-72">
                          Add the availability of the interviewer
                          {isMultiple ? "s" : ""} to enable scheduling.{" "}
                          <Dropdown
                            width={280}
                            isInfo
                            button={
                              <span className="link-color">Learn more</span>
                            }
                          >
                            <h6 className="mb-3">
                              About interviewer availability
                            </h6>
                            <p className="text-sm">
                              Adding the availability of the interviewer enables
                              scheduling tools, designed to save you time.
                            </p>
                            <ul className="mt-2 text-sm">
                              <li>
                                It allows you to use autoschedule: a tool that
                                automatically schedules your interviews within
                                the available time of the interviewer.
                              </li>
                              <li className="mt-2">
                                It also enables smart notifications that warn
                                you when interviews are being scheduled outside
                                the availability of the interviewer.
                              </li>
                              <li className="mt-2">
                                With interviewer availability, you can also add
                                the Availability field to a form. This allows
                                interviewees to submit their own availability on
                                the days the interviewer is available.
                              </li>
                            </ul>
                          </Dropdown>
                        </div>
                      </div>
                      {hasInterviewers && (
                        <Button
                          title="Add another interviewer"
                          titleRight
                          className="ml-auto -mt-2.5"
                          onClick={() => {
                            this.props.addToArray(
                              "projects",
                              project.id,
                              "interviewers",
                              {
                                name: "",
                                id: getSemiUniqueKey(),
                                availability: [],
                              },
                            )
                          }}
                          icon="add"
                        />
                      )}
                    </div>
                  </div>
                  {project.interviewers.map((interviewer, i) => {
                    const updateInterviewer = (data) =>
                      this.props.updateInArray(
                        "projects",
                        project.id,
                        "interviewers",
                        interviewer.id,
                        data,
                      )
                    return (
                      <div
                        key={interviewer.id}
                        className="border-light flex py-2 px-10"
                      >
                        {project.interviewers.length > 1 && (
                          <div className="h5 mr-5 flex h-6 w-6 flex-none items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700">
                            {i + 1}
                          </div>
                        )}
                        <div className="flex-1">
                          <div className="mb-2">
                            <h6 className="mb-2">Name</h6>
                            <Input
                              placeholder="Add interviewer name..."
                              hasBorder
                              defaultValue={interviewer.name}
                              onBlur={(name) => updateInterviewer({ name })}
                            />
                          </div>
                          <AvailabilitySheet
                            key={interviewer.id}
                            availability={interviewer.availability}
                            onUpdate={(availability) => {
                              this.maybeUpdateLiveForms(
                                project,
                                interviewer,
                                availability,
                              )
                              updateInterviewer({ availability })
                            }}
                            onDelete={async () => {
                              const confirm = await this.props.warn(
                                "Are you sure?",
                                "Deleting this interviewer is permanent and cannot be undone.",
                                "Delete",
                              )
                              if (confirm) {
                                const interviews = this.props.interviews.filter(
                                  (x) =>
                                    x.projectId === project.id &&
                                    x.interviewerId === interviewer.id,
                                )
                                let obj = {}
                                for (const interview of interviews) {
                                  obj[interview.id] = { interviewerId: null }
                                }
                                if (Object.keys(obj).length > 0)
                                  this.props.updateMultiple("interviews", obj)
                                this.maybeUpdateLiveForms(project, interviewer)
                                this.props.deleteFromArray(
                                  "projects",
                                  project.id,
                                  "interviewers",
                                  interviewer.id,
                                )
                              }
                            }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <State title="No interviewers" icon="user">
                  <div className="mx-auto max-w-sm px-4 text-center leading-normal">
                    Add the availability of the interviewer(s) to enable
                    scheduling.{" "}
                    <Dropdown
                      width={280}
                      isInfo
                      button={<span className="link-color">Learn more</span>}
                    >
                      <h6 className="mb-3 text-left">
                        About interviewer availability
                      </h6>
                      <p className="text-left text-sm">
                        Adding the availability of the interviewer enables
                        scheduling tools, designed to save you time.
                      </p>
                      <ul className="mt-2 text-left text-sm">
                        <li>
                          It allows you to use autoschedule: a tool that
                          automatically schedules your interviews within the
                          available time of the interviewer.
                        </li>
                        <li className="mt-2">
                          It also enables smart notifications that warn you when
                          interviews are being scheduled outside the
                          availability of the interviewer.
                        </li>
                        <li className="mt-2">
                          With interviewer availability, you can also add the
                          Availability field to a form. This allows interviewees
                          to submit their own availability on the days the
                          interviewer is available.
                        </li>
                      </ul>
                    </Dropdown>
                  </div>
                  <Button
                    primary
                    className="mt-6"
                    onClick={() => {
                      this.props.addToArray(
                        "projects",
                        project.id,
                        "interviewers",
                        { name: "", id: getSemiUniqueKey(), availability: [] },
                      )
                    }}
                  >
                    Add interviewer
                  </Button>
                </State>
              )}
            </div>
          )}
        />
      </Page>
    )
  }
}

export default withData(withNotify(ProjectSettings))
