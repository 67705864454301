import React from "react"
import {
  getSemiUniqueKey,
  Button,
  List,
  Page,
  withData,
  withNotify,
  State,
} from "../shared"
import { Route, Switch } from "react-router-dom"
import ProjectEmailNewTemplate from "./ProjectEmailNewTemplate"

class projectEmailNewTemplates extends React.Component {
  componentDidMount() {
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const emailTemplates = project.emailTemplates || []
    if (!this.props.match.params.templateId && emailTemplates.length > 0) {
      this.props.history.replace(
        `/projects/${this.props.match.params.projectId}/emails${
          this.props.match.params.emailId
            ? `/${this.props.match.params.emailId}`
            : ""
        }/new/templates/${emailTemplates[0].id}`,
        this.props.location.state,
      )
    }
  }
  addTemplate(project, backUrl) {
    const emailTemplates = [...(project.emailTemplates || [])]
    const id = getSemiUniqueKey()
    emailTemplates.push({
      id,
      subject: "",
      message: "",
    })
    this.props.update("projects", this.props.match.params.projectId, {
      emailTemplates,
    })
    this.props.history.push(`${backUrl}/templates/${id}`)
  }
  render() {
    const project =
      this.props.projects.find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}
    const templates = project.emailTemplates || []
    const backUrl = `/projects/${this.props.match.params.projectId}/emails${
      this.props.match.params.emailId
        ? `/${this.props.match.params.emailId}`
        : ""
    }/new`

    return (
      <Page height={560} isPopup backTo={backUrl} width="5xl" noBar>
        <div className="two-pages-sm">
          <Page
            isPopupChild
            icon="folder"
            title="Templates"
            hasPadding
            isSidebar
          >
            <List
              singular="template"
              keySecondary="subject"
              keyTertiary="message"
              fields={[
                { key: "subject" },
                { key: "message", defaultValue: "Empty" },
              ]}
              data={templates}
              to={`${backUrl}/templates/:id`}
            />
            {!!templates.length && (
              <Button
                secondary
                full
                className="mt-3"
                onClick={() => this.addTemplate(project, backUrl)}
              >
                Add template
              </Button>
            )}
          </Page>
          <Switch>
            <Route
              path="/projects/:projectId/emails/:emailId?/new/templates/:templateId"
              component={ProjectEmailNewTemplate}
            />
            <Route
              path="/projects/:projectId/emails/:emailId?/new/templates/"
              exact
              render={(props) => (
                <Page
                  {...props}
                  isPopupChild
                  barRight={
                    <Button secondary to={backUrl}>
                      Done
                    </Button>
                  }
                >
                  {!templates.length && (
                    <State title="No templates" icon="folder" className="-mt-5">
                      <p className="mx-auto mb-5 max-w-sm text-center">
                        Set up email templates to speed up your workflow.
                      </p>
                      <Button
                        primary
                        onClick={() => this.addTemplate(project, backUrl)}
                      >
                        Add template
                      </Button>
                    </State>
                  )}
                </Page>
              )}
            />
          </Switch>
        </div>
      </Page>
    )
  }
}

projectEmailNewTemplates.propTypes = {}

export default withData(withNotify(projectEmailNewTemplates))
