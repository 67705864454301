import React from "react"
import {
  withData,
  State,
  Shortcut,
  Button,
  Icon,
  format,
  fName,
  withNotify,
  DropdownItem,
} from "../shared"
import AvailabilitySheet from "../components/AvailabilitySheet"

class ProjectInterviewAvailability extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAvailability: false,
    }
  }
  render() {
    const interview = this.props.interview
    const firstNameS = fName(interview.name, { withS: true })
    const availability = interview.availability || []

    const responsesWithAvailability = Array.isArray(interview.responses)
      ? (this.props.formResponses || [])
          .filter((x) => interview.responses.includes(x.id))
          .filter((response) => {
            const form = this.props.forms.find((x) => x.id === response.formId)
            if (!form) return false
            const questions = form.questions || []
            if (!questions.length) return false
            return questions.some((x) => x.type === "availability")
          })
      : []

    const buttonMore = responsesWithAvailability.length ? (
      <>
        <h6 className="pf">From response</h6>
        {responsesWithAvailability.map((response) => {
          const form = this.props.forms.find((x) => x.id === response.formId)
          return (
            <DropdownItem
              onClick={() => {
                this.props.update("interviews", interview.id, {
                  availability: [...response.availability],
                })
                this.props.success("Availability added")
              }}
              desc={format("date", response.createdOn, { month: "full" })}
            >
              {form.title}
            </DropdownItem>
          )
        })}
      </>
    ) : null

    return (
      <div className="animate-up h-full overflow-scroll p-10 pt-5">
        <Shortcut
          press="Escape"
          onPress={() =>
            this.props.history.push(`/projects/${this.props.project.id}`)
          }
        />
        {availability.length || this.state.showAvailability ? (
          <>
            <div className="mt-1 flex pt-3 pb-2">
              <Icon size={16} className="mr-3" icon="clock" />
              <div>
                <h5>Availability</h5>
                <p className="sub mb-5 mt-1.5">
                  {firstNameS} availability below will be used when scheduling
                  interviews.
                </p>
              </div>
            </div>
            <AvailabilitySheet
              firstColName="Date"
              key={interview.id}
              availability={interview.availability}
              onUpdate={(availability) => {
                this.props.update("interviews", interview.id, { availability })
              }}
            />
          </>
        ) : (
          <State title="No availability" icon="clock">
            <p className="mx-auto mb-5 max-w-xs text-center">
              Enter {firstNameS} interview availability to enable scheduling
              tools.
            </p>
            <Button
              more={buttonMore}
              moreWidth={220}
              secondary
              onClick={() => this.setState({ showAvailability: true })}
            >
              Add availability
            </Button>
          </State>
        )}
      </div>
    )
  }
}

export default withData(withNotify(ProjectInterviewAvailability))
