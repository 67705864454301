import React from "react"
import { withRouter } from "react-router-dom"
import {
  Page,
  withData,
  withNotify,
  Input,
  Shortcut,
  Icon,
  toPreview,
} from "../shared"
import { getQuoteFromTranscript } from "../functions"

class Find extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      q: "",
      activeZ: 0,
    }
  }
  onUp() {
    if (this.state.activeZ > 0) {
      this.setState({ activeZ: this.state.activeZ - 1 })
    }
  }
  onDown() {
    this.setState({
      activeZ: Math.min(this.state.activeZ + 1, this.results.length - 1),
    })
  }
  onEnter() {
    if (this.results.length) {
      const item = this.results[this.state.activeZ]
      this.props.history.push(
        `/projects/${item.projectId}/${item._collection}/${item.id}`,
      )
    }
  }
  render() {
    const { activeZ } = this.state
    const archivedLast = (a, b) => {
      if (a.isArchived && !b.isArchived) return 1
      if (!a.isArchived && b.isArchived) return -1
      return 0
    }
    const q = (this.state.q || "").toLowerCase()
    const interviews = this.props.interviews
      .filter((x) => x.name?.toLowerCase().includes(q))
      .sort(archivedLast)
    const findings = this.props.findings
      .filter((x) => {
        if (x.text) {
          return x.text.toLowerCase().includes(q)
        } else {
          const interview = this.props.interviews.find(
            (y) => y.id === x.interviewId,
          )
          const sentence = getQuoteFromTranscript(
            interview.transcript,
            interview.transcriptEntities,
            x,
          )
          return sentence?.toLowerCase().includes(q)
        }
      })
      .sort(archivedLast)
    const emails = this.props.emails
      .filter(
        (x) =>
          x.subjectUnparsed?.toLowerCase().includes(q) ||
          (!!x.messageUnparsed?.toLowerCase &&
            x.messageUnparsed?.toLowerCase().includes(q)),
      )
      .sort(archivedLast)
    const forms = this.props.forms
      .filter((x) => x.title?.toLowerCase().includes(q))
      .sort(archivedLast)
    this.results = [
      // Note: play close attention to this order, they need to be in the same order as the results are shown to get the correct index.
      ...interviews.map((x) => ({ ...x, _collection: "interviews" })),
      ...forms.map((x) => ({ ...x, _collection: "forms" })),
      ...findings.map((x) => ({ ...x, _collection: "findings" })),
      ...emails.map((x) => ({ ...x, _collection: "emails" })),
    ]
    const hasResults = this.results.length > 0

    return (
      <Page
        title="Find"
        width="xl"
        noBar
        style={{ height: 600 }}
        backTo={toPreview()}
        isPopup
        isFlexCol
        noBg
        zIndex={999}
      >
        <Shortcut
          press="Escape"
          alsoWorksWhenInputInFocus
          onPress={() => this.props.history.push(toPreview())}
        />
        <div className="flex rounded-3xl bg-white p-6 px-10 pb-3 dark:bg-gray-800">
          <Icon icon="search" color={window.appColor} className="mr-6" />
          <Input
            name="search"
            value={q}
            onChange={(q) => this.setState({ q, activeZ: 0 })}
            placeholder="Find..."
            autoFocus
            classNameInput="text-xl"
            noInputStyling
            noStyling
          />
        </div>
        <div
          className="flex-1"
          onClick={() => this.props.history.push(toPreview())}
        >
          {q && (
            <div
              onClick={(e) => e.stopPropagation()}
              style={{ maxHeight: "50vh" }}
              className="mt-4 space-y-8 overflow-scroll rounded-3xl bg-white p-5 dark:bg-gray-800"
            >
              <Shortcut
                press="ArrowUp"
                alsoWorksWhenInputInFocus
                onPress={this.onUp.bind(this)}
              />
              <Shortcut
                press="ArrowDown"
                alsoWorksWhenInputInFocus
                onPress={this.onDown.bind(this)}
              />
              <Shortcut
                press="Enter"
                alsoWorksWhenInputInFocus
                onPress={this.onEnter.bind(this)}
              />

              {interviews.length > 0 && (
                <div>
                  <div className="flex items-center pl-4 pb-4 text-lg font-medium text-gray-500">
                    <Icon icon="mic" size={16} className="mr-3" />
                    <div>Interviews</div>
                  </div>
                  {interviews.map((interview, i) => {
                    const project = this.props.projects.find(
                      (x) => x.id === interview.projectId,
                    )
                    return (
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/projects/${interview.projectId}/interviews/${interview.id}`,
                          )
                        }
                        className={`flex cursor-pointer truncate rounded-lg px-5 py-3 text-lg ${
                          activeZ === i ? "bg-indigo-500 text-white" : ""
                        }`}
                      >
                        <div className="truncate">{interview.name}</div>
                        {project?.name && (
                          <div className="ml-auto pl-2 opacity-60">
                            {project.name}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

              {forms.length > 0 && (
                <div>
                  <div className="flex items-center pl-4 pb-4 text-lg font-medium text-gray-500">
                    <Icon icon="form" size={16} className="mr-3" />
                    <div>Forms</div>
                  </div>
                  {forms.map((form, i) => {
                    const z = interviews.length + i
                    const project = this.props.projects.find(
                      (x) => x.id === form.projectId,
                    )
                    return (
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/projects/${form.projectId}/forms/${form.id}`,
                          )
                        }
                        className={`flex cursor-pointer truncate rounded-lg px-5 py-3 text-lg ${
                          activeZ === z ? "bg-indigo-500 text-white" : ""
                        }`}
                      >
                        <div className="truncate">{form.title}</div>
                        {project?.name && (
                          <div className="ml-auto pl-2 opacity-60">
                            {project.name}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

              {findings.length > 0 && (
                <div>
                  <div className="flex items-center pl-4 pb-4 text-lg font-medium text-gray-500">
                    <Icon icon="star" size={16} className="mr-3" />
                    <div>Findings</div>
                  </div>
                  {findings.map((finding, i) => {
                    const z = interviews.length + forms.length + i
                    const interview = this.props.interviews.find(
                      (x) => finding.interviewId === x.id,
                    )
                    const project = this.props.projects.find(
                      (x) => x.id === finding.projectId,
                    )
                    const sentence =
                      !finding.text &&
                      getQuoteFromTranscript(
                        interview.transcript,
                        interview.transcriptEntities,
                        finding,
                        true,
                      )
                    return (
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/projects/${finding.projectId}/findings/${finding.id}`,
                          )
                        }
                        className={`flex cursor-pointer truncate rounded-lg px-5 py-3 text-lg ${
                          activeZ === z ? "bg-indigo-500 text-white" : ""
                        }`}
                      >
                        <div className="truncate">
                          {finding.text || sentence}
                        </div>
                        {project?.name && (
                          <div className="ml-auto pl-2 opacity-60">
                            {project.name}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

              {emails.length > 0 && (
                <div>
                  <div className="flex items-center pl-4 pb-4 text-lg font-medium text-gray-500">
                    <Icon icon="send" size={16} className="mr-3" />
                    <div>Emails</div>
                  </div>
                  {emails.map((email, i) => {
                    const z =
                      interviews.length + forms.length + findings.length + i
                    const project = this.props.projects.find(
                      (x) => x.id === email.projectId,
                    )
                    return (
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/projects/${email.projectId}/emails/${email.id}`,
                          )
                        }
                        className={`flex cursor-pointer truncate rounded-lg px-5 py-3 text-lg ${
                          activeZ === z ? "bg-indigo-500 text-white" : ""
                        }`}
                      >
                        <div className="truncate">{email.subjectUnparsed}</div>
                        {project?.name && (
                          <div className="ml-auto pl-2 opacity-60">
                            {project.name}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

              {!hasResults && (
                <div className="p-10 text-center">No results</div>
              )}
            </div>
          )}
        </div>
      </Page>
    )
  }
}

Find.propTypes = {}

export default withData(withNotify(withRouter(Find)))
