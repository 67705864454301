import React from "react"
import {
  Button,
  Icon,
  LoginBox,
  Page,
  withData,
  withNotify,
} from "../../shared"
import { withRouter } from "react-router-dom"

class PopoverOnboarding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
    }
  }
  render() {
    return (
      <Page isPopup backTo={false} width="md" noBar>
        <div className="relative flex h-28 items-center justify-center">
          <div className="absolute left-0 right-0 -bottom-10 mx-auto flex h-24 w-24 items-center justify-center rounded-full border-8 border-white bg-yellow-400 dark:border-gray-800">
            <Icon icon="mic" size={40} className="mt-px" color="yellow-800" />
          </div>
        </div>
        <div className="p-10 pt-12 pb-10">
          <h4 className="mx-auto text-center text-3xl leading-relaxed">
            InterviewKit
          </h4>
          <h4 className="text-center font-medium text-gray-500">
            Welcome back.
          </h4>
          <LoginBox
            hideTitle
            redirectTo={this.props.pathname}
            className="mx-auto mt-8"
          />
        </div>
        <div className="border-light flex border-t p-6 px-10">
          <Button
            onClick={() => this.props.setSession({ isOnboarded: true })}
            full
            icon="cursor"
          >
            Try it out
          </Button>
          <Button
            onClick={() => (window.location.href = "//interviewkit.co")}
            full
            icon="help"
          >
            Learn more
          </Button>
        </div>
      </Page>
    )
  }
}

PopoverOnboarding.propTypes = {}

export default withData(withNotify(withRouter(PopoverOnboarding)))
