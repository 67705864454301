import React from 'react';

class Divider extends React.Component {
    render() {
        return (
            this.props.children ? (
                <h6 onClick={this.props.onClick} className={`tr select-none ${this.props.pl ? 'pl-3' : ''} pt-8 pb-2 ${this.props.className || ''}`}>
                    {this.props.children}
                </h6>
            ) : (
                <div className={`border-b my-1 ${this.props.className || ''}`} />
            )
        );
    }
}

export default Divider;