import React from "react"
import {
  ButtonImages,
  Input,
  Toggle,
  capitalize,
  withData,
  PopoverImage,
} from "../shared"
import { Route, withRouter } from "react-router-dom"

class ProjectFormDesign extends React.Component {
  render() {
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const form = this.props.form
    const defaultImage =
      "https://images.unsplash.com/photo-1508614999368-9260051292e5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1280&q=80"

    return (
      <>
        <Route
          path="/projects/:projectId/forms/:formId/design/image"
          render={(props) => (
            <PopoverImage
              {...props}
              backTo={`/projects/${project.id}/forms/${form.id}/design`}
              minWidth={1280}
              minHeight={960}
              image={form.image}
              onChange={(image) =>
                this.props.update("forms", form.id, { image })
              }
            />
          )}
        />
        <div className="p-10">
          <h5 className="mb-3">Color palette</h5>
          <Input
            type="color"
            beforeWidth={1}
            className="w-48"
            hasBorder
            before={capitalize(form.accentColor) || "Indigo"}
            defaultValue={form.accentColor || "indigo"}
            arrowPosition="left"
            noWeights
            hasAll
            onBlur={(accentColor) =>
              this.props.update("forms", form.id, { accentColor })
            }
            canClear={false}
          />
          <h5 className="mb-5 mt-10">Cover image</h5>
          <ButtonImages
            onChange={(image) => this.props.update("forms", form.id, { image })}
            onMore={() =>
              this.props.history.push(
                `/projects/${project.id}/forms/${form.id}/design/image`,
              )
            }
            value={form.image || defaultImage}
            options={[
              {
                value: defaultImage,
                preview:
                  "https://images.unsplash.com/photo-1508614999368-9260051292e5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
              },
              {
                value:
                  "https://images.unsplash.com/photo-1557683311-eac922347aa1?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1280&q=80",
                preview:
                  "https://images.unsplash.com/photo-1557683311-eac922347aa1?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
              },
              {
                value:
                  "https://images.unsplash.com/photo-1569521569926-8bb185c2b5d8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80",
                preview:
                  "https://images.unsplash.com/photo-1569521569926-8bb185c2b5d8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60",
              },
              {
                value:
                  "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1280&q=80",
                preview:
                  "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
              },
              {
                value:
                  "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1280&q=80",
                preview:
                  "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
              },
            ]}
          />
          <Toggle
            value={form.showImageOnMobile}
            onChange={(showImageOnMobile) =>
              this.props.update("forms", form.id, { showImageOnMobile })
            }
            className="mt-5 max-w-lg"
          >
            Show cover image on mobile
          </Toggle>
          <p className="sub mt-1 max-w-lg">
            The cover image is shown full-screen behind the form. On mobile, it
            is displayed below the title on the introduction page.
          </p>
        </div>
      </>
    )
  }
}

export default withData(withRouter(ProjectFormDesign))
