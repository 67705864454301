import React from "react"
import { withRouter } from "react-router-dom"
import Shortcut from "./Shortcut"
import Icon from "./Icon"

class Path extends React.Component {
  render() {
    return (
      <div
        onClick={
          this.props.to
            ? () => this.props.history.push(this.props.to)
            : this.props.onClick
        }
        className={`inline-flex h-10 select-none items-center whitespace-nowrap rounded-lg px-4 text-lg ${
          this.props.value.length > 1 ? "pushable hover:bg-gray-200" : ""
        }`}
      >
        {this.props.value.map((child, i) => (
          <React.Fragment key={i}>
            {i > 0 ? (
              <div className="-mt-px px-2">
                <Icon icon="chevron-right" size={8} color="gray-500" />
              </div>
            ) : null}
            <div
              className={`${
                i < this.props.value.length - 1
                  ? "text-gray-500"
                  : "font-medium"
              }`}
            >
              {child}
            </div>
          </React.Fragment>
        ))}
        {this.props.value.length > 1 ? (
          <Icon icon="delete" size={12} color="gray-500" className="ml-2" />
        ) : null}
        <Shortcut
          press="Escape"
          onPress={() => this.props.history.push(this.props.to)}
        />
      </div>
    )
  }
}

export default withRouter(Path)
