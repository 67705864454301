import React from "react"
import { withNotify } from "../notify"
import ContextMenu from "./ContextMenu"
import DropdownItem from "./DropdownItem"
import PropTypes from "prop-types"
import { withData } from "../data"
import Loader from "./Loader"
import { getColor } from "../functions"

class Avatar extends React.Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    if (props.id) this.props.fetch({ collection: "usersPublic", id: props.id })
    this.state = {
      isLoading: false,
    }
  }
  async uploadAvatar(e) {
    if (!this.props.canUpload) return false
    const file = e.target.files[0]
    if (!file) return false
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      return this.props.error("Please upload a JPG or PNG image.")
    }
    if (file.size > 5000000) {
      return this.props.error("Please upload an image smaller than 5 MB.")
    }
    try {
      this.setState({ isLoading: true })
      const { url, path } = await this.props.uploadFile(
        `users/${this.props.userId}`,
        file,
      )
      await this.props.update("usersPublic", this.props.userId, {
        avatarUrl: url,
        avatarPath: path,
      })
      this.props.success("New avatar saved. Looks good!")
      this.setState({ isLoading: false })
    } catch (e) {
      this.props.error("Could not upload avatar", e.message)
      this.setState({ isLoading: false })
    }
  }
  async deleteAvatar(path) {
    if (!this.props.canUpload) return false
    const confirm = await this.props.warn(
      "Are you sure you want to delete your avatar?",
      null,
      "Delete avatar",
    )
    if (confirm) {
      this.setState({ isLoading: true })
      try {
        await this.props.deleteFile(path)
        await this.props.update("usersPublic", this.props.userId, {
          avatarUrl: null,
          avatarPath: null,
        })
        this.props.success("Avatar deleted")
      } catch (e) {
        this.props.error("Could not delete avatar", e.message)
      }
      this.setState({ isLoading: false })
    }
  }
  render() {
    const isGroup = this.props.isGroup && this.props.i != null
    const size = this.props.size || 8
    const userId = this.props.id
    const user =
      userId && this.props.usersPublic
        ? this.props.usersPublic.find((x) => x.id === userId) || {}
        : {}
    const src = user.avatarUrl
    const name = user.name || user.email || ""
    const nameSplit = name.split(" ")
    const shortName = (
      nameSplit.length > 1
        ? `${nameSplit[0][0]}${nameSplit[nameSplit.length - 1][0]}`
        : name.substring(0, 2)
    ).toUpperCase()
    const color =
      this.props.i != null
        ? getColor(this.props.i * 2 + 6, 500)
        : window.appColor
    const child = (
      <div
        onClick={() => (this.input.current ? this.input.current.click() : null)}
        className={`${this.state.isLoading ? "pointer-events-none" : ""} ${
          isGroup ? "border-2 border-white dark:border-gray-800" : ""
        } ${isGroup && this.props.i > 0 ? "-ml-4" : ""} relative bg-cover ${
          this.props.isOnline === false && this.props.userId !== this.props.id
            ? ""
            : ""
        } bg-center ${
          this.props.canUpload ? "pushable-sm cursor-pointer" : null
        } w-${size} h-${size} ${
          src ? "" : `bg-${color}`
        } relative flex items-center justify-center rounded-full ${
          this.props.className || ""
        }`}
        style={{
          backgroundImage: src ? `url(${src})` : null,
          zIndex: isGroup ? 10 - this.props.i : null,
        }}
      >
        {src || this.state.isLoading ? null : shortName ? (
          <span
            className={`text-center font-bold text-white ${
              size >= 24
                ? "text-4xl"
                : size >= 16
                ? "text-2xl"
                : size < 10
                ? "pt-0.5 text-xs"
                : ""
            }`}
          >
            {shortName}
          </span>
        ) : null}
        {this.props.canUpload ? (
          <input
            onChange={this.uploadAvatar.bind(this)}
            ref={this.input}
            className="hidden"
            type="file"
            accept="image/png,image/jpeg"
          />
        ) : null}
        {this.state.isLoading ? (
          <Loader
            color="white"
            colorDark="white"
            className="absolute"
            size={40}
          />
        ) : null}
      </div>
    )
    return src && this.props.canUpload ? (
      <ContextMenu
        menu={
          <DropdownItem
            isRed
            onClick={() => this.deleteAvatar(user.avatarPath)}
            icon="delete"
          >
            Delete image
          </DropdownItem>
        }
      >
        {child}
      </ContextMenu>
    ) : (
      child
    )
  }
}

Avatar.propTypes = {
  size: PropTypes.number,
  id: PropTypes.string,
  canUpload: PropTypes.bool,
}

export default withNotify(withData(Avatar))
