import React from "react"
import PropTypes from "prop-types"
import { parseShortcut } from "../functions"

/**
 * Component to register keyboard shortcuts.
 * Can be used like: <Shortcut press="cmd-s" onPress={this.save} />
 */
class Shortcut extends React.Component {
  constructor(props) {
    super(props)
    this.handleShortcutBound = this.handleShortcut.bind(this)
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleShortcutBound)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleShortcutBound)
  }
  handleShortcut(e) {
    if (!this.props.onPress) return false

    // Only execute shortcut if no elements are in focus, unless explicitly allowed.
    if (!this.props.alsoWorksWhenInputInFocus) {
      if (
        e.target.tagName.toUpperCase() === "INPUT" ||
        e.target.tagName.toUpperCase() === "TEXTAREA"
      )
        return true
    }

    // Convert digits, so we can do e.g. "shift-2"
    let key = e.key
    if (e.code === "Digit1") key = "1"
    if (e.code === "Digit2") key = "2"
    if (e.code === "Digit3") key = "3"
    if (e.code === "Digit4") key = "4"
    if (e.code === "Digit5") key = "5"
    if (e.code === "Digit6") key = "6"
    if (e.code === "Digit7") key = "7"
    if (e.code === "Digit8") key = "8"
    if (e.code === "Digit9") key = "9"

    const { letter, isOption, isShift, isCmd } = parseShortcut(this.props.press)

    if (key && letter && key.toLowerCase() === letter.toLowerCase()) {
      const cmd = isCmd && (e.metaKey || e.ctrlKey)
      const option = isOption && e.altKey
      const shift = key === "Tab" || (isShift && e.shiftKey) // Enable shift-tab for tab shortcuts so that we can go backwards in the tab order.
      const none = !isCmd && !isShift && !e.metaKey && !e.shiftKey

      if (cmd || shift || option || none) {
        // Shortcut matches the key combination - execute the callback.
        e.preventDefault()
        e.stopImmediatePropagation()
        if (!this.props.disabled) this.props.onPress(e) // If disabled, still prevent default action.
        return false
      }
    }
  }
  render() {
    return this.props.children || null
  }
}

Shortcut.propTypes = {
  onPress: PropTypes.func,
  press: PropTypes.string,
  alsoWorksWhenInputInFocus: PropTypes.bool,
}

export default Shortcut
