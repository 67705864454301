import React from "react"
import Button from "../Button"
import Page from "../Page"
import Input from "../Input"
import { withData } from "../../data"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { format } from "../../functions"

class PopoverSetDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: this.props.date,
    }
  }
  finalize() {
    this.props.onSubmit(format("date", this.state.date, this.props.format))
    this.props.history.push(this.props.backTo)
  }
  render() {
    return (
      <Page
        isPopup
        title="Go to date"
        position={this.props.position}
        offset={this.props.offset}
        hasPadding
        backTo={this.props.backTo}
        footerGrid={2}
        footer={
          <>
            <Button
              shortcut="Enter"
              disabled={!this.state.date}
              onClick={this.finalize.bind(this)}
              primary
            >
              Go to date
            </Button>
            <Button to={this.props.backTo} secondary>
              Cancel
            </Button>
          </>
        }
      >
        <Input
          defaultValue={this.state.date}
          autoFocus
          type="date"
          placeholder="Set a date..."
          onBlur={(date) => this.setState({ date })}
        />
      </Page>
    )
  }
}

PopoverSetDate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.string.isRequired,
  date: PropTypes.string,
  format: PropTypes.string,
  position: PropTypes.string,
}

PopoverSetDate.defaultProps = {
  position: "topRight",
}

export default withData(withRouter(PopoverSetDate))
