import React from 'react';
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import Button from "./Button";

class ButtonSwitch extends React.Component {
    handleClick(value) {
        if(this.props.onChange) this.props.onChange(value);
    }
    render() {
        const value = this.props.value;
        const buttons = this.props.buttons || [];
        const isNav = buttons.some(button => button.to);
        return (
            <div className={`h-10 rounded-full tr group hover:bg-gray-100 dark:hover:bg-gray-900 inline-flex select-none ${this.props.disabled ? 'opacity-70 pointer-events-none' : ''} ${this.props.className || ''}`}>
                {buttons.map((button, i) => (
                    <div key={`button-${i}`} className={`relative ${this.props.full ? 'flex-1' : ''}`}>
                        {button.badge ? <div className={`absolute right-0 top-0 z-10 -mr-1 -mt-1 rounded bg-${window.appColor} text-white font-bold text-xs p-1 pt-px pb-0`}>{button.badge}</div> : null}
                        {isNav ? (
                            <NavLink to={button.to} exact={button.exact} title={button.title} activeClassName='bg-gray-100 dark:bg-gray-700 group-hover:bg-gray-200 dark:group-hover:bg-gray-700' className='rounded-full outline-none mx-px appearance-none focus:outline-none tr relative whitespace-nowrap select-none font-medium inline-flex items-center justify-center px-4 h-10'>{button.text}</NavLink>
                        ) : (
                            <Button full={this.props.full} title={button.title} noHover className={`mx-px ${value === button.value ? 'group-hover:bg-gray-200 dark:group-hover:bg-gray-700' : ''}`} onClick={() => this.handleClick(button.value)} selected={value === button.value} icon={button.icon}>{button.text}</Button>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}

ButtonSwitch.propTypes = {
    className: PropTypes.string,
    shortcuts: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default ButtonSwitch;