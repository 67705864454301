import React from "react"
import moment from "moment"
import {
  withData,
  Page,
  capitalize,
  format,
  InputGroup,
  sortData,
  Button,
  withNotify,
} from "../shared"
import { withRouter } from "react-router-dom"
import { DropdownItem } from "../shared"
import AvailabilitySheet from "../components/AvailabilitySheet"

class ProjectFormResponse extends React.Component {
  async turnIntoInterview(response) {
    const isCreateDisabled =
      this.props.plan === "regular" &&
      1 +
        this.props.interviews.filter(
          (x) =>
            x.createdBy === this.props.userId &&
            moment(x.createdOn, "X").isSameOrAfter(moment().startOf("month")),
        ).length >
        10
    if (isCreateDisabled)
      return this.props.error(
        "This exceeds 10 interviews this month",
        "Contact us to upgrade your plan.",
      )

    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    await this.props.create("interviews", {
      name: response.name || null,
      age: response.age || null,
      gender: response.gender || null,
      email: response.email || null,
      phone: response.phone || null,
      education: response.education || null,
      job: response.job || null,
      availability: response.availability || null,
      responses: [response.id],
      projectId: project.id,
      formId: this.props.match.params.formId,
    })
    this.props.success("Interview created")
  }
  async unlink(response, interview) {
    const responses = [...interview.responses]
    responses.splice(responses.indexOf(response.id), 1)
    await this.props.update("interviews", interview.id, { responses })
    this.props.success("Response unlinked from interview")
  }
  render() {
    const responseId =
      this.props.match.params.responseId || this.props.location.state.responseId
    let response = this.props.formResponses.find((x) => x.id === responseId)
    if (!response) return null
    const fields = []
    if (response.name) fields.push({ key: "name" })
    if (response.age) fields.push({ key: "age", type: "number" })
    if (response.gender) fields.push({ key: "gender" })
    if (response.email) fields.push({ key: "email", type: "email" })
    if (response.phone) fields.push({ key: "phone" })
    if (response.education) fields.push({ key: "education" })
    if (response.job) fields.push({ key: "job" })

    const others = (response.questions || []).filter(
      (x) => x.type.includes("-choice") || x.type === "text",
    )
    const backTo = `/projects/${this.props.match.params.projectId}/forms/${this.props.match.params.formId}/responses`

    const sortBy = this.props.local[`responsesSortBy`] || "createdOn"
    const sortDesc = this.props.local[`responsesSortDesc`] || false
    const sortByField = [
      ...fields,
      {
        key: "createdOn",
        type: "date",
        name: "Submitted",
        format: { includeTime: true },
      },
    ].find((x) => x.key === sortBy)
    const responses = this.props.formResponses
      .filter((x) => x.formId === this.props.match.params.formId)
      .sort((a, b) => sortData(a, b, sortByField, sortDesc))
    const index = responses.findIndex((x) => x.id === response.id)
    const prevResponse = responses[index - 1] || {}
    const nextResponse = responses[index + 1] || {}
    const interview = this.props.interviews.find((x) =>
      (x.responses || []).includes(response.id),
    )

    return (
      <Page
        isPopup
        title={`Response${response.name ? ` from ${response.name}` : ""}`}
        hasPadding
        width="2xl"
        backTo={backTo}
        titleIsSmall
        style={{ height: 660 }}
        barRight={
          <>
            <Button
              title="Previous"
              shortcut="cmd-ArrowLeft"
              to={`${backTo}/${prevResponse.id}`}
              disabled={index === 0}
              icon="chevron-left"
            />
            <h5 className="px-2 tabular-nums">
              {index + 1} of {responses.length}
            </h5>
            <Button
              title="Next"
              shortcut="cmd-ArrowRight"
              to={`${backTo}/${nextResponse.id}`}
              disabled={index === responses.length - 1}
              icon="chevron-right"
            />
          </>
        }
        more={
          <DropdownItem
            isRed
            icon="delete"
            onClick={async () => {
              const isConfirm = await this.props.delete(
                "formResponses",
                this.props.match.params.responseId,
                this.props.warn,
                undefined,
                "response",
              )
              if (isConfirm) {
                this.props.history.push(
                  `/projects/${this.props.match.params.projectId}/forms/${this.props.match.params.formId}/responses`,
                )
                this.props.success("Response deleted")
              }
            }}
          >
            Delete response
          </DropdownItem>
        }
        footerGrid={2}
        footer={
          <>
            {interview ? (
              <Button
                secondary
                full
                to={`/projects/${this.props.match.params.projectId}/interviews/${interview.id}`}
                moreWidth={200}
                more={
                  <DropdownItem
                    onClick={() => this.unlink(response, interview)}
                    icon="unlink"
                  >
                    Unlink from interview
                  </DropdownItem>
                }
              >
                Open interview
              </Button>
            ) : (
              <Button
                full
                primary
                onClick={() => this.turnIntoInterview(response)}
                moreWidth={230}
                more={
                  <DropdownItem
                    to={`${backTo}/${response.id}/link`}
                    icon="link"
                  >
                    Link to existing interview
                  </DropdownItem>
                }
              >
                Turn into interview
              </Button>
            )}
            <Button secondary to={backTo}>
              Cancel
            </Button>
          </>
        }
      >
        <InputGroup
          readOnly
          doc={response}
          className="mb-8"
          beforeWidth={80}
          fields={fields}
        />
        {others.map((x) => (
          <React.Fragment key={x.id}>
            <h6 className="mt-7 mb-1">{x.title}</h6>
            <div className="whitespace-pre-line leading-normal">
              {Array.isArray(x.answer)
                ? `${
                    x.answer.length ? (
                      x.answer
                        .sort((a, b) => a - b)
                        .map((x) => "• " + x)
                        .join("\n")
                    ) : (
                      <span className="text-gray-500">No answer</span>
                    )
                  }`
                : capitalize(x.answer) || (
                    <span className="text-gray-500">No answer</span>
                  )}
            </div>
          </React.Fragment>
        ))}
        {response.availability ? (
          <AvailabilitySheet
            key={response.id}
            className="mt-4 mb-2"
            availability={response.availability}
          />
        ) : null}
        <p className="sub mt-6">
          Form submitted on {format("date", response.createdOn)} at{" "}
          {format("time", response.createdOn)}.
        </p>
      </Page>
    )
  }
}

export default withData(withNotify(withRouter(ProjectFormResponse)))
