import React from "react"
import {
  Page,
  withData,
  withNotify,
  Button,
  Radio,
  RadioItem,
} from "../../shared"

class PopoverLinkResponse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      interviewId: false,
    }
  }
  async link(response, backTo) {
    const data = {}
    const interview = this.props.interviews.find(
      (x) => x.id === this.state.interviewId,
    )

    // Add response
    data.responses = [...(interview.responses || [])]
    data.responses.push(response.id)

    // Add other data (if missing in interview)
    const keys = ["name", "age", "gender", "email", "phone", "education", "job"]
    for (const key of keys) {
      if (!interview[key] && response[key]) data[key] = response[key]
    }

    // Add availability (if missing in interview)
    if (
      (!interview.availability || !interview.availability.length) &&
      response.availability &&
      response.availability.length
    ) {
      data.availability = response.availability
    }

    await this.props.update("interviews", interview.id, data)
    this.props.success("Response added to interview")
    this.props.history.push(backTo)
  }
  render() {
    const responseId =
      this.props.match.params.responseId ||
      (this.props.location.state || {}).responseId
    const interviews = this.props.interviews.filter(
      (x) => x.projectId === this.props.match.params.projectId,
    )
    const response = this.props.formResponses.find((x) => x.id === responseId)
    const backTo = `/projects/${this.props.match.params.projectId}/forms/${
      this.props.match.params.formId
    }/responses${
      this.props.match.params.responseId
        ? `/${this.props.match.params.responseId}`
        : ""
    }`
    return (
      <Page
        title="Link response to..."
        showError={!responseId ? "No response ID" : null}
        isPopup
        icon="link"
        width="xs"
        hasPadding
        backTo={backTo}
        footerGrid={2}
        footer={
          <>
            <Button
              onClick={() => this.link(response, backTo)}
              primary
              disabled={!this.state.interviewId}
            >
              Link
            </Button>
            <Button secondary to={backTo}>
              Cancel
            </Button>
          </>
        }
      >
        <Radio
          isSmall
          value={this.state.interviewId}
          onChange={(interviewId) => this.setState({ interviewId })}
        >
          {interviews.map((x) => (
            <RadioItem value={x.id}>{x.name}</RadioItem>
          ))}
        </Radio>
      </Page>
    )
  }
}

export default withData(withNotify(PopoverLinkResponse))
