import React from "react";
import DropdownItem from '../DropdownItem';

export default function DropdownItemInvite({project, to, ...props}) {
    const isShared = (project.memberIds || []).length > 1;
    return (
        <DropdownItem {...props} to={to} icon={isShared ? 'users' : 'user-add'}>
            {isShared ? `${project.memberIds.length} people have access` : 'Share project'}
        </DropdownItem>
    )
}