import React from "react"
import Icon from "./Icon"

class Callout extends React.Component {
  getContent() {
    if (this.props.isSuccess) {
      return {
        icon: "check",
        color: "green",
      }
    } else if (this.props.isTip) {
      return {
        icon: "zap",
        color: "blue",
      }
    } else if (this.props.isWarning) {
      return {
        icon: "warning",
        color: "orange",
      }
    } else if (this.props.isError) {
      return {
        icon: "warning",
        color: "red",
      }
    } else {
      // Default is info
      return {
        icon: "help",
        // color: gray (default)
      }
    }
  }
  render() {
    // Note: using the isPulse prop, you can change the icon for a dot. Only the isSuccess callout has a pulse animation.
    const { color, icon } = this.getContent()
    return (
      <div
        className={`px-4 ${this.props.noBg ? "" : "py-3"} flex rounded-lg ${
          color
            ? `text-${color}-${
                this.props.noBg ? 600 : 700
              } dark:text-${color}-${this.props.noBg ? 400 : 300} ${
                this.props.noBg ? "" : `bg-${color}-100 dark:bg-${color}-900`
              }`
            : this.props.noBg
            ? ""
            : "bg-gray-100 dark:bg-gray-700"
        } ${this.props.className || ""}`}
      >
        {this.props.isPulse ? (
          <div
            style={{ width: 14, height: 14 }}
            className="flex flex-none items-center justify-center"
          >
            {this.props.isSuccess ? (
              <div className="mt-1.5 h-2 w-2 flex-none animate-pulse rounded-full border-2 border-green-200 bg-green-500 dark:border-green-800" />
            ) : (
              <div className="mt-1.5 h-2 w-2 flex-none rounded-full border-2 border-orange-200 bg-orange-600 dark:border-orange-800 dark:bg-orange-400" />
            )}
          </div>
        ) : (
          <Icon
            icon={icon}
            size={this.props.isSuccess ? 12 : 14}
            className={`${this.props.isSuccess ? "px-px" : ""} mt-px`}
          />
        )}
        <p className="ml-3 text-sm font-medium">{this.props.children}</p>
      </div>
    )
  }
}

export default Callout
