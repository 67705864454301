import React from "react"
import { Page, withData, withNotify, Button, List, format } from "../shared"
import ProjectEmail from "./ProjectEmail"
import { Link, Route, withRouter } from "react-router-dom"
import { capitalize, State } from "../shared"
import { sortSends } from "../functions"
import ProjectEmailNew from "./ProjectEmailNew"
import ProjectEmailNewTemplates from "./ProjectEmailNewTemplates"
import moment from "moment"

class ProjectEmails extends React.Component {
  componentDidMount() {
    this.openFirst()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.projectId !== this.props.match.params.projectId
    ) {
      this.openFirst()
    }
  }
  openFirst() {
    const emails = (this.props.emails || [])
      .filter((x) => x.projectId === this.props.match.params.projectId)
      .sort((a, b) => b.createdOn - a.createdOn)
    if (!this.props.match.params.emailId && emails.length > 0) {
      this.props.history.replace(
        `/projects/${this.props.match.params.projectId}/emails/${emails[0].id}`,
        this.props.location.state,
      )
    }
    if (this.props.match.params.emailId === "new" && emails.length > 0) {
      this.props.history.replace(
        `/projects/${this.props.match.params.projectId}/emails/${emails[0].id}/new`,
        this.props.location.state,
      )
    }
  }
  render() {
    const project =
      (this.props.projects || []).find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}
    const interviews = (this.props.interviews || []).filter(
      (x) => x.projectId === this.props.match.params.projectId,
    )
    const emails = (this.props.emails || [])
      .filter((x) => x.projectId === project.id)
      .sort((a, b) => b.createdOn - a.createdOn)

    const fields = [
      { key: "toStr" },
      { key: "toNames" },
      { key: "subject" },
      { key: "message" },
      {
        key: "date",
        calc: (date, send) => {
          // send is a combination of the first send and other variables defined in data below
          let sends = (
            (this.props.emails || []).find((x) => x.id === send.id).sends || []
          ).sort((a, b) => b.scheduledAt - a.scheduledAt)
          if (send.isCancelled) return "Aborted"
          // Any email that is still (partially) scheduled should display "Scheduled"
          if (
            sends.length &&
            moment(sends[sends.length - 1].date, "X").isAfter(
              moment(),
              "minute",
            )
          )
            return "Scheduled"
          // Otherwise, just display the relative date.
          const day = format("date", date, {
            year: "not-current",
            isRelative: true,
          })
          return day === "today" ? format("time", date) : capitalize(day)
        },
      },
    ]

    return (
      <div className="two-pages">
        <Page
          title={`${emails.length} sent email${emails.length === 1 ? "" : "s"}`}
          required={{ project }}
          isSidebar
          hasPadding
          searchDisabled={emails.length === 0}
          barRight={
            <Button
              icon="add"
              disabled={interviews.length === 0}
              title="New email"
              to={`/projects/${project.id}/emails${
                this.props.match.params.emailId
                  ? `/${this.props.match.params.emailId}`
                  : ""
              }/new`}
            />
          }
        >
          <List
            className="-mt-4"
            isSmall
            keyPrimary="toStr"
            keySecondary="subject"
            keyTertiary="message"
            keyRight="date"
            fields={fields}
            to={`/projects/${project.id}/emails/:id`}
            data={emails.map((email) => {
              const sends = (email.sends || []).sort(sortSends)
              const to = sends && sends[0] && sends[0].to ? sends[0].to : {}
              const toInterview = interviews.find((x) => x.email === to.email)
              return {
                ...sends[0],
                isCancelled: email.isCancelled,
                id: email.id,
                toNames: (email.to || []).map((x) => x.name),
                toStr: `${
                  toInterview ? toInterview.name : to.name || to.email
                } ${sends.length > 1 ? `+${sends.length - 1}` : ""}`,
              }
            })}
          />
        </Page>
        {!!Object.keys(project).length && (
          <>
            <Route
              path="/projects/:projectId/emails/:emailId"
              render={(props) => (
                <ProjectEmail
                  {...props}
                  project={project}
                  interviews={interviews}
                  filteredEmails={emails}
                />
              )}
            />
            {emails.length === 0 && (
              <Route
                path={[
                  "/projects/:projectId/emails",
                  "/projects/:projectId/emails/new",
                ]}
                exact
                render={(props) => (
                  <State icon="send" className="pt-12" title="No emails sent">
                    <p className="mb-5 max-w-md text-center">
                      Send emails to interviewees with InterviewKit to save
                      time. You will still receive replies at your own email
                      address.
                    </p>
                    {interviews.length === 0 ? (
                      <p className="sub">
                        Go to{" "}
                        <Link
                          className="link-color"
                          to={`/projects/${project.id}/interviews`}
                        >
                          Interviews
                        </Link>{" "}
                        and add your (potential) interviewees to email them.
                      </p>
                    ) : (
                      <Button primary to={`/projects/${project.id}/emails/new`}>
                        New email
                      </Button>
                    )}
                  </State>
                )}
              />
            )}
            <Route
              path="/projects/:projectId/emails/:emailId?/new"
              component={ProjectEmailNew}
            />
            <Route
              path="/projects/:projectId/emails/:emailId?/new/templates"
              component={ProjectEmailNewTemplates}
            />
          </>
        )}
      </div>
    )
  }
}

export default withData(withNotify(withRouter(ProjectEmails)))
