import React from "react"
import {
  Page,
  Calendar,
  Checkbox,
  DemoBox,
  format,
  withData,
  getDayPart,
  fName,
  withNotify,
  Label,
  Button,
  Widget,
  getColor,
  toPreview,
} from "../shared"
import moment from "moment"
import {
  combineLabels,
  getFindingFields,
  getFormResponseFields,
  getInterviewFields,
} from "../functions"
import p1 from "../assets/p1.svg"
import p2 from "../assets/p2.svg"
import { Link, Route } from "react-router-dom"
import Widgets from "./Widgets"
import confetti from "canvas-confetti"

class Today extends React.Component {
  constructor(props) {
    super(props)
    this.welcomeWidget = React.createRef()
    this.state = {
      showHumans: true,
    }
  }
  componentDidMount() {
    if (this.welcomeWidget.current?.clientHeight < 300) {
      this.setState({ showHumans: false })
    }
  }
  getWidgetData(widgetArr, labels = []) {
    const allWidgets = [
      "welcome",
      "schedule",
      "interviews-upcoming",
      "findings-latest",
      "responses-latest",
      "forms-live",
    ]
    for (const label of labels) {
      allWidgets.push(`label-with-${label.name.toLowerCase()}`)
    }

    // Set default widgets
    if (!widgetArr)
      widgetArr = [
        "welcome",
        "schedule",
        "interviews-upcoming",
        "findings-latest",
        "responses-latest",
        "forms-live",
      ]

    if (widgetArr.length < allWidgets.length) {
      const missingWidgets = allWidgets.filter(
        (x) => widgetArr.indexOf(x) === -1,
      )
      widgetArr = [...widgetArr, ...missingWidgets]
    }

    const newWidgets = []
    const newAllWidgets = []
    for (const widget of widgetArr) {
      if (widget === "welcome")
        newWidgets.push({
          id: widget,
          icon: "smiley",
          name: "Welcome",
        })
      else if (widget === "schedule")
        newWidgets.push({
          id: widget,
          icon: "calendar",
          name: "Schedule",
        })
      else if (widget === "interviews-upcoming")
        newWidgets.push({
          id: widget,
          icon: "mic",
          editName: "Upcoming interviews",
          name: "Interviews",
        })
      else if (widget === "findings-latest")
        newWidgets.push({
          id: widget,
          icon: "star",
          editName: "Recent findings",
          name: "Findings",
        })
      else if (widget === "responses-latest")
        newWidgets.push({
          id: widget,
          icon: "form",
          name: "Form responses",
        })
      else if (widget === "forms-live")
        newWidgets.push({
          id: widget,
          icon: "form",
          name: "Live forms",
        })
      else if (widget.startsWith("label-with-")) {
        const label = labels.find(
          (x) =>
            x.name.toLowerCase() ===
            widget.substring("label-with-".length).toLowerCase(),
        )
        if (label)
          newWidgets.push({
            id: widget,
            icon: "label",
            name: `${label.name} interviews`,
          })
      }
    }

    for (const widget of allWidgets) {
      if (widget === "welcome")
        newAllWidgets.push({
          id: widget,
          icon: "smiley",
          name: "Welcome",
        })
      else if (widget === "schedule")
        newAllWidgets.push({
          id: widget,
          icon: "calendar",
          name: "Schedule",
        })
      else if (widget === "interviews-upcoming")
        newAllWidgets.push({
          id: widget,
          icon: "mic",
          editName: "Recent interviews",
          name: "Interviews",
        })
      else if (widget === "findings-latest")
        newAllWidgets.push({
          id: widget,
          icon: "star",
          editName: "Recent findings",
          name: "Findings",
        })
      else if (widget === "responses-latest")
        newAllWidgets.push({
          id: widget,
          icon: "form",
          name: "Form responses",
        })
      else if (widget === "forms-live")
        newAllWidgets.push({
          id: widget,
          icon: "form",
          name: "Live forms",
        })
      else if (widget.startsWith("label-with-"))
        newAllWidgets.push({
          id: widget,
          icon: "label",
          name: `${labels
            .find(
              (x) =>
                x.name.toLowerCase() === widget.substring("label-with-".length),
            )
            .name.toLowerCase()}`,
        })
    }

    return { widgets: newWidgets, allWidgets: newAllWidgets }
  }
  render() {
    const user = this.props.getUser()
    const interviewLabels = combineLabels(this.props.projects)
    const date = format("date", "today", {
      day: "full",
      month: "full",
      year: false,
    })
    const projects = (this.props.projects || []).filter((x) => !x.isArchived)
    const projectIds = projects.map((x) => x.id)
    const interviews = (this.props.interviews || []).filter((x) =>
      projectIds.includes(x.projectId),
    ) // Non-archived interviews
    const projectsCalendarMap = projects.map((x, i) => ({
      name: x.name,
      id: x.id,
      color: getColor(i + 6, 500),
      hex: getColor(i + 6, true),
    }))
    const interviewFields = getInterviewFields(
      [],
      this.props.findings,
      this.props.projects,
    )
    const findingFields = getFindingFields(this.props.interviews)
    const formResponseFields = getFormResponseFields(this.props.forms)
    const widgetData = this.getWidgetData(user.widgets, interviewLabels)
    const currentHour = moment().hour()
    const numWidgets = user.widgetDisplay === "compact" ? 6 : 3
    const indexMessage =
      !this.props.isDemo &&
      this.props.index &&
      this.props.index[0] &&
      this.props.index[0].message
    const isWelcomeDone =
      projects.length > 0 &&
      !!this.props.getUser().avatarUrl &&
      interviews.length > 0
    const hasPlan = !!this.props.plan

    if (window.appIsFirstLogin) {
      this.props.notify({
        title: `Hooray! Welcome to InterviewKit, ${fName(
          this.props.usersPublic[0].name,
        )}! 🎉`,
      })
      confetti({
        gravity: 0.5,
        ticks: 400,
        spread: 180,
        particleCount: 150,
      })
      window.appIsFirstLogin = false
      window.noConfettiOnProjectCreate = true // Don't double-confetti on the first project creation
    }

    return (
      <Page
        title={numWidgets === 6 ? date || "Today" : null}
        titleHead="Today"
        hasPadding
        isGray
        moreWidth={260}
        moreDisabled={!this.props.userId}
        bar={
          numWidgets === 6 &&
          indexMessage && (
            <div
              title={indexMessage}
              className="max-w-2xl truncate rounded-full bg-yellow-200 py-2 px-5 text-sm text-yellow-900 dark:bg-yellow-900 dark:text-yellow-100"
            >
              {indexMessage}
            </div>
          )
        }
        barRight={
          <Button
            disabled={!hasPlan}
            icon="settings"
            title="Today settings"
            to="/widgets"
          />
        }
      >
        {this.props.isDemo &&
          this.props.session.isOnboarded &&
          !this.props.location.pathname.includes("/setup") && (
            <DemoBox
              step={1}
              to="/projects/demo-project/forms/demo-form"
              primary="Hey, let's show you around! This is your Today page."
              secondary="It shows you an overview of your interviews, to catch up during the day. You can modify widgets as well: just click the settings button in the top right."
            />
          )}
        {hasPlan && (
          <Route
            path="/widgets"
            exact
            render={(props) => (
              <Widgets {...props} widgets={widgetData.widgets} />
            )}
          />
        )}
        <div
          className={`grid grid-cols-3 ${
            numWidgets === 3 ? "max-w-screen-lg" : "max-w-screen-xl"
          } relative z-10 mx-auto h-full grid-rows-4 gap-5`}
        >
          {numWidgets === 3 && (
            <div
              className={`col-span-3 ${
                hasPlan ? "" : "row-span-2"
              } flex flex-col justify-center`}
            >
              {!hasPlan && (
                <Label color="indigo" className="mb-4 self-start">
                  Guest
                </Label>
              )}
              {this.props.isDemo ? (
                <>
                  <h3 className="leading-snug">
                    Good {getDayPart()}, it is{" "}
                    <span className="text-indigo-500 dark:text-indigo-400">
                      {date}
                    </span>
                    . <br />
                    {indexMessage ? (
                      <span className="text-yellow-500">{indexMessage}</span>
                    ) : (
                      "These are your interviews at a glance."
                    )}
                  </h3>
                </>
              ) : (
                <>
                  <h3 className="leading-snug">
                    Good {getDayPart()}
                    {user.name ? ", " : ""}
                    <span className="text-indigo-500 dark:text-indigo-400">
                      {fName(user.name)}
                    </span>
                    .{" "}
                    {hasPlan && (
                      <>
                        It is {date}. <br />
                        {indexMessage ? (
                          <span className="text-yellow-500">
                            {indexMessage}
                          </span>
                        ) : (
                          "These are your interviews at a glance."
                        )}
                      </>
                    )}
                  </h3>
                </>
              )}
              {!hasPlan && (
                <p className="mt-3 max-w-xl text-lg leading-relaxed">
                  You have a free guest account.{" "}
                  {this.props.projects?.length > 0
                    ? "Browse the projects you're a guest of on the left, or"
                    : "You are currently not invited to any projects, but you can"}{" "}
                  <Link
                    className="link-color border-b"
                    to={toPreview("sign-up")}
                  >
                    buy a plan
                  </Link>{" "}
                  to create projects yourself.
                </p>
              )}
            </div>
          )}
          {hasPlan &&
            widgetData.widgets.slice(0, numWidgets).map(({ id }) => {
              if (id === "welcome")
                return (
                  <div
                    ref={this.welcomeWidget}
                    key={id}
                    className="row-span-2 flex h-full flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 "
                  >
                    <div className="mt-1 p-6">
                      <h5>Welcome</h5>
                      <p className="sub mt-1.5 mb-6 truncate">
                        We are happy you are here.
                      </p>
                      {!!this.props.getUser().avatarUrl ? (
                        <Checkbox isActive disabled>
                          Upload a profile picture
                        </Checkbox>
                      ) : (
                        <Link to="/settings/profile">
                          <Checkbox readOnly>Upload a profile picture</Checkbox>
                        </Link>
                      )}
                      {projects.length === 0 ? (
                        <>
                          <Link to={toPreview("new-project")}>
                            <Checkbox readOnly>
                              Create or join a project
                            </Checkbox>
                          </Link>
                          <Checkbox disabled>Add your first interview</Checkbox>
                        </>
                      ) : (
                        <>
                          <Checkbox isActive disabled>
                            Create or join a project
                          </Checkbox>
                          {interviews.length === 0 ? (
                            <Link to={`/projects/${projects[0].id}/interviews`}>
                              <Checkbox readOnly>
                                Add your first interview
                              </Checkbox>
                            </Link>
                          ) : (
                            <Checkbox disabled isActive>
                              Add your first interview
                            </Checkbox>
                          )}
                        </>
                      )}
                      {isWelcomeDone && (
                        <p className="sub mt-6 -mb-4">
                          🎉{" "}
                          <span className="ml-1 font-medium text-black dark:text-white">
                            You completed all 3 steps to start!
                          </span>{" "}
                          <br />
                          You can remove this widget by clicking the widget
                          settings button in the top right.
                        </p>
                      )}
                    </div>
                    {this.state.showHumans && (
                      <div
                        className="relative mx-auto mt-auto flex max-w-sm justify-between overflow-hidden"
                        style={{ height: 173 }}
                      >
                        <img
                          alt=""
                          style={{ height: 173 }}
                          className="w-auto"
                          src={p1}
                        />
                        <img
                          alt=""
                          style={{ height: 173 }}
                          className="w-auto"
                          src={p2}
                        />
                      </div>
                    )}
                  </div>
                )
              if (id === "schedule")
                return (
                  <Widget
                    key={id}
                    title="Schedule"
                    singular="interview"
                    plural="interviews"
                    right={
                      <Button
                        isSmall
                        className="ml-5 flex-none"
                        secondary
                        to="/schedule"
                      >
                        Open
                      </Button>
                    }
                    afterSubtitle={`${
                      (this.props.interviews || []).filter(
                        (x) =>
                          x.date && moment(x.date, "X").isSame(moment(), "day"),
                      ).length
                        ? "remaining"
                        : ""
                    } today`}
                    icon="mic"
                    isSmall={projects.length > 1}
                    fields={interviewFields}
                    to={`/projects/:projectId/interviews/:id`}
                    data={(this.props.interviews || [])
                      .filter(
                        (x) =>
                          x.date && moment(x.date, "X").isSame(moment(), "day"),
                      )
                      .filter((x) => {
                        const project = projects.find(
                          (y) => y.id === x.projectId,
                        )
                        if (!project) return false
                        const interviewDuration =
                          x.duration || project.duration || 60
                        return moment(x.date, "X")
                          .add(interviewDuration, "minutes")
                          .isAfter(moment(), "minute")
                      })
                      .sort((a, b) => a.date - b.date)}
                  >
                    <div className="relative top-px -mr-px h-full">
                      <Calendar
                        view="day"
                        isInline
                        onClick={(x) => this.props.history.push(x)}
                        minHour={currentHour - 1}
                        maxHour={currentHour + 3}
                        events={(this.props.interviews || [])
                          .filter(
                            (x) =>
                              x.date &&
                              moment(x.date, "X").isSame(moment(), "day"),
                          )
                          .map((x) => {
                            const project = (this.props.projects || [])
                              .filter((x) => !x.isArchived)
                              .find((y) => y.id === x.projectId)
                            if (!project) return false
                            return {
                              to: `/projects/${project.id}/interviews/${x.id}`,
                              color: projectsCalendarMap.find(
                                (y) => y.id === x.projectId,
                              ).hex,
                              id: x.id,
                              date: x.date,
                              duration: x.duration || project.duration || 60,
                              title: x.name || "New interview",
                            }
                          })
                          .filter((x) => x)}
                      />
                    </div>
                  </Widget>
                )
              if (id === "interviews-upcoming")
                return (
                  <Widget
                    key={id}
                    title="Interviews"
                    singular="interview"
                    plural="interviews"
                    afterSubtitle="coming up"
                    icon="mic"
                    keyPrimary="name"
                    keyRight="dateTimeRelative"
                    keySecondary={projects.length > 1 ? "projectName" : null}
                    isSmall={projects.length > 1}
                    fields={interviewFields}
                    to={`/projects/:projectId/interviews/:id`}
                    data={(this.props.interviews || [])
                      .filter((x) => {
                        const project = projects.find(
                          (y) => y.id === x.projectId,
                        )
                        if (!project) return false
                        return (
                          x.date &&
                          moment(x.date, "X").isAfter(moment(), "hour")
                        )
                      })
                      .sort((a, b) => a.date - b.date)}
                  />
                )
              if (id === "findings-latest")
                return (
                  <Widget
                    key={id}
                    title="Findings"
                    singular="finding"
                    plural="findings"
                    afterSubtitle="recently added"
                    icon="star"
                    keyPrimary="interviewName"
                    keyRight="createdOn"
                    keySecondary="text"
                    isSmall
                    fields={findingFields}
                    to={`/projects/:projectId/findings/:id`}
                    data={(this.props.findings || [])
                      .filter((x) => {
                        const project = projects.find(
                          (y) => y.id === x.projectId,
                        )
                        if (!project) return false
                        return moment(x.createdOn, "X").isAfter(
                          moment().subtract(7, "days"),
                          "days",
                        )
                      })
                      .sort((a, b) => b.createdOn - a.createdOn)}
                  />
                )
              if (id === "responses-latest")
                return (
                  <Widget
                    key={id}
                    title="Form responses"
                    singular="response"
                    plural="responses"
                    afterSubtitle="recently received"
                    icon="form"
                    keyPrimary="name"
                    keyRight="createdOn"
                    fields={formResponseFields}
                    to={`/projects/:projectId/forms/:formId/responses/:id`}
                    data={(this.props.formResponses || [])
                      .filter((x) => {
                        const project = projects.find(
                          (y) => y.id === x.projectId,
                        )
                        if (!project) return false
                        return moment(x.createdOn, "X").isAfter(
                          moment().subtract(7, "days"),
                          "days",
                        )
                      })
                      .sort((a, b) => b.createdOn - a.createdOn)}
                  />
                )
              if (id === "forms-live")
                return (
                  <Widget
                    key={id}
                    title="Live forms"
                    singular="form"
                    plural="forms"
                    afterSubtitle="currently live"
                    icon="form"
                    keyPrimary="title"
                    keyRight="live"
                    keySecondary={projects.length > 1 ? "projectName" : null}
                    isSmall={projects.length > 1}
                    fields={[
                      { key: "title" },
                      { key: "live", defaultValue: "Live" },
                      {
                        key: "projectName",
                        calc: (_, x) =>
                          (
                            this.props.projects.find(
                              (y) => y.id === x.projectId,
                            ) || {}
                          ).name || "No project",
                      },
                    ]}
                    to={`/projects/:projectId/forms/:id`}
                    data={(this.props.forms || [])
                      .filter((x) => x.isLive)
                      .sort((a, b) => b.createdOn - a.createdOn)}
                  />
                )
              if (id.startsWith("label")) {
                const labelName = id
                  .substring("label-with-".length)
                  .toLowerCase()
                const label = interviewLabels.find(
                  (x) => x.name.toLowerCase() === labelName,
                )
                if (!label) return null
                const labelSpan = (
                  <Label color={label.color} className={"mr-1"}>
                    {label.name}
                  </Label>
                )
                return (
                  <Widget
                    key={id}
                    title={<span>{labelSpan} interviews</span>}
                    singular="interview"
                    plural="interviews"
                    icon="mic"
                    keyPrimary="name"
                    keyRight="dateTimeRelative"
                    keySecondary={projects.length > 1 ? "projectName" : null}
                    isSmall={projects.length > 1}
                    fields={interviewFields}
                    to={`/projects/:projectId/interviews/:id`}
                    data={(this.props.interviews || [])
                      .filter((x) => {
                        const project = (this.props.projects || [])
                          .filter((x) => !x.isArchived)
                          .find((y) => y.id === x.projectId)
                        if (!project) return false
                        return (x.labelIds || [])
                          .map((id) =>
                            (
                              project.interviewLabels.find(
                                (x) => x.id === id,
                              ) || {}
                            ).name.toLowerCase(),
                          )
                          .includes(labelName.toLowerCase())
                      })
                      .sort((a, b) => a.date - b.date)}
                  />
                )
              }
              return null
            })}
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(Today))
