import React from "react"
import styles from "./CheckboxV2.module.scss";

const CheckboxV2 = ({
    className = "",
    checked = false,
    onChange,
    ...otherProps
}) => {

    return (
        <input
            className={`${styles.checkbox} ${className}`}
            type='checkbox'
            checked={checked}
            {...(onChange && { onChange: onChange })}
            {...otherProps}
        />
    )
}

export default CheckboxV2;
