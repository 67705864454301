import React from "react"
import {
  Audio,
  Button,
  Page,
  withData,
  secondsToTime,
  toLowerWithDashes,
  Shortcut,
  Progress,
  format,
  DropdownItemFile,
  Tabs,
  Video,
  Dropdown,
  DropdownItem,
  Tab,
  sortData,
  withNotify,
  loadFile,
} from "../shared"
import { Route, Switch } from "react-router-dom"
import ProjectInterviewOverview from "./ProjectInterviewOverview"
import ProjectInterviewEmails from "./ProjectInterviewEmails"
import ProjectInterviewFindings from "./ProjectInterviewFindings"
import ProjectInterviewAvailability from "./ProjectInterviewAvailability"
import { getInterviewFields } from "../functions"
import ProjectInterviewResponses from "./ProjectInterviewResponses"
import ProjectInterviewFocus from "./ProjectInterviewFocus"
import { functions } from "../index"
import * as tus from "tus-js-client"
import { motion } from "framer-motion"
import demoPeaks from "../assets/demo-peaks.json"
import firebase from "firebase/app"
import "firebase/auth"
import ProjectInterviewFindingsTimestamps from "./ProjectInterviewFindingsTimestamps"

class ProjectInterview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mediaSeconds: null,
      uploadType: false,
      isMediaShowing: false,
      isMediaPlaying: false,
      isMoreLoading: false,
      uploadPercentage: 0,
    }
    this.audio = React.createRef()
    this.video = React.createRef()
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.interviewId !== this.props.match.params.interviewId
    ) {
      this.setState({
        mediaSeconds: null,
        uploadType: false,
        isMediaPlaying: false,
        isMediaShowing: false,
        uploadPercentage: 0,
      })
    }
  }
  componentWillUnmount() {
    if (this.interval) window.clearInterval(this.interval)
  }
  toggle(mediaType, forcePlay) {
    if (mediaType === "audio") {
      const isMediaPlaying =
        forcePlay == null ? !this.state.isMediaPlaying : forcePlay
      if (this.audio.current) {
        if (isMediaPlaying) {
          this.audio.current.play()
        } else {
          this.audio.current.pause()
          this.props.setSession({
            [`interview-${this.props.match.params.interviewId}-time`]:
              this.audio.current.getCurrentTime(),
          })
        }
      }
      this.setState({ isMediaPlaying, isMediaShowing: true })
    } else if (mediaType === "video") {
      const isMediaShowing =
        forcePlay === false ? false : !this.state.isMediaShowing
      let mediaSeconds = this.state.mediaSeconds
      if (!isMediaShowing) {
        // If closing, save time.
        const video = this.video.current
        if (video)
          this.props.setSession({
            [`interview-${this.props.match.params.interviewId}-time`]:
              video.currentTime,
          })
        mediaSeconds = null
        this.video.current = null
      }
      this.setState({ isMediaShowing, mediaSeconds })
    }
  }
  async deleteVideo() {
    const interview =
      (this.props.interviews || []).find(
        (x) => x.id === this.props.match.params.interviewId,
      ) || {}
    const deleteVideo = functions.httpsCallable("deleteVideo")
    const { data } = await deleteVideo({ interviewId: interview.id })
    if (data && data.isSuccess) {
      await this.props.fetch({
        collection: "interviews",
        id: interview.id,
        force: true,
      })
      this.props.setSession({ [`interview-${interview.id}-time`]: null })
      this.props.success("Video deleted")
    } else {
      this.props.error("Could not delete video")
    }
  }
  async getSampleRate(file) {
    return new Promise((resolve, reject) => {
      try {
        const audioContext = new AudioContext()
        const fileReader = new FileReader()

        fileReader.onloadend = () => {
          audioContext.decodeAudioData(fileReader.result, (audioBuffer) => {
            resolve(audioBuffer.sampleRate)
          })
        }
        fileReader.onerror = (e) => {
          console.error(e)
          reject()
        }
        fileReader.readAsArrayBuffer(file)
      } catch (e) {
        console.error(e)
        reject()
      }
    })
  }
  async uploadFile(file, alsoTranscribe, languageCode) {
    if (!file) return
    const interview =
      (this.props.interviews || []).find(
        (x) => x.id === this.props.match.params.interviewId,
      ) || {}
    const audioTypes = ["audio/mpeg", "audio/wav", "audio/mp4", "audio/x-m4a"]
    const isAudio = file.type.startsWith("audio/")
    const isVideo = file.type.startsWith("video/")
    if (file.size > 1024 * 1024 * 1024)
      return this.props.error(
        "Uploads cannot be bigger than 1 GB.",
        "Please contact us if this is a problem for your use case.",
      )
    if (isAudio) {
      if (!audioTypes.includes(file.type))
        return this.props.error(
          "Unsupported audio file",
          "InterviewKit currently supports MP3, M4A, MPEG and WAV audio files.",
        )
      const sampleRate = await this.getSampleRate(file).catch(() =>
        this.props.error("Could not read audio file", true),
      )
      if (!sampleRate) return

      const isSecondary = interview.mediaType === "video" // Upload audio as secondary file for transcription (not displayed in UI)
      this.setState({ uploadType: "audio" })
      try {
        const { url, path } = await this.props.uploadFile(
          `projects/${this.props.match.params.projectId}/interviews/${this.props.match.params.interviewId}/audio`,
          file,
          (uploadTask) => (this.audioUpload = uploadTask),
          (uploadPercentage) => {
            this.setState({ uploadPercentage })
          },
        )
        if (!isSecondary) {
          await this.props.update(
            "interviews",
            this.props.match.params.interviewId,
            {
              mediaUrl: url,
              mediaType: "audio",
              mediaPath: path,
              mediaSampleRate: sampleRate,
            },
          )
        }
        this.setState({ uploadType: false })
        if (alsoTranscribe) {
          this.transcribe(path, sampleRate, languageCode, file.size)
        }
      } catch (e) {
        this.audioUpload = null
        this.setState({ uploadType: false })
        this.props.error(
          "Could not upload audio",
          "Please try again or contact us.",
        )
      }
    } else if (isVideo) {
      this.setState({ uploadType: "video" })
      const video = await loadFile(file, "video")
      const token = await firebase.auth().currentUser.getIdToken()
      this.videoUpload = new tus.Upload(file, {
        endpoint: `https://europe-west2-interviewkit-eu.cloudfunctions.net/getVideoUploadToken?interviewId=${
          interview.id
        }&duration=${Math.ceil(video.duration)}&size=${
          file.size
        }&token=${token}`,
        chunkSize: 50 * 1024 * 1024,
        retryDelays: null,
        metadata: {
          filetype: file.type,
          filename: interview.id,
        },
        onError: (error) => {
          this.setState({ uploadType: false, uploadPercentage: 0 })
          this.videoUpload = null
          this.props.error("Could not upload video")
          throw new Error(error.message)
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const uploadPercentage = Math.round(
            (bytesUploaded / bytesTotal) * 100,
          )
          this.setState({ uploadPercentage })
        },
        onSuccess: async () => {
          this.props.fetch({
            collection: "interviews",
            id: interview.id,
            force: true,
          })
          this.setState({ uploadType: false, uploadPercentage: 0 })
          this.props.success(
            "Video uploaded",
            "Please wait a few minutes for conversion to finish.",
          )
          this.videoUpload = null
        },
      })
      // Check for previous uploads
      const prevUploads = await this.videoUpload.findPreviousUploads()
      if (prevUploads.length)
        this.videoUpload.resumeFromPreviousUpload(prevUploads[0])
      // Start the upload
      this.videoUpload.start()
    } else {
      return this.props.error(
        "This file is not supported",
        "Please upload an audio or video file.",
      )
    }
  }
  async cancelUpload() {
    const confirm = await this.props.warn(
      "Are you sure?",
      "Any upload progress will be lost.",
      "Cancel upload",
    )
    if (confirm) {
      if (this.state.uploadType === "audio") {
        if (!this.audioUpload) {
          return this.setState({ uploadType: false, uploadPercentage: 0 })
        }
        this.audioUpload.cancel()
        this.audioUpload = null
        this.props.success("Cancelled upload")
        this.setState({ uploadType: false, uploadPercentage: 0 })
      } else if (this.state.uploadType === "video") {
        if (!this.videoUpload) {
          return this.setState({ uploadType: false, uploadPercentage: 0 })
        }
        this.videoUpload
          .abort()
          .then(async () => {
            await this.deleteVideo()
            this.props.success("Cancelled upload")
            this.setState({ uploadType: false, uploadPercentage: 0 })
          })
          .catch((err) => {
            this.props.error("Could not cancel upload")
          })
      }
    }
  }
  setTime(time, type) {
    if (type === "audio" && this.audio.current) {
      this.audio.current.setTime(time, true)
    } else if (type === "video") {
      if (this.video.current) {
        if (this.props.isDemo) {
          this.video.current.currentTime = time
          this.video.current.play()
        } else {
          this.video.current.setTime(time, true)
        }
      } else {
        // Open video and set time in memory, so that it can seek when loaded.
        this.props.setSession({
          [`interview-${this.props.match.params.interviewId}-time`]: time,
        })
        this.toggle("video")
      }
    }
  }
  async transcribe(
    audioPath,
    sampleRate,
    languageCode,
    hasQualityConfirm = false,
    hasPayConfirm = false,
  ) {
    this.setState({ isTranscribeLoading: true })
    const interviewId = this.props.match.params.interviewId
    try {
      const transcribe = functions.httpsCallable("transcribe")
      const { data } = await transcribe({
        audioPath,
        sampleRate,
        interviewId,
        languageCode,
        hasPayConfirm,
        hasQualityConfirm,
      })
      if (data && data.isSuccess) {
        await this.props.fetch({
          collection: "interviews",
          id: interviewId,
          force: true,
        })
        this.setState({ isTranscribeLoading: false })
        // Realtime DB will handle updating of the interview when the transcript is ready.
      } else {
        if (data.price && data.minutes) {
          const confirm = await this.props.openModal({
            title: `$${format("currency", data.price)} will be charged`,
            desc: `Transcribing this file exceeds the 5 hours of transcription included per month. By confirming, $${format(
              "currency",
              data.price,
            )} will be charged (${
              data.minutes
            } minutes at $0,12 per minute, excl. taxes).`,
            icon: "billing",
            action: "Confirm",
          })
          if (confirm) {
            this.transcribe(audioPath, sampleRate, languageCode, true, true)
          } else {
            this.setState({ isTranscribeLoading: false })
          }
        } else if (
          data.numWords >= 0 &&
          data.startTime >= 0 &&
          data.endTime >= 0 &&
          data.duration >= 0
        ) {
          const confirm = await this.props.openModal({
            title: `Audio quality may be too low`,
            desc: `We tested ${data.duration} seconds ${
              data.startTime > 60 ? "in the middle" : "at the start"
            } of your audio (from ${secondsToTime(
              data.startTime,
            )} until ${secondsToTime(
              data.endTime,
            )}) before transcribing the entire recording. In this ${
              data.duration
            }-second sample, ${data.numWords > 0 ? "only " : ""}${
              data.numWords
            } word${
              data.numWords === 1 ? "" : "s"
            } could be transcribed. This can be an indication of low-quality audio. Your audio might not be correctly transcribed.`,
            icon: "warning",
            action: "Continue",
          })
          if (confirm) {
            this.transcribe(audioPath, sampleRate, languageCode, true)
          } else {
            this.setState({ isTranscribeLoading: false })
          }
        } else {
          this.props.error("Could not transcribe", (data || {}).message || true)
          this.setState({ isTranscribeLoading: false })
        }
      }
    } catch (e) {
      this.props.error("Could not transcribe", true)
      this.setState({ isTranscribeLoading: false })
    }
  }
  async downloadMedia(interview) {
    if (interview.mediaType === "audio") {
      const audioLink = document.createElement("a")
      const ext = interview.mediaUrl.split("?")[0].split(".").slice(-1)[0]
      audioLink.setAttribute("href", interview.mediaUrl)
      audioLink.setAttribute(
        "download",
        encodeURIComponent(
          `${toLowerWithDashes(interview.name) || "unnamed-interview"}.${ext}`,
        ),
      )
      audioLink.style.display = "none"
      document.body.appendChild(audioLink)
      audioLink.click()
      document.body.removeChild(audioLink)
    } else if (interview.mediaType === "video") {
      const generateVideoDownload = functions.httpsCallable(
        "generateVideoDownload",
      )
      const { data } = await generateVideoDownload({
        videoId: interview.mediaUrl,
      })
      if (data && data.isSuccess) {
        if (data.status === "ready") {
          const link = document.createElement("a")
          link.setAttribute(
            "href",
            `https://videodelivery.net/${data.token}/downloads/default.mp4`,
          )
          link.setAttribute(
            "download",
            encodeURIComponent(
              `${toLowerWithDashes(interview.name) || "unnamed-interview"}.mp4`,
            ),
          )
          link.style.display = "none"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          this.props.notify({
            title: "Generating video download, please wait...",
            icon: "download",
          })
          let i = 0
          this.interval = window.setInterval(async () => {
            const { data } = await generateVideoDownload({
              videoId: interview.mediaUrl,
            })
            if (data.status === "ready") {
              window.clearInterval(this.interval)
              this.setState({ isMoreLoading: true })
              this.interval = null
              const link = document.createElement("a")
              link.setAttribute(
                "href",
                `https://videodelivery.net/${data.token}/downloads/default.mp4`,
              )
              link.setAttribute(
                "download",
                encodeURIComponent(
                  `${
                    toLowerWithDashes(interview.name) || "unnamed-interview"
                  }.mp4`,
                ),
              )
              link.style.display = "none"
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
            if (i === 2) {
              // If loading 20+ seconds
              this.props.notify({
                title: "Your download is taking a while",
                desc: "Feel free to close this interview and come back later.",
                icon: "download",
              })
            }
            i++
          }, 10000)
          this.setState({ isMoreLoading: true })
        }
      } else {
        this.props.error("Could not download video", true)
      }
    }
  }
  render() {
    // Get interview
    const interview =
      (this.props.interviews || []).find(
        (x) => x.id === this.props.match.params.interviewId,
      ) || {}
    const findings =
      (this.props.findings || [])
        .filter((x) => x.interviewId === this.props.match.params.interviewId)
        .sort((a, b) => {
          if (!a.time && !b.time) return -1
          if (!a.time) return -1
          if (!b.time) return 1
          return a.time - b.time
        }) || []
    const createdBy =
      (this.props.usersPublic.find((x) => x.id === interview.createdBy) || {})
        .name || "a deleted user"

    // Get project
    const project =
      (this.props.projects || []).find((x) => x.id === interview.projectId) ||
      {}

    const backUrl = `/projects/${project.id}/interviews`
    const basePath = `/projects/:projectId/interviews/:interviewId`
    const baseUrl = `${backUrl}/${this.props.match.params.interviewId}`
    const urlTailWithoutSlash = this.props.location.pathname
      .substring(baseUrl.length)
      .split("/")[1]
    const urlTail =
      urlTailWithoutSlash && urlTailWithoutSlash !== "timestamps"
        ? `/${urlTailWithoutSlash}`
        : ""
    const isFocus = this.props.location.pathname.includes("focus")

    const fields = getInterviewFields(project.interviewLabels, [], [])
    const sortByKey = this.props.local["interviewsSortBy"] || fields[0].key
    const sortbyField = fields.find((x) => x.key === sortByKey)
    const sortDesc = this.props.local["interviewsSortDesc"]
    const existingFilters = (
      this.props.session.interviewsFilterLabelIds || []
    ).filter((id) => (project.interviewLabels || []).some((y) => y.id === id)) // Filter out any labels that doesn't exist anymore.
    const projectInterviews = (this.props.interviews || [])
      .filter((x) => {
        if (x.projectId !== this.props.match.params.projectId) return false
        return (
          !existingFilters.length ||
          (x.labelIds || []).some((id) => existingFilters.includes(id))
        )
      })
      .sort((a, b) => sortData(a, b, sortbyField, sortDesc))
    const index = projectInterviews.findIndex((x) => x.id === interview.id)
    const prevInterview = projectInterviews[index - 1] || {}
    const nextInterview = projectInterviews[index + 1] || {}
    const isAudioShowing = interview.mediaType === "audio"
    const isVideoShowing =
      this.state.isMediaShowing && interview.mediaType === "video"
    const showEditTimestamps =
      this.props.location.pathname.includes("findings") &&
      interview.mediaType &&
      interview.transcript

    return (
      <Page
        noBar
        isPopup
        width={isVideoShowing ? "7xl" : "2xl"}
        height={640}
        required={{ interview, project }}
        singular="Interview"
        backTo={
          !!this.state.uploadType || !!this.state.isTranscribeLoading
            ? false
            : backUrl
        }
      >
        <div
          className="grid h-full"
          style={{ gridTemplateColumns: `${isVideoShowing ? 28 : 42}rem auto` }}
        >
          <Page
            isFlexCol
            width="2xl"
            icon="mic"
            title={interview.name || "New interview"}
            titleIsSmall
            titleOnUpdate={(name) =>
              this.props.update("interviews", interview.id, { name })
            }
            onFileDrop={
              interview.mediaType || this.props.isDemo
                ? false
                : (file) => this.uploadFile(file)
            }
            fileDropTitle="Drop audio or video to upload..."
            isPopupChild
            barRight={
              <>
                {interview.mediaType === "audio" && !isFocus && (
                  <Shortcut
                    alsoWorksWhenInputInFocus
                    press="cmd-ArrowUp"
                    onPress={() => this.audio.current.setTime("+5")}
                  />
                )}
                {interview.mediaType === "audio" && !isFocus && (
                  <Shortcut
                    alsoWorksWhenInputInFocus
                    press="cmd-ArrowDown"
                    onPress={() => this.audio.current.setTime("-5")}
                  />
                )}
                {interview.mediaType === "video" && !isFocus && (
                  <Shortcut
                    alsoWorksWhenInputInFocus
                    press="cmd-ArrowUp"
                    onPress={() => this.video.current.setTime("+5")}
                  />
                )}
                {interview.mediaType === "video" && !isFocus && (
                  <Shortcut
                    alsoWorksWhenInputInFocus
                    press="cmd-ArrowDown"
                    onPress={() => this.video.current.setTime("-5")}
                  />
                )}
                {interview.mediaType && (
                  <Button
                    alsoWorksWhenInputInFocus
                    shortcut={isFocus ? "" : "cmd-p"}
                    className="tabular"
                    titleShowAlways
                    title={
                      interview.mediaType === "video"
                        ? `${
                            this.state.isMediaShowing ? "Close" : "Play"
                          } video`
                        : `${
                            this.state.isMediaPlaying ? "Pause" : "Play"
                          } audio`
                    }
                    primary={interview.mediaType === "video" && !isVideoShowing}
                    onClick={() => this.toggle(interview.mediaType)}
                    disabled={isFocus}
                    selected={
                      interview.mediaType === "audio"
                        ? this.state.isMediaPlaying
                        : this.state.isMediaShowing
                    }
                    icon={
                      interview.mediaType === "audio"
                        ? this.state.isMediaPlaying
                          ? "ControlButtons.EntertainmentControlButtonPause1"
                          : "ControlButtons.EntertainmentControlButtonPlay"
                        : this.state.isMediaShowing
                        ? "ControlButtons.EntertainmentControlButtonStop"
                        : "ControlButtons.EntertainmentControlButtonPlay"
                    }
                  >
                    {interview.mediaType === "audio" &&
                    this.state.mediaSeconds != null
                      ? secondsToTime(this.state.mediaSeconds)
                      : null}
                  </Button>
                )}
                {!isVideoShowing && (
                  <>
                    <Button
                      title="Previous interview"
                      className="ml-1"
                      shortcut="cmd-ArrowLeft"
                      to={`${backUrl}/${prevInterview.id}${urlTail}`}
                      disabled={
                        isFocus || index === 0 || !!this.state.uploadType
                      }
                      icon="chevron-left"
                    />
                    <h5 className="whitespace-nowrap px-2 tabular-nums">
                      {index + 1} of {projectInterviews.length}
                    </h5>
                    <Button
                      title="Next interview"
                      shortcut="cmd-ArrowRight"
                      className="mr-1"
                      to={`${backUrl}/${nextInterview.id}${urlTail}`}
                      disabled={
                        isFocus ||
                        index === projectInterviews.length - 1 ||
                        !!this.state.uploadType
                      }
                      icon="chevron-right"
                    />
                  </>
                )}
                {!isVideoShowing && (
                  <Dropdown
                    desc={`Added on ${format("date", interview.createdOn, {
                      month: "full",
                    })} at ${format(
                      "time",
                      interview.createdOn,
                    )} by ${createdBy}.`}
                    width={210}
                    button={
                      <Button
                        isLoading={this.state.isMoreLoading}
                        disabled={!!this.state.uploadType}
                        title="More"
                        icon="more"
                      />
                    }
                  >
                    {!interview.mediaType && (
                      <DropdownItemFile
                        disabled={this.props.isDemo || this.state.uploadType}
                        accept="audio/mpeg,audio/wav,audio/mp4,audio/x-m4a,video/*"
                        primary
                        full
                        onChange={(e) => this.uploadFile(e)}
                      >
                        Add audio or video
                      </DropdownItemFile>
                    )}
                    {showEditTimestamps && (
                      <DropdownItem
                        to={`${baseUrl}/timestamps`}
                        disabled={this.props.isDemo}
                      >
                        Edit timestamps
                      </DropdownItem>
                    )}
                    {interview.mediaType === "audio" && (
                      <>
                        <DropdownItem
                          borderTop={showEditTimestamps}
                          onClick={() => this.downloadMedia(interview)}
                          icon="download"
                          disabled={this.props.isDemo}
                        >
                          Download audio
                        </DropdownItem>
                        <DropdownItem
                          disabled={this.props.isDemo}
                          icon="delete"
                          onClick={async () => {
                            try {
                              const confirm = await this.props.warn(
                                "Are you sure you want to delete the audio of this interview?",
                                "The audio will be deleted permanently.",
                                "Delete",
                              )
                              if (confirm) {
                                await this.props.deleteFile(interview.mediaPath)
                                await this.props.update(
                                  "interviews",
                                  interview.id,
                                  {
                                    mediaType: null,
                                    mediaPath: null,
                                    mediaUrl: null,
                                    mediaSampleRate: null,
                                  },
                                )
                                this.props.setSession({
                                  [`interview-${interview.id}-time`]: null,
                                })
                                this.props.success("Audio deleted")
                              }
                            } catch (e) {
                              this.props.error("Could not delete audio", true)
                            }
                          }}
                        >
                          Delete audio
                        </DropdownItem>
                      </>
                    )}
                    {interview.mediaType === "video" && (
                      <>
                        <DropdownItem
                          onClick={() => this.downloadMedia(interview)}
                          icon="download"
                          disabled={this.props.isDemo}
                        >
                          Download video
                        </DropdownItem>
                        <DropdownItem
                          borderTop={showEditTimestamps}
                          disabled={this.props.isDemo}
                          icon="delete"
                          isRed
                          onClick={async () => {
                            try {
                              const confirm = await this.props.warn(
                                "Are you sure you want to delete the video of this interview?",
                                "The video will be deleted permanently.",
                                "Delete",
                              )
                              if (confirm) {
                                this.setState({ isMoreLoading: true })
                                await this.deleteVideo()
                                this.setState({ isMoreLoading: false })
                              }
                            } catch (e) {
                              this.props.error("Could not delete video", true)
                              this.setState({ isMoreLoading: false })
                            }
                          }}
                        >
                          Delete video
                        </DropdownItem>
                      </>
                    )}
                    {interview.transcript && (
                      <DropdownItem
                        icon="delete"
                        onClick={async () => {
                          try {
                            const confirm = await this.props.warn(
                              "Are you sure you want to delete the transcript of this interview?",
                              "The transcript will be deleted permanently. Any findings that refer to the transcript will still exist, but will not contain the quote anymore.",
                              "Delete",
                            )
                            if (confirm) {
                              this.props.update("interviews", interview.id, {
                                transcript: null,
                                transcriptEntities: null,
                                transcriptTimes: null,
                              })
                            }
                          } catch (e) {
                            this.props.error(
                              "Could not delete transcript",
                              true,
                            )
                          }
                        }}
                      >
                        Delete transcript
                      </DropdownItem>
                    )}
                    <DropdownItem
                      borderTop={!!interview.mediaType}
                      disabled={this.props.isDemo}
                      isRed
                      icon="delete"
                      onClick={async () => {
                        try {
                          const isSuccess = await this.props.delete(
                            "interviews",
                            interview.id,
                            this.props.warn,
                            null,
                            { singular: "interview" },
                          )
                          if (isSuccess) {
                            this.props.history.push(
                              `/projects/${project.id}/interviews`,
                            )
                            this.props.success("Interview deleted")
                          }
                        } catch (e) {}
                      }}
                    >
                      Delete interview
                    </DropdownItem>
                  </Dropdown>
                )}
              </>
            }
            singular="interview"
            onDelete={async (e) => {
              const isConfirm = await this.props.delete(
                "interviews",
                interview.id,
                this.props.warn,
                e,
              )
              if (isConfirm) this.props.history.push(backUrl)
            }}
          >
            {this.state.uploadType && (
              <div className="animate-fade-in mb-2 flex h-24 flex-none items-center bg-gray-100 px-10 dark:bg-gray-800">
                <Progress percentage={this.state.uploadPercentage} />
                <div className="ml-6 mr-auto mt-1">
                  <h5>Uploading {this.state.uploadType}...</h5>
                  <p className="sub mt-1">Please keep this interview open.</p>
                </div>
                <Button hasBorder onClick={() => this.cancelUpload()}>
                  Cancel
                </Button>
              </div>
            )}
            {isAudioShowing && !isFocus && !this.state.uploadType && (
              <div className="tr -ml-px mb-2 h-24 flex-none">
                <Audio
                  key={`audio-${interview.id}`}
                  ref={this.audio}
                  peaks={this.props.isDemo ? demoPeaks : null}
                  time={
                    this.props.session[
                      `interview-${this.props.match.params.interviewId}-time`
                    ]
                  }
                  onToggle={(isMediaPlaying) =>
                    this.setState({ isMediaPlaying })
                  }
                  onTime={(mediaSeconds) =>
                    parseInt(mediaSeconds) !== this.state.mediaSeconds &&
                    this.setState({ mediaSeconds: parseInt(mediaSeconds) })
                  }
                  url={interview.mediaUrl}
                />
              </div>
            )}
            <motion.div
              layout="position"
              className="flex flex-1 flex-col overflow-hidden"
            >
              <Tabs marginSide={0} margin={8} className="px-10">
                <Tab to={baseUrl} alsoActive={[`${basePath}/labels`]} exact>
                  Overview
                </Tab>
                <Tab to={`${baseUrl}/findings`} className="mr-auto">
                  Findings
                </Tab>
                <Tab to={`${baseUrl}/emails`}>Emails</Tab>
                <Tab to={`${baseUrl}/responses`}>Forms</Tab>
                <Tab to={`${baseUrl}/availability`} className="sm:pr-0">
                  Availability
                </Tab>
              </Tabs>
              <Route
                path={[basePath, `${basePath}/labels`]}
                exact
                render={(props) => (
                  <ProjectInterviewOverview
                    {...props}
                    isVideoShowing={isVideoShowing}
                    project={project}
                    interview={interview}
                  />
                )}
              />
              <Switch>
                <Route
                  path={`${basePath}/findings/focus`}
                  render={(props) => (
                    <ProjectInterviewFocus
                      {...props}
                      backTo={`${baseUrl}/findings`}
                      project={project}
                      interview={interview}
                      findings={findings}
                    />
                  )}
                />
                <Route
                  path={`${basePath}/findings`}
                  render={(props) => (
                    <ProjectInterviewFindings
                      {...props}
                      mediaSeconds={this.state.mediaSeconds}
                      isLoading={this.state.isTranscribeLoading}
                      onConfirm={(languageCode) =>
                        this.transcribe(
                          interview.mediaPath,
                          interview.mediaSampleRate,
                          languageCode,
                        )
                      }
                      onUpload={(e, languageCode) =>
                        this.uploadFile(e, true, languageCode)
                      }
                      setTime={(time) =>
                        this.setTime(time, interview.mediaType)
                      }
                      project={project}
                      interview={interview}
                      findings={findings}
                      toggle={(forcePlay) =>
                        this.toggle(interview.mediaType, forcePlay)
                      }
                      isUploading={!!this.state.uploadType}
                    />
                  )}
                />
              </Switch>
              <Route
                path={`${basePath}/emails`}
                render={(props) => (
                  <ProjectInterviewEmails
                    {...props}
                    disabled={!!this.state.uploadType}
                    project={project}
                    interview={interview}
                    findings={findings}
                  />
                )}
              />
              <Route
                path={`${basePath}/responses`}
                render={(props) => (
                  <ProjectInterviewResponses
                    {...props}
                    disabled={!!this.state.uploadType}
                    project={project}
                    interview={interview}
                    findings={findings}
                  />
                )}
              />
              <Route
                path={`${basePath}/availability`}
                render={(props) => (
                  <ProjectInterviewAvailability
                    {...props}
                    project={project}
                    interview={interview}
                    findings={findings}
                  />
                )}
              />
              <Route
                path={`${basePath}/timestamps`}
                render={(props) => (
                  <ProjectInterviewFindingsTimestamps
                    {...props}
                    transcript={interview.transcript}
                    transcriptTimes={interview.transcriptTimes}
                    mediaSeconds={this.state.mediaSeconds}
                    mediaType={interview.mediaType}
                    setTime={this.setTime}
                    toggle={this.toggle}
                    backTo={`${baseUrl}/findings`}
                  />
                )}
              />
            </motion.div>
          </Page>
          <div className="relative" style={{ backgroundColor: "#0A0A0A" }}>
            {isVideoShowing && !this.props.isDemo && (
              <Video
                autoplay
                ref={this.video}
                id={async () => {
                  const getVideoDownloadToken = functions.httpsCallable(
                    "getVideoDownloadToken",
                  )
                  const { data } = await getVideoDownloadToken({
                    videoId: interview.mediaUrl,
                  })
                  if (data && data.isSuccess) {
                    return `https://iframe.videodelivery.net/${data.token}`
                  } else {
                    this.props.error("Could not load video")
                    return false
                  }
                }}
                disableShortcuts={isFocus}
                onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
                onTime={(mediaSeconds) =>
                  parseInt(mediaSeconds) !== this.state.mediaSeconds &&
                  this.setState({ mediaSeconds: parseInt(mediaSeconds) })
                }
                time={
                  this.props.session[
                    `interview-${this.props.match.params.interviewId}-time`
                  ]
                }
              />
            )}
            {isVideoShowing && this.props.isDemo && (
              <video
                autoPlay
                controls
                className="h-full"
                controlsList="nodownload"
                disablePictureInPicture
                style={{ maxHeight: 620 }}
                ref={this.video}
                src={interview.mediaUrl}
                onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
                onTimeUpdate={() =>
                  this.video.current &&
                  this.setState({
                    mediaSeconds: this.video.current.currentTime,
                  })
                }
              />
            )}
            {isVideoShowing && this.props.isDemo && (
              <div className="pointer-events-none absolute inset-0 z-20 flex items-center justify-center">
                <div className="rounded-full bg-black p-2 px-4 text-xs font-medium uppercase tracking-wider text-white opacity-70">
                  Demo video
                </div>
              </div>
            )}
          </div>
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(ProjectInterview))
