import React from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

class Preview extends React.Component {
    render() {
        const params = {};
        if(this.props.idKey) {
            const value = queryString.parse(this.props.location.search).id;
            params[this.props.idKey] = value;
        }
        const props = {
            ...this.props.location,
            ...this.props.history,
            match: {
                params,
            }
        };
        return this.props.render(props);
    }
}

export default withRouter(Preview);