import React from "react"
import PropTypes from "prop-types"
import Icon from "./Icon"

class Checkbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: this.props.isActive,
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.isActive !== this.props.isActive &&
      this.props.isActive !== this.state.isActive
    ) {
      this.setState({ isActive: this.props.isActive })
    }
  }
  handleClick(newIsActive) {
    this.setState({ isActive: newIsActive })
    if (this.props.onChange) this.props.onChange(newIsActive)
  }
  render() {
    // Note: the color has a mr-1.5 to center-align it with an Input field that has a Colorpicker as button. This is
    // useful when we display checkboxes to pick labels, and an input field below to add a new one.
    const isActive =
      this.props.isActive === undefined
        ? this.state.isActive
        : this.props.isActive
    const { hasBorder } = this.props
    return (
      <>
        {this.props.borderTop ? <div className="mt-1 border-t pt-1" /> : null}
        <div
          title={this.props.title}
          onClick={() => this.handleClick(!isActive)}
          className={`select-none items-center ${
            this.props.isSmall
              ? `rounded py-2 px-3 hover:bg-${window.appColorBase}-500 hover:text-white active:bg-${window.appColorBase}-600`
              : "py-1"
          } flex ${this.props.disabled ? "opacity-70" : ""} ${
            this.props.disabled || this.props.readOnly
              ? "pointer-events-none"
              : "cursor-pointer"
          } ${this.props.className || ""}`}
        >
          <div
            className={`${
              this.props.isSmall
                ? "h-4"
                : `p-2 ${
                    hasBorder
                      ? this.props.borderColor
                        ? null
                        : "border"
                      : "bg-gray-100 dark:bg-gray-900"
                  } h-8 w-8 rounded-full`
            } flex-none`}
            style={{
              width: this.props.isSmall ? 12 : null,
              marginTop: 2,
              border: this.props.borderColor
                ? `1px solid ${this.props.borderColor}`
                : null,
            }}
          >
            {isActive ? <Icon icon="check" size={12} /> : null}
          </div>
          {this.props.children ? (
            <div
              className={`${this.props.color ? "mr-2" : ""} ${
                this.props.isSmall ? "ml-3 text-sm font-medium" : "ml-4"
              } ${this.props.classNameChildren || ""}`}
            >
              {this.props.children}
            </div>
          ) : null}
          {this.props.color && (
            <div
              className={`ml-auto mr-1.5 inline-block h-2 w-2 rounded-full ring-1 ring-white dark:ring-gray-800 bg-${this.props.color}-500`}
            />
          )}
        </div>
      </>
    )
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasBorder: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  isActive: PropTypes.bool,
}

Checkbox.defaultProps = {
  hasBorder: true,
}

export default Checkbox
