import React from "react"
import {
  Button,
  Dropdown,
  Callout,
  Input,
  getMetaKey,
  withNotify,
  withData,
} from "../shared"
import { guessTranscriptTimestamp } from "../functions"

class ProjectInterviewFindingsTimestamps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      indexPlaying: null,
    }
  }
  update(index, time) {
    const { transcript, transcriptTimes } = this.props
    time =
      time != null && !isNaN(time)
        ? Math.max(0, Math.round(time * 10) / 10)
        : null // Round float to 1 decimal place
    const words = transcript
      .map((x) => x.text.split(" "))
      .flat()
      .filter((x) => x)
    let newTranscriptTimes = []
    for (let i = 0; i < words.length; i++) {
      if (index === i) {
        newTranscriptTimes[i] = time
      } else if (transcriptTimes && transcriptTimes[i] != null) {
        newTranscriptTimes[i] = transcriptTimes[i]
      } else {
        newTranscriptTimes[i] = null
      }
    }
    this.props.update("interviews", this.props.match.params.interviewId, {
      transcriptTimes: newTranscriptTimes,
    })
  }
  render() {
    const { mediaType, transcript, transcriptTimes } = this.props
    const words = transcript
      .map((x) => x.text.split(" "))
      .flat()
      .filter((x) => x)
    return (
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="flex-1 overflow-scroll px-10 pt-8 pb-6">
          <h5>Edit timestamps</h5>
          <p className="mt-2 mb-5 text-gray-500">
            A timestamp can be attached to each word in your transcript, to link
            your transcript to your interview {mediaType || "audio"}.
          </p>

          <Callout className="mb-6">
            You generally should not edit this, unless you added a transcript
            manually and want to use timestamp features.{" "}
            <Dropdown
              width={308}
              isInfo
              button={<span className="link-color">Learn more</span>}
            >
              <h6 className="mb-3">About timestamps</h6>
              <p className="text-sm">
                By adding a timestamp to each word in your transcript, you can
                link them to your interview {mediaType || "audio"}. This allows
                for easier analysis:
              </p>
              <ul className="mt-2 text-sm">
                <li>
                  You can click on any word in your transcript (while holding
                  the {getMetaKey()} key) to go there in your interview{" "}
                  {mediaType || "audio"}.
                </li>
                <li className="mt-2">
                  While playing your interview {mediaType || "audio"}, a small
                  arrow in front will indicate where you are in the transcript.
                </li>
                <li className="mt-2">
                  When you create a finding from your transcript, the time in
                  the interview {mediaType || "audio"} will be automatically
                  added, so that you can play back findings.
                </li>
              </ul>
              <p className="mt-2 text-sm">
                When generating a transcript, timestamps will be added
                automatically. The timestamps of any new words will be guessed
                based on the words around them.
              </p>
            </Dropdown>
          </Callout>

          {words.map((word, i) => {
            const time = (transcriptTimes || [])[i]
            let timeGuess =
              time == null &&
              transcriptTimes &&
              guessTranscriptTimestamp(transcriptTimes, i)
            if (timeGuess || timeGuess === 0)
              timeGuess = timeGuess.toFixed(1).split(".").join(",")
            const timeToEdit = time != null ? time : timeGuess
            const isPlaying = this.state.indexPlaying === i
            return (
              <div
                key={`word-${i}`}
                className={`${i > 0 ? "border-t" : ""} flex items-center py-2`}
              >
                <div className="flex-1 truncate">{word}</div>
                <Input
                  defaultValue={time}
                  onBlur={(x) => this.update(i, x)}
                  placeholder={timeGuess || "—"}
                  hasBorder
                  type="number"
                  className="mr-3 w-40 flex-none"
                  before="At"
                  after="sec."
                />
                <Button
                  isSmall
                  icon="subtract"
                  disabled={(!time && !timeGuess) || timeToEdit === 0}
                  onClick={() =>
                    this.update(
                      i,
                      timeToEdit.toString().split(",").join(".") - 0.5,
                    )
                  }
                />
                <Button
                  isSmall
                  icon="add"
                  disabled={!time && !timeGuess}
                  onClick={() =>
                    this.update(
                      i,
                      parseFloat(timeToEdit.toString().split(",").join(".")) +
                        0.5,
                    )
                  }
                />
                <Button
                  isSmall
                  disabled={!time}
                  onClick={() => {
                    if (isPlaying) {
                      this.setState({ indexPlaying: null })
                      this.props.setTime(time)
                      this.props.toggle(false)
                    } else {
                      this.setState({ indexPlaying: i })
                      this.props.setTime(time)
                    }
                  }}
                  selected={isPlaying}
                  icon={isPlaying ? "stop" : "play"}
                />
              </div>
            )
          })}
        </div>
        <div className="border-light flex-none border-t px-10 py-6">
          <Button secondary full to={this.props.backTo}>
            Done
          </Button>
        </div>
      </div>
    )
  }
}

export default withNotify(withData(ProjectInterviewFindingsTimestamps))
