import React from "react"
import {
  Button,
  Labels,
  format,
  DropdownItemCopy,
  fName,
  Page,
  Shortcut,
  withData,
  ColorPicker,
  getSemiUniqueKey,
  withNotify,
  Input,
  Audio,
  Video,
  Dropdown,
  DropdownItem,
} from "../shared"
import demoPeaks from "../assets/demo-peaks.json"
import {
  getFindingFields,
  getInterviewUpdateObjOnFindingDelete,
  getQuoteFromTranscript,
  sortFindings,
} from "../functions"
import { functions } from "../index"

class ProjectFinding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEdit: props.isInterviewSingle,
    }
    this.audio = React.createRef()
    this.video = React.createRef()
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.findingId !== this.props.match.params.findingId
    ) {
      const newFinding =
        (this.props.findings || []).find(
          (x) => x.id === this.props.match.params.findingId,
        ) || {}
      const newInterview =
        (this.props.interviews || []).find(
          (x) => x.id === newFinding.interviewId,
        ) || {}
      if (newInterview.mediaType && newFinding.time) {
        if (newInterview.mediaType === "audio" && this.audio.current) {
          this.audio.current.pause()
          this.audio.current.setTime(newFinding.time)
        } else if (newInterview.mediaType === "video" && this.video.current) {
          this.video.current.pause()
          this.video.current.currentTime = newFinding.time - 3 // setTime doesn't work here sometimes
        }
      }
    }
  }
  componentDidMount() {
    if (this.props.isDemo && this.video.current) {
      const finding =
        this.props.findings.find(
          (x) => x.id === this.props.match.params.findingId,
        ) || {}
      if (finding.time) this.video.current.currentTime = finding.time - 3
    }
  }
  render() {
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const interviews = (this.props.interviews || []).filter(
      (x) => x.projectId === this.props.match.params.projectId,
    )
    const existingFilters = (
      this.props.session.findingsFilterLabelIds || []
    ).filter((id) => (project.findingLabels || []).some((y) => y.id === id)) // Filter out any labels that doesn't exist anymore.
    const findings = (this.props.findings || []).filter((x) => {
      if (x.projectId !== this.props.match.params.projectId) return false
      return (
        !existingFilters.length ||
        (x.labelIds || []).some((id) => existingFilters.includes(id))
      )
    })
    const finding =
      this.props.findings.find(
        (x) => x.id === this.props.match.params.findingId,
      ) || {}
    const isInFilter = findings.some(
      (x) => x.id === this.props.match.params.findingId,
    )
    const interview = interviews.find((x) => x.id === finding.interviewId)
    const backUrl =
      this.props.isInterviewSingle && interview
        ? `/projects/${project.id}/interviews/${interview.id}/findings`
        : `/projects/${project.id}/findings`
    const createdBy =
      (this.props.usersPublic.find((x) => x.id === finding.createdBy) || {})
        .name || "a deleted user"

    const groupBy = this.props.local.findingsGroupBy || false
    const sortBy = this.props.local.findingsSortBy || "createdOn"
    const sortDesc = this.props.local.findingsSortDesc || false
    const pinStarred = this.props.local.isFindingsStarredPinned

    sortFindings(
      findings,
      interviews,
      project,
      groupBy,
      sortBy,
      sortDesc,
      pinStarred,
    )

    const isAudio = interview && interview.mediaType === "audio" && finding.time
    const isVideo = interview && interview.mediaType === "video" && finding.time

    const index = findings.findIndex((x) => x.id === finding.id)
    const prevFinding = findings[index - 1] || {}
    const nextFinding = findings[index + 1] || {}

    const sentence =
      interview &&
      getQuoteFromTranscript(
        interview.transcript,
        interview.transcriptEntities,
        finding,
        true,
      )
    const fields = getFindingFields(interviews, project.findingLabels)

    return (
      <Page
        isPopup
        title="Finding"
        noBar
        width="2xl"
        required={{ finding }}
        backTo={backUrl}
      >
        {isAudio && (
          <div className="tr -ml-px h-24 flex-none">
            <Audio
              ref={this.audio}
              peaks={this.props.isDemo ? demoPeaks : null}
              time={finding.time - 3}
              url={interview.mediaUrl}
              onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
            />
            <Shortcut
              alsoWorksWhenInputInFocus
              press="cmd-ArrowUp"
              onPress={() => this.audio.current.setTime("+5")}
            />
            <Shortcut
              alsoWorksWhenInputInFocus
              press="cmd-ArrowDown"
              onPress={() => this.audio.current.setTime("-5")}
            />
          </div>
        )}
        {isVideo && (
          <div className="tr -ml-px h-96 flex-none">
            <Shortcut
              alsoWorksWhenInputInFocus
              press="cmd-ArrowUp"
              onPress={() => this.video.current.setTime("+5")}
            />
            <Shortcut
              alsoWorksWhenInputInFocus
              press="cmd-ArrowDown"
              onPress={() => this.video.current.setTime("-5")}
            />
            <Shortcut
              alsoWorksWhenInputInFocus
              press="cmd-p"
              onPress={() => this.video.current.toggle()}
            />
            {this.props.isDemo ? (
              <video
                controls
                className="h-full bg-black"
                controlsList="nodownload"
                disablePictureInPicture
                ref={this.video}
                src={interview.mediaUrl}
                onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
                onTimeUpdate={() =>
                  this.video.current &&
                  this.setState({
                    mediaSeconds: this.video.current.currentTime,
                  })
                }
              />
            ) : (
              <Video
                ref={this.video}
                time={finding.time - 3}
                id={async () => {
                  const getVideoDownloadToken = functions.httpsCallable(
                    "getVideoDownloadToken",
                  )
                  const { data } = await getVideoDownloadToken({
                    videoId: interview.mediaUrl,
                  })
                  if (data && data.isSuccess) {
                    return `https://iframe.videodelivery.net/${data.token}`
                  } else {
                    this.props.error("Could not load video")
                    return false
                  }
                }}
                onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
              />
            )}
          </div>
        )}
        <div
          className="flex h-full flex-col p-10 py-8 pb-6"
          style={{ minHeight: 280 }}
        >
          <Labels
            onNew={async () => {
              let name, color
              const confirm = await this.props.openModal({
                title: "New label",
                desc: `Apply to this finding.`,
                icon: "label",
                action: "Add",
                child: (
                  <Input
                    hasBorder
                    onChange={(x) => (name = x)}
                    autoFocus
                    placeholder="Name..."
                    button={
                      <ColorPicker
                        onChange={(x) => (color = x)}
                        noWeights
                        hasAll
                      />
                    }
                  />
                ),
              })
              if (confirm && name) {
                const id = getSemiUniqueKey()
                this.props.addToArray(
                  "projects",
                  this.props.match.params.projectId,
                  "findingLabels",
                  {
                    id,
                    name,
                    color: color || null,
                  },
                )
                this.props.addToArray("findings", finding.id, "labelIds", id)
              }
            }}
            className="mb-2"
            toEdit={this.state.isEdit && `${backUrl}/${finding.id}/labels`}
            labels={project.findingLabels}
            labelIds={finding.labelIds}
            onChange={
              this.state.isEdit
                ? (labelIds) =>
                    this.props.update("findings", finding.id, { labelIds })
                : null
            }
          />
          {sentence && (
            <p
              className={`mt-2 flex-none ${
                finding.text || this.state.isEdit ? "text-gray-500" : "text-lg"
              }`}
            >
              {sentence}
            </p>
          )}
          <Input
            readOnly={!this.state.isEdit}
            placeholder={
              finding.text || this.state.isEdit ? "Add note..." : null
            }
            autosize
            noStyling
            noInputStyling
            rows={1}
            className="flex-1"
            classNameInput={`h-full resize-none text-lg mt-2 bg-transparent`}
            defaultValue={finding.text}
            onBlur={(text) =>
              this.props.update("findings", finding.id, { text })
            }
          />
          <div className="mt-7 flex items-center pt-5">
            {this.state.isEdit ? (
              <>
                {interview && interview.mediaUrl && (
                  <Input
                    className="mr-4 w-56"
                    classNameInput="text-right"
                    after="sec."
                    before="Timestamp:"
                    hasBorder
                    type="number"
                    placeholder="—"
                    onBlur={(time) =>
                      this.props.update("findings", finding.id, { time })
                    }
                    value={finding.time}
                  />
                )}
                <Button
                  className="ml-auto"
                  primary
                  key="done"
                  to={this.props.isInterviewSingle ? backUrl : null}
                  onClick={
                    this.props.isInterviewSingle
                      ? null
                      : () => this.setState({ isEdit: false })
                  }
                >
                  Done
                </Button>
              </>
            ) : (
              <>
                {isAudio && (
                  <Button
                    title={this.state.isMediaPlaying ? "Pause" : "Play"}
                    titleTop
                    className="mr-2"
                    primary
                    onClick={() => this.audio.current.playPause()}
                    shortcut="cmd-p"
                    selected={this.state.isMediaPlaying}
                    icon={
                      this.state.isMediaPlaying
                        ? "ControlButtons.EntertainmentControlButtonPause1"
                        : "ControlButtons.EntertainmentControlButtonPlay"
                    }
                  />
                )}
                {(isAudio || isVideo) && (
                  <Button
                    title="Replay"
                    titleTop
                    onClick={() => {
                      if (isAudio) this.audio.current.setTime(finding.time - 3)
                      if (isVideo) {
                        if (this.props.isDemo)
                          this.video.current.currentTime = finding.time - 3
                        else this.video.current.setTime(finding.time - 3)
                      }
                    }}
                    icon="arrow-left"
                  />
                )}
                <Button
                  title="Mark as key"
                  titleTop
                  onClick={() =>
                    this.props.update("findings", finding.id, {
                      isStarred: !finding.isStarred,
                    })
                  }
                  iconFill={finding.isStarred}
                  iconColor={finding.isStarred ? "yellow-400" : null}
                  iconColorDark={finding.isStarred ? "yellow-500" : null}
                  icon="star"
                />
                {isAudio && <div className="divider" />}
                {interview && (
                  <Button
                    icon="user"
                    to={`/projects/${project.id}/interviews/${interview.id}`}
                  >
                    {fName(interview.name || "Unnamed")}
                    {interview.age ? ` (${interview.age})` : ""}
                  </Button>
                )}
                {isInFilter ? (
                  <>
                    <Button
                      className="ml-auto"
                      title="Previous finding"
                      titleTop
                      shortcut="cmd-ArrowLeft"
                      to={`${backUrl}/${prevFinding.id}`}
                      disabled={index === 0}
                      icon="chevron-left"
                    />
                    <h5
                      className={`tr select-none px-2 tabular-nums ${
                        this.state.isEdit ? "opacity-50" : ""
                      }`}
                    >
                      {index + 1} of {findings.length}
                    </h5>
                    <Button
                      title="Next finding"
                      titleTop
                      shortcut="cmd-ArrowRight"
                      to={`${backUrl}/${nextFinding.id}`}
                      disabled={
                        this.state.isEdit || index === findings.length - 1
                      }
                      icon="chevron-right"
                    />
                  </>
                ) : (
                  <div className="ml-auto" />
                )}
                <Dropdown
                  desc={`Added on ${format("date", finding.createdOn, {
                    month: "full",
                  })} at ${format("time", finding.createdOn)} by ${createdBy}.`}
                  width={220}
                  button={
                    <Button title="More" titleTop key="more" icon="more" />
                  }
                >
                  <DropdownItemCopy
                    defaultFormat="[Finding] — [Name]"
                    singular="finding"
                    plural="findings"
                    fields={fields}
                    data={findings}
                    selectedIds={[finding.id]}
                  />
                  <DropdownItem
                    to={`/projects/${project.id}/findings/${finding.id}/copy-as`}
                    toData={{ selectedIds: [finding.id] }}
                  >
                    Copy as
                  </DropdownItem>
                  <DropdownItem
                    borderTop
                    icon="edit"
                    onClick={() => this.setState({ isEdit: true })}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    disabled={this.props.isDemo}
                    onClick={async (e) => {
                      const interview = interviews.find(
                        (x) => x.id === finding.interviewId,
                      )
                      const updateObj = getInterviewUpdateObjOnFindingDelete(
                        interview,
                        finding.id,
                      )
                      if (updateObj)
                        this.props.update("interviews", interview.id, updateObj)
                      this.props.delete("findings", finding.id)
                      this.props.history.push(backUrl)
                    }}
                    icon="delete"
                    isRed
                  >
                    Delete
                  </DropdownItem>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(ProjectFinding))
