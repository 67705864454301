import React from "react"
import {
  Plus,
  PlusCircle,
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  PieChart,
  BarChart,
  LineChart,
  Calendar,
  Check,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Clock,
  Delete,
  Send,
  Download,
  MessageCircle,
  Edit,
  Filter,
  Users,
  HelpCircle,
  Hourglass,
  Home,
  Info,
  Image,
  Import,
  Mic,
  Key,
  Bookmark,
  Layers,
  Link,
  Lock,
  User,
  Search,
  Settings,
  Star,
  Minus,
  Unlock,
  AlertCircle,
  MoreHorizontal,
  RefreshCcw,
  Upload,
  UserPlus,
  Share2,
  Square,
  Play,
  Volume2,
  Video,
  FileText,
  Archive,
  MousePointer2,
  AtSign,
  Voicemail,
  Zap,
  CheckCheck,
  PersonStanding,
  Phone,
  Smile,
  Eye,
  Folder,
  Mail,
  Reply,
  FormInput,
  Building,
  Briefcase,
  ThumbsUp,
  Unlink,
  EyeOff,
  List,
  AlignLeft,
  Copy,
  Maximize2,
  CreditCard,
  LogOut,
  Coffee,
  Wifi,
  X,
} from "lucide-react"

export function getIcon(icon, size, fill) {
  switch (icon) {
    case "add":
      return <Plus size={size} fill={fill} />
    case "add-circle":
      return <PlusCircle size={size} fill={fill} />
    case "archive":
      return <Archive size={size} fill={fill} />
    case "arrow-up":
      return <ArrowUp size={size} fill={fill} />
    case "arrow-down":
      return <ArrowDown size={size} fill={fill} />
    case "arrow-left":
      return <ArrowLeft size={size} fill={fill} />
    case "arrow-right":
      return <ArrowRight size={size} fill={fill} />
    case "at":
      return <AtSign size={size} fill={fill} />
    case "transcribe":
      return <Voicemail size={size} fill={fill} />
    case "audio":
      return <Volume2 size={size} fill={fill} />
    case "billing":
      return <CreditCard size={size} fill={fill} />
    case "bullets":
      return <List size={size} fill={fill} />
    case "chat":
      return <MessageCircle size={size} fill={fill} />
    case "cursor":
      return <MousePointer2 size={size} fill={fill} />
    case "calendar":
      return <Calendar size={size} fill={fill} />
    case "chart-pie":
      return <PieChart size={size} fill={fill} />
    case "chart-bar":
      return <BarChart size={size} fill={fill} />
    case "chart-line":
      return <LineChart size={size} fill={fill} />
    case "check":
      return <Check size={size} fill={fill} />
    case "check-double":
      return <CheckCheck size={size} fill={fill} />
    case "chevron-left":
      return <ChevronLeft size={size} fill={fill} />
    case "chevron-right":
      return <ChevronRight size={size} fill={fill} />
    case "chevron-up":
      return <ChevronUp size={size} fill={fill} />
    case "chevron-down":
      return <ChevronDown size={size} fill={fill} />
    case "clock":
      return <Clock size={size} fill={fill} />
    case "cross":
      return <X size={size} fill={fill} />
    case "coffee":
      return <Coffee size={size} fill={fill} />
    case "copy":
      return <Copy size={size} fill={fill} />
    case "delete":
      return <Delete size={size} fill={fill} />
    case "expand":
      return <Maximize2 size={size} fill={fill} />
    case "download":
      return <Download size={size} fill={fill} />
    case "gender":
      return <PersonStanding size={size} fill={fill} />
    case "edit":
      return <Edit size={size} fill={fill} />
    case "envelope":
      return <Mail size={size} fill={fill} />
    case "education":
      return <Building size={size} fill={fill} />
    case "hide":
      return <EyeOff size={size} fill={fill} />
    case "job":
      return <Briefcase size={size} fill={fill} />
    case "form-input":
      return <FormInput size={size} fill={fill} />
    case "folder":
      return <Folder size={size} fill={fill} />
    case "filter":
      return <Filter size={size} fill={fill} />
    case "form":
      return <FileText size={size} fill={fill} />
    case "group":
      return <Users size={size} fill={fill} />
    case "help":
      return <HelpCircle size={size} fill={fill} />
    case "hourglass":
      return <Hourglass size={size} fill={fill} />
    case "home":
      return <Home size={size} fill={fill} />
    case "info":
      return <Info size={size} fill={fill} />
    case "image":
      return <Image size={size} fill={fill} />
    case "import":
      return <Import size={size} fill={fill} />
    case "like":
      return <ThumbsUp size={size} fill={fill} />
    case "logout":
      return <LogOut size={size} fill={fill} />
    case "key":
      return <Key size={size} fill={fill} />
    case "label":
      return <Bookmark size={size} fill={fill} />
    case "layers":
      return <Layers size={size} fill={fill} />
    case "link":
      return <Link size={size} fill={fill} />
    case "lock":
      return <Lock size={size} fill={fill} />
    case "mic":
      return <Mic size={size} fill={fill} />
    case "more":
      return <MoreHorizontal size={size} fill={fill} />
    case "stop":
      return <Square size={size} fill={fill} />
    case "play":
      return <Play size={size} fill={fill} />
    case "phone":
      return <Phone size={size} fill={fill} />
    case "smiley":
      return <Smile size={size} fill={fill} />
    case "share":
      return <Share2 size={size} fill={fill} />
    case "reply":
      return <Reply size={size} fill={fill} />
    case "refresh":
      return <RefreshCcw size={size} fill={fill} />
    case "user":
      return <User size={size} fill={fill} />
    case "user-add":
      return <UserPlus size={size} fill={fill} />
    case "users":
      return <Users size={size} fill={fill} />
    case "unlink":
      return <Unlink size={size} fill={fill} />
    case "search":
      return <Search size={size} fill={fill} />
    case "settings":
      return <Settings size={size} fill={fill} />
    case "send":
      return <Send size={size} fill={fill} />
    case "star":
      return <Star size={size} fill={fill} />
    case "subtract":
      return <Minus size={size} fill={fill} />
    case "text":
      return <AlignLeft size={size} fill={fill} />
    case "unlock":
      return <Unlock size={size} fill={fill} />
    case "upload":
      return <Upload size={size} fill={fill} />
    case "video":
      return <Video size={size} fill={fill} />
    case "view":
      return <Eye size={size} fill={fill} />
    case "warning":
      return <AlertCircle size={size} fill={fill} />
    case "wifi":
      return <Wifi size={size} fill={fill} />
    case "zap":
      return <Zap size={size} fill={fill} />
    default:
      return null
  }
}
