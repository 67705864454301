import React from "react"
import Icon from "./Icon"
import Button from "./Button"
import { withRouter } from "react-router-dom"
import { withNotify } from "../notify"
import { withData } from "../data"
import { toPreview } from "../functions"

class DemoBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMounted: false,
    }
  }
  componentDidMount() {
    this.timeout = window.setTimeout(() => {
      this.setState({ isMounted: true })
    }, 1000)
  }
  componentWillUnmount() {
    if (this.timeout) window.clearTimeout(this.timeout)
  }
  render() {
    if (
      !this.state.isMounted ||
      this.props.session.isDemoClosed ||
      this.props.location?.search?.includes("sign-up")
    )
      return null
    return (
      <div
        key="demobox-open"
        className={`${
          this.props.isLeft
            ? "left-10"
            : this.props.isRight
            ? "right-10"
            : "inset-x-0 mx-auto"
        } demobox animate-open pointer-events-none fixed bottom-0 z-40 flex w-full max-w-3xl items-center justify-center bg-white`}
      >
        <div className="pointer-events-auto relative my-28 w-full max-w-md rounded-3xl bg-indigo-500 p-6 pb-5 shadow-2xl">
          <div className="absolute -left-4 -top-5 flex h-8 w-32 -rotate-6 transform select-none items-center justify-center rounded-full bg-yellow-400 text-center font-medium text-yellow-800 shadow">
            <Icon icon="cursor" size={14} className="mr-1.5" />
            {`Tour ${this.props.step}/10`}
          </div>
          <div
            onClick={() => this.props.setSession({ isDemoClosed: true })}
            className="pushable-sm absolute -right-1 -top-1 flex h-7 w-7 transform select-none items-center justify-center rounded-full bg-indigo-600 text-center text-lg font-medium text-indigo-100 shadow"
          >
            <span className="-mt-px">×</span>
          </div>
          <p className="h5 text-white">
            {this.props.primary}
            {this.props.secondary && (
              <span className="opacity-70"> {this.props.secondary}</span>
            )}
          </p>
          <div className="mt-5 flex items-center">
            <Button
              full
              isSmall
              noHover
              color="white"
              disabled={!this.props.back}
              to={this.props.back || ""}
              toData={this.props.backData}
            >
              &larr; Back
            </Button>
            {this.props.to && (
              <Button
                full
                isSmall
                primary
                color="indigo-600"
                className="ml-1"
                to={this.props.to || ""}
                toData={this.props.toData}
              >
                Continue &rarr;
              </Button>
            )}
            {!this.props.to && (
              <div
                style={{ border: "1px solid #A5B4FC", padding: 4 }}
                className={`rounded-xl ${
                  this.props.to ? "ml-auto" : "ml-4 flex-1"
                }`}
              >
                <Button
                  isSmall
                  isWhite
                  className="px-10 shadow-sm"
                  full
                  to={toPreview("sign-up")}
                >
                  Start 7-day free trial
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withNotify(withData(DemoBox)))
