import React from 'react';
import PropTypes from 'prop-types';
import Loader from "./Loader";
import {Stream} from "@cloudflare/stream-react";
import Shortcut from "./Shortcut";

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoSrc: '',
            isLoaded: false,
            isEncoding: false,
        };
    }
    async componentDidMount() {
        let videoSrc = this.props.id;
        if(typeof videoSrc === 'function') {
            const result = await videoSrc();
            this.setState({videoSrc: result});
        } else {
            this.setState({videoSrc});
        }
    }
    setTime(seconds, playWhenPaused) {
        if(typeof seconds === 'string' && (seconds.startsWith('+') || seconds.startsWith('-'))) {
            return this.setTimeRelative(seconds);
        }
        if(this.props.videoRef.current) this.props.videoRef.current.currentTime = seconds;
        if(playWhenPaused) this.props.videoRef.current.play();
    }
    setTimeRelative(seconds) {
        if(typeof seconds === 'string' && seconds.startsWith('+')) seconds = parseInt(seconds.substring(1));
        if(typeof seconds === 'string' && seconds.startsWith('-')) seconds = parseInt(seconds);
        if(this.props.videoRef.current) this.setTime(this.props.videoRef.current.currentTime + seconds);
    }
    toggle() {
        if(this.props.videoRef.current) {
            if(this.props.videoRef.current.paused) {
                this.props.videoRef.current.play();
            } else {
                this.props.videoRef.current.pause();
            }
        }
    }
    render() {
        return (
            <div className='relative overflow-hidden h-full' style={{backgroundColor: '#0A0A0A'}}>
                {this.state.isLoaded && !this.props.disableShortcuts ? <>
                    <Shortcut press=' ' onPress={this.toggle.bind(this)} />
                    <Shortcut press='shift-ArrowLeft' onPress={() => this.setTimeRelative(-1)} />
                    <Shortcut press='shift-ArrowRight' onPress={() => this.setTimeRelative(1)} />
                    <Shortcut press='ArrowLeft' onPress={() => this.setTimeRelative(-5)} />
                    <Shortcut press='ArrowRight' onPress={() => this.setTimeRelative(5)} />
                </> : <Loader fullScreen />}
                {this.state.isEncoding && <div style={{backgroundColor: '#0A0A0A'}} className='absolute inset-0 flex flex-col items-center justify-center text-white'>
                    <Loader color={window.appColor} />
                    <h4 className='mt-6 mb-4 text-white'>Almost ready...</h4>
                    <p className='opacity-75 max-w-sm text-center'>We are converting your video to the right format. This should only take a few minutes.</p>
                </div>}
                <div className={`tr h-full relative z-10 ${this.state.isLoaded ? 'animate-open opacity-1' : 'opacity-0'}`}>
                    {this.state.videoSrc && <Stream
                        controls
                        height='100%'
                        src={this.state.videoSrc}
                        preload="metadata"
                        streamRef={this.props.videoRef}
                        onCanPlay={() => {
                            const time = this.props.time;
                            if(time && this.props.videoRef.current.currentTime < time) {
                                this.props.videoRef.current.currentTime = time;
                            }
                            if(this.state.isEncoding) this.setState({isEncoding: false});
                            if(this.props.autoplay) this.props.videoRef.current.play();
                        }}
                        onPlay={() => this.props.onToggle ? this.props.onToggle(true) : null}
                        onPause={() => this.props.onToggle ? this.props.onToggle(false) : null}
                        onLoadedMetaData={(e) => {
                            this.setState({isLoaded: true});
                        }}
                        onLoadStart={e => {
                            this.props.videoRef.current.setTime = this.setTime.bind(this);
                            this.props.videoRef.current.toggle = this.toggle.bind(this);
                        }}
                        onTimeUpdate={() => {
                            if(this.props.onTime && this.props.videoRef.current) {
                                this.props.onTime(this.props.videoRef.current.currentTime);
                            }
                        }}
                        onError={() => this.setState({isEncoding: true})}
                    />}
                </div>
            </div>
        );
    }
}

Video.propTypes = {
    autoplay: PropTypes.bool,
}

export default React.forwardRef((props, ref) => <Video {...props} videoRef={ref} />);