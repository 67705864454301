import React from "react"
import { Button, Input, Page, withData, withNotify, toPreview } from "../shared"
import "firebase/auth"
import { withRouter } from "react-router-dom"
import { functions } from "../index"

class New extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      isLoading: false,
    }
  }
  async createProject(e) {
    e.preventDefault()
    try {
      this.setState({ isLoading: true })
      const createProject = functions.httpsCallable("createProject")
      const { data } = await createProject({ name: this.state.name })
      await this.props.userRefreshClaims()
      await this.props.fetch({ collection: "projects", id: data.projectId })
      window.justCreatedProject = true
      this.props.history.push("/projects/" + data.projectId)
    } catch (e) {
      this.props.error("Could not create project", e.message)
      this.setState({ isLoading: false })
    }
  }
  render() {
    return (
      <Page
        isPopup
        title="New project"
        noBar
        width="md"
        backTo={toPreview()}
        hasPadding
      >
        <form className="pt-0.5" onSubmit={this.createProject.bind(this)}>
          <h4>New project</h4>
          <p className="mt-1.5 mb-6 text-gray-500">
            You can always rename your project later.
          </p>
          <Input
            onChange={(name) => this.setState({ name })}
            hasBorder
            value={this.state.name}
            autoFocus
            placeholder="Enter project name..."
          />
          <Button
            disabled={!this.state.name}
            type="submit"
            isLoading={this.state.isLoading}
            primary
            className="mt-6 w-full"
          >
            Create
          </Button>
        </form>
      </Page>
    )
  }
}

export default withData(withNotify(withRouter(New)))
