import React from "react"
import {
  Button,
  Label,
  Table,
  Page,
  Icon,
  DropdownItemInvite,
  ColorPicker,
  getSemiUniqueKey,
  withData,
  withNotify,
  Callout,
  DemoBox,
  PopoverView,
  PopoverInvite,
  DropdownItem,
  ButtonInvite,
  format,
  PopoverCopyAs,
  sortData,
  Input,
  Dropdown,
  toLowerWithDashes,
  PopoverImport,
  downloadFile,
  Checkbox,
  toggleInArray,
  ContextMenu,
} from "../shared"
import { Route, Switch, Link } from "react-router-dom"
import { getInterviewFields, checkSchedulingIssue } from "../functions"
import PopoverSchedule from "./popovers/PopoverSchedule"
import { DropdownItemCopy } from "../shared"
import ProjectInterview from "./ProjectInterview"
import ProjectInterviewsLabels from "./ProjectInterviewsLabels"
import ProjectSettings from "./ProjectSettings"
import ProjectInterviewsInsights from "./ProjectInterviewsInsights"
import { functions } from "../index"
import moment from "moment"
import confetti from "canvas-confetti"
import _ from "lodash";

class ProjectInterviews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newInterviewName: "",
      isEditInterviewsOpen: false,
      selectedInterviewIds: [],
    }
    this.actionsBtnRef = React.createRef(null)
  }
  componentDidMount() {
    if (!window.noConfettiOnProjectCreate && window.justCreatedProject) {
      confetti({
        gravity: 0.5,
        origin: { x: 0.6, y: 0.45 },
        ticks: 400,
        spread: 180,
        particleCount: 150,
        disableForReducedMotion: true,
      })
      window.justCreatedProject = false
    }
  }
  exportToCSV() {
    const esc = (str) => {
      if (typeof str === "string") {
        return str?.split(";").join(",") || ""
      } else {
        return str || ""
      }
    }
    const project =
      (this.props.projects || []).find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}

    let csvContent = "data:text/csv;charset=utf-8,"
    // Add head
    csvContent +=
      "Name;Age;Gender;Email;Phone;Education;Job;Interview date;Notes;Labels;Transcript\n"
    csvContent += this.props.interviews
      .filter((x) => x.projectId === this.props.match.params.projectId)
      .map((x) => {
        const labels = x.labelIds
          ? x.labelIds
            .map((labelId) =>
              project.interviewLabels.find((x) => x.id === labelId),
            )
            .filter((x) => x)
            .map((x) => esc(x.name))
            .join(", ")
          : ""
        const transcript = x.transcript?.map((x) => esc(x.text)).join(" /// ")
        return `${esc(x.name)};${x.age || ""};${x.gender || ""};${esc(
          x.email,
        )};${esc(x.phone)};${esc(x.education)};${esc(x.job)};${x.date ? format("date", x.date, { includeTime: true }) : ""
          };${esc(x.notes)?.split("\n").join(" /// ")};${labels};${transcript || ""
          }`
      })
      .join("\n")
    downloadFile(
      `interviews-${toLowerWithDashes(project.name || "untitled")}.csv`,
      csvContent,
    )
  }
  async create() {
    const id = await this.props.create("interviews", {
      name: this.state.newInterviewName,
      projectId: this.props.match.params.projectId,
    })
    if (window.appShiftKey)
      this.props.history.push(
        `/projects/${this.props.match.params.projectId}/interviews/${id}`,
      )
    this.setState({ newInterviewName: "" })
  }
  async clearSchedules(interviewIds) {
    const confirm = await this.props.warn(
      "Are you sure?",
      `This will clear the date of ${interviewIds.length === 1 ? "this" : interviewIds.length
      } interview${interviewIds.length === 1 ? "" : "s"} and cannot be undone.`,
      `Clear schedule${interviewIds.length === 1 ? "" : "s"}`,
    )
    if (confirm) {
      const obj = {}
      for (const id of interviewIds) {
        obj[id] = { date: "", time: "" }
      }
      this.props.updateMultiple("interviews", obj)
      this.props.success(
        `${interviewIds.length} date${interviewIds.length === 1 ? "" : "s"
        } cleared`,
      )
    }
  }
  render() {
    const project =
      (this.props.projects || []).find(
        (x) => x.id === this.props.match.params.projectId,
      ) || {}
    const existingFilters = (
      this.props.session.interviewsFilterLabelIds || []
    ).filter((id) => (project.interviewLabels || []).some((y) => y.id === id)) // Filter out any labels that doesn't exist anymore.
    let interviews = (this.props.interviews || [])
      .filter((x) => {
        if (x.projectId !== this.props.match.params.projectId) return false
        return (
          !existingFilters.length ||
          (x.labelIds || []).some((id) => existingFilters.includes(id))
        )
      })
      .map((x) => ({ ...x, duration: x.duration || project.duration || 60, checkbox: false }))
    const fields = getInterviewFields(
      project.interviewLabels,
      (this.props.findings || []).filter((x) => x.projectId === project.id),
      this.props.projects,
      false,
      (project.interviewers || []).length > 1,
    )

    const singular = "interview"
    const plural = "interviews"

    // Sort for PopoverCopyAs so that Table/Grid sort (which sort themselves) affects sort in PopoverCopyToText
    const sortBy = this.props.local[`${plural}SortBy`] || "createdOn"
    const sortByField = fields.find((x) => x && x.key === sortBy)
    const sortDesc = this.props.local[`${plural}SortDesc`]
    if (sortByField)
      interviews = interviews.sort((a, b) =>
        sortData(a, b, sortByField, sortDesc),
      )

    const baseUrl = `/projects/${project.id}/interviews`
    const someHaveDateFunc = (selectedIds) =>
      selectedIds.some((x) => {
        const interview = interviews.find((y) => y.id === x)
        return interview?.date
      })

    const contextMenu = (selectedIds) => {
      const someHaveDate = someHaveDateFunc(selectedIds)
      const selectedInterviews = interviews.filter((x) =>
        selectedIds.includes(x.id),
      )
      const arrays = selectedInterviews
        .map((interview) => interview.labelIds)
        .filter((x) => x)
      const activeIds =
        arrays.length > 0
          ? arrays.shift().filter(function (v) {
            return arrays.every(function (a) {
              return a.indexOf(v) !== -1
            })
          })
          : []

      return (
        <>
          <DropdownItemCopy
            defaultFormat="[Name] – [Date]"
            plural="interviews"
            fields={fields}
            data={selectedInterviews}
            selectedIds={selectedIds}
          />
          <DropdownItem to={`${baseUrl}/copy-as`} toData={{ selectedIds }}>
            Copy as
          </DropdownItem>
          <DropdownItem
            borderTop
            icon="clock"
            to={`${baseUrl}/schedule`}
            toData={{ selectedIds }}
          >
            {someHaveDate ? "Reschedule" : "Schedule"}
          </DropdownItem>
          <DropdownItem
            icon="delete"
            disabled={!someHaveDate}
            onClick={() => this.clearSchedules(selectedIds)}
          >
            Clear schedule
          </DropdownItem>
          <DropdownItem
            borderTop
            icon="send"
            to={`/projects/${project.id}/emails/new`}
            toData={{ toIds: selectedIds }}
          >
            Send email
          </DropdownItem>
          <div className="mt-1 border-t pt-1">
            {project.interviewLabels &&
              project.interviewLabels.length > 0 &&
              (project.interviewLabels || []).map((x) => (
                <Checkbox
                  color={x.color}
                  isActive={activeIds.includes(x.id)}
                  onChange={(isActive) => {
                    let obj = {}
                    for (const interview of selectedInterviews) {
                      obj[interview.id] = {}
                      obj[interview.id].labelIds = toggleInArray(
                        x.id,
                        interview.labelIds,
                        isActive,
                      )
                    }
                    this.props.updateMultiple("interviews", obj)
                  }}
                  isSmall
                  key={x.id}
                  value={x.id}
                >
                  {x.name || "New label"}
                </Checkbox>
              ))}
            <DropdownItem
              icon="add"
              onClick={async () => {
                let name, color
                const confirm = await this.props.openModal({
                  title: "New label",
                  desc: `Apply to ${selectedInterviews.length} interview${selectedInterviews.length === 1 ? "" : "s"
                    }.`,
                  icon: "label",
                  action: "Add",
                  child: (
                    <Input
                      hasBorder
                      onChange={(x) => (name = x)}
                      autoFocus
                      placeholder="Name..."
                      button={
                        <ColorPicker
                          onChange={(x) => (color = x)}
                          noWeights
                          hasAll
                        />
                      }
                    />
                  ),
                })
                if (confirm && name) {
                  const id = getSemiUniqueKey()
                  this.props.addToArray(
                    "projects",
                    this.props.match.params.projectId,
                    "interviewLabels",
                    {
                      id,
                      name,
                      color: color || null,
                    },
                  )
                  for (const interview of selectedInterviews) {
                    this.props.addToArray(
                      "interviews",
                      interview.id,
                      "labelIds",
                      id,
                    )
                  }
                }
              }}
            >
              New label
            </DropdownItem>
          </div>
          <DropdownItem
            borderTop
            autoClose
            isRed
            disabled={this.props.isDemo}
            icon="delete"
            onClick={() =>
              this.props.deleteMultiple(
                "interviews",
                selectedIds,
                this.props.warn,
                plural,
              )
            }
          >
            Delete {this.props.singular}
          </DropdownItem>
        </>
      )
    }
    const someHaveDate = someHaveDateFunc(interviews.map((x) => x.id))
    const isEmpty = interviews.length === 0
    const unscheduledInterviews = interviews.filter((x) => !x.date)
    const scheduledInterviews = interviews.filter((x) => x.date)
    const isFiltered = existingFilters.length > 0
    const hideFields =
      this.props.local.interviewsHideFields ||
      fields
        .map((x) => x.key)
        .filter(
          (x) => !["checkbox", "name", "date", "interviewerId", "labelIds"].includes(x),
        )
    const isCreateDisabled =
      this.props.plan === "regular" &&
      (this.props.interviews ?? []).filter(
        (x) =>
          x.createdBy === this.props.userId &&
          moment(x.createdOn, "X").isSameOrAfter(moment().startOf("month")),
      ).length > 10

    const fixedX = _.isNumber(this.actionsBtnRef?.current?.offsetLeft) ? this.actionsBtnRef.current.offsetLeft + 240 : null;
    const fixedY = _.isNumber(this.actionsBtnRef?.current?.offsetTop) ? this.actionsBtnRef.current.offsetTop + 39.5 : null;

    return (
      <Page
        title={`${interviews.length} interview${interviews.length === 1 ? "" : "s"
          }`}
        isFlexCol
        required={{ project }}
        filteredTo={isFiltered && `${baseUrl}/view`}
        onFilterClear={() =>
          this.props.setSession({ interviewsFilterLabelIds: [] })
        }
        singular="project"
        barRight={
          <>
            <Dropdown
              arrowPosition="right"
              onSubmit={this.create.bind(this)}
              width={260}
              button={
                <Button
                  primary
                  className="mr-2"
                  disabled={this.props.isDemo}
                  icon="add"
                  title="New interview"
                />
              }
            >
              <Input
                disabled={isCreateDisabled}
                autoClose={false}
                placeholder="Enter interviewee name..."
                hasBorder
                autoFocus
                onChange={(x) => this.setState({ newInterviewName: x })}
              />
              <Button
                disabled={isCreateDisabled}
                autoClose={false}
                type="submit"
                className="mt-2 w-full"
                primary
              >
                Create interview
              </Button>
              <p className="sub mt-2 text-center">
                {isCreateDisabled ? (
                  "You reached 10 interviews this month. Contact us to upgrade your plan."
                ) : (
                  <>
                    Hold{" "}
                    <span className="relative z-10 inline-flex h-5 items-center justify-center rounded border px-1 pt-0.5 text-xs text-gray-500">
                      Shift
                    </span>{" "}
                    to open interview.
                  </>
                )}
              </p>
            </Dropdown>
            <ContextMenu
              className={'h-full'}
              position="underButton"
              fixedX={fixedX}
              fixedY={fixedY}
              singular={singular}
              plural={plural}
              desc={typeof this.props.desc === 'function' ? this.props.desc(this.state.selectedInterviewIds) : this.props.desc}
              numSelected={_.size(this.state.selectedInterviewIds)}
              menu={contextMenu(this.state.selectedInterviewIds)}
              triggerOnlyOnLeftClick
            >
              <Button
                disabled={_.size(this.state.selectedInterviewIds) === 0}
                innerRef={this.actionsBtnRef}
                icon="chevron-down"
                title="Actions"
                endIcon
                hasBorder
              >
                {"Actions"}
              </Button>
            </ContextMenu>
            <Button
              disabled={isEmpty}
              icon="zap"
              title="Insights"
              to={`/projects/${project.id}/interviews/insights`}
            />
            <ButtonInvite
              project={project}
              to={`/projects/${project.id}/interviews/invite`}
            />
          </>
        }
        moreWidth={206}
        more={
          <>
            <DropdownItem
              icon="layers"
              to={`/projects/${project.id}/interviews/view`}
            >
              View
            </DropdownItem>
            <DropdownItem icon="label" to={`${baseUrl}/labels`}>
              Edit labels
            </DropdownItem>
            <DropdownItem
              borderTop
              disabled={unscheduledInterviews.length === 0}
              icon="clock"
              to={`${baseUrl}/schedule`}
              toData={{ selectedIds: unscheduledInterviews.map((x) => x.id) }}
            >
              Schedule{" "}
              {unscheduledInterviews.length > 0
                ? `${unscheduledInterviews.length}`
                : ""}{" "}
              interview{unscheduledInterviews.length === 1 ? "" : "s"}
            </DropdownItem>
            <DropdownItem
              icon="delete"
              disabled={!someHaveDate}
              onClick={() =>
                this.clearSchedules(scheduledInterviews.map((x) => x.id))
              }
            >
              Clear{" "}
              {scheduledInterviews.length > 0
                ? `${scheduledInterviews.length}`
                : ""}{" "}
              interview{scheduledInterviews.length === 1 ? "" : "s"}...
            </DropdownItem>
            <DropdownItem
              borderTop
              disabled={this.props.isDemo}
              icon="upload"
              to={`/projects/${project.id}/interviews/import`}
            >
              Import interviews
            </DropdownItem>
            <DropdownItem
              disabled={this.props.isDemo}
              icon="download"
              onClick={this.exportToCSV.bind(this)}
            >
              Export interviews (CSV)
            </DropdownItem>
            <DropdownItemInvite
              borderTop
              project={project}
              to={`/projects/${project.id}/interviews/invite`}
            />
            <DropdownItem
              disabled={this.props.isDemo}
              icon="settings"
              to={`${baseUrl}/settings`}
            >
              Project settings
            </DropdownItem>
          </>
        }
      >
        {!this.props.location.pathname.endsWith("schedule") &&
          !this.props.location.pathname.includes("demo-interview") &&
          this.props.isDemo &&
          this.props.session.isOnboarded && (
            <DemoBox
              step={4}
              back="/projects/demo-project/forms/demo-form/responses"
              to="/projects/demo-project/interviews/schedule"
              toData={{
                selectedIds: [
                  "demo-interview-4",
                  "demo-interview-5",
                  "demo-interview-7",
                  "demo-interview-8",
                ],
              }}
              primary="These are our interviews."
              secondary="You can view them however you like, and organize them with labels. You can also view insights of your sample by clicking the light bulb icon in the top right."
            />
          )}
        <Switch>
          <Route
            path="/projects/:projectId/interviews/import"
            render={(props) => (
              <PopoverImport
                {...props}
                backTo={`/projects/${project.id}/interviews`}
                collection="interviews"
                collectionFields={{
                  interviews: getInterviewFields([], [], [], true),
                }}
                addToEach={{
                  interviews: {
                    projectId: this.props.match.params.projectId,
                  },
                }}
              />
            )}
          />
          <Route
            path="/projects/:projectId/interviews/invite"
            render={(props) => (
              <PopoverInvite
                {...props}
                backTo={baseUrl}
                project={project}
                inviteFunc={functions.httpsCallable("inviteMember")}
                revokeFunc={functions.httpsCallable("revokeMember")}
              />
            )}
          />
          <Route path="/projects/:projectId/interviews/labels" />
          <Route
            path="/projects/:projectId/interviews/insights"
            render={(props) => (
              <ProjectInterviewsInsights
                {...props}
                interviews={interviews}
                project={project}
                backTo={baseUrl}
              />
            )}
          />
          <Route
            path="/projects/:projectId/interviews/settings"
            render={(props) => (
              <ProjectSettings {...props} project={project} backTo={baseUrl} />
            )}
          />
          <Route
            path="/projects/:projectId/interviews/copy-as"
            render={(props) => (
              <PopoverCopyAs
                backTo={baseUrl}
                defaultFormat="[Name] – [Date]"
                {...props}
                fields={fields}
                data={interviews}
                singular={singular}
                plural={plural}
              />
            )}
          />
          <Route
            path="/projects/:projectId/interviews/view"
            render={(props) => (
              <PopoverView
                {...props}
                backTo={baseUrl}
                onSubmit={({ filterLabelIds, tableHideFields }) => {
                  this.props.setSession({
                    interviewsFilterLabelIds: filterLabelIds,
                  })
                  this.props.setLocal({
                    interviewsHideFields: tableHideFields,
                  })
                }}
                options={{
                  view: {
                    value: "table",
                  },
                  tableHideFields: {
                    value: hideFields,
                    options: fields,
                  },
                  filterLabels: {
                    value: existingFilters,
                    options: project.interviewLabels,
                  },
                }}
              />
            )}
          />
          <Route
            path="/projects/:projectId/interviews/schedule"
            render={(props) => <PopoverSchedule {...props} backTo={baseUrl} />}
          />
          <Route
            path="/projects/:projectId/interviews/:interviewId"
            component={ProjectInterview}
          />
        </Switch>
        <Route
          path="/projects/:projectId/interviews/:interviewId?/labels"
          render={(props) => (
            <ProjectInterviewsLabels
              {...props}
              backTo={`${baseUrl}${props.match.params.interviewId
                ? `/${props.match.params.interviewId}`
                : ""
                }`}
            />
          )}
        />

        {!isEmpty ? (
          <Table
            tableType={"interview"}
            ignoreDefaultActions
            contextMenu={contextMenu}
            singular={singular}
            plural={plural}
            isNumbered
            defaultSortBy="createdOn"
            hideFields={hideFields}
            showTotals={false}
            to={`/projects/${project.id}/interviews/:id`}
            onEdit={(data) => {
              this.props.updateMultiple("interviews", data)
              this.setState({ isEditingTable: false })
            }}
            onDelete={(ids) => {
              const isConfirm = this.props.deleteMultiple(
                "interviews",
                ids,
                this.props.warn,
              )
              if (isConfirm) this.setState({ isEditingTable: false })
            }}
            fields={fields}
            data={interviews}
            badges={(interview) => {
              if (hideFields.includes("labelIds")) return null
              let isDone = false
              let schedulingIssue = false
              if (interview.date) {
                const duration = interview.duration || project.duration || 60
                isDone = moment(interview.date, "X")
                  .add(duration, "minutes")
                  .isBefore(moment())
                const obj = checkSchedulingIssue(
                  interview,
                  project.duration || 60,
                  project,
                  this.props.interviews,
                )
                schedulingIssue = obj.schedulingIssueMessage
              }
              const renderSomething =
                interview.transcript ||
                (!interview.transcript && interview.transcriptProgressId) ||
                interview.mediaType ||
                interview.date
              // Without renderSomething, badges returns empty fragment, which messes up padding.
              return renderSomething ? (
                <>
                  {!interview.transcript && interview.transcriptProgressId && (
                    <Label color="gray" isLoading>
                      Transcribing...
                    </Label>
                  )}
                  {interview.transcript &&
                    interview.transcript.map((x) => x.text).join("") && (
                      <Label color="gray" title="Has transcript" icon="text" />
                    )}
                  {interview.mediaType && (
                    <Label
                      color="gray"
                      title={
                        interview.mediaType === "audio"
                          ? "Has audio"
                          : "Has video"
                      }
                      icon={interview.mediaType === "audio" ? "audio" : "video"}
                    />
                  )}
                  {interview.date &&
                    (schedulingIssue && !isDone ? (
                      <Label
                        color="orange"
                        title={schedulingIssue}
                        icon="warning"
                      />
                    ) : (
                      <Label
                        color={isDone ? "gray" : "green"}
                        title={isDone ? "Done" : "Scheduled"}
                        icon={isDone ? "check" : "clock"}
                      />
                    ))}
                </>
              ) : null
            }}
            setSelectedInterviewIds={data => this.setState({ selectedInterviewIds: data })}
            projectId={this.props.match.params.projectId}
          />
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center">
            <div className="w-full max-w-screen-sm pb-24 text-center">
              <h4 className="mb-3 text-indigo-500 dark:text-indigo-400">
                Created {project.name}!{" "}
                <span className="ml-2 inline-block">🎉</span>
              </h4>
              <h3>Let's add your first interviews.</h3>

              <div className="mt-16 grid w-full grid-cols-3 gap-16 text-left leading-normal">
                <div>
                  <Icon
                    padding={3}
                    size={16}
                    icon="add"
                    bg="indigo-100"
                    bgDark="indigo-800"
                    color="indigo-500"
                    colorDark="indigo-400"
                  />
                  <h5 className="mt-3">Add one interview</h5>
                  <p className="text-gray-500">
                    by clicking the plus icon in the top right.
                  </p>
                </div>
                <div>
                  <Icon
                    padding={3}
                    size={16}
                    icon="upload"
                    bg="indigo-100"
                    bgDark="indigo-800"
                    color="indigo-500"
                    colorDark="indigo-400"
                  />
                  <h5 className="mt-3">Import interviews</h5>
                  <p className="text-gray-500">
                    by clicking the{" "}
                    <Link
                      className="link"
                      to={`/projects/${project.id}/interviews/import`}
                    >
                      Import
                    </Link>{" "}
                    button in the More menu.
                  </p>
                </div>
                <div>
                  <Icon
                    padding={3}
                    size={16}
                    icon="form"
                    bg="indigo-100"
                    bgDark="indigo-800"
                    color="indigo-500"
                    colorDark="indigo-400"
                  />
                  <h5 className="mt-3">Create a form</h5>
                  <p className="text-gray-500">
                    to recruit respondents by going to your{" "}
                    <Link className="link" to={`/projects/${project.id}/forms`}>
                      Forms
                    </Link>
                    .
                  </p>
                </div>
              </div>

              <Callout
                noBg
                className="border-light mx-auto mt-16 max-w-md rounded-t-none border-t pt-5 text-left text-gray-500"
              >
                Not sure if you will interview someone yet? Just add them here –
                you can always label or delete interviews later.
              </Callout>
            </div>
          </div>
        )}
      </Page>
    )
  }
}

export default withData(withNotify(ProjectInterviews))
