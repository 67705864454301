import React from "react"
import {
  withData,
  withNotify,
  Shortcut,
  State,
  fName,
  Icon,
  List,
} from "../shared"
import { getFormResponseFields } from "../functions"
import { Link } from "react-router-dom"

class ProjectInterviewResponses extends React.Component {
  render() {
    const interview = this.props.interview
    const forms = (this.props.forms || []).filter(
      (x) => x.projectId === this.props.project.id,
    )
    const responses = Array.isArray(interview.responses)
      ? (this.props.formResponses || []).filter((x) =>
          interview.responses.includes(x.id),
        )
      : []
    const fields = getFormResponseFields(this.props.forms)

    return (
      <div className="animate-up flex h-full flex-col overflow-scroll">
        <Shortcut
          press="Escape"
          onPress={() =>
            this.props.history.push(`/projects/${this.props.project.id}`)
          }
        />
        <div className="flex-1">
          {responses.length ? (
            <div className="p-10 pt-7">
              <div className="flex items-center pt-1 pb-6">
                <Icon size={16} className="mr-3" icon="form" />
                <h5>
                  {responses.length} form response
                  {responses.length === 1 ? "" : "s"}
                </h5>
              </div>
              <List
                disabled={this.props.disabled}
                hasBorder
                keyPrimary="formName"
                keyTertiary="createdOn"
                fields={fields}
                to={`/projects/${this.props.project.id}/forms/:formId/responses/:id`}
                data={responses}
              />
            </div>
          ) : (
            <State className="p-10 pt-4" title="No responses" icon="form">
              <p className="mx-auto max-w-xs px-4 text-center">
                When {fName(interview.name)} responds to a form, it will show up
                here.
              </p>
              {forms.length ? (
                <p className="sub mx-auto mt-3 max-w-xs px-4 text-center">
                  You can link any missing responses by going to{" "}
                  <Link
                    className={`link ${
                      this.props.disabled
                        ? "pointer-events-none opacity-50"
                        : ""
                    }`}
                    to={`/projects/${this.props.project.id}/forms`}
                  >
                    forms
                  </Link>
                  , then responses.
                </p>
              ) : null}
            </State>
          )}
        </div>
      </div>
    )
  }
}

export default withData(withNotify(ProjectInterviewResponses))
