import React from "react"
import {
  Button,
  getColor,
  DemoBox,
  Icon,
  PopoverView,
  Calendar,
  Page,
  withData,
  withNotify,
} from "../shared"
import { PopoverSetDate } from "../shared"
import { Route } from "react-router-dom"
import moment from "moment"

class Schedule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "Schedule",
    }
  }
  updateSetting(data) {
    this.props.update("usersPrivate", this.props.userId, data)
  }
  render() {
    const user = this.props.getUser()
    const view = user.calendarView || "week"
    const showWeekends = user.calendarShowWeekends
    const minHour = user.calendarShow24Hours ? null : 8
    const maxHour = user.calendarShow24Hours ? null : 18
    const projects = (this.props.projects || [])
      .filter((x) => !x.isArchived)
      .map((x, i) => ({
        name: x.name,
        interviewers: x.interviewers,
        id: x.id,
        color: getColor(i + 6),
        hex: getColor(i + 6, true),
      }))
    const visibleProjects = projects.filter(
      (project) =>
        !this.props.session.scheduleHideProjectIds?.includes(project.id),
    )
    let interviewerNames = visibleProjects
      .map((project) => project.interviewers)
      .flat()
      .filter((x) => x?.availability?.length > 0)
      .map((interviewer) => interviewer.name)
    if (
      interviewerNames.length > 1 &&
      this.props.interviews.some((x) => !x.interviewerId)
    )
      interviewerNames.push(null)
    const visibleInterviewerNames = interviewerNames.filter(
      (name) =>
        !(this.props.session.scheduleHideInterviewerNames || []).includes(name),
    )
    const isFiltered =
      projects.length !== visibleProjects.length ||
      interviewerNames.length !== visibleInterviewerNames.length
    return (
      <Page
        title={this.state.title}
        filteredTo={isFiltered && `/schedule/view`}
        bar={
          <>
            <Button
              title={`Previous ${view}`}
              onClick={() => {
                this.calendar.prev()
                this.props.setSession({
                  calendarInitialDate: moment(this.calendar.getDate()).unix(),
                })
              }}
              icon="chevron-left"
            />
            <Button
              onClick={() => {
                this.calendar.today()
                this.props.setSession({
                  calendarInitialDate: moment(this.calendar.getDate()).unix(),
                })
              }}
            >
              Today
            </Button>
            <Button
              title={`Next ${view}`}
              onClick={() => {
                this.calendar.next()
                this.props.setSession({
                  calendarInitialDate: moment(this.calendar.getDate()).unix(),
                })
              }}
              icon="chevron-right"
            />
          </>
        }
        barRight={
          <>
            <Button icon="layers" to={`/schedule/view`} title="View" />
            <Button
              to="/schedule/set-date"
              autoClose
              icon="arrow-right"
              title="Go to date"
            />
          </>
        }
      >
        {this.props.isDemo && this.props.session.isOnboarded && (
          <DemoBox
            step={10}
            back="/projects/demo-project/findings/present"
            primary="That was a quick tour of InterviewKit."
            secondary="We haven't even mentioned this schedule, focus mode, real‑time collaboration, and more. Why not give InterviewKit a try? We think you'll like it!"
          />
        )}
        <Route
          path="/schedule/set-date"
          render={() => (
            <PopoverSetDate
              backTo="/schedule"
              format="date"
              onSubmit={(x) => {
                this.calendar.gotoDate(x)
                this.props.setSession({
                  calendarInitialDate: moment(this.calendar.getDate()).unix(),
                })
              }}
            />
          )}
        />
        <Route
          path="/schedule/view"
          render={(props) => (
            <PopoverView
              {...props}
              backTo="/schedule"
              onSubmit={(x) => {
                this.updateSetting({
                  calendarView: x.calendarView,
                  calendarShow24Hours: x.calendarShow24Hours,
                  calendarShowWeekends: x.calendarShowWeekends,
                })
                this.props.setSession({
                  scheduleHideProjectIds: x.calendarHideProjectIds,
                  scheduleHideInterviewerNames: x.calendarHideInterviewerNames,
                })
              }}
              options={{
                view: { value: "calendar" },
                calendarView: {
                  value: view,
                  options: ["day", "week", "month"],
                },
                calendarShow24Hours: !minHour,
                calendarShowWeekends: showWeekends,
                calendarProjects: projects,
                calendarInterviewerNames: interviewerNames,
                calendarHideInterviewerNames:
                  this.props.session.scheduleHideInterviewerNames,
                calendarHideProjectIds:
                  this.props.session.scheduleHideProjectIds,
              }}
            />
          )}
        />

        {(this.props.interviews || []).filter((x) => x.date).length === 0 && (
          <div className="absolute inset-0 z-10 m-auto flex h-72 w-full max-w-md flex-col items-center justify-center rounded-lg bg-white p-10 shadow-sm dark:bg-gray-800">
            <Icon
              icon="calendar"
              color="indigo-500"
              bg="indigo-50"
              bgDark="indigo-900"
            />
            <h4 className="mt-5 mb-4">This is your schedule.</h4>
            <p className="text-center text-gray-500">
              When you schedule interviews, they will show up here in a daily,
              weekly or monthly calendar. This allows you to keep a clear
              overview, even with multiple projects going on.
            </p>
          </div>
        )}

        <Calendar
          eventColor="#6366F1" // indigo-500
          showAllDay={false}
          timeFormat="H:mm"
          onTitleChange={(title) =>
            title !== this.state.title ? this.setState({ title }) : null
          }
          view={view}
          onClick={(x) => this.props.history.push(x)}
          showWeekends={showWeekends}
          initialDate={this.props.session.calendarInitialDate}
          ref={(x) => (this.calendar = x)}
          minHour={minHour}
          maxHour={maxHour}
          events={(this.props.interviews || [])
            .filter((x) => x.projectId && x.date)
            .map((x) => {
              const project = this.props.projects
                .filter((x) => !x.isArchived)
                .find((y) => y.id === x.projectId)
              if (!project) return false
              if (
                (this.props.session.scheduleHideProjectIds || []).includes(
                  project.id,
                )
              )
                return false
              let interviewerColor
              if (interviewerNames.length > 1) {
                if (x.interviewerId) {
                  const interviewer = project.interviewers?.find(
                    (y) => y.id === x.interviewerId,
                  )
                  if (
                    interviewer &&
                    (
                      this.props.session.scheduleHideInterviewerNames || []
                    ).includes(interviewer.name)
                  )
                    return false
                  const index = project.interviewers
                    .map((x) => x.id)
                    .indexOf(x.interviewerId)
                  interviewerColor = getColor(index + 6, true)
                } else if (
                  this.props.session.scheduleHideInterviewerNames?.includes(
                    null,
                  )
                ) {
                  return false
                }
              }
              return {
                to: `/projects/${project.id}/interviews/${x.id}`,
                color:
                  interviewerNames.length > 1 && visibleProjects.length === 1
                    ? interviewerColor
                    : projects.find((y) => y.id === x.projectId).hex,
                id: x.id,
                date: x.date,
                duration: x.duration || project.duration || 60,
                title: x.name || "New interview",
              }
            })
            .filter((x) => x)}
        />
      </Page>
    )
  }
}

export default withData(withNotify(Schedule))
