import React from 'react';
import PropTypes from 'prop-types';
import Button from "./Button";
import Dropdown from "./Dropdown";
import {getColor} from "../functions";

function ColorPickerColor(props) {
    return <div className={`w-6 h-6 bg-${props.value} rounded pushable ${props.isActive ? 'ring ring-gray-200 dark:ring-gray-600' : ''}`} onClick={() => props.onClick(props.value)} />
}

class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            value: this.props.defaultValue || false,
        };
    }
    render() {
        const defaultWeight = 500;
        let value = this.props.value != null ? this.props.value : this.state.value;
        value = value ? (value.includes('-') ? value : `${value}-${defaultWeight}`) : false;
        const h = this.props.isSmall ? 8 : 10;
        const className = `tr relative rounded${this.props.isSmall ? '' : '-lg'} border whitespace-nowrap ${this.props.disabled ? 'opacity-50' : ''} ${this.props.disabled || this.props.loading ? 'pointer-events-none' : ''} select-none ${this.props.hasBorder ? 'border' : 'border-transparent'} font-medium inline-flex items-center justify-center h-${h} pushable ${this.props.className || ''}`;
        const colorCategories = getColor(false, false, this.props.hasAll);
        const colors = [];
        for(const color of colorCategories) {
            if(this.props.noWeights) {
                colors.push(`${color}-${defaultWeight}`);
            } else {
                for(let i = 100; i < 900; i += 100) {
                    colors.push(`${color}-${i}`);
                }
            }
        }
        if(this.props.hasGrayscale) {
            if(!this.props.noWeights) {
                colors.push('white');
                for(let i = 200; i < 800; i += 100) {
                    colors.push(`gray-${i}`);
                }
                colors.push('black');
            } else {
                colors.push('white', 'black');
            }
        }
        const arrowLeft = this.props.arrowPosition == null || this.props.arrowPosition === 'left';
        const numCols = this.props.noWeights ? (this.props.hasGrayscale ? 5 : 4) : 8;
        return (
            <Dropdown isWithInput offset={arrowLeft ? -10 : null} buttonWidth={this.props.isSmall ? 14 : 20} width={this.props.noWeights ? (this.props.hasGrayscale ? 150.5 : 119) : 227.5} autoClose isSmall={this.props.isSmall} arrowPosition={this.props.arrowPosition} positionTop={this.props.positionTop} className='inline-block' button={<div className={className}><div className={`bg-${value || 'gray-200'} dark:bg-${value || 'gray-700'} h-${h-4} rounded w-${this.props.isSmall ? 4 : 6}`} /></div>}>
                <div className={`grid grid-cols-${numCols} gap-2`} style={{width: this.props.noWeights ? (this.props.hasGrayscale ? '9.5rem' : '7.25rem') : '15rem'}}>
                    {colors.map(color => <ColorPickerColor isActive={color === value} key={`color-${color}`} autoClose value={color} onClick={() => {
                        const value = this.props.noWeights ? color.split('-')[0] : color;
                        this.setState({value});
                        if(this.props.onChange) this.props.onChange(value);
                    }} />)}
                    {this.props.canClear && value && <Button secondary onClick={() => {
                        this.setState({value: false});
                        if(this.props.onChange) this.props.onChange(false);
                    }} className={`col-span-${numCols}`}>Clear</Button>}
                </div>
            </Dropdown>
        );
    }
}

ColorPicker.propTypes = {
    hasAll: PropTypes.bool,
    arrowPosition: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    noWeights: PropTypes.bool,
    hasGrayscale: PropTypes.bool,
    positionTop: PropTypes.bool,
    onChange: PropTypes.func,
};

ColorPicker.defaultProps = {
    canClear: true,
}

export default ColorPicker;