import React from "react"
import {
  Button,
  format,
  capitalize,
  Dropdown,
  List,
  Page,
  withData,
  withNotify,
} from "../shared"
import moment from "moment"
import { functions } from "../index"

class ProjectEmailSends extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAborting: false,
    }
  }
  componentDidMount() {
    this.openFirst()
  }
  openFirst() {
    if (
      !this.props.match.params.sendId &&
      this.props.sends &&
      this.props.sends.length > 0
    ) {
      this.props.history.replace(
        `/projects/${this.props.match.params.projectId}/emails/${this.props.match.params.emailId}/sends/${this.props.sends[0].id}`,
      )
    }
  }
  async abortAll(scheduledSends) {
    if (this.props.isDemo) return false
    const confirm = await this.props.warn(
      `Abort scheduled send${scheduledSends.length === 1 ? "" : "s"}?`,
      `This will abort the ${scheduledSends.length} remaining scheduled send${
        scheduledSends.length === 1 ? "" : "s"
      } for this email.`,
      "Abort",
    )
    if (confirm) {
      this.setState({ isAborting: true })
      const cancelBatch = functions.httpsCallable("cancelBatch")
      const { data } = await cancelBatch({
        emailId: this.props.match.params.emailId,
      })
      if (data && data.isSuccess) {
        await this.props.fetch({
          collection: "emails",
          id: this.props.match.params.emailId,
          force: true,
        })
        this.props.success("Scheduled emails aborted")
      } else {
        this.props.error(
          "Could not abort scheduled emails",
          (data || {}).message ||
            "Please note that emails scheduled within 15 minutes cannot be guaranteed to be aborted.",
        )
      }
      this.setState({ isAborting: false })
    }
  }
  render() {
    const fields = [
      {
        key: "toStr",
        calc: (_, send) => {
          const interview = this.props.interviews.find(
            (x) => x.email === send.to.email,
          )
          return interview ? interview.name : send.to.email
        },
      },
      { key: "toNames" },
      { key: "subject" },
      { key: "message" },
      {
        key: "date",
        calc: (date, send) => {
          if (send.isCancelled || (send.status && send.status !== "delivered"))
            return null
          const day = format("date", date, { format: "dddd", isRelative: true })
          return `${capitalize(day)}, ${format("time", date)}`
        },
      },
    ]

    const backUrl = `/projects/${this.props.match.params.projectId}/emails/${this.props.match.params.emailId}`
    const sends = this.props.sends.sort((a, b) => a.date - b.date)
    const send = sends.find((x) => x.id === this.props.match.params.sendId)
    const isScheduled =
      send &&
      !send.isCancelled &&
      send.date &&
      moment(send.date, "X").isAfter(moment(), "minute")
    const sendInterview = send
      ? this.props.interviews.find((x) => x.email === send.to.email)
      : false
    const undeliveredSends = sends.filter(
      (send) =>
        send.isCancelled ||
        (send.status &&
          send.status !== "delivered" &&
          send.status !== "opened"),
    )
    const scheduledSends = sends.filter(
      (send) =>
        !send.status &&
        !send.isCancelled &&
        moment(send.date, "X").isAfter(moment(), "minute"),
    )
    const unknownSends = sends.filter(
      (send) =>
        !send.status &&
        !send.isCancelled &&
        !moment(send.date, "X").isAfter(moment(), "minute"),
    )
    const deliveredSends = sends.filter((send) => send.status === "delivered")
    const openedSends = sends.filter((send) => send.status === "opened")

    let status,
      statusColor,
      statusDesc = ""
    if (send?.status === "dropped" && !send.isCancelled) {
      status = "Not delivered"
      statusColor = "text-red-500"
      statusDesc = "The email address likely does not exist."
    } else if (send?.status === "deferred") {
      status = "Not delivered"
      statusColor = "text-red-500"
      statusDesc =
        "The email has been deferred. This means the email platform of the interviewee takes a long time to process email (it may have technical issues). You don't have to do anything at the moment, as we continue to attempt sending the message in the next 48 hours. If you need to contact the interviewee quickly, however, you might want to contact the interviewee in a different way."
    } else if (send?.status === "bounced") {
      status = "Not delivered"
      statusColor = "text-red-500"
      statusDesc = "The email address likely does not exist."
    } else if (send?.status === "delivered") {
      status = "Delivered"
      statusColor = "text-green-500"
      statusDesc =
        "The email has been delivered. It almost always shows up in the recipient's inbox. In rare cases, it might be in their spam folder."
    } else if (send?.status === "opened") {
      status = "Opened"
      statusColor = "text-green-500"
      statusDesc = (
        <>
          The email has been delivered and was opened by the recipient.{" "}
          <span className="mt-3 block">
            Please note that some emails may stay at 'delivered' status even
            though they have been opened as well: this depends on the email
            client and privacy settings of the recipient.
          </span>
        </>
      )
    } else if (!send?.status) {
      if (
        send &&
        !send.isCancelled &&
        moment(send.date, "X").isAfter(moment(), "minute")
      ) {
        status = "Scheduled"
        statusColor = "text-gray-500"
        statusDesc = `This email is scheduled and will be sent at the time you selected, which is ${format(
          "date",
          send.date,
          {
            day: "full",
            month: "full",
            year: "not-current",
            isRelative: true,
            includeTime: true,
          },
        )}.`
      } else if (send?.isCancelled) {
        status = "Aborted"
        statusColor = "text-red-500"
        statusDesc =
          "This scheduled email has been aborted by you (or someone else in your project) from sending."
      } else {
        status = "Sent"
        statusColor = "text-gray-500"
        statusDesc =
          !send?.date ||
          moment(send?.date).isBefore(moment().subtract(10, "minutes"))
            ? "The email is sent, but its delivery status is not available."
            : "The email is sent. More detailed delivery information will be available shortly."
      }
    }

    // These will overwrite the status above.
    if (send?.isCancelled) {
      status = "Aborted"
      statusColor = "text-red-500"
      statusDesc = "The email has been aborted and will not be delivered."
    } else if (isScheduled) {
      status = "Scheduled"
      statusColor = "text-gray-500"
    }

    return (
      <Page isPopup noBar backTo={backUrl} width="5xl" height={600}>
        <div className="two-pages">
          <Page
            title="Individual sends"
            icon="at"
            classNameChild="space-y-7"
            hasPadding
            isPopupChild
            isSidebar
          >
            {scheduledSends.length > 0 && (
              <div>
                <h6 className="mb-2 ml-4">Scheduled</h6>
                <List
                  keyPrimary="toStr"
                  keyRight="date"
                  singular="email"
                  plural="emails"
                  fields={fields}
                  data={scheduledSends}
                  to={`${backUrl}/sends/:id`}
                />
                <Button
                  isLoading={this.state.isAborting}
                  className="mt-3"
                  disabled={this.props.isDemo}
                  onClick={() => this.abortAll(scheduledSends)}
                  full
                  isRed
                  secondary
                >
                  Abort
                </Button>
              </div>
            )}
            {undeliveredSends.length > 0 && (
              <div>
                <h6 className="mb-2 ml-4">Not delivered</h6>
                <List
                  keyPrimary="toStr"
                  keyRight="date"
                  singular="email"
                  plural="emails"
                  fields={fields}
                  data={undeliveredSends}
                  to={`${backUrl}/sends/:id`}
                />
              </div>
            )}
            {unknownSends.length > 0 && (
              <div>
                <h6 className="mb-2 ml-4">Sent</h6>
                <List
                  keyPrimary="toStr"
                  keyRight="date"
                  singular="email"
                  plural="emails"
                  fields={fields}
                  data={unknownSends}
                  to={`${backUrl}/sends/:id`}
                />
              </div>
            )}
            {deliveredSends.length > 0 && (
              <div>
                <h6 className="mb-2 ml-4">Delivered</h6>
                <List
                  keyPrimary="toStr"
                  keyRight="date"
                  singular="email"
                  plural="emails"
                  fields={fields}
                  data={deliveredSends}
                  to={`${backUrl}/sends/:id`}
                />
              </div>
            )}
            {openedSends.length > 0 && (
              <div>
                <h6 className="mb-2 ml-4">Opened</h6>
                <List
                  keyPrimary="toStr"
                  keyRight="date"
                  singular="email"
                  plural="emails"
                  fields={fields}
                  data={openedSends}
                  to={`${backUrl}/sends/:id`}
                />
              </div>
            )}
          </Page>
          {send ? (
            <Page
              barRight={
                <Button secondary to={backUrl}>
                  Done
                </Button>
              }
              backTo={backUrl}
              title={sendInterview ? sendInterview.name : send.to.email}
              isPopupChild
              hasPadding
            >
              <div
                className="grid leading-snug"
                style={{ gridTemplateColumns: "100px 1fr" }}
              >
                <h5>To:</h5>
                <div>
                  {sendInterview
                    ? `${sendInterview.name} <${send.to.email}>`
                    : send.to.email}
                </div>
              </div>
              <div
                className="mt-2 grid leading-snug"
                style={{ gridTemplateColumns: "100px 1fr" }}
              >
                <h5>Date:</h5>
                {send.isCancelled || !send.date ? (
                  <div className="text-gray-500">—</div>
                ) : (
                  <div>
                    {format("date", send.date, {
                      day: "full",
                      month: "full",
                      year: "not-current",
                      isRelative: true,
                      capitalize: true,
                      includeTime: true,
                    })}
                  </div>
                )}
              </div>
              <div
                className="mt-2 grid leading-snug"
                style={{ gridTemplateColumns: "100px 1fr" }}
              >
                <h5>Subject:</h5>
                <div>{send.subject}</div>
              </div>
              <div
                className="mt-2 grid leading-snug"
                style={{ gridTemplateColumns: "100px 1fr" }}
              >
                <h5>Status:</h5>
                <div className="flex">
                  <div className={statusColor}>{status}</div>
                  {statusDesc && (
                    <Dropdown
                      width={250}
                      isInfo
                      button={
                        <Button className="-mt-1 ml-1" isSmall icon="help" />
                      }
                    >
                      <h6>What does this status mean?</h6>
                      <p className="mt-3 text-sm">{statusDesc}</p>
                    </Dropdown>
                  )}
                </div>
              </div>
              <div className="border-light mt-6 whitespace-pre-wrap border-t pb-2 pt-6 leading-normal">
                {send.message}
              </div>
            </Page>
          ) : null}
        </div>
      </Page>
    )
  }
}

ProjectEmailSends.propTypes = {}

export default withData(withNotify(ProjectEmailSends))
