import React from "react"
import {
  Button,
  Page,
  Audio,
  Video,
  capitalize,
  DemoBox,
  format,
  Shortcut,
  withData,
  fName,
} from "../shared"
import { functions } from "../index"
import demoPeaks from "../assets/demo-peaks.json"
import { getQuoteFromTranscript } from "../functions"

class ProjectFindingsPresent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageIndex: 0,
      isMediaPlaying: false,
    }
    this.audio = React.createRef()
    this.video = React.createRef()
  }
  render() {
    const { pageIndex } = this.state
    const {
      backTo,
      project,
      interviews,
      presentOnlyKey,
      allLabels,
      hasChapters,
      presentNames,
      findings,
    } = this.props
    const user = this.props.getUser()
    const title = capitalize(`${presentOnlyKey ? "key " : ""}findings`)

    const hasFindingsWithoutLabel = hasChapters
      ? findings.some((x) => !x.labelIds || !x.labelIds.length)
      : false
    let labels = hasChapters
      ? [...new Set(findings.map((x) => x.labelIds[0]).flat())]
          .filter((x) => x)
          .map((id) => allLabels.find((x) => x.id === id))
      : false
    if (hasFindingsWithoutLabel)
      labels.push({ id: "other", name: "Other findings" })

    const totalPages = findings.length + 2 + (hasChapters ? labels.length : 0)
    const pages = []

    const chapterIndexes = []
    let pageIndexLoop = 0
    if (hasChapters)
      for (const label of labels) {
        const findingsWithLabel =
          label.id === "other"
            ? findings.filter((x) => !x.labelIds || !x.labelIds.length).length
            : findings.filter((x) => (x.labelIds || [])[0] === label.id).length
        const index = pageIndexLoop + 1
        chapterIndexes.push(index)
        pageIndexLoop = index + findingsWithLabel
      }

    let findingIndex = 0
    let chapterIndex = 0
    for (let i = 0; i < totalPages; i++) {
      if (i === 0) {
        pages.push("start")
        continue
      }
      if (chapterIndexes.includes(i)) {
        pages.push("chapter-" + chapterIndex)
        chapterIndex++
        continue
      }
      if (i === totalPages - 1) {
        pages.push("end")
      } else {
        pages.push("finding-" + findingIndex)
        findingIndex++
      }
    }
    const page = pages[pageIndex]
    const finding = page.startsWith("finding")
      ? findings.find((x, i) => i === parseInt(page.split("-")[1]))
      : false
    const interview = finding
      ? interviews.find((x) => x.id === finding.interviewId) || {}
      : false
    const mediaType = interview ? interview.mediaType : false
    const label =
      hasChapters && finding
        ? finding.labelIds
          ? labels.find((x) => x.id === finding.labelIds[0]) || {}
          : labels.find((x) => x.id === "other")
        : false
    const findingsWithLabel =
      hasChapters && label
        ? label.id === "other"
          ? findings.filter((x) => !x.labelIds || !x.labelIds.length)
          : findings.filter((x) => (x.labelIds || [])[0] === label.id)
        : false
    let findingNumber = hasChapters ? 1 : parseInt(page.split("-")[1]) + 1
    const sentence = getQuoteFromTranscript(
      interview.transcript,
      interview.transcriptEntities,
      finding,
      true,
    )

    if (hasChapters) {
      for (let i = pageIndex - 1; i >= 0; i--) {
        // go down pages from current
        const page = pages[i]
        if (page.startsWith("finding")) {
          findingNumber++
        } else {
          break
        }
      }
    }

    const media =
      mediaType === "audio" ? (
        <div className="mr-10 h-96 flex-none p-5">
          <Audio
            ref={this.audio}
            height={260}
            peaks={this.props.isDemo ? demoPeaks : null}
            time={finding.time - 3}
            isDark
            url={interview.mediaUrl}
            onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
          />
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowUp"
            onPress={() => this.audio.current.setTime("+5")}
          />
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowDown"
            onPress={() => this.audio.current.setTime("-5")}
          />
          <div className="mt-10 flex justify-center">
            <Button
              className="mr-1"
              hasBorder
              onClick={() => this.audio.current.playPause()}
              shortcut="cmd-p"
              selected={this.state.isMediaPlaying}
              icon={this.state.isMediaPlaying ? "pause" : "play"}
            >
              Play
            </Button>
            <Button
              className="ml-1"
              hasBorder
              onClick={() => this.audio.current.setTime(finding.time - 3)}
              icon="arrow-left"
            />
          </div>
        </div>
      ) : mediaType === "video" ? (
        <div className="mr-20 h-96 flex-none overflow-hidden rounded-2xl">
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowUp"
            onPress={() => this.video.current.setTime("+5")}
          />
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowDown"
            onPress={() => this.video.current.setTime("-5")}
          />
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-p"
            onPress={() => this.video.current.toggle()}
          />
          {this.props.isDemo ? (
            <video
              controls
              className="h-full"
              controlsList="nodownload"
              disablePictureInPicture
              ref={this.video}
              src={interview.mediaUrl}
              onLoadedData={() =>
                (this.video.current.currentTime = finding.time - 3)
              }
              onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
            />
          ) : (
            <Video
              ref={this.video}
              time={finding.time - 3}
              id={async () => {
                const getVideoDownloadToken = functions.httpsCallable(
                  "getVideoDownloadToken",
                )
                const { data } = await getVideoDownloadToken({
                  videoId: interview.mediaUrl,
                })
                if (data && data.isSuccess) {
                  return `https://iframe.videodelivery.net/${data.token}`
                } else {
                  this.props.error("Could not load video")
                  return false
                }
              }}
              onToggle={(isMediaPlaying) => this.setState({ isMediaPlaying })}
            />
          )}
        </div>
      ) : null

    return (
      <Page
        isFullScreen
        hasDone
        backTo={backTo}
        className="dark"
        titleTop={
          pageIndex === 0 || pageIndex === pages.length - 1 ? null : title
        }
        title={
          pageIndex === 0 || pageIndex === pages.length - 1
            ? null
            : project.name
        }
        bar={
          <>
            <Button
              shortcut="cmd-ArrowLeft"
              onClick={() =>
                this.setState({
                  pageIndex: this.state.pageIndex - 1,
                  isMediaPlaying: false,
                })
              }
              disabled={this.state.pageIndex === 0}
              icon="chevron-left"
            />
            <h5 className="whitespace-nowrap px-2 tabular-nums">
              {this.state.pageIndex + 1} of {totalPages}
            </h5>
            <Button
              shortcut="cmd-ArrowRight"
              onClick={() =>
                this.setState({
                  pageIndex: this.state.pageIndex + 1,
                  isMediaPlaying: false,
                })
              }
              disabled={this.state.pageIndex === pages.length - 1}
              icon="chevron-right"
            />
          </>
        }
        barRight={
          interview.mediaType === "video" && (
            <Button
              onClick={() =>
                this.props.isDemo
                  ? (this.video.current.currentTime = finding.time - 3)
                  : this.video.current.setTime(finding.time - 3)
              }
              className="mr-2"
              title="Replay video"
              icon="reply"
            />
          )
        }
      >
        {this.props.isDemo && this.props.session.isOnboarded && (
          <DemoBox
            step={9}
            to="/schedule"
            back={`/projects/${project.id}/findings`}
            primary="We can even turn findings into a presentation."
            secondary="Show them to your team, a client, or anyone. No more days wasted in PowerPoint – it now takes just one click. Plus, you can present audio and video."
          />
        )}

        {page === "start" && (
          <div className="animate-open-top mx-auto flex h-full max-w-screen-md flex-col justify-center pb-10">
            <h6 className="text-lg">{user.name}</h6>
            <h1 className="mt-5 mb-8 text-7xl">{project.name}</h1>
            <h4 className="font-medium dark:text-gray-400">
              {title} <span className="mx-2">·</span>{" "}
              {format("date", "today", { month: "full" })}
            </h4>
          </div>
        )}
        {page.startsWith("chapter") && (
          <div className="animate-open-top mx-auto flex h-full max-w-xl flex-col justify-center pb-10">
            {labels.map((label, i) => {
              const isActive = i === parseInt(page.split("-")[1])
              return (
                <div
                  key={label.id}
                  className={`flex items-center ${
                    isActive ? "" : "opacity-50"
                  }`}
                >
                  <div className="h4 my-3 mr-5 h-10 w-10 rounded-full border-2 border-gray-600 p-2 pl-3 dark:border-white">
                    {i + 1}
                  </div>
                  <h3>{label.name}</h3>
                </div>
              )
            })}
          </div>
        )}
        {page.startsWith("finding") && (
          <div
            key={finding.id}
            className={`animate-open-top grid grid-cols-${
              mediaType ? 2 : 1
            } mx-auto h-full max-w-screen-2xl items-center px-20 pb-10`}
          >
            <div className={`relative mx-auto max-w-lg p-6`}>
              <div className="absolute -left-5 top-0 text-7xl opacity-25">
                “
              </div>
              <h6 className="text-sm">
                {hasChapters && label.name
                  ? label.name
                  : presentOnlyKey
                  ? "Key finding"
                  : "Finding"}{" "}
                — {findingNumber} of{" "}
                {hasChapters ? findingsWithLabel.length : findings.length}
              </h6>
              {sentence && finding.text && (
                <div className="mt-3 text-lg leading-relaxed text-gray-500 dark:text-gray-400 dark:text-white">
                  {sentence}
                </div>
              )}
              <div className="mt-3 text-2xl leading-relaxed dark:text-white">
                {finding.text || sentence}
              </div>
              {presentNames !== "none" && interview.name && (
                <div className="mt-3 text-lg text-gray-500 dark:text-gray-400">
                  —{" "}
                  {presentNames === "first"
                    ? fName(interview.name)
                    : interview.name}
                  {interview.age ? ` (${interview.age})` : ""}
                </div>
              )}
            </div>
            {mediaType && media}
          </div>
        )}
        {page === "end" && (
          <div className="animate-open-top mx-auto flex h-full max-w-lg flex-col items-center justify-center pb-20">
            <p
              className="-mt-10 dark:text-white"
              style={{ fontSize: 130, fontFamily: "down" }}
            >
              Thank you.
            </p>
            <h5 className="text-center font-medium leading-snug dark:text-gray-400">
              {title} of {project.name} by {user.name}
            </h5>
          </div>
        )}
      </Page>
    )
  }
}

export default withData(ProjectFindingsPresent)
