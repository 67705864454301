import React from "react"
import { NotifyRoot } from "../notify"
import { withData } from "../data"
import Button from "./Button"
import State from "./State"
import Icon from "./Icon"
import Loader from "./Loader"
import Error from "./Error"
import "../index.css"
import moment from "moment"
import "moment/locale/es"
import "moment/locale/nl"
import "moment/locale/fr"
import "moment/locale/de"
import { checkIfDark, fName, isLocalhost } from "../functions"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { defaults } from "react-chartjs-2"

class Root extends React.Component {
  constructor(props) {
    super(props)
    this.setDisplayModeBound = this.setDisplayMode.bind(this)
    // Initialize moment
    moment.locale("en") // This is necessary to keep English as default locale.

    defaults.global.responsive = true
    defaults.global.maintainAspectRatio = false

    defaults.global.layout = {
      ...defaults.global.layout,
      padding: 9,
    }

    defaults.global.elements.point = {
      ...defaults.global.elements.point,
      hitRadius: 25,
    }

    defaults.global.elements.line = {
      ...defaults.global.elements.line,
      tension: 0.45,
      borderCapStyle: "round",
      fill: false,
    }

    const isDark = checkIfDark()
    const bgColor = isDark ? "#1F2937" : "#FFFFFF"
    const textColor = isDark ? "#E5E7EB" : "#1F2937"
    const borderColor = isDark ? "#374151" : "#E3E8EF"

    defaults.global.tooltips = {
      ...defaults.global.tooltips,
      mode: "point",
      backgroundColor: bgColor,
      titleFontColor: textColor,
      bodyFontColor: textColor,
      bodySpacing: 6,
      titleFontSize: 13,
      titleFontStyle: "500",
      bodyFontSize: 13,
      xPadding: 10,
      borderColor: borderColor,
      borderWidth: 1,
      yPadding: 8,
      titleFontFamily:
        "Inter, --apple-system, Helvetica Neue, Helvetica, Roboto, sans-serif",
      bodyFontFamily:
        "Inter, --apple-system, Helvetica Neue, Helvetica, Roboto, sans-serif",
    }
    defaults.global.legend = {
      ...defaults.global.legend,
      display: false,
    }

    this.state = {
      isFontLoaded: false,
    }
    this.isLocalhost = isLocalhost()
    window.appName = this.props.name
    if (this.props.color) {
      const darkMode = checkIfDark()
      if (darkMode && this.props.colorDark) {
        const color = this.props.colorDark.split("-")[0]
        const weight = parseInt(this.props.colorDark.split("-")[1])
        window.appColor = this.props.colorDark
        window.appColorHex = this.props.colorHex
        window.appColorBase = color
        window.appColorDarker = color + "-" + (weight + 100)
      } else {
        const color = this.props.color.split("-")[0]
        const weight = parseInt(this.props.color.split("-")[1])
        window.appColor = this.props.color
        window.appColorHex = this.props.colorHex
        window.appColorBase = color
        window.appColorDarker = color + "-" + (weight + 100)
      }
    }
  }
  setDisplayMode() {
    // Set light/dark mode preferences (also in demo, defaults to OS)
    const userPrivate =
      (this.props.usersPrivate || []).find((x) => x.id === this.props.userId) ||
      {}
    if (
      userPrivate.displayMode === "dark" ||
      (!userPrivate.displayMode &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
  }
  async componentDidMount() {
    if (document.fonts.ready) {
      document.fonts.ready.then(() => this.setState({ isFontLoaded: true }))
    } else {
      this.setState({ isFontLoaded: true })
    }
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.setDisplayModeBound)
  }
  componentWillUnmount() {
    document.documentElement.classList.remove("dark")
    window
      .matchMedia("(prefers-color-scheme: dark)")
      ?.removeEventListener("change", this.setDisplayModeBound)
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      this.setDisplayMode()
      if (!this.props.isDemo && this.props.userStartedLoggedOut) {
        const user = (await this.props.getUser()) || {}
        const firstName = fName(user.name)
        this.props.success(`Welcome back${firstName ? `, ${firstName}` : ""}!`)
      }
    }
  }
  render() {
    if (!this.props.isDataLoaded || !this.state.isFontLoaded) {
      return (
        <div className="h-screen">
          <Loader fullScreen />
        </div>
      )
    }
    if (this.props.isDataError) {
      return (
        <div className="h-screen">
          <Error
            code={this.props.dataErrorCode}
            message={this.props.dataErrorMessage}
            isNoPermission={false}
            showContact={false}
          />
        </div>
      )
    }
    if (window.innerWidth < 800 && !this.props.isMobileAllowed) {
      return (
        <div className="h-screen w-screen p-10">
          <State
            img={this.props.img}
            title={`${this.props.name || "This"} is a desktop app.`}
            icon={this.props.img ? null : "Computer.ComputerLaptop"}
          >
            {this.props.desc && (
              <p className="max-w-sm text-center text-gray-500">
                {this.props.desc}
              </p>
            )}
            <p
              className={`text-${window.appColor} mt-3 max-w-sm text-center font-medium`}
            >
              Open this page on your computer to get started.
            </p>
          </State>
        </div>
      )
    }
    return (
      <>
        <div
          className={`tr z0 pointer-events-none fixed inset-x-0 flex justify-center ${
            this.props.notificationIsVisible ? "opacity-1" : "opacity-0"
          }`}
          style={{
            zIndex: 990,
            top: this.props.notificationIsVisible ? "1rem" : "0rem",
          }}
        >
          <div
            onClick={() => this.props.closeNotification()}
            className={`${
              this.props.notificationIsVisible ? "pointer-events-auto" : ""
            } tr flex select-none overflow-hidden rounded-2xl border bg-white px-6 py-3 leading-relaxed shadow-2xl dark:bg-gray-800`}
          >
            <div className="flex flex-1 items-start">
              {this.props.notificationIcon ? (
                <Icon
                  icon={this.props.notificationIcon}
                  size={this.props.notificationIcon === "cross" ? 10 : 14}
                  color={this.props.notificationColor}
                  className={`${
                    this.props.notificationIcon === "cross" ? "mt-1.5" : "mt-1"
                  } mr-4 flex-none`}
                />
              ) : null}
              <div>
                <h5 className="-mt-px leading-normal">
                  {this.props.notification}
                </h5>
                {this.props.notificationDesc ? (
                  <p className="sub mt-px max-w-screen-md pr-2">
                    {this.props.notificationDesc}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {this.props.modalIsOpen && (
          <>
            <div
              className="tr z0 animate-fade-in fixed inset-0"
              style={{
                zIndex: 970,
                background: "rgba(0,0,0,0.05)",
                backdropFilter: "blur(4px)",
                WebkitBackdropFilter: "blur(4px)",
              }}
            />
            <div
              className="z0 fixed inset-0 flex items-center justify-center"
              style={{ zIndex: 980 }}
            >
              <div
                className={`animate-up relative inset-0 flex max-w-md flex-col overflow-scroll rounded-2xl bg-white p-6 pr-8 dark:bg-gray-800`}
                style={{ maxHeight: "90vh", minWidth: 320 }}
              >
                {this.props.modalIcon ? (
                  <Icon
                    icon={this.props.modalIcon}
                    size={24}
                    color="black"
                    colorDark="white"
                    className="my-4 self-center"
                  />
                ) : null}
                <div>
                  <h5 className="max-w-xl px-3 text-center text-lg leading-normal">
                    {this.props.modalTitle}
                  </h5>
                  <p className="mt-1 mb-6 px-2 text-center text-gray-600 dark:text-gray-400">
                    {this.props.modalDesc}
                  </p>
                  {this.props.modalChild}
                  <div className="mt-7 flex space-x-2">
                    {this.props.modalAction ? (
                      <Button
                        full
                        key="modal-action"
                        shortcut="Enter"
                        alsoWorksWhenInputInFocus
                        primary
                        isRed={this.props.modalColor === "red-500"}
                        onClick={() => this.props.modalOnAction(true)}
                      >
                        {this.props.modalAction || "Confirm"}
                      </Button>
                    ) : null}
                    <Button
                      full
                      key="modal-cancel"
                      shortcut={this.props.modalIsOpen ? "Escape" : ""}
                      secondary={this.props.modalAction}
                      primary={!this.props.modalAction}
                      onClick={() => this.props.modalOnAction(false)}
                    >
                      {this.props.modalCancel}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* {!this.props.isClientOnline && (
          <>
            <div
              className="tr z0 animate-fade-in fixed inset-0"
              style={{
                zIndex: 10000,
                background: "rgba(0,0,0,0.05)",
                backdropFilter: "blur(4px)",
                WebkitBackdropFilter: "blur(4px)",
              }}
            />
            <div
              className="z0 fixed inset-0 flex items-center justify-center"
              style={{ zIndex: 10001 }}
            >
              <div
                className={`animate-up relative inset-0 flex max-w-md flex-col overflow-scroll rounded-2xl bg-white p-6 pr-8 dark:bg-gray-800`}
                style={{ maxHeight: "90vh", minWidth: 320 }}
              >
                <Icon
                  icon="wifi"
                  size={24}
                  color="red-500"
                  className="my-4 self-center"
                />
                <div>
                  <h5 className="max-w-xl px-3 text-center text-lg leading-normal">
                    Lost connection
                  </h5>
                  <p className="mt-1 mb-6 px-2 text-center text-gray-600 dark:text-gray-400">
                    You either lost internet connection or have been inactive in
                    a while. To make sure you have the most up-to-date data,
                    please reconnect.
                  </p>
                </div>
                <Button
                  icon="refresh"
                  secondary
                  key="modal-refresh"
                  onClick={() => window.location.reload()}
                >
                  Reconnect
                </Button>
              </div>
            </div>
          </>
        )} */}
        <div className="h-screen overflow-hidden">{this.props.children}</div>
      </>
    )
  }
}

Root.propTypes = {
  name: PropTypes.string.isRequired,
}

export default NotifyRoot(withData(withRouter(Root)))
