import React from "react"
import { Page, withData, withNotify, Button, toPreview } from "../shared"
import { withRouter } from "react-router-dom"
import { functions } from "../index"

class Archive extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRestoreLoading: false,
    }
  }
  async restore(projectId) {
    try {
      this.setState({ isRestoreLoading: true })
      const unarchiveProject = functions.httpsCallable("unarchiveProject")
      const { data } = await unarchiveProject({ projectId })
      if (data && data.isSuccess) {
        await this.props.fetch({
          collection: "projects",
          id: projectId,
          force: true,
        })
        this.props.success("Project restored")
        this.props.history.push(`/projects/${projectId}/interviews`)
      } else {
        this.props.error("Could not restore project", data.message)
        this.setState({ isRestoreLoading: false })
      }
    } catch (e) {
      this.props.error("Could not restore project", e.message)
      this.setState({ isRestoreLoading: false })
    }
  }
  render() {
    return (
      <Page
        isPopup
        title="Archive"
        style={{ minHeight: "min(340px, 90vh)" }}
        icon="archive"
        width="md"
        backTo={toPreview()}
        footer={
          <Button to="/" className="w-full" secondary>
            Done
          </Button>
        }
      >
        <h6 className="px-10 pb-2 pt-2">Projects</h6>
        <div className="border-light border-b">
          {this.props.projects
            .filter((x) => x.isArchived)
            .map((project) => (
              <div
                key={project.id}
                className="border-light flex items-center border-t px-10 py-2"
              >
                <h5 className="flex-1 truncate">{project.name}</h5>
                <Button
                  hasBorder
                  className="mr-1"
                  isSmall
                  to={`/projects/${project.id}/interviews`}
                >
                  Open
                </Button>
                <Button
                  isLoading={this.state.isRestoreLoading}
                  isSmall
                  onClick={() => this.restore(project.id)}
                >
                  Restore
                </Button>
              </div>
            ))}
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(withRouter(Archive)))
