import React from "react"
import queryString from "query-string"
import {
  Button,
  Error,
  Input,
  Page,
  Steps,
  rot13,
  isLocalhost,
  Step,
  fName,
  withData,
  withNotify,
} from "../shared"
import modeAuto from "../assets/mode-auto.png"
import modeLight from "../assets/mode-light.png"
import modeDark from "../assets/mode-dark.png"
import { functions } from "../index"

class Setup extends React.Component {
  constructor(props) {
    super(props)
    this.debug = isLocalhost()
    const args = queryString.parse(this.props.location.search)
    this.state = {
      step: 1,
      // step: this.debug ? 4 : 1,
      name: "",
      sessionId: args.session,
      email: args.token ? decodeURIComponent(rot13(args.token)) : null,
      isLoading: true,
      isFinishing: false,
      password: "",
      password2: "",
      displayMode: null,
    }
    this.hasNoName = true
  }
  async componentDidMount() {
    try {
      if (this.state.sessionId) {
        const getStripeSession = functions.httpsCallable("getStripeSession")
        const { data } = await getStripeSession({
          sessionId: this.state.sessionId,
        })
        if (data && data.isSuccess) {
          if (data.name) this.hasNoName = false
          this.setState({ name: data.name || "", isLoading: false })
        } else {
          this.setState({ isLoading: false })
        }
      } else if (this.state.email) {
        const getNewUserName = functions.httpsCallable("getNewUserName")
        const { data } = await getNewUserName({ email: this.state.email })
        if (data.name) {
          if (data.name) this.hasNoName = false
          this.setState({ name: data.name || "", isLoading: false })
        } else {
          this.setState({ isLoading: false })
        }
      } else {
        this.setState({ isLoading: false })
      }
    } catch (e) {
      this.setState({ sessionId: false, email: false, isLoading: false }) // This will show the error page.
    }
  }
  setDisplayMode(displayMode) {
    if (
      displayMode === "dark" ||
      (!displayMode &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
    this.setState({ displayMode })
  }
  async checkPassword() {
    if (!this.state.password || !this.state.password2) {
      return this.props.error("Please enter both passwords")
    }
    if (this.state.password.length < 6) {
      return this.props.error("Please use a longer password")
    }
    if (this.state.password !== this.state.password2) {
      return this.props.error("Your passwords do not match")
    }
    this.next()
  }
  async finish() {
    this.setState({ isFinishing: true })
    const setInitialPassword = functions.httpsCallable("setInitialPassword")
    const { data } = await setInitialPassword({
      name: this.state.name,
      sessionId: this.state.sessionId,
      password: this.state.password,
      email: this.state.email,
      displayMode: this.state.displayMode,
    })
    if (data && data.isSuccess) {
      try {
        await this.props.login(data.email, this.state.password)
        window.appIsFirstLogin = true
        this.props.history.push("/")
      } catch (e) {
        this.setState({ isFinishing: false })
        return this.props.error("Could not log in", true)
      }
    } else {
      this.setState({ step: this.hasNoName ? 3 : 2, isFinishing: false })
      return this.props.error("Could not set password", true)
    }
  }
  back() {
    this.setState({ step: this.state.step - 1 })
  }
  next() {
    this.setState({ step: this.state.step + 1 })
  }
  render() {
    const { step, password, name, displayMode, isFinishing, password2 } =
      this.state
    const user = this.props.userId !== "demo-user" && this.props.getUser()
    const index = this.hasNoName ? 1 : 0
    const alreadyDone = user && !this.debug
    return (
      <Page
        title={alreadyDone ? "Thank you" : "Set up your InterviewKit"}
        noBar={alreadyDone}
        isPopup
        backTo={false}
        isLoading={this.state.isLoading}
        width={alreadyDone ? "xl" : "4xl"}
        height={alreadyDone ? null : 500}
        hasPadding
      >
        {(this.debug || this.state.sessionId || this.state.email) &&
        !alreadyDone ? (
          <>
            {this.hasNoName ? (
              <Steps activeStep={step} className="mb-5">
                <Step>Welcome</Step>
                <Step>Name</Step>
                <Step>Password</Step>
                <Step>Feedback</Step>
                <Step>Preferences</Step>
              </Steps>
            ) : (
              <Steps activeStep={step} className="mb-5">
                <Step>Welcome</Step>
                <Step>Password</Step>
                <Step>Feedback</Step>
                <Step>Preferences</Step>
              </Steps>
            )}
            <div>
              {step === 1 && (
                <>
                  <p
                    className={`text-xl ${
                      this.state.sessionId ? "mt-24" : "mt-20"
                    } mb-3 max-w-md`}
                  >
                    <span className="font-semibold">
                      Welcome to InterviewKit{fName(name, { before: ", " })}!
                    </span>{" "}
                    🎉
                  </p>
                  <p className={`mb-6 max-w-md text-xl`}>
                    Your account is almost ready, just a few small things to get
                    you up and running.
                  </p>
                  <Button onClick={() => this.next()} primary>
                    Set up your account &rarr;
                  </Button>
                  <p className={`sub mt-20 max-w-xl`}>
                    Need help? Email us at{" "}
                    <span
                      className="link"
                      onClick={() =>
                        (window.location.href = atob(
                          "bWFpbHRvOnN1cHBvcnRAaW50ZXJ2aWV3a2l0LmNv",
                        ))
                      }
                    >
                      support@interviewkit.co
                    </span>
                    , we're quick to respond!
                  </p>
                  {!this.state.sessionId && (
                    <p className="sub mt-1 max-w-xl">
                      By setting up your account, you agree to our{" "}
                      <a className="link" href="//interviewkit.co/terms">
                        terms of service
                      </a>{" "}
                      and{" "}
                      <a className="link" href="//interviewkit.co/privacy">
                        privacy statement
                      </a>
                      .
                    </p>
                  )}
                </>
              )}
              {this.hasNoName && step === 2 && (
                <>
                  <p className="mt-12 max-w-md pr-8 text-xl">
                    <span className="font-semibold">What is your name?</span>{" "}
                    <br />
                    Let's make this a little more personal. Only you and people
                    you invite will see this.
                  </p>
                  <Input
                    className="mt-8 mb-10 w-80"
                    placeholder="Your full name..."
                    autoFocus
                    classNameInput="text-lg"
                    hasBorder
                    value={name}
                    onChange={(name) => this.setState({ name })}
                  />
                  <div className="flex">
                    <Button
                      className="mr-3"
                      secondary
                      onClick={() => this.back()}
                    >
                      &larr;
                    </Button>
                    <Button
                      onClick={() => this.next()}
                      primary
                      disabled={!name}
                    >
                      Next &rarr;
                    </Button>
                  </div>
                </>
              )}
              {step === 2 + index && (
                <>
                  <p className="mt-12 max-w-sm text-xl">
                    <span className="font-semibold">Set a password</span> <br />
                    What do you want your new <br />
                    password to be?
                  </p>
                  <Input
                    className="mt-8 w-80"
                    placeholder="Your password..."
                    autoFocus
                    classNameInput="text-lg"
                    hasBorder
                    type="password"
                    value={password}
                    onChange={(password) => this.setState({ password })}
                  />
                  <Input
                    onEnter={() => this.checkPassword()}
                    className="mt-3 mb-14 w-80"
                    placeholder="Your password again..."
                    classNameInput="text-lg"
                    hasBorder
                    type="password"
                    value={password2}
                    onChange={(password2) => this.setState({ password2 })}
                  />
                  <div className="flex" style={{ paddingTop: 2 }}>
                    <Button
                      className="mr-3"
                      secondary
                      onClick={() => this.back()}
                    >
                      &larr;
                    </Button>
                    <Button
                      onClick={() => this.checkPassword()}
                      primary
                      disabled={!password || !password2}
                    >
                      Set password &rarr;
                    </Button>
                  </div>
                </>
              )}
              {step === 3 + index && (
                <>
                  <div className="flex items-end">
                    <div className="flex-1">
                      <p className="mt-8 mb-6 text-xl">
                        Great! If we can make InterviewKit better for you,
                        please don't hesitate to email us at
                        support@interviewkit.co.
                      </p>
                      <div className="flex">
                        <Button
                          className="mr-3"
                          secondary
                          onClick={() => this.back()}
                        >
                          &larr;
                        </Button>
                        <Button primary onClick={() => this.next()}>
                          Great, will do! &rarr;
                        </Button>
                      </div>
                    </div>
                    <div style={{ width: 300 }} className="flex-0 ml-16 pt-10">
                      <div
                        className="relative overflow-hidden rounded-xl"
                        style={{ height: 256, width: 300 }}
                      >
                        <video
                          loop
                          preload="auto"
                          poster="/assets/message.png"
                          width="100%"
                          muted
                          autoPlay
                        >
                          <source
                            src="/assets/message.webm"
                            type="video/webm"
                          />
                          <source src="/assets/message.mp4" type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 4 + index && (
                <div className="text-center">
                  <p className="mx-auto mt-10 max-w-xs text-xl">
                    <span className="font-semibold">That's it!</span> Finally,
                    how do you want InterviewKit to look?
                  </p>
                  <div className="mt-8">
                    <div className="mx-auto flex max-w-xl justify-between">
                      <div style={{ maxWidth: 150 }}>
                        <img
                          alt=""
                          onClick={() => this.setDisplayMode(null)}
                          className={`pushable-sm cursor-pointer rounded-lg border ring-indigo-500 ${
                            displayMode == null ? "ring-2" : ""
                          }`}
                          src={modeAuto}
                        />
                        <div className="mt-3 text-center text-sm font-medium">
                          Automatic
                        </div>
                      </div>
                      <div style={{ maxWidth: 150 }}>
                        <img
                          alt=""
                          onClick={() => this.setDisplayMode("light")}
                          className={`pushable-sm cursor-pointer rounded-lg border ring-indigo-500 ${
                            displayMode === "light" ? "ring-2" : ""
                          }`}
                          src={modeLight}
                        />
                        <div className="mt-3 text-center text-sm font-medium">
                          Light
                        </div>
                      </div>
                      <div style={{ maxWidth: 150 }}>
                        <img
                          alt=""
                          onClick={() => this.setDisplayMode("dark")}
                          className={`pushable-sm cursor-pointer rounded-lg border ring-indigo-500 ${
                            displayMode === "dark" ? "ring-2" : ""
                          }`}
                          src={modeDark}
                        />
                        <div className="mt-3 text-center text-sm font-medium">
                          Dark
                        </div>
                      </div>
                    </div>
                    <p className="sub mt-5">
                      Automatic will toggle light and dark mode based on your OS
                      settings.
                    </p>
                  </div>
                  <div className="mt-5 flex justify-center">
                    <Button
                      className="mr-1.5"
                      secondary
                      onClick={() => this.back()}
                    >
                      &larr;
                    </Button>
                    <Button
                      primary
                      className="ml-1.5"
                      isLoading={isFinishing}
                      onClick={() => this.finish()}
                    >
                      Get started!
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : user ? (
          <>
            <p className="mt-2 max-w-xl pr-4 text-xl">
              Hi {fName(user.name) || "there"},
            </p>
            <p className="mt-5 pr-4 text-xl">
              You just got InterviewKit – hooray! 🎉
            </p>
            <p className="mt-5 pr-4 text-xl">
              Thank you for becoming a customer. Your support means a lot to us,
              as we continue to work on making InterviewKit better every day.
            </p>
            <p className="mt-5 pr-4 text-xl">
              Just a quick note before you start – if we can make InterviewKit
              better for you, please don't hesitate to email us at
              support@interviewkit.co at any time, and send us what you'd like
              to see.
            </p>
            <p className="mt-5 mb-6 pr-4 text-xl">Let's get interviewing!</p>
            <Button
              primary
              full
              isLoading={isFinishing}
              onClick={async () => {
                if (!this.props.plan) {
                  try {
                    this.setState({ isFinishing: true })
                    await this.props.userRefreshClaims()
                  } catch (e) {}
                }
                window.appIsFirstLogin = true
                this.props.history.push("/")
              }}
            >
              Get started &rarr;
            </Button>
          </>
        ) : (
          <Error
            tryAgain={false}
            message="The session ID is invalid. Please contact us at support@interviewkit.co for help."
          />
        )}
      </Page>
    )
  }
}

Setup.propTypes = {}

export default withData(withNotify(Setup))
