import React from "react"
import {
  Label,
  ContextMenu,
  ColorPicker,
  State,
  Status,
  Checkbox,
  formatShortcut,
  DropdownItem,
  isLocalhost,
  Dropdown,
  toggleInArray,
  getSemiUniqueKey,
  Input,
  withData,
  withNotify,
} from "../shared"
import {
  Editor,
  EditorState,
  Modifier,
  CompositeDecorator,
  SelectionState,
  convertToRaw,
  convertFromRaw,
} from "draft-js"
import { motion } from "framer-motion"
import { withRouter } from "react-router-dom"
import "draft-js/dist/Draft.css"
import firebase from "firebase/app"
import "firebase/firestore"
import {
  getInterviewUpdateObjOnFindingDelete,
  getQuoteFromTranscript,
  guessTranscriptTimestamp,
} from "../functions"
import { debounce } from "lodash"

class ProjectInterviewFindingsTranscript extends React.Component {
  constructor(props) {
    super(props)
    this.transcript = React.createRef()
    this.editorWrap = React.createRef()
    this.handleSpanClickBound = this.handleSpanClick.bind(this)
    this.state = {
      hoverFindingId: null,
      isSaved: null, // true or false if transcript has been edited, otherwise null
      labels: [],
      editorState: this.createEditorState(),
    }
    this.updateTranscriptDebounced = debounce(this.updateTranscript, 1000)
    this.debug = isLocalhost()
  }
  createEditorState() {
    const HandleFinding = (props) => {
      const { id } = props.contentState.getEntity(props.entityKey).getData()
      const finding = this.props.findings.find((x) => x.id === id)
      if (!finding) return props.children
      const existingLabelIds = finding.labelIds?.filter((labelId) =>
        this.props.labels?.some((x) => x.id === labelId),
      )
      const singleLabel =
        existingLabelIds &&
        existingLabelIds.length === 1 &&
        this.props.labels?.find((x) => x.id === existingLabelIds[0])
      const color = singleLabel && singleLabel.color
      const isHover = this.state.hoverFindingId
      const isHoverMe = this.state.hoverFindingId === id
      return (
        <span
          {...props}
          data-id={id}
          className={`${isHoverMe ? "text-gray-800 dark:text-gray-200" : ""} ${
            this.props.session.findingsHideMarkers
              ? ""
              : `bg-${color || "gray"}-${
                  isHover && !isHoverMe ? 50 : 100
                } dark:bg-${color || "gray"}-${
                  isHover && !isHoverMe ? 900 : 800
                }`
          }`}
        >
          {props.children}
        </span>
      )
    }
    this.decorator = new CompositeDecorator([
      {
        strategy: (contentBlock, callback, contentState) => {
          contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity()
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === "FINDING"
            )
          }, callback)
        },
        component: HandleFinding,
      },
    ])
    let editorState
    if (this.props.transcript && this.props.transcriptEntities) {
      const entityMap = {}
      for (const key in this.props.transcriptEntities) {
        const val = this.props.transcriptEntities[key]
        entityMap[key] = {
          type: val.type,
          mutability: "MUTABLE",
          data: val.type === "FINDING" ? { id: val.id } : {},
        }
      }
      const content = {
        blocks: this.props.transcript,
        entityMap,
      }
      editorState = EditorState.createWithContent(
        convertFromRaw(content),
        this.decorator,
      )
    } else {
      editorState = EditorState.createEmpty(this.decorator)
    }
    return editorState
  }
  componentDidMount() {
    this.updateLabels()
    // Update again in 400 ms to fix issue where labels would not position correctly after
    // directly navigating to findings or focus URL.
    this.timeout = window.setTimeout(() => {
      this.updateLabels()
    }, 400)
    if (this.editorWrap.current && this.props.transcriptTimes)
      this.editorWrap.current.addEventListener(
        "click",
        this.handleSpanClickBound,
      )
  }
  componentWillUnmount() {
    if (this.timeout) window.clearTimeout(this.timeout)
    if (this.editorWrap.current && this.props.transcriptTimes)
      this.editorWrap.current.removeEventListener(
        "click",
        this.handleSpanClickBound,
      )
  }
  getSelectedText(contentState, selectionState) {
    let strArr = []
    const blocks = contentState.getBlocksAsArray()
    let isInside = false
    for (const block of blocks) {
      if (block.getKey() === selectionState.getStartKey()) {
        const endOffset =
          selectionState.getEndKey() === block.getKey()
            ? selectionState.getEndOffset()
            : undefined
        strArr.push(
          block.getText().substring(selectionState.getStartOffset(), endOffset),
        )
        if (selectionState.getEndKey() !== block.getKey()) isInside = true
      } else if (block.getKey() === selectionState.getEndKey()) {
        strArr.push(block.getText().substring(0, selectionState.getEndOffset()))
        isInside = false
      } else if (isInside) {
        strArr.push(block.getText())
      }
    }
    return strArr.join(" ")
  }
  syncEditor() {
    const editorState = this.createEditorState()
    this.setState({ editorState }, () => {
      this.forceUpdateEditor()
      this.updateLabels()
    })
  }
  async componentDidUpdate(prevProps, prevState, snapshot) {
    const isEdited =
      JSON.stringify(prevProps.transcript) !==
        JSON.stringify(this.props.transcript) ||
      JSON.stringify(prevProps.findings) !== JSON.stringify(this.props.findings)
    if (isEdited && this.state.isSaved !== false) {
      // Update editor when collaborating with others, unless we are typing.
      // The onBlur handler of the editor will take care of syncing after typing.
      this.syncEditor()
    }
    if (
      prevProps.session.findingsHideMarkers !==
      this.props.session.findingsHideMarkers
    ) {
      this.forceUpdateEditor()
    }
    if (prevState.hoverFindingId !== this.state.hoverFindingId) {
      this.forceUpdateEditor()
    }
    if (!prevProps.isMarkOpen && this.props.isMarkOpen) {
      let { labels } = this.props
      const contentState = this.state.editorState.getCurrentContent()
      let selectionStateUnmodified = this.state.editorState.getSelection()
      // Move first char to encapsulate word
      const startBlockText = contentState
        .getBlockForKey(selectionStateUnmodified.getStartKey())
        .getText()
      const endBlockText = contentState
        .getBlockForKey(selectionStateUnmodified.getEndKey())
        .getText()
      let startOffset = selectionStateUnmodified.getStartOffset()
      let startChar = startBlockText.charAt(startOffset)
      while (startChar !== " " && startOffset > 0) {
        startOffset--
        startChar = startBlockText.charAt(startOffset)
      }
      if (startChar === " ") {
        startOffset++
        startChar = startBlockText.charAt(startOffset)
      }
      // Move last char to encapsulate word
      let endOffset = selectionStateUnmodified.getEndOffset()
      let endChar = endBlockText.charAt(endOffset)
      let endCharPrev = endBlockText.charAt(Math.max(0, endOffset - 1))
      if (endCharPrev === " ") {
        while (endCharPrev === " " && endOffset > 0) {
          endOffset--
          endCharPrev = endBlockText.charAt(endOffset)
        }
      }
      while (endChar !== " " && endOffset < endBlockText.length) {
        endOffset++
        endChar = endBlockText.charAt(endOffset)
      }
      const selectionState = new SelectionState({
        anchorKey: selectionStateUnmodified.getStartKey(),
        anchorOffset: startOffset,
        focusKey: selectionStateUnmodified.getEndKey(),
        focusOffset: endOffset,
        isBackward: false,
      })
      const selectedText = this.getSelectedText(contentState, selectionState)
      if (selectedText && selectedText !== " ") {
        let note,
          selectedLabels = [],
          newLabelName,
          newLabelColor
        const confirm = await this.props.openModal({
          title: `"${selectedText.trim()}"`,
          icon: "edit",
          action: "Mark finding",
          child: (
            <>
              <h6 className="mt-6 pb-1">Note</h6>
              <Input
                hasBorder
                autosize
                placeholder="Add note (optional)..."
                rows={3}
                autoFocus
                onChange={(x) => (note = x)}
              />
              <h6 className="mt-6 pb-1">Labels</h6>
              <div className="overflow-hidden rounded-lg border">
                {labels?.length > 0 && (
                  <div
                    className="overflow-scroll border-b"
                    style={{ maxHeight: 220 }}
                  >
                    {labels.map((x) => (
                      <Checkbox
                        className="rounded-b-none rounded-t-none"
                        isSmall
                        key={x.id}
                        color={x.color}
                        onChange={(isActive) =>
                          (selectedLabels = toggleInArray(
                            x.id,
                            selectedLabels,
                            isActive,
                          ))
                        }
                      >
                        {x.name}
                      </Checkbox>
                    ))}
                  </div>
                )}
                <Input
                  noStyling
                  className="pl-4"
                  placeholder="Add new..."
                  onChange={(x) => (newLabelName = x)}
                  button={
                    <ColorPicker
                      onChange={(x) => (newLabelColor = x)}
                      noWeights
                      hasAll
                    />
                  }
                />
              </div>
            </>
          ),
        })
        if (confirm) {
          let newLabelId
          if (newLabelName) {
            newLabelId = getSemiUniqueKey()
            this.props.addToArray(
              "projects",
              this.props.match.params.projectId,
              "findingLabels",
              {
                id: newLabelId,
                name: newLabelName,
                color: newLabelColor || null,
              },
            )
            selectedLabels.push(newLabelId)
          }
          const id = firebase.firestore().collection("findings").doc().id
          const contentStateWithEntity = contentState.createEntity(
            "FINDING",
            "MUTABLE",
            {
              id,
            },
          )
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
          const contentStateWithLink = Modifier.applyEntity(
            contentStateWithEntity,
            selectionState,
            entityKey,
          )
          const newEditorState = EditorState.set(this.state.editorState, {
            currentContent: contentStateWithLink,
          })
          this.props.create("findings", {
            id,
            text: note || null,
            interviewId: this.props.match.params.interviewId,
            projectId: this.props.match.params.projectId,
            labelIds: selectedLabels,
          })
          this.setState({ editorState: newEditorState }, () => {
            this.updateTranscript()
            this.forceUpdateEditor()
            window.setTimeout(() => {
              this.updateLabels()
            })
          })
        }
      } else {
        this.props.error(
          "No text selected",
          "Select the text that you want to mark as finding first.",
        )
      }
      this.props.closeMark(false)
    }
  }
  getTimeOfSelectedWord() {
    if (!this.props.transcriptTimes) return false
    const selection = this.state.editorState.getSelection()
    const contentState = this.state.editorState.getCurrentContent()
    let startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const blockKeys = contentState.getBlocksAsArray().map((x) => x.getKey())
    // Note: added "startOffset +1" below, because without the +1 if you select text and turn it into a finding, the
    // selection will end with a space which gets filtered away. The one letter makes the index correct again.
    const textInActiveBlock = contentState
      .getBlockForKey(startKey)
      .getText()
      .substring(0, startOffset + 1)
    let wordIndex = Math.max(
      0,
      textInActiveBlock.split(" ").filter((x) => x).length - 1,
    )
    const blockIndex = blockKeys.indexOf(startKey)
    for (let i = blockIndex - 1; i >= 0; i--) {
      wordIndex += contentState
        .getBlockForKey(blockKeys[i])
        .getText()
        .split(" ")
        .filter((x) => x).length
    }
    const time = this.props.transcriptTimes[wordIndex]
    if (time != null) {
      return time
    } else {
      const timeGuess = guessTranscriptTimestamp(
        this.props.transcriptTimes,
        wordIndex,
      )
      if (timeGuess != null) {
        return timeGuess
      } else {
        return false
      }
    }
  }
  handleSpanClick(e) {
    const transcriptTimes = this.props.transcriptTimes
    if (e.metaKey && transcriptTimes && e.target.tagName === "SPAN") {
      const time = this.getTimeOfSelectedWord()
      if (time || time === 0) {
        this.props.setTime(time)
      } else {
        this.props.error("No timestamp available")
      }
    }
  }
  forceUpdateEditor() {
    const editorState = this.state.editorState
    const contentState = editorState.getCurrentContent()

    const newEditorStateInstance = EditorState.createWithContent(
      contentState,
      this.decorator,
    )

    const copyOfEditorState = EditorState.set(newEditorStateInstance, {
      selection: editorState.getSelection(),
      undoStack: editorState.getUndoStack(),
      redoStack: editorState.getRedoStack(),
      lastChangeType: editorState.getLastChangeType(),
    })
    this.setState({ editorState: copyOfEditorState })
  }
  updateLabels() {
    const labels = []
    document.querySelectorAll(`.DraftEditor-root [data-id]`).forEach((node) => {
      const top =
        node.getBoundingClientRect().top -
        this.editorWrap.current.getBoundingClientRect().top
      const id = node.dataset.id
      // Check uniqueness because will produce double labels otherwise when selecting multiple paragraphs.
      const isNotInLabels = !labels.map((x) => x.id).includes(id)
      if (isNotInLabels) labels.push({ id, top: Math.round(top) - 2 })
    })
    this.setState({ labels })
  }
  async quickTurnIntoFinding(labelId) {
    const contentState = this.state.editorState.getCurrentContent()
    let selectionStateUnmodified = this.state.editorState.getSelection()
    if (this.debug)
      console.log(1, {
        anchorKey: selectionStateUnmodified.getStartKey(),
        anchorOffset: selectionStateUnmodified.getStartOffset(),
        focusKey: selectionStateUnmodified.getEndKey(),
        focusOffset: selectionStateUnmodified.getEndOffset(),
        isBackward: false,
        selectedText: this.getSelectedText(
          contentState,
          selectionStateUnmodified,
        ),
      })
    // Move first char to encapsulate word
    const startBlockText = contentState
      .getBlockForKey(selectionStateUnmodified.getStartKey())
      .getText()
    const endBlockText = contentState
      .getBlockForKey(selectionStateUnmodified.getEndKey())
      .getText()
    let startOffset = selectionStateUnmodified.getStartOffset()
    let startChar = startBlockText.charAt(startOffset)
    while (startChar !== " " && startOffset > 0) {
      startOffset--
      startChar = startBlockText.charAt(startOffset)
    }
    if (startChar === " ") {
      startOffset++
      startChar = startBlockText.charAt(startOffset)
    }
    // Move last char to encapsulate word
    let endOffset = selectionStateUnmodified.getEndOffset()
    let endChar = endBlockText.charAt(endOffset)
    let endCharPrev = endBlockText.charAt(Math.max(0, endOffset - 1))
    if (endCharPrev === " ") {
      while (endCharPrev === " " && endOffset > 0) {
        endOffset--
        endCharPrev = endBlockText.charAt(endOffset)
      }
    }
    while (endChar !== " " && endOffset < endBlockText.length) {
      endOffset++
      endChar = endBlockText.charAt(endOffset)
    }
    const selectionState = new SelectionState({
      anchorKey: selectionStateUnmodified.getStartKey(),
      anchorOffset: startOffset,
      focusKey: selectionStateUnmodified.getEndKey(),
      focusOffset: endOffset,
      isBackward: false,
    })

    const id = firebase.firestore().collection("findings").doc().id
    const contentStateWithEntity = contentState.createEntity(
      "FINDING",
      "MUTABLE",
      {
        id,
      },
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState,
      entityKey,
    )
    const newEditorState = EditorState.set(this.state.editorState, {
      currentContent: contentStateWithLink,
    })

    const selectedText = this.getSelectedText(contentState, selectionState)
    if (this.debug)
      console.log(2, {
        anchorKey: selectionStateUnmodified.getStartKey(),
        anchorOffset: startOffset,
        focusKey: selectionStateUnmodified.getEndKey(),
        focusOffset: endOffset,
        isBackward: false,
        selectedText,
      })
    if (!selectedText || selectedText === " ")
      return this.props.error(
        "No text selected",
        "Select the text that you want to mark as finding first.",
      )

    if (labelId === "NEW") {
      let name, color
      const confirm = await this.props.openModal({
        title: "New label",
        desc: `Apply to "${selectedText}".`,
        icon: "label",
        action: "Add",
        child: (
          <Input
            hasBorder
            onChange={(x) => (name = x)}
            autoFocus
            placeholder="Name..."
            button={
              <ColorPicker onChange={(x) => (color = x)} noWeights hasAll />
            }
          />
        ),
      })
      if (!confirm || !name) return false
      labelId = getSemiUniqueKey() // Overwrite labelId of "NEW" with actual ID to use below.
      this.props.addToArray(
        "projects",
        this.props.match.params.projectId,
        "findingLabels",
        {
          id: labelId,
          name,
          color: color || null,
        },
      )
    }

    const time = this.getTimeOfSelectedWord()
    this.props.create("findings", {
      id,
      interviewId: this.props.match.params.interviewId,
      projectId: this.props.match.params.projectId,
      labelIds: labelId ? [labelId] : [],
      time: time || time === 0 ? time : null,
    })
    this.setState({ editorState: newEditorState }, () => {
      this.updateTranscript()
      this.forceUpdateEditor()
      window.setTimeout(() => {
        this.updateLabels()
      })
    })
  }
  updateTranscript(isText) {
    const raw = convertToRaw(this.state.editorState.getCurrentContent())
    const transcript = raw.blocks.map((x) => ({
      text: x.text,
      entityRanges: x.entityRanges,
    })) // transcript is an array of blocks
    const transcriptEntities = {}
    for (const key in raw.entityMap) {
      if (raw.entityMap.hasOwnProperty(key)) {
        const { type, data } = raw.entityMap[key]
        if (type === "FINDING") {
          transcriptEntities[key] = { type, id: data.id }
        }
      }
    }
    this.props
      .update("interviews", this.props.match.params.interviewId, {
        transcript,
        transcriptEntities,
      })
      .then(() => {
        if (isText) this.setState({ isSaved: true })
      })
      .catch((e) => {
        this.props.error("Transcript could not be saved", true)
      })
  }
  getTextNodesIn(node, includeWhitespaceNodes) {
    const textNodes = [],
      whitespace = /^\s*$/

    function getTextNodes(node) {
      if (node.nodeType === 3) {
        if (includeWhitespaceNodes || !whitespace.test(node.nodeValue)) {
          textNodes.push(node)
        }
      } else {
        for (let i = 0, len = node.childNodes.length; i < len; ++i) {
          getTextNodes(node.childNodes[i])
        }
      }
    }

    getTextNodes(node)
    return textNodes
  }
  render() {
    let {
      labels,
      findings,
      transcript,
      transcriptTimes,
      transcriptEntities,
      mediaSeconds,
      isFocus,
    } = this.props
    const hasLabels = labels && labels.length > 0
    const topValues = [...new Set(this.state.labels.map((x) => x.top))]
    const hasTranscript =
      this.state.editorState.getCurrentContent().getPlainText("\u0001").length >
      0

    let arrowTop
    if (this.editorWrap.current && transcriptTimes) {
      const transcriptTimesWithGuesses = []
      let i = 0
      for (const time of transcriptTimes) {
        if (time != null) {
          transcriptTimesWithGuesses.push(time)
        } else {
          transcriptTimesWithGuesses.push(
            guessTranscriptTimestamp(transcriptTimes, i),
          )
        }
        i++
      }
      const activeWordIndex = transcriptTimesWithGuesses.findIndex(
        (time) => time != null && time >= mediaSeconds,
      )
      if (activeWordIndex > -1) {
        let textNodes = this.getTextNodesIn(this.editorWrap.current)
        let wordElIndex = -1
        for (const textNode of textNodes) {
          const words = textNode.wholeText.split(" ").filter((x) => x)
          if (wordElIndex + words.length >= activeWordIndex) {
            const range = document.createRange()
            const wordsToSkip = words.slice(0, activeWordIndex - wordElIndex)
            range.setStart(textNode, wordsToSkip.join(" ").length)
            range.setEnd(textNode, words.join(" ").length)
            const rects = range.getClientRects()
            arrowTop =
              rects[0].y -
              this.editorWrap.current.getBoundingClientRect().top +
              4
            break
          } else {
            wordElIndex += words.length
          }
        }
      }
    }

    return (
      <>
        {this.state.isSaved != null && (
          <Status
            title={this.state.isSaved ? "Changes saved" : "Saving changes..."}
            isGreen={this.state.isSaved}
            className="absolute z-10 mt-12 py-3 px-2"
            style={{ right: "33%" }}
          />
        )}
        <div className="relative grid flex-1 grid-cols-3 items-start overflow-scroll">
          {arrowTop && (
            <motion.div
              layout="position"
              className="absolute"
              style={{
                top: arrowTop,
                left: isFocus ? 6 : 0,
                width: 0,
                height: 0,
                borderStyle: "solid",
                borderWidth: "5px 0 5px 6px",
                borderColor: "transparent transparent transparent #9CA3AF",
              }}
            />
          )}
          <ContextMenu
            title="Quick mark as..."
            menu={(selectedText, e) => {
              if (selectedText) {
                return (
                  <>
                    {hasLabels &&
                      labels.map((x) => (
                        <DropdownItem
                          color={x.color}
                          onClick={() => this.quickTurnIntoFinding(x.id)}
                          key={x.id}
                          value={x.id}
                        >
                          {x.name}
                        </DropdownItem>
                      ))}
                    {!hasLabels && (
                      <DropdownItem
                        onClick={() => this.quickTurnIntoFinding(null)}
                      >
                        Finding
                      </DropdownItem>
                    )}
                    <DropdownItem
                      onClick={() => this.quickTurnIntoFinding("NEW")}
                      borderTop
                      icon="add"
                    >
                      New label
                    </DropdownItem>
                  </>
                )
              } else {
                e.preventDefault()
              }
            }}
            className="relative col-span-2 h-full"
          >
            <div
              ref={this.editorWrap}
              className={`h-full ${
                this.state.hoverFindingId
                  ? "text-gray-500 dark:text-gray-400"
                  : ""
              }`}
            >
              {!hasTranscript && (
                <div className="absolute inset-0 flex items-center justify-center text-gray-500">
                  <State title="Type your transcript here..." icon="edit">
                    <p className="mx-auto max-w-sm px-4 text-center">
                      {!!this.props.mediaType
                        ? `Tip: you can play and pause ${
                            this.props.mediaType
                          } while transcribing by pressing ${formatShortcut(
                            "cmd-p",
                          )}. Press ${formatShortcut(
                            "cmd-ArrowDown",
                          )} to go back a few seconds, or ${formatShortcut(
                            "cmd-ArrowUp",
                          )} to go forward.`
                        : "Tip: drag the audio or video of your interview to this window to help you transcribe."}
                    </p>
                  </State>
                </div>
              )}
              <Editor
                blockStyleFn={() => "editor-block"}
                stripPastedStyles
                editorState={this.state.editorState}
                onBlur={() => this.syncEditor()}
                onChange={(editorState) => {
                  const currentContent =
                    this.state.editorState.getCurrentContent()
                  const newContent = editorState.getCurrentContent()
                  const isContentChange = currentContent !== newContent

                  if (isContentChange) {
                    this.setState({ isSaved: false })
                    this.updateTranscriptDebounced(true)
                  }

                  if (transcriptTimes && isContentChange) {
                    // Update transcript times if necessary.
                    const prevText = this.state.editorState
                      .getCurrentContent()
                      .getBlocksAsArray()
                      .map((x) => x.getText())
                      .join(" ")
                    const newText = editorState
                      .getCurrentContent()
                      .getBlocksAsArray()
                      .map((x) => x.getText())
                      .join(" ")

                    const isAdded = prevText.length < newText.length
                    const prevOffsetStart = this.state.editorState
                      .getSelection()
                      .getStartOffset()
                    const prevOffsetEnd = this.state.editorState
                      .getSelection()
                      .getEndOffset()
                    const newOffsetStart = editorState
                      .getSelection()
                      .getStartOffset()
                    const newOffsetEnd = editorState
                      .getSelection()
                      .getEndOffset()
                    const diff = isAdded
                      ? newText.substring(prevOffsetStart, newOffsetEnd)
                      : prevText.substring(newOffsetStart, prevOffsetEnd)

                    // Letter index in current paragraph
                    let letterIndex = isAdded ? prevOffsetStart : newOffsetStart
                    // Add letters in previous paragraphs
                    for (const block of editorState
                      .getCurrentContent()
                      .getBlocksAsArray()) {
                      if (
                        block.key === editorState.getSelection().getStartKey()
                      )
                        break
                      letterIndex += block.getText().length
                    }

                    if (this.debug)
                      console.log(
                        `${
                          isAdded ? "Added" : "Deleted"
                        } "${diff}" at letter index ${letterIndex}`,
                      )

                    let wordCount = Math.abs(
                      prevText.split(" ").filter((x) => x).length -
                        newText.split(" ").filter((x) => x).length,
                    )
                    let wordIndex = 0
                    const compareWith = isAdded ? newText : prevText

                    // Note: double spaces should not be counted. Therefore we loop each letter to not
                    // count double spaces as words, and check for !isDoubleSpace.
                    let indexCount = 0
                    for (let letter of compareWith) {
                      if (
                        letter === " " &&
                        indexCount > 0 &&
                        compareWith[indexCount - 1] !== " "
                      ) {
                        wordIndex++
                      }
                      indexCount++
                      if (indexCount >= letterIndex) break
                    }

                    if (this.debug)
                      console.log(
                        `%cTotal words: ${
                          wordIndex + 1
                        }, Word count: ${wordCount}, index: ${indexCount}`,
                        "color: gray",
                      )

                    const spaceBefore =
                      diff.startsWith(" ") ||
                      compareWith[letterIndex - 1] === " "
                    if (!spaceBefore) {
                      if (this.debug)
                        console.log(
                          "%cNo space before: index + 1",
                          "color: gray",
                        )
                      wordIndex++
                    }
                    if (diff.startsWith(" ")) {
                      if (this.debug)
                        console.log(
                          "%cSpace at start: index + 1",
                          "color: gray",
                        )
                      wordIndex++
                    }
                    wordCount = Math.max(0, wordCount)
                    const isDoubleSpace =
                      (compareWith[letterIndex - 1] === " " &&
                        diff.startsWith(" ")) ||
                      (diff.endsWith(" ") &&
                        compareWith[letterIndex + diff.length] === " ")

                    if (wordCount && !isDoubleSpace) {
                      if (this.debug)
                        console.log(
                          `${
                            isAdded ? "Add" : "Delete"
                          } ${wordCount} words at word index ${wordIndex}`,
                        )
                      let newTranscriptTimes = [...transcriptTimes]
                      if (isAdded) {
                        for (let i = 0; i < wordCount; i++) {
                          newTranscriptTimes.splice(wordIndex, 0, null)
                        }
                      } else {
                        newTranscriptTimes.splice(wordIndex, wordCount)
                      }
                      if (this.debug)
                        console.log(
                          "Prev:",
                          transcriptTimes.length,
                          transcriptTimes[transcriptTimes.length - 1],
                          [...transcriptTimes],
                        )
                      if (this.debug)
                        console.log(
                          "New:",
                          newTranscriptTimes.length,
                          newTranscriptTimes[newTranscriptTimes.length - 1],
                          [...newTranscriptTimes],
                        )
                      this.props.update(
                        "interviews",
                        this.props.match.params.interviewId,
                        { transcriptTimes: newTranscriptTimes },
                      )
                    }
                  }
                  this.setState({ editorState }, () => {
                    this.updateLabels()
                  })
                }}
              />
            </div>
          </ContextMenu>
          <div className="border-light relative h-full flex-none border-l px-6">
            {topValues.map((top) => (
              <div
                key={`top-${top}`}
                className="absolute flex space-x-1"
                style={{ top }}
              >
                {this.state.labels
                  .filter((x) => x.top === top)
                  .map((label) => {
                    // Note: label & this.state.labels refer to all labels retrieved from transcript entities.
                    // Each label is an object with id and top values. On the other hand, labels refers to
                    // project.findingLabels containing a name, id and color.
                    const finding = findings.find((x) => x.id === label.id)
                    if (!finding) return null
                    const existingLabelIds = finding.labelIds?.filter(
                      (labelId) =>
                        this.props.labels?.some((x) => x.id === labelId),
                    )
                    const singleLabel =
                      existingLabelIds &&
                      existingLabelIds.length === 1 &&
                      (labels || []).find((x) => x.id === existingLabelIds[0])
                    const isMultiple =
                      existingLabelIds && existingLabelIds.length > 1
                    return (
                      <div
                        key={`finding-${finding.id}`}
                        onMouseEnter={() =>
                          this.setState({ hoverFindingId: finding.id })
                        }
                        onMouseLeave={() =>
                          this.setState({ hoverFindingId: null })
                        }
                      >
                        <Dropdown
                          width={240}
                          onOpen={(isOpen) =>
                            isOpen
                              ? null
                              : this.setState({ hoverFindingId: null })
                          }
                          button={
                            <Label
                              icon={finding.isStarred ? "star" : ""}
                              color={(singleLabel || {}).color || "gray"}
                              isSmall
                            >
                              {(isMultiple &&
                                `${existingLabelIds.length} labels`) ||
                                (singleLabel || {}).name ||
                                (hasLabels ? "No label" : "Finding")}
                            </Label>
                          }
                        >
                          <h6 className="px-3 pt-2">Note</h6>
                          <Input
                            placeholder="Add note..."
                            autosize
                            className="mb-1 px-3 pb-2"
                            defaultValue={finding.text}
                            onBlur={(text) =>
                              this.props.update("findings", finding.id, {
                                text,
                              })
                            }
                          />
                          {hasLabels &&
                            labels.map((label) => (
                              <Checkbox
                                key={label.id}
                                isActive={(existingLabelIds || []).includes(
                                  label.id,
                                )}
                                color={label.color}
                                onChange={() => {
                                  this.props.toggleInArray(
                                    "findings",
                                    finding.id,
                                    "labelIds",
                                    label.id,
                                  )
                                  this.forceUpdateEditor()
                                }}
                              >
                                {label.name}
                              </Checkbox>
                            ))}
                          <DropdownItem
                            icon="add"
                            onClick={async () => {
                              const selectedText = getQuoteFromTranscript(
                                transcript,
                                transcriptEntities,
                                finding,
                              )
                              let name, color
                              const confirm = await this.props.openModal({
                                title: "New label",
                                desc:
                                  selectedText && `Apply to "${selectedText}".`,
                                icon: "label",
                                action: "Add",
                                child: (
                                  <Input
                                    hasBorder
                                    onChange={(x) => (name = x)}
                                    autoFocus
                                    placeholder="Name..."
                                    button={
                                      <ColorPicker
                                        onChange={(x) => (color = x)}
                                        noWeights
                                        hasAll
                                      />
                                    }
                                  />
                                ),
                              })
                              if (confirm && name) {
                                const id = getSemiUniqueKey()
                                this.props.addToArray(
                                  "projects",
                                  this.props.match.params.projectId,
                                  "findingLabels",
                                  {
                                    id,
                                    name,
                                    color: color || null,
                                  },
                                )
                                this.props.addToArray(
                                  "findings",
                                  finding.id,
                                  "labelIds",
                                  id,
                                )
                                this.forceUpdateEditor()
                              }
                            }}
                          >
                            New label
                          </DropdownItem>
                          <DropdownItem
                            borderTop
                            onClick={() => this.props.setTime(finding.time)}
                            icon="play"
                          >
                            Play
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.props.update("findings", finding.id, {
                                isStarred: !finding.isStarred,
                              })
                              this.forceUpdateEditor()
                            }}
                            iconFill={finding.isStarred}
                            iconColor={finding.isStarred ? "yellow-400" : null}
                            iconColorDark={
                              finding.isStarred ? "yellow-500" : null
                            }
                            icon="star"
                          >
                            {finding.isStarred ? "Unmark" : "Mark"} as key
                            finding
                          </DropdownItem>
                          <DropdownItem
                            icon="arrow-right"
                            to={`/projects/${this.props.match.params.projectId}/findings/${finding.id}`}
                          >
                            View in Findings
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              const interview = this.props.interviews.find(
                                (x) => x.id === finding.interviewId,
                              )
                              const updateObj =
                                getInterviewUpdateObjOnFindingDelete(
                                  interview,
                                  finding.id,
                                )
                              if (updateObj)
                                this.props.update(
                                  "interviews",
                                  interview.id,
                                  updateObj,
                                )
                              this.props.delete("findings", finding.id)
                              this.forceUpdateEditor()
                            }}
                            isRed
                            icon="delete"
                          >
                            Delete
                          </DropdownItem>
                        </Dropdown>
                      </div>
                    )
                  })}
                <div className="w-6 flex-none" />
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(
  withData(withNotify(ProjectInterviewFindingsTranscript)),
)
