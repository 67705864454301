import React from 'react';

class Progress extends React.Component {
    render() {
        const {percentage, size} = this.props;
        const strokeDashoffset = 100 - percentage;
        return (
            <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center relative">
                    <div style={{width: size, height: size}}>
                        <svg className='animate-spin text-gray-200 dark:text-gray-600' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
                            <circle className='stroke-current' strokeWidth={14} fill='none' cx='100' cy='100' r='90' />
                            <circle style={{strokeDashoffset, strokeDasharray: 100, strokeLinecap: 'round'}} strokeWidth={14} className={`stroke-current text-${window.appColor}`} fill='none' cx='100' cy='100' r='90' pathLength="100"/>
                        </svg>
                    </div>
                    <div className="absolute h4 pl-1">
                        {percentage}
                        <span className="text-gray-500 text-xs" style={{verticalAlign: 'super'}}>%</span>
                    </div>
                </div>
            </div>
        );
    }
}

Progress.defaultProps = {
    size: 60,
    percentage: 0,
}

export default Progress;