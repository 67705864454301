import React from 'react';
import PropTypes from 'prop-types';
import Shortcut from "./Shortcut";
import {format, parse, capitalize, toggleInArray, isEmail} from "../functions";
import InputDateDropdown from "./InputDateDropdown";
import InputTimeDropdown from "./InputTimeDropdown";
import InputDocumentDropdown from "./InputDocumentDropdown";
import Icon from "./Icon";
import autosize from "autosize";
import Toggle from "./Toggle";
import moment from 'moment';
import ColorPicker from "./ColorPicker";

const alwaysAllowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();

        this.defaults = {
            document: {
                parseInit: id => {
                    const option = (this.props.options || []).find(x => x.id === id);
                    return option ? option.name : '';
                },
                parseBlur: id => {
                    if(!id) return '';
                    const doc = (this.props.options || []).find(x => x.id === id);
                    if(!doc) return '';
                    return doc.name;
                },
            },
            currency: {
                before: '$',
                placeholder: (this.props.format || {}).round ? '0' : '0,00',
                parseToData: x => parse('currency', x),
                parseBlur: x => format('currency', x, this.props.format),
                parseInit: x => format('currency', x, this.props.format),
                parseOnFocus: x => x ? x.replace('.', '') : x,
                prevent: e => !/^[0-9]$/i.test(e.key) && e.key !== ',' && e.key !== '-' && !alwaysAllowedKeys.includes(e.key) && !e.metaKey,
            },
            date: {
                parseToData: x => parse('date', x),
                parseBlur: x => format('date', x, this.props.format),
                parseInit: x => format('date', x, this.props.format),
            },
            number: {
                parseToData: x => x ? parseFloat(parse('number', Math.max(Math.min(x.split(',').join('.'), this.props.max || 99999999), this.props.min || 0)).split(',').join('.')) : null,
                parseBlur: x => x ? format('number', Math.max(Math.min(x.split(',').join('.'), this.props.max || 99999999), this.props.min || 0)) : '',
                parseInit: x => format('number', x),
                parseOnFocus: x => x ? x.toString().replace('.', '') : x,
                prevent: e => !/^[0-9]$/i.test(e.key) && e.key !== ',' && !alwaysAllowedKeys.includes(e.key) && !e.metaKey,
            },
            time: {
                parseToData: x => parse('time', x),
                parseBlur: x => format('time', x, this.props.format),
                parseInit: x => format('time', x, this.props.format),
            },
            email: {
                parseToData: x => isEmail(x) ? x : null,
                parseBlur: x => isEmail(x) ? x : '',
            },
            array: {
                parseToData: x => x.split(';').join('[38H430J]').split(',').map(x => x.split('[38H430J]').join(',').trim()), // Do this thing to enable escaping , by using ;
                parseInit: x => x && x.map ? x.map(x => x.split(',').join(';')).join(', ') : x,
                placeholder: 'Seperate by comma (,)',
            },
            dropdown: {
                parseToData: x => x ? x : null, // No value should return as null instead of empty string
            }
        }
        this.default = this.defaults[this.props.type] || {}; // Type defaults to 'text'
        this.wrapperRef = React.createRef();
        // Set value to first value that is not null or undefined (note == instead of ===)
        let value = this.props.value;
        if(value == null) value = this.props.defaultValue;
        if(value == null) value = '';
        // Format value
        if(this.default.parseInit) value = this.default.parseInit(value) || '';
        this.state = {
            value,
            valueIds: this.props.defaultIds || [],
            hasFocus: false,
        };
    }
    componentDidMount() {
        const isTextArea = this.props.autosize || this.props.rows;
        if(isTextArea) {
            if(this.props.autosize) {
                autosize(this.input);
            }
            if(this.props.autoFocus && this.input && !this.props.readOnly) {
                // Use this instead of default autoFocus prop on textarea to set caret and end of text
                // (otherwise it would, inconsistently with <input>, set it at the start.
                this.input.focus();
                this.input.setSelectionRange(this.input.value.length, this.input.value.length);
            }
        }
        if(this.props.autoSelect && this.input && !this.props.readOnly) this.input.select();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const valueChanged = (prevProps.value !== this.props.value) || (prevProps.defaultValue !== this.props.defaultValue) || (JSON.stringify(prevProps.format) !== JSON.stringify(this.props.format));
        if(valueChanged) {
            let value = this.props.value;
            if(value == null) value = this.props.defaultValue;
            if(value == null) value = '';
            if(this.default.parseInit) value = this.default.parseInit(value);
            if(!value) value = '';
            if(value !== this.state.value) this.setState({value}, () => {
                if(this.props.autosize) autosize.update(this.input);
            });
        }

        const valueIdsChanged = JSON.stringify(prevProps.defaultIds) !== JSON.stringify(this.props.defaultIds);
        if(valueIdsChanged) {
            this.setState({valueIds: this.props.defaultIds || []});
        }
    }
    focus() {
        if(this.input && this.input.focus) this.input.focus();
    }
    select() {
        if(this.input && this.input.select) this.input.select();
    }
    handleFocus() {
        if(this.props.readOnly) return false;
        let value = this.default.parseOnFocus ? this.default.parseOnFocus(this.state.value) : this.state.value;
        if(this.props.valueOnFocus && !value) value = this.props.valueOnFocus;
        if(!value) value = '';
        this.setState({value, hasFocus: true}, () => {
            if(this.props.autosize) {
                autosize.update(this.input);
            }
        });
    }
    handleChange(e) {
        if(this.props.readOnly) return false;
        const value = e.target ? e.target.value : e;
        this.setState({value}, () => {
            if(this.props.autosize) {
                autosize.update(this.input);
            }
        });
        if(this.props.onChange) this.props.onChange(this.default.parseToData ? this.default.parseToData(value) : value); // onChange returns direct value, not formatted!
    }
    handleKeyDown(e) {
        if(this.props.readOnly) return false;
        const isDocumentMultiple = this.props.type === 'document' && this.props.multiple;
        if(this.default.prevent && this.default.prevent(e)) {
            e.preventDefault();
        }
        if(this.props.onEnter && e.key === 'Enter') this.props.onEnter(this.state.value, e);
        if(this.props.onEscape && e.key === 'Escape') this.props.onEscape(this.state.value, e);
        if(this.props.onTab && e.key === 'Tab' && !e.shiftKey) this.props.onTab(this.state.value, e);
        if(this.props.onKeyDown) this.props.onKeyDown(this.state.value, e);
        if(isDocumentMultiple && e.key === 'Backspace' && this.state.value === '' && this.state.valueIds.length) {
            const valueIds = [...this.state.valueIds];
            valueIds.pop();
            this.setState({valueIds});
        }
    }
    handleBlur(e, isTab = false, forceBlur = false) {
        if(this.props.readOnly) return this.setState({hasFocus: false});
        const isDocumentMultiple = this.props.type === 'document' && this.props.multiple;
        // Parse & update value
        let value = e && e.target ? e.target.value : e;
        if(this.props.type === 'date' && !moment(value, 'X').isValid()) value = '';
        if(['date', 'document'].includes(this.props.type) && !isTab) return false;
        if(this.props.type === 'time' && this.state.value && !isTab) return false;
        let valueDisplay = this.default.parseBlur ? this.default.parseBlur(value) : value;
        if(!valueDisplay) valueDisplay = '';
        if(isDocumentMultiple) {
            valueDisplay = ''; // Clear value as input will hide
            if(this.props.onBlur) {
                const x = this.props.onBlur(this.state.valueIds, e);
                if(x === false) return; // Do not update state in this case.
            }
        } else {
            const valueData = this.default.parseToData ? this.default.parseToData(value) : value;
            if(this.props.onBlur) {
                const x = this.props.onBlur(valueData, e);
                if(x === false) return; // Do not update state in this case.
            }
        }
        this.setState({value: valueDisplay, hasFocus: false}, () => {
            // Pass through
            if(isDocumentMultiple) {
                if(forceBlur) document.activeElement.blur();
            } else {
                if(forceBlur) document.activeElement.blur();
            }
        });
    }
    render() {
        let before = (this.props.before === undefined ? this.default.before : this.props.before) || null;
        const icon = (this.props.icon === undefined ? this.default.icon : this.props.icon) || null;
        if(icon) before = <Icon icon={icon} size={14} />;
        const after = (this.props.after === undefined ? this.default.after : this.props.after) || null;
        const placeholder = this.props.placeholder || this.default.placeholder || null;
        const isTabularFont = this.props.isTabularFont === undefined ? ['number', 'currency', 'date', 'time'].includes(this.props.type) : this.props.isTabularFont;

        const flex = this.props.beforeWidth ? (this.props.beforeWidth === 1 ? 1 : `0 0 ${this.props.beforeWidth}px`) : null;
        const isTextarea = this.props.rows || this.props.autosize;
        const isDocumentMultiple = this.props.type === 'document' && this.props.multiple;
        const className = `${this.props.noStyling ? this.props.className : `${this.props.hasBorder ? 'border rounded-lg' : 'border-b'} relative ${this.state.hasFocus ? `border-${window.appColor}` : ''}`} ${isTextarea || isDocumentMultiple ? '' : 'h-10'} ${flex === 1 && !after ? 'pr-2' : ''} flex items-start cursor-text ${this.props.classNameFocus && this.state.hasFocus ? this.props.classNameFocus : ''} ${this.props.className || ''}`;
        const hasBorder = this.props.hasBorder || (this.props.noStyling);
        const hasPadding = this.props.noPadding ? false : ((this.props.hasBorder && !this.props.isGroup) || (this.props.isGroup && !this.props.before));

        let classNameInput = `${this.props.disabled ? 'opacity-70' : ''} ${this.props.hasBorder && !this.props.button && !after && !before ? `focus:ring-1 ring-${window.appColor || 'indigo-500'}` : ''} h-full focus:appearance-none ${this.props.align ? 'text-' + this.props.align : ''} outline-none ${isTextarea ? 'leading-normal' : ''} resize-none bg-transparent rounded-lg ${this.props.beforeWidth === 1 ? '' : 'w-full'} ${isTextarea ? 'h-full overflow-scroll' : 'tr h-10'} pb-px ${hasPadding ? 'px-3' : ''} ${this.props.classNameInput || ''}`;
        if(!this.props.before && !this.props.after) classNameInput += ' rounded-lg overflow-hidden';
        if(this.props.noInputStyling) classNameInput = `${this.props.disabled ? 'opacity-70' : ''} outline-none resize-none w-full ${this.props.align ? 'text-' + this.props.align : ''} ${this.props.classNameInput}`;

        const classNameBefore = `${this.props.noSidePadding || !hasBorder ? '' : 'pl-3'} ${hasPadding ? '' : 'mr-3'} -pt-px py-3 ${this.props.isBeforeGray ? 'text-gray-500' : ''} flex items-center whitespace-nowrap select-none rounded-lg`;
        const classNameAfter = `${this.props.noSidePadding || !hasBorder ? 'pr-2' : 'pr-3'} -pt-px py-3 text-gray-500 flex items-center whitespace-nowrap select-none rounded-lg`; // Keep pr-2 with props.noSidePadding, looks weird otherwise combined with buttons

        const option = this.props.value && this.props.options ? (this.props.options || []).find(x => x.id === this.props.value) : false;
        const dropdownOption = this.state.value && this.props.options ? (this.props.options || []).find(x => x.key === this.state.value) : false;

        if(this.props.type === 'dropdown') {
            return (
                <div ref={this.wrapperRef} className={`relative w-full flex items-center ${this.props.disabled || this.props.readOnly ? 'pointer-events-none' : ''} ${this.props.className || ''}`}>
                    {before ? <div className={`${this.props.noSidePadding ? '' : 'pl-3'} pointer-events-none absolute text-gray-500 flex items-center whitespace-nowrap select-none`} style={{flex, ...this.props.style}}>{before}</div> : null}
                    {dropdownOption && dropdownOption.icon ? <div onClick={this.focus.bind(this)} className={classNameBefore} style={{flex}}><Icon icon={dropdownOption.icon} className='mt-px' size={12} /></div> : null}
                    <select ref={this.input} value={this.state.value} onChange={this.handleBlur.bind(this)} id={this.props.id} className={`${this.props.noStyling || !this.props.hasBorder ? '' : 'border rounded-lg'} ${hasPadding || (this.props.isGroup && before && !this.props.noPadding) ? 'px-3' : ''} ${this.props.hasBorder ? '' : (this.props.noStyling ? '' : 'border-b')} w-full ${!this.state.value ? 'text-gray-300 dark:text-gray-500' : ''} leading-normal outline-none items-center ${this.props.before ? 'mx-3' : ''} h-10 ${this.props.disabled ? 'opacity-70 pointer-events-none' : ''}`} style={{marginLeft: before ? (this.props.beforeWidth) : 0}}>
                        {this.props.canClear ? <option key='clear' value='' /> : null}
                        {(this.props.options || []).filter(x => x).map(x => typeof x === 'object' ? <option disabled={x.disabled} key={x.key} value={x.key}>{capitalize(x.name || x.key)}</option> : <option key={x} value={x}>{capitalize(x)}</option>)}
                    </select>
                    <div className={`${this.props.disabled || this.props.readOnly ? 'opacity-0' : ''} w-6 ${this.props.noSidePadding || !hasBorder ? '' : 'mr-1'} mt-1 absolute pointer-events-none`} style={{top: 8, right: 1}}>
                        <Icon icon='chevron-down' size={12} />
                    </div>
                </div>
            );
        }

        return (
            <div ref={this.wrapperRef} id={this.props.id} onSubmit={e => e.preventDefault()} className={className} style={{minHeight: isTextarea ? '2.5rem' : null, ...this.props.style}}>
                {before ? <div onClick={this.focus.bind(this)} className={classNameBefore} style={{flex}}>{before}</div> : null}
                {option && option.icon ? <div onClick={this.focus.bind(this)} className={classNameBefore} style={{flex}}><Icon icon={option.icon} className='mt-px' size={12} /></div> : null}
                {this.props.rows || this.props.autosize ? (
                    <textarea
                        readOnly={this.props.readOnly}
                        className={classNameInput}
                        disabled={this.props.disabled}
                        id={this.props.id}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        onFocus={this.handleFocus.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        onChange={this.handleChange.bind(this)}
                        ref={x => this.input = x}
                        rows={this.props.rows || 1}
                        spellCheck={false}
                        style={this.props.noInputStyling ? null : {paddingTop: 7, paddingBottom: 7}}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        value={this.state.value}
                    />
                ) : (
                    this.props.type === 'boolean' ? (
                        <Toggle className={`${classNameInput} -mt-px`} disabled={this.props.disabled} readOnly={this.props.readOnly} defaultValue={this.props.defaultValue} value={this.props.value} onChange={val => {this.handleChange(val); this.handleBlur(val)}} />
                    ) : (this.props.type === 'color' ? (
                        <ColorPicker
                            hasAll={this.props.hasAll}
                            arrowPosition={this.props.arrowPosition}
                            className={this.props.classNameInput}
                            value={this.state.value}
                            noWeights={this.props.noWeights}
                            hasGrayscale={this.props.hasGrayscale}
                            positionTop={this.props.positionTop}
                            canClear={this.props.canClear}
                            onChange={this.handleBlur.bind(this)}
                        />
                    ) : (isDocumentMultiple ? (
                        <div className={`${this.state.valueIds.length > 12 ? 'h-24 overflow-scroll' : ''} w-full flex justify-start items-center flex-wrap cursor-text doc-multiple py-1`} onClick={e => {if(e.target.classList.contains('doc-multiple')) {this.setState({hasFocus: true}); this.input.focus()}}}>
                            {this.state.valueIds.map(id => {
                                const option = (this.props.options || []).find(x => x.id === id);
                                return (
                                    <div key={option.id} className={`h-8 flex items-center mr-1 ${this.props.readOnly ? '' : 'select-none'} doc-multiple`}>
                                        <div className='border whitespace-nowrap flex items-center rounded'>
                                            <div title={option.title || (option.desc && option.sub ? `${option.desc} (${option.sub})` : option.desc || option.sub)} className={`${this.props.disabled || this.props.readOnly ? 'px-2' : 'pl-2 cursor-default'} py-1`}>{option.name || 'Unnamed'}</div>
                                            {this.props.disabled || this.props.readOnly ? null : (
                                                <div className='cursor-pointer px-2 py-1' onClick={() => {
                                                    const valueIds = toggleInArray(id, this.state.valueIds, false);
                                                    this.setState({valueIds});
                                                    if(this.props.onBlur) this.props.onBlur(valueIds);
                                                }}>
                                                    <Icon icon='remove' size={9} color='gray-500' />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                            <input
                                readOnly={this.props.readOnly}
                                spellCheck={false}
                                autoComplete='new-field'
                                className={`appearance-none bg-transparent flex-1 outline-none pb-px`}
                                type='text'
                                name={this.props.name}
                                ref={x => this.input = x}
                                disabled={this.props.disabled}
                                style={{minHeight: '2rem'}}
                                onKeyDown={this.handleKeyDown.bind(this)}
                                onFocus={this.handleFocus.bind(this)}
                                onBlur={this.handleBlur.bind(this)}
                                size="2"
                                required={this.props.required}
                                placeholder={placeholder}
                                onChange={this.handleChange.bind(this)}
                                value={this.state.value}
                            />
                            {this.state.hasFocus || this.state.valueIds.length === 0 ? (
                                <span />
                            ) : null}
                        </div>
                    ) : (
                        <input
                            readOnly={this.props.readOnly}
                            spellCheck={false}
                            autoFocus={this.props.autoFocus}
                            autoComplete='new-field'
                            className={classNameInput}
                            name={this.props.name}
                            type={['email', 'password'].includes(this.props.type) ? this.props.type : 'text'}
                            ref={x => this.input = x}
                            disabled={this.props.disabled}
                            pattern={['number', 'currency'].includes(this.props.type) ? "[0-9]*" : null}
                            style={{fontVariantNumeric: isTabularFont ? 'tabular-nums' : null}}
                            onKeyDown={this.handleKeyDown.bind(this)}
                            onFocus={this.handleFocus.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            required={this.props.required}
                            placeholder={placeholder}
                            onChange={this.handleChange.bind(this)}
                            value={this.state.value}
                        />
                    )
                )))}
                {after ? <div onClick={this.focus.bind(this)} className={classNameAfter}>{after}</div> : null}
                {this.props.button ? <div className={`flex items-center pl-1 ${this.props.noSidePadding ? '' : 'pr-2'} self-center`}>
                    {this.props.button}
                </div> : null}
                {!this.props.readOnly && this.state.hasFocus && this.props.type === 'date' ? (
                    <InputDateDropdown arrowPosition={this.props.arrowPosition} offset={this.props.beforeWidth} positionTop={this.props.positionTop} onBlur={(date, forceBlur) => this.handleBlur(date, true, forceBlur)} onClose={() => this.handleBlur(this.state.value, true, true)} value={this.state.value} />
                ) : null}
                {!this.props.readOnly && this.state.hasFocus && this.props.type === 'document' ? (
                    <InputDocumentDropdown valueIds={this.state.valueIds} multiple={this.props.multiple} arrowPosition={this.props.arrowPosition} offset={this.props.beforeWidth} positionTop={this.props.positionTop} options={this.props.options} onBlur={(id, forceBlur) => this.handleBlur(id, true, forceBlur)} onSelect={id => {
                        const valueIds = toggleInArray(id, this.state.valueIds, true);
                        this.setState({valueIds, value: ''});
                    }} onClose={() => this.handleBlur(this.state.value, true, true)} value={this.state.value} />
                ) : null}
                {!this.props.readOnly && this.state.value && this.state.hasFocus && this.props.type === 'time' ? (
                    <InputTimeDropdown arrowPosition={this.props.arrowPosition} offset={this.props.beforeWidth} positionTop={this.props.positionTop} onBlur={(time, forceBlur) => this.handleBlur(time, true, forceBlur)} onClose={() => this.handleBlur(this.state.value, true, true)} value={this.state.value} />
                ) : null}
                {this.props.shortcut ? <Shortcut press={this.props.shortcut} onPress={() => this.select()} /> : null}
            </div>
        );
    }
}

Input.propTypes = {
    after: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoSelect: PropTypes.bool,
    autosize: PropTypes.bool,
    before: PropTypes.string,
    beforeWidth: PropTypes.number,
    className: PropTypes.string,
    classNameInput: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    isTabularFont: PropTypes.bool,
    noStyling: PropTypes.bool,
    noInputStyling: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onEnter: PropTypes.func,
    onEscape: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.any,
    round: PropTypes.bool,
    shortcut: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
    value: PropTypes.any,
};

Input.defaultProps = {
    canClear: true,
    isBeforeGray: true,
};

export default Input;