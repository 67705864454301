import React from 'react';
import Button from "../Button";
import Page from "../Page";
import {withData} from "../../data";
import Input from "../Input";
import Step from "../Step";
import Steps from "../Steps";
import InputGroup from "../InputGroup";
import Sheet from "../Sheet";
import {capitalize, formatData, getSemiUniqueKey, isEmail, parse} from "../../functions";
import Dropdown from "../Dropdown";
import Radio from "../Radio";
import RadioItem from "../RadioItem";
import Table from "../Table/Table";
import moment from 'moment';
import {withNotify} from "../../notify";
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

class PopoverImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 'raw',
            raw: '',
            separator: '	',
            collection: this.props.collection || '',
            fields: [],
            preview: [],
            skipFirstLine: true,
            isImporting: false,
            isParsingOptionsShowing: false,
        };
    }
    setFields(goToStep = false) {
        const raw = this.state.raw;
        // Get first line with most fields
        const lines = raw.split('\n');
        if(this.state.skipFirstLine) lines.shift();
        let previewFields = [];
        for(const line of lines) {
            if(!line.replace(/\s/g, '').length) continue; // line contains only whitespace
            const fields = line.split(this.state.separator).filter(x => x);
            if(fields.length > previewFields.length) {
                previewFields = fields;
            }
        }
        const fields = previewFields.map(x => ({value: x.trim(), id: getSemiUniqueKey()}));
        const obj = {fields};
        if(goToStep) {
            obj.step = 'fields';
            // Set separator. If all are likely, assume tab.
            if(raw.split('	').length > 10) obj.separator = '	';
            else if(raw.split(';').length > 10) obj.separator = ';';
            else if(raw.split(',').length > 10) obj.separator = ',';
        }
        this.setState(obj);
    }
    setLinks(fields) {
        const newFields = [];
        for(const field of fields) {
            let newField = {...field};
            if(newField.linkTo) {
                const fieldObj = this.getFieldLinks(fields).find(x => x.key === newField.linkTo);
                let val = newField.value;
                if(fieldObj.type === 'date') val = parse('date', val, newField.parseFrom);
                if(fieldObj.type === 'time') val = parse('time', val);
                if(fieldObj.type === 'email') val = isEmail(val) ? val : '—';
                newField.preview = formatData({[newField.linkTo]: val}, fieldObj, false).val || '—';
            } else if(newField.preview) {
                delete newField.preview;
            }
            newFields.push(newField);
        }
        this.setState({fields: newFields});
    }
    getFieldLinks(fields = this.state.fields, isPreview) {
        const fieldLinks = [];
        if(this.props.collectionFields[this.state.collection]) {
            for(const f of this.props.collectionFields[this.state.collection]) {
                if(!f) continue; // Skip null values for hidden fields
                if(f.type === 'date') {
                    const hasSeparateTime = (fields || []).some(x => x && x.linkTo === `_${f.key}_time`);
                    fieldLinks.push({...f, format: {includeTime: isPreview || !hasSeparateTime}});
                    fieldLinks.push({key: `_${f.key}_time`, name: `${f.name || capitalize(f.key)} (time)`, type: 'time'});
                } else {
                    fieldLinks.push(f);
                }
            }
        }
        return fieldLinks;
    }
    setPreview(goToStep = false) {
        const raw = this.state.raw;
        // Get first line with most fields
        const lines = raw.split('\n');
        if(this.state.skipFirstLine) lines.shift();
        let preview = [];
        for(const line of lines) {
            if(!line || !line.replace(/\s/g, '').length) continue; // Check if line contains only whitespace
            const dataItem = {id: getSemiUniqueKey()};
            const fields = line.split(this.state.separator).filter(x => x);
            let i = -1;
            for(const field of fields) {
                i++;
                let val = field;
                // field is string with value "Chemelin"
                const {linkTo, parseFrom} = this.state.fields[i];
                if(!linkTo) continue;
                const fieldObj = this.getFieldLinks().find(x => x.key === linkTo);
                val = parse(fieldObj.type, field, parseFrom);
                if(fieldObj.type === 'email') val = isEmail(val) ? val : '—';
                if(val && fieldObj.type === 'date') {
                    const separateTime = (this.state.fields || []).find(x => x && x.linkTo === `_${fieldObj.key}_time`);
                    if(separateTime && separateTime.value) {
                        // Since time is a separate field before, but not in preview, we need to combine them here.
                        val = moment(val, 'X').hours(separateTime.value.split(':')[0]).minutes(separateTime.value.split(':')[1]).startOf('minute').unix();
                    }
                }
                if(val && fieldObj.type === 'dropdown') {
                    const options = fieldObj.options || [];
                    const index = typeof fieldObj.options[0] === 'object' ? (options.findIndex(x => x.key === val) || options.findIndex(x => x.key === val.toLowerCase())) : (options.indexOf(val) === -1 ? options.indexOf(val.toLowerCase()) : options.indexOf(val));
                    const option = index > -1 ? options[index] : null;
                    val = option && typeof option === 'object' ? (option.name || option.key) : option;
                }
                if(val) dataItem[linkTo] = val;
            }
            preview.push(dataItem);
        }
        const obj = {preview};
        if(goToStep) obj.step = 'preview';
        this.setState(obj);
    }
    async finalize() {
        this.setState({isImporting: true});
        const arr = [];
        for(const item of this.state.preview) {
            delete item.id;
            delete item['_date_time'];
            const newItem = {
                ...item,
                ...((this.props.addToEach || {})[this.state.collection] || {}),
            };
            arr.push(newItem);
        }
        try {
            debugger;
            await this.props.createMultiple(this.state.collection, arr);
            this.props.history.push(this.props.backTo);
            this.props.success(`${this.state.preview.length} ${this.state.collection} imported`);
        } catch(e) {
            this.props.error(`Could not import ${this.state.collection}`, e);
            this.setState({isImporting: false});
        }
    }
    render() {
        const activeStep = this.state.step === 'raw' ? 1 : (this.state.step === 'fields' ? 2 : 3);
        const chosenKeys = this.state.collection ? this.state.fields.filter(x => x.linkTo).map(x => {
            const fieldObj = this.getFieldLinks().find(y => y.key === x.linkTo);
            return fieldObj.key;
        }) : [];
        const firstWords = this.state.raw?.split("\n")[0]?.split(' ')?.slice(0,5)?.join(' ');
        const fields = [
            {key: 'separator', before: 'Separate by', type: 'dropdown', canClear: false, options: [
                {key: '	', name: 'Tab (⇥)'},
                {key: ',', name: 'Comma (,)'},
                {key: ';', name: 'Semicolon (;)'},
            ]},
            {key: 'skipFirstLine', before: 'First row is header', type: 'boolean', button: <Dropdown arrowPosition='right' isInfo width={220} button={<Button icon='help' isSmall />}>
                    <h6 className='mb-3'>About the header row</h6>
                    <p className='text-sm mb-3'>Usually, the first row in a spreadsheet or CSV file contains the titles of the columns. If it is, this box needs to be checked to have it separated from the actual data.</p>
                    {firstWords && <p className='text-sm'>In your case, the first row you provided starts with "{firstWords}".</p>}
                </Dropdown>},
        ];
        const fieldLinks = this.getFieldLinks();
        if(!this.props.collection) fields.push({key: 'collection', type: 'dropdown', options: Object.keys(this.props.collectionFields)});

        return (
            <Page
                isPopup
                title={`Import ${this.props.collection}`}
                width='4xl'
                icon='upload'
                height={700}
                hasPadding
                backTo={this.props.backTo}
                footerGrid={2}
                footer={<>
                    {this.state.step === 'raw' ? <><div /><Button onClick={() => {this.setFields(true)}} primary disabled={!this.state.raw}>Next &rarr;</Button></> : null}
                    {this.state.step === 'fields' ? <><Button onClick={() => this.setState({step: 'raw'})} secondary>&larr; Back</Button><Button disabled={!this.state.collection || !chosenKeys.length} onClick={() => this.setPreview(true)} primary>Next &rarr;</Button></> : null}
                    {this.state.step === 'preview' ? <><Button disabled={this.state.isImporting} onClick={() => this.setState({step: 'fields'})} secondary>&larr; Back</Button><Button onClick={() => this.finalize()} isLoading={this.state.isImporting} primary>Import {this.state.preview.length} {this.state.collection}</Button></> : null}
                </>}>

                <div className='h-full flex flex-col'>
                    <Steps activeStep={activeStep} className='mb-5'>
                        <Step>Paste data</Step>
                        <Step>Link fields</Step>
                        <Step>Preview</Step>
                    </Steps>

                    {this.state.step === 'raw' ? <>
                        <Input value={this.state.raw} hasBorder rows={1} className='flex-1' classNameInput='h-full leading-relaxed' placeholder={this.props.placeholder || `Paste your ${this.props.collection || 'data'} from a spreadsheet or CSV...`} autoFocus onChange={raw => this.setState({raw})} />
                    </> : null}

                    {this.state.step === 'fields' ? (
                        <>
                            {this.state.collection ? (
                                <Sheet
                                    canSort={false}
                                    data={this.state.fields}
                                    onUpdate={fields => this.setLinks(fields)}
                                    buttons={(dataItem, rowIndex) => {
                                        const fieldObj = dataItem.linkTo ? this.getFieldLinks().find(x => x.key === dataItem.linkTo) : false;
                                        if(fieldObj.type === 'dropdown') {
                                            return <Dropdown arrowPosition='right' isInfo width={210} button={<Button isSmall icon='help' />}>
                                                <h6>Help</h6>
                                                <p className='sub mt-3 mb-3'>This field must be one of these values to be imported:</p>
                                                {(fieldObj.options || []).map((option, i) => (
                                                    <p className='text-sm' key={i}>• {capitalize(option && typeof option === 'object' ? (option.name || option.key) : option)}</p>
                                                ))}
                                            </Dropdown>
                                        }
                                        if(fieldObj.type === 'email' && !isEmail(dataItem.value)) {
                                            return <Dropdown arrowPosition='right' isInfo width={200} button={<Button isSmall icon='warning' />}>
                                                <h6>Warning</h6>
                                                <p className='text-sm mt-3'>The email address is invalid.</p>
                                            </Dropdown>
                                        }
                                        if(fieldObj.type === 'labels') {
                                            return <Dropdown arrowPosition='right' isInfo width={200} button={<Button isSmall icon='help' />}>
                                                <h6>Help</h6>
                                                <p className='text-sm mt-3'>The value of this field will be converted into a new label. If a label with this value already exists, it will be linked to this item.</p>
                                            </Dropdown>
                                        }
                                        if(fieldObj.type === 'date') {
                                            return <Dropdown approxHeight={320} arrowPosition='right' width={240} button={<Button isSmall icon='more' />}>
                                                <h6 className='pf'>Date format</h6>
                                                <Radio value={dataItem.parseFrom || ''} onChange={parseFrom => {
                                                    const fields = [...this.state.fields];
                                                    fields[rowIndex].parseFrom = parseFrom === '' ? ['', 'D MMM'] : '';
                                                    this.setLinks(fields);
                                                }}>
                                                    <RadioItem value=''>Guess</RadioItem>
                                                    <RadioItem value='DD-MM-YYYY'>DD-MM-YYYY</RadioItem>
                                                    <RadioItem value='MM-DD-YYYY'>MM-DD-YYYY</RadioItem>
                                                    <RadioItem value='YYYY-MM-DD'>YYYY-MM-DD</RadioItem>
                                                </Radio>
                                                <h6 className='p'>Custom</h6>
                                                <div className='p'><Input placeholder='Type notation...' value={dataItem.parseFrom || ''} onChange={parseFrom => {
                                                    const fields = [...this.state.fields];
                                                    fields[rowIndex].parseFrom = parseFrom;
                                                    this.setLinks(fields);
                                                }} hasBorder /></div>
                                                <p className='sub mt-2 p pb-1'>If your date is not parsed correctly, you can specify an explicit format here. <a href='https://momentjs.com/docs/#/displaying/' className='link' target='_blank' rel='noopener noreferrer'>Click here</a> for the notation.</p>
                                            </Dropdown>
                                        }
                                        return null;
                                    }}
                                    fields={[
                                        {key: 'value', disabled: true, name: 'Your data (first line)'},
                                        {key: 'linkTo', name: 'Link to field', canClear: false, type: 'dropdown', options: [{key: '', name: "Don't import"}, ...fieldLinks.filter(x => x && x.type !== 'labels' && !x.calc).map(x => ({...x, disabled: x.disabled || chosenKeys.includes(x.key)}))]},
                                        {key: 'preview', disabled: true, name: 'Will be imported as', placeholder: 'Not imported'},
                                    ]}
                                />
                            ) : null}
                            {this.state.isParsingOptionsShowing ? <>
                                <div className='flex mt-8 mb-2.5'>
                                    <h6>Parsing options</h6>
                                    <div className='sub ml-auto'>If the fields above looks good, you don't need to change this.</div>
                                </div>
                                <InputGroup
                                    hasBorder
                                    doc={this.state}
                                    onBlur={data => {this.setState(data, () => this.setFields())}}
                                    beforeWidth={150}
                                    fields={fields}
                                />
                            </> : <p className='sub mt-4 self-start'>Link each field that you want to import in the second column. Does something <span className='link' onClick={() => this.setState({isParsingOptionsShowing: true})}>look wrong</span>?</p>}
                        </>
                    ) : null}

                    {this.state.step === 'preview' ? (
                        <Table
                            canSort={false}
                            noPaddingBottom
                            className='-mx-10 border-b border-light'
                            style={{maxHeight: 'calc(100% - 70px)'}}
                            singular={this.state.collection}
                            plural={this.state.collection}
                            collection={this.state.collection}
                            isNumbered
                            fields={this.getFieldLinks(this.state.fields, true).filter(x => x && chosenKeys.includes(x.key) && !x.key.startsWith('_'))}
                            data={this.state.preview}
                        />
                    ) : null}
                </div>

            </Page>
        );
    }
}

PopoverImport.propTypes = {
    backTo: PropTypes.string.isRequired,
    collectionFields: PropTypes.object.isRequired,
    placeholder: PropTypes.object,
};

export default withData(withNotify(withRouter(PopoverImport)));