import React from "react"
import {
  Audio,
  Video,
  Shortcut,
  Button,
  Page,
  secondsToTime,
  withData,
  withNotify,
} from "../shared"
import ProjectInterviewFindings from "./ProjectInterviewFindings"
import moment from "moment"
import { motion } from "framer-motion"
import demoPeaks from "../assets/demo-peaks.json"
import { functions } from "../index"

class ProjectInterviewFocus extends React.Component {
  constructor(props) {
    super(props)
    this.startTime = moment().subtract(1, "second")
    const locState = this.props.location.state || {}
    this.state = {
      isSidebar: true,
      mediaSeconds: null,
      isMediaPlaying: locState.isMediaPlaying,
      currentTime: 0,
    }
    this.video = React.createRef()
    this.audio = React.createRef()
  }
  componentDidMount() {
    if (this.props.isDemo && this.video.current && this.state.isMediaShowing) {
      this.video.current.currentTime =
        this.props.session[
          `interview-${this.props.match.params.interviewId}-time`
        ]
    }
  }
  componentWillUnmount() {
    if (this.audio.current) {
      this.props.setSession({
        [`interview-${this.props.match.params.interviewId}-time`]:
          this.audio.current.getCurrentTime(),
      })
      this.audio.current.destroy()
    }
    if (this.video.current)
      this.props.setSession({
        [`interview-${this.props.match.params.interviewId}-time`]:
          this.video.current.currentTime,
      })
  }
  toggle(mediaType, forcePlay) {
    if (mediaType === "audio") {
      const isMediaPlaying =
        forcePlay == null ? !this.state.isMediaPlaying : forcePlay
      if (this.audio.current) {
        if (isMediaPlaying) {
          this.audio.current.play()
        } else {
          this.audio.current.pause()
          this.props.setSession({
            [`interview-${this.props.match.params.interviewId}-time`]:
              this.audio.current.getCurrentTime(),
          })
        }
      }
      if (!(forcePlay !== false)) this.audio.current = null
      this.setState({ isMediaPlaying, isMediaShowing: forcePlay !== false })
    } else if (mediaType === "video") {
      this.video.current.toggle()
    }
  }
  setTime(time, type) {
    if (type === "audio" && this.audio.current) {
      this.audio.current.setTime(time, true)
    } else if (type === "video") {
      if (this.video.current) {
        if (this.props.isDemo) {
          this.video.current.currentTime = time
          this.video.current.play()
        } else {
          this.video.current.setTime(time, true)
        }
      } else {
        // Open video and set time in memory, so that it can seek when loaded.
        this.props.setSession({
          [`interview-${this.props.match.params.interviewId}-time`]: time,
        })
        this.toggle("video")
      }
    }
  }
  render() {
    const interview = this.props.interview
    return (
      <Page
        isFullScreen
        hasDone
        isFlexCol
        title={interview.name || "Unnamed interviewee"}
        titleTop="Focus"
        bar={
          <>
            {interview.mediaType === "audio" && (
              <Button
                alsoWorksWhenInputInFocus
                shortcut="cmd-p"
                className="tabular"
                titleShowAlways
                titleLeft
                title={`${this.state.isMediaPlaying ? "Pause" : "Play"} audio`}
                onClick={() => this.toggle(interview.mediaType)}
                selected={this.state.isMediaPlaying}
                icon={this.state.isMediaPlaying ? "pause" : "play"}
              >
                {this.state.mediaSeconds != null
                  ? secondsToTime(this.state.mediaSeconds)
                  : null}
              </Button>
            )}
          </>
        }
        backTo={this.props.backTo}
      >
        {interview.mediaType === "audio" && (
          <Audio
            className="h-24 flex-none"
            autoplay={this.state.isMediaPlaying}
            peaks={this.props.isDemo ? demoPeaks : null}
            ref={this.audio}
            time={
              this.props.session[
                `interview-${this.props.match.params.interviewId}-time`
              ]
            }
            onTime={(mediaSeconds) => {
              if (parseInt(mediaSeconds) !== this.state.mediaSeconds)
                this.setState({ mediaSeconds: parseInt(mediaSeconds) })
            }}
            onToggle={(isMediaPlaying) => {
              this.setState({ isMediaPlaying })
            }}
            url={interview.mediaUrl}
          />
        )}
        {interview.mediaType === "audio" && (
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowUp"
            onPress={() => this.setTime("+5", "audio")}
          />
        )}
        {interview.mediaType === "audio" && (
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowDown"
            onPress={() => this.setTime("-5", "audio")}
          />
        )}
        {interview.mediaType === "video" && (
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowUp"
            onPress={() => this.setTime("+5", "video")}
          />
        )}
        {interview.mediaType === "video" && (
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-ArrowDown"
            onPress={() => this.setTime("-5", "video")}
          />
        )}
        {interview.mediaType === "video" && (
          <Shortcut
            alsoWorksWhenInputInFocus
            press="cmd-p"
            onPress={() => this.toggle("video")}
          />
        )}
        <div className="flex h-full flex-1 overflow-scroll">
          {interview.mediaType === "video" && (
            <div style={{ width: 400 }} className="h-full flex-none p-4">
              <div className="h-72 overflow-hidden rounded-2xl bg-black shadow-lg">
                {!this.props.isDemo && (
                  <Video
                    autoplay={this.state.isMediaPlaying}
                    ref={this.video}
                    onTime={(mediaSeconds) =>
                      parseInt(mediaSeconds) !== this.state.mediaSeconds &&
                      this.setState({ mediaSeconds: parseInt(mediaSeconds) })
                    }
                    id={async () => {
                      const getVideoDownloadToken = functions.httpsCallable(
                        "getVideoDownloadToken",
                      )
                      const { data } = await getVideoDownloadToken({
                        videoId: interview.mediaUrl,
                      })
                      if (data && data.isSuccess) {
                        return `https://iframe.videodelivery.net/${data.token}`
                      } else {
                        this.props.error("Could not load video")
                        return false
                      }
                    }}
                    time={
                      this.props.session[
                        `interview-${this.props.match.params.interviewId}-time`
                      ]
                    }
                  />
                )}
                {this.props.isDemo && (
                  <video
                    className="h-full"
                    controls
                    controlsList="nodownload"
                    disablePictureInPicture
                    ref={this.video}
                    src={interview.mediaUrl}
                    onTimeUpdate={() =>
                      this.video.current &&
                      this.setState({
                        mediaSeconds: this.video.current.currentTime,
                      })
                    }
                  />
                )}
              </div>
            </div>
          )}
          <motion.div layout className="border-light h-full flex-1">
            <ProjectInterviewFindings
              mediaSeconds={this.state.mediaSeconds}
              setTime={(time) => this.setTime(time, interview.mediaType)}
              isFocus
              project={this.props.project}
              interview={this.props.interview}
              findings={this.props.findings}
            />
          </motion.div>
        </div>
      </Page>
    )
  }
}

ProjectInterviewFocus.propTypes = {}

export default withData(withNotify(ProjectInterviewFocus))
