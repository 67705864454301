import React from 'react';
import Icon from "./Icon";

class ButtonImages extends React.Component {
    render() {
        const otherValue = !this.props.options.map(x => x.value).includes(this.props.value);
        return (
            <div className={`grid gap-5 grid-cols-${this.props.cols}`} style={{maxWidth: `${this.props.cols * 10 + (this.props.cols - 1) * 1.25}rem ${this.props.className || ''}`}}>
                {this.props.options.map(option => (
                    <div key={option.value} onClick={() => this.props.onChange(option.value)} className={`h-28 w-40 bg-gray-100 dark:bg-gray-900 pushable ${this.props.value === option.value ? `ring ring-${window.appColor}` : ''} rounded-lg bg-cover bg-center`} style={{backgroundImage: `url(${option.preview})`}} />
                ))}
                {!!this.props.onMore ? <div key='more' onClick={() => this.props.onMore()} className={`${otherValue ? `ring ring-${window.appColor}` : ''}  h-28 w-40 relative border flex items-center justify-center pushable rounded-lg`}>
                    {otherValue ? <div className='absolute inset-0 bg-cover bg-center rounded-lg' style={{zIndex: -1, backgroundImage: `url(${this.props.value})`}} /> : null}
                    <div className='w-10 h-10 rounded-full flex items-center justify-center' style={{background: otherValue ? 'rgba(0,0,0,0.5)' : null}}><Icon icon='more' color={otherValue ? 'white' : 'black'} colorDark='white' size={16} /></div>
                </div> : null}
            </div>
        );
    }
}

ButtonImages.defaultProps = {
    cols: 3,
};

export default ButtonImages;