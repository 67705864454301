import React from "react"
import {
  Button,
  deleteObjectInArray,
  getSemiUniqueKey,
  Page,
  Sheet,
  withData,
  withNotify,
} from "../shared"

class ProjectInterviewsLabels extends React.Component {
  addLabel() {
    this.props.addToArray(
      "projects",
      this.props.match.params.projectId,
      "interviewLabels",
      {
        color: null,
        id: getSemiUniqueKey(),
        name: "",
      },
    )
  }
  render() {
    const project = (this.props.projects || []).find(
      (x) => x.id === this.props.match.params.projectId,
    )

    return (
      <Page
        title="Edit labels"
        isPopup
        icon="label"
        backTo={this.props.backTo}
        hasPadding
        style={{ minHeight: "min(560px, 90vh)" }}
        width="md"
        footerGrid={2}
        footer={
          <>
            <Button secondary onClick={() => this.addLabel()}>
              Add label
            </Button>
            <Button primary to={this.props.backTo}>
              Done
            </Button>
          </>
        }
      >
        <p className="mb-5 text-gray-500">
          Use interview labels to assign a status to each interview, categorize
          interviewees, or whatever works for your project.
        </p>
        <Sheet
          noHead
          canReorder
          fields={[
            {
              key: "color",
              type: "color",
              size: "auto",
              noWeights: true,
              hasAll: true,
            },
            {
              key: "name",
              placeholder: "New label",
            },
          ]}
          onCreate={this.addLabel.bind(this)} // add row to array
          onUpdate={(interviewLabels) =>
            this.props.update("projects", this.props.match.params.projectId, {
              interviewLabels,
            })
          }
          buttons={(label) => (
            <Button
              icon="cross"
              isSmall
              onClick={(e) =>
                this.props.update(
                  "projects",
                  this.props.match.params.projectId,
                  {
                    interviewLabels: deleteObjectInArray(
                      label.id,
                      project.interviewLabels,
                    ),
                  },
                )
              }
            />
          )}
          data={project.interviewLabels}
        />
      </Page>
    )
  }
}

export default withData(withNotify(ProjectInterviewsLabels))
