import React from "react"
import PropTypes from "prop-types"
import { getIcon } from "./IconSources"

class Icon extends React.Component {
  render() {
    if (!this.props.icon) return <div className={`h-${this.props.size}`} />

    return (
      <div
        onClick={this.props.onClick}
        style={this.props.style}
        title={this.props.title}
        className={`icon text-${this.props.color} ${
          this.props.colorDark ? `dark:text-${this.props.colorDark}` : ""
        } inline-block ${this.props.title ? "" : "pointer-events-none"} ${
          this.props.bg
            ? `bg-${this.props.bg} dark:bg-${this.props.bgDark}`
            : ""
        } ${this.props.className || ""} ${
          this.props.noStyling
            ? ""
            : this.props.bg
            ? `rounded-full p-${this.props.padding}`
            : ""
        }`}
      >
        {getIcon(
          this.props.icon,
          this.props.size,
          this.props.fill ? "fill" : "none",
        )}
      </div>
    )
  }
}

Icon.propTypes = {
  bg: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  colorDark: PropTypes.string,
  fill: PropTypes.bool,
  icon: PropTypes.string,
  noStyling: PropTypes.bool,
  padding: PropTypes.number,
  size: PropTypes.number,
  style: PropTypes.object,
}

Icon.defaultProps = {
  padding: 4,
  size: 24,
}

export default Icon
