import React from "react"
import {
  Page,
  format,
  timestamp,
  DropdownItem,
  withData,
  Button,
  withNotify,
  Input,
  InputGroup,
  fName,
  Dropdown,
  DropdownDateFormat,
  parseTemplate,
} from "../shared"

class ProjectEmailTemplate extends React.Component {
  constructor(props) {
    super(props)
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const emailTemplates = project.emailTemplates || []
    const template = emailTemplates.find(
      (x) => x.id === this.props.match.params.templateId,
    )
    this.messageRef = React.createRef()
    this.state = {
      message: (template || {}).message || "",
      isPreview: false,
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.templateId !== this.props.match.params.templateId
    ) {
      const project = this.props.projects.find(
        (x) => x.id === this.props.match.params.projectId,
      )
      const emailTemplates = project.emailTemplates || []
      const template = emailTemplates.find(
        (x) => x.id === this.props.match.params.templateId,
      )
      this.setState({ message: template.message, isPreview: false })
    }
  }
  insert(str) {
    let message = this.state.message
    if (!message.endsWith(" ")) {
      message += " "
    }
    message += str
    this.setState({ message })
    this.messageRef.current.focus()
  }
  render() {
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const emailTemplates = project.emailTemplates || []
    const template = emailTemplates.find(
      (x) => x.id === this.props.match.params.templateId,
    )
    const backUrl = `/projects/${this.props.match.params.projectId}/emails${
      this.props.match.params.emailId
        ? `/${this.props.match.params.emailId}`
        : ""
    }/new`
    if (!template) {
      this.props.history.push(backUrl)
      return null
    }

    const update = (data) =>
      this.props.updateInArray(
        "projects",
        this.props.match.params.projectId,
        "emailTemplates",
        template.id,
        data,
      )

    // Preview
    const fields = [
      { key: "name" },
      { key: "firstname" },
      {
        key: "date",
        type: "date",
        format: {
          day: "full",
          month: "full",
          year: false,
          dateFormat: template.dateFormat,
          dateLocale: template.dateLocale,
        },
      },
      {
        key: "dateshort",
        dataKey: "date",
        type: "date",
        format: {
          day: false,
          year: false,
          dateFormat: template.dateFormat,
          dateLocale: template.dateLocale,
        },
      },
      {
        key: "dateday",
        dataKey: "date",
        type: "date",
        format: { format: "dddd", dateLocale: template.dateLocale },
      },
      { key: "time", type: "time", format: template.timeFormat },
      { key: "duration", type: "number" },
      { key: "myname" },
      { key: "myfirstname" },
      { key: "project" },
    ]
    const user = this.props.getUser()
    const data = [
      {
        name: "Emma Example",
        firstName: "Emma",
        date: timestamp(),
        dateshort: timestamp(),
        dateday: timestamp(),
        time: "103000",
        duration: 45,
        myname: user.name || "Denise Demo",
        myfirstname: fName(user.name) || "Denise",
        project: "Coffee Tasting",
      },
    ]
    const parsed = this.state.isPreview
      ? parseTemplate(this.state.message, fields, data)
      : false
    const parsedSubject = this.state.isPreview
      ? parseTemplate(template.subject, fields, data)
      : false

    return (
      <Page
        title={template.subject || "New template"}
        hasPadding
        isPopupChild
        barRight={
          <>
            <Dropdown arrowPosition="right" button={<Button icon="add" />}>
              <h6 className="pf">Insert variable</h6>
              <DropdownItem
                onClick={() => this.insert("[name]")}
                desc="Emma Example"
              >
                Interviewee name
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[firstname]")}
                desc="Emma"
              >
                Interviewee first name
              </DropdownItem>
              <DropdownItem
                borderTop
                onClick={() => this.insert("[date]")}
                desc={format("date", "today", {
                  day: "full",
                  month: "full",
                  year: false,
                  dateLocale: template.dateLocale,
                  dateFormat: template.dateFormat,
                })}
              >
                Full interview date
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[dateshort]")}
                desc={format("date", "today", {
                  month: "full",
                  year: false,
                  dateLocale: template.dateLocale,
                  dateFormat: template.dateFormat,
                })}
              >
                Short interview date
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[dateday]")}
                desc={format("date", "today", {
                  format: "dddd",
                  dateLocale: template.dateLocale,
                })}
              >
                Interview day
              </DropdownItem>
              <DropdownItem
                borderTop
                onClick={() => this.insert("[time]")}
                desc={format("time", "123000", { to: template.timeFormat })}
              >
                Interview time
              </DropdownItem>
              <DropdownItem onClick={() => this.insert("[duration]")} desc="60">
                Interview duration (min.)
              </DropdownItem>
              <DropdownItem
                borderTop
                onClick={() => this.insert("[project]")}
                desc="Coffee Tasting"
              >
                Project name
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[myname]")}
                desc={user.name || "Denise Demo"}
              >
                Your name
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[myfirstname]")}
                desc={fName(user.name) || "Denise"}
              >
                Your first name
              </DropdownItem>
            </Dropdown>
            <Dropdown
              width={230}
              arrowPosition="right"
              button={<Button className="mr-3" icon="more" />}
            >
              <h6 className="pf">Date settings</h6>
              <DropdownDateFormat
                doc={template}
                onBlur={(data) => update(data)}
              />
              <p className="sub p pt-3 pb-5">
                Variables will be displayed in the selected format and language.
              </p>
              <DropdownItem
                onClick={() => this.setState({ isPreview: true })}
                icon="view"
              >
                Preview
              </DropdownItem>
              <DropdownItem
                isRed
                icon="delete"
                onClick={async () => {
                  const isConfirm = await this.props.deleteFromArray(
                    "projects",
                    this.props.match.params.projectId,
                    "emailTemplates",
                    this.props.match.params.templateId,
                    this.props.warn,
                    undefined,
                    "template",
                  )
                  if (isConfirm) {
                    this.props.history.push(
                      `/projects/${this.props.match.params.projectId}/emails${
                        this.props.match.params.emailId
                          ? `/${this.props.match.params.emailId}`
                          : ""
                      }/new/templates`,
                    )
                    this.props.success("Template deleted")
                  }
                }}
              >
                Delete template
              </DropdownItem>
            </Dropdown>
            <Button secondary to={backUrl}>
              Done
            </Button>
          </>
        }
      >
        <div className="flex h-full flex-col">
          <InputGroup
            doc={this.state.isPreview ? { subject: parsedSubject } : template}
            onBlur={(data) => update(data)}
            beforeWidth={60}
            fields={[{ key: "subject", autoFocus: !template.subject }]}
          />
          <Input
            placeholder="Type message..."
            disabled={this.state.isPreview}
            ref={this.messageRef}
            rows={1}
            value={this.state.isPreview ? parsed : this.state.message}
            onChange={(x) => this.setState({ message: x })}
            onBlur={(message) => update({ message })}
            noStyling
            noInputStyling
            className="mt-5 flex-1"
            classNameInput="bg-transparent resize-none h-full leading-relaxed"
          />
          {this.state.isPreview && (
            <div className="no-select flex grid grid-cols-3 items-center rounded-full border p-4 pl-8">
              <div className="col-span-2 flex items-center">
                <div className="ml-1 flex-1">
                  <h6>Preview as</h6>
                  <h5 className="truncate">Emma Example (mock data)</h5>
                </div>
              </div>
              <Button
                secondary
                className="ml-10"
                onClick={() => this.setState({ isPreview: false })}
              >
                Done
              </Button>
            </div>
          )}
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(ProjectEmailTemplate))
