import React from 'react';
import {getSemiUniqueKey} from "../functions";
import {AnimateSharedLayout} from "framer-motion";

class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.layoutId = getSemiUniqueKey();
    }
    render() {
        return (
            <AnimateSharedLayout>
                <div className={`h-${this.props.height} overflow-scroll flex-none relative flex box-content mx-${this.props.marginSide} ${this.props.center ? `justify-center ${this.props.margin === 'auto' ? '' : `pl-${this.props.margin + 10}`}` : ''} select-none ${this.props.className || ''}`}>
                    {React.Children.map(this.props.children, (tab) =>
                        tab ? React.cloneElement(tab, {
                            layoutId: this.layoutId,
                            center: this.props.center,
                            height: this.props.height,
                            margin: tab.props.margin == null ? this.props.margin : tab.props.margin,
                        }) : null,
                    )}
                    <span className={`inset-x-0 absolute bottom-0 h-px border-b border-light`} />
                    {this.props.right}
                </div>
            </AnimateSharedLayout>
        );
    }
}

Tabs.defaultProps = {
    margin: 10,
    height: 10,
    marginSide: 10,
}

export default Tabs;