import React from "react"
import {
  format,
  Page,
  Callout,
  capitalize,
  DropdownItem,
  Button,
  withNotify,
  withData,
  Dropdown,
} from "../shared"
import { Route, Link } from "react-router-dom"
import moment from "moment"
import ProjectEmailSends from "./ProjectEmailSends"
import { sortSends } from "../functions"

class ProjectEmail extends React.Component {
  render() {
    if (this.props.match.params.emailId === "new") return null

    const email =
      this.props.filteredEmails.find(
        (x) => x.id === this.props.match.params.emailId,
      ) || {}
    const first = email.sends ? email.sends[0] : {}
    const interviews = this.props.interviews
    const sends = (email.sends || []).sort(sortSends)
    const baseUrl = `/projects/${this.props.match.params.projectId}/emails/${email.id}`
    const isScheduled =
      sends.length &&
      sends[sends.length - 1].date &&
      moment(sends[sends.length - 1].date, "X").isAfter(moment(), "minute")
    const isScheduledLater =
      isScheduled && sends.every((x) => x.date === sends[0].date)
    const uniqueStatuses = [...new Set(sends.map((x) => x.status))]
    const status =
      uniqueStatuses.length === 1
        ? uniqueStatuses[0] &&
          uniqueStatuses[0] !== "delivered" &&
          uniqueStatuses[0] !== "opened"
          ? "ERROR"
          : null
        : sends.some(
            (x) =>
              x.status && x.status !== "delivered" && x.status !== "opened",
          )
        ? "ERROR_SOME"
        : null
    const statusMessage =
      status && status === "ERROR"
        ? "This message could not be delivered."
        : status === "ERROR_SOME"
        ? "Some recipients did not receive this email."
        : null

    return (
      <Page
        title={first.subject || "No subject"}
        titleIsBold={false}
        required={{ email }}
        barRight={
          <>
            <Dropdown
              isInfo
              width={230}
              arrowPosition="right"
              button={<Button className="ml-2" icon="help" />}
            >
              <h6>Help</h6>
              <p className="mt-3 text-sm">
                InterviewKit allows you to send emails to multiple people
                quickly, and organize them by interview.
              </p>
              <p className="mt-3 text-sm">
                This is where all of your sent emails live. Any replies will go
                straight to your email address,{" "}
                <span className="font-medium">{this.props.userEmail}</span>.
              </p>
            </Dropdown>
          </>
        }
        hasPadding
        moreWidth={210}
        more={
          <>
            <DropdownItem to={`${baseUrl}/sends`} icon="at">
              Individual sends
            </DropdownItem>
            <DropdownItem
              disabled={this.props.isDemo}
              isRed
              icon="delete"
              onClick={async (e) => {
                const isDeleted = await this.props.delete(
                  "emails",
                  email.id,
                  this.props.warn,
                  e,
                  { singular: "email" },
                )
                if (isDeleted)
                  this.props.history.push(
                    `/projects/${this.props.match.params.projectId}/emails`,
                  )
              }}
            >
              Delete email
            </DropdownItem>
          </>
        }
      >
        <Route
          path="/projects/:projectId/emails/:emailId/sends/:sendId?"
          render={(props) => (
            <ProjectEmailSends
              {...props}
              sends={sends}
              batchId={email.batchId}
            />
          )}
        />
        {status && (
          <Callout className="mb-6" isWarning>
            {statusMessage}{" "}
            <Link className="link-orange" to={`${baseUrl}/sends`}>
              View
            </Link>
          </Callout>
        )}
        <div
          className="grid leading-snug"
          style={{ gridTemplateColumns: "100px 1fr" }}
        >
          <h5>To:</h5>
          <div>
            {(email.sends || []).sort(sortSends).map((x, i) => {
              const interview = interviews.find((y) => y.email === x.to.email)
              if (interview)
                return (
                  <React.Fragment key={interview.id}>
                    {i > 0 ? ", " : ""}
                    <Link
                      title={`${interview.name || "Unnamed"} <${x.to.email}>`}
                      to={{
                        pathname: `/projects/${email.projectId}/interviews/${interview.id}`,
                      }}
                    >
                      {interview.name || x.to.email}
                    </Link>
                  </React.Fragment>
                )
              else
                return (
                  <span title={x.to.email} key={x.to.email}>
                    {i > 0 ? ", " : ""}
                    {x.to.email}
                  </span>
                )
            })}
          </div>
        </div>
        <div
          className="mt-2 grid leading-snug"
          style={{ gridTemplateColumns: "100px 1fr" }}
        >
          <h5>Date:</h5>
          <div>
            {isScheduled || email.isCancelled ? (
              <span>
                {email.isCancelled ? (
                  <span className="text-red-500">
                    {email.isCancelled === "partial"
                      ? "Partially aborted"
                      : "Aborted"}
                  </span>
                ) : isScheduledLater ? (
                  `Scheduled ${format("date", first.date, {
                    year: "not-current",
                    isRelative: true,
                  })} at ${format("time", first.date)}`
                ) : (
                  "Scheduled dynamically"
                )}{" "}
                ·{" "}
                <Link
                  to={`${baseUrl}/sends`}
                  className="link-color font-medium"
                >
                  View
                </Link>
              </span>
            ) : (
              `${capitalize(
                format("date", first.date, {
                  day: "full",
                  month: "full",
                  year: "not-current",
                  isRelative: true,
                }),
              )} at ${format("time", first.date)}`
            )}
          </div>
        </div>
        <div
          className="border-light mt-2 grid border-b pb-6 leading-snug"
          style={{ gridTemplateColumns: "100px 1fr" }}
        >
          <h5>Subject:</h5>
          <div>{first.subject}</div>
        </div>
        <div className="max-w-prose whitespace-pre-wrap pt-6 leading-normal">
          {first.message}
        </div>
      </Page>
    )
  }
}

export default withNotify(withData(ProjectEmail))
