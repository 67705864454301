import moment from "moment";
import React from 'react';
import XLSX from 'xlsx';
import Icon from "./ui/Icon";
import Labels from "./ui/Labels";
import queryString from 'query-string';
import { matchPath } from "react-router-dom";

export function isInt(value) {
    // Checks if value is actual integer or string containing only numbers.
    if (typeof value === 'number') value = value.toString();
    return /^\d+$/.test(value);
}

export function isActive(pathname, to, exact, alsoActive) {
    const isActive = !!matchPath(pathname, { path: to, exact: exact });
    if (isActive) return true;
    if (Array.isArray(alsoActive)) {
        for (const alsoTo of alsoActive) {
            const isActive = !!matchPath(pathname, { path: alsoTo, exact: exact });
            if (isActive) return true;
        }
    }
    return false;
}

export function isPreview() {
    return window.location.search && window.location.search.includes('p=');
}

export function toPreview(path, id, sub) {
    if (path === undefined) {
        return window.location.pathname; // Go back
    } else {
        const qs = queryString.parse(window.location.search);
        let str = window.location.pathname; // Clear current query string
        if (path || qs.preview) str += '?p=' + (path || qs.preview); // Add ?p=[...]
        if (id || qs.id) str += '&id=' + (id || qs.id);
        if (sub || qs.sub) str += '&sub=' + (sub || qs.sub);
        return str;
    }
}

export function checkIfDark() {
    return document.documentElement.classList.contains('dark');
}

export function sortUserIds(userIds = [], onlineIds = [], userId = false, adminId = false) {
    userIds = [...userIds];
    // Bring active admin ID to the third place
    if (adminId && userIds.includes(adminId)) {
        userIds = userIds.filter(item => item !== adminId);
        userIds.unshift(adminId);
    }
    // Bring online ID's to the second place
    for (const uid of onlineIds) {
        if (uid === userId) continue;
        if (!userIds.includes(uid)) continue;
        userIds = userIds.filter(item => item !== uid);
        userIds.unshift(uid);
    }
    // Bring active user ID to the front
    if (userId && userIds.includes(userId)) {
        userIds = userIds.filter(item => item !== userId);
        userIds.unshift(userId);
    }
    return userIds;
}

const hex500All = ['#EF4444', '#F97316', '#F59E0B', '#EAB308', '#84CC16', '#22C55E', '#10B981', '#14B8A6', '#06B6D4', '#0EA5E9', '#3B82F6', '#6366F1', '#A855F7', '#D946EF', '#EC4899', '#F43F5E'];
const hex400All = ['#F87171', '#FB923C', '#FBBF24', '#FACC15', '#A3E635', '#4ADE80', '#34D399', '#2DD4BF', '#22D3EE', '#38BDF8', '#60A5FA', '#818CF8', '#C084FC', '#E879F9', '#F472B6', '#FB7185'];
const colorsAll = ['red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'purple', 'fuchsia', 'pink', 'rose'];

export function getHex(color, isMuted = false) {
    if (!color) return false;
    if (color.includes('-')) color = color.split('-')[0];
    const index = colorsAll.indexOf(color);
    return index > -1 ? (isMuted ? hex400All[index] : hex500All[index]) : false;
}

export function getColor(index = false, weightOrIsHex = false, hasAll = false, isMuted = false) {
    const hex500Limited = ['#EF4444', '#F97316', '#EAB308', '#22C55E', '#0EA5E9', '#3B82F6', '#6366F1', '#A855F7', '#EC4899'];
    const hex400Limited = ['#F87171', '#FB923C', '#FBBF24', '#4ADE80', '#38BDF8', '#60A5FA', '#818CF8', '#C084FC', '#F472B6'];
    const colorsLimited = ['red', 'orange', 'yellow', 'green', 'sky', 'blue', 'indigo', 'purple', 'pink'];

    const hexes = hasAll ? (isMuted ? hex400All : hex500All) : (isMuted ? hex400Limited : hex500Limited);
    const colors = hasAll ? colorsAll : colorsLimited;

    if (index === false) return weightOrIsHex === true ? hexes : (weightOrIsHex ? colors.map(x => `${x}-${weightOrIsHex}`) : colors);
    while (index > colors.length - 1) {
        index -= colors.length;
    }
    if (weightOrIsHex === true) return hexes[index];

    return colors[index] + (weightOrIsHex ? `-${weightOrIsHex}` : '');
}

export function isLocalhost() {
    return window.location.hostname === "localhost";
}

export function recursiveMap(children, fn) {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return child;
        }

        if (child.props.children) {
            child = React.cloneElement(child, {
                children: recursiveMap(child.props.children, fn)
            });
        }

        return fn(child);
    });
}

export function isEmail(str) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
}

export function copyToClipboard(str, errorFunc) {
    return new Promise(async (resolve, reject) => {
        if (window.navigator.clipboard) {
            try {
                await window.navigator.clipboard.writeText(str);
                resolve();
            } catch (e) {
                if (errorFunc) errorFunc('Could not copy to clipboard', 'Please upgrade your browser to enable this functionality.')
                reject();
            }
        } else if (errorFunc) {
            reject();
            errorFunc('Could not copy to clipboard', 'Please upgrade your browser to enable this functionality.');
        }
    })
}

export function rand(min = 1, max = 99999) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function toLowerWithDashes(str) {
    // Converts any letter lowercase and non-alphanumeric characters to "-".
    return str.replace(/\W+/g, '-').toLowerCase();
}

export function downloadFile(name, content) {
    const encodedUri = encodeURI(content);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
}

export function getSemiUniqueKey() {
    // Returns pseudo-unique key like: kkft9y8b1mswoap1py1
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function loadFile(file, type) {
    if (type === 'video') {
        return new Promise((resolve, reject) => {
            try {
                let video = document.createElement('video')
                video.preload = 'metadata'
                video.onloadedmetadata = function () {
                    resolve(video)
                }
                video.onerror = function () {
                    reject("Invalid video. Please select a video file.")
                }
                video.src = window.URL.createObjectURL(file)
            } catch (e) {
                reject(e)
            }
        });
    } else if (type === 'audio') {
        return new Promise((resolve, reject) => {
            const audio = new Audio();
            audio.onloadedmetadata = function () {
                resolve(this)
            }
            audio.src = file;
        });
    }
}

export function capitalize(str) {
    if (!str || typeof str !== 'string' || str.includes('@')) return str;
    return str.charAt(0).toUpperCase() + str.slice(1) || '';
}

export function format(type, value, options) {
    if (value === '' || value == null) return null;
    if (type === 'date') return formatDate(value, options);
    if (type === 'time') return formatTime(value, options);
    if (type === 'currency') return formatCurrency(value, options);
    if (type === 'number') return formatNumber(value);
    return value;
}

export function compare(a, b) {
    // Replacement of localCompare with additional handling of empty values.
    if (a && !b) return -1;
    if (!a && b) return 1;
    if (!a && !b) return 0;
    return a.localeCompare(b)
}

export function parse(type, value, arg1) {
    if (type === 'date') return parseDate(value, arg1);
    if (type === 'time') return parseTime(value, arg1);
    if (type === 'currency') return parseCurrency(value);
    if (type === 'number') return parseNumber(value);
    return value;
}

function formatTime(timestamp, format) {
    if (timestamp === undefined) return '';
    let to, leadingZero = true;
    if (typeof format === 'object') {
        to = format.to;
        leadingZero = format.leadingZero;
    } else {
        to = format;
    }
    if (!to) to = window?.appTimeFormat || 'H:mm';
    if (to === 'H:mm' && leadingZero) to = 'HH:mm';
    const isHHmmss = typeof timestamp === 'string' && timestamp.includes(':');
    const m = isHHmmss ? moment(timestamp, 'HH:mm:ss') : moment(timestamp, 'X');
    return to === 'moment' ? m : m.format(to);
}

function parseTime(str) {
    let parsed = typeof str === "number" ? moment(str, 'X') : moment(str, ['h:m a', 'h:ma', 'H:m', 'h.m a', 'h.ma', 'H.m', 'ha', 'h a', 'H']);
    return parsed.isValid() ? parsed.format('HH:mm:ss') : null;
}

function formatDate(dateNumber, options = {}) {
    // Note: you can call this with dateNumber === 'today' to get the current date.
    // This also works with yesterday, tomorrow etc (see parseDate for options).
    if (!dateNumber) return '';
    let fullDay = false;
    let includeTime = false;
    let fullMonth, year, isRelative, toFormat, doCapitalize;
    let shortDay = typeof options === 'boolean' ? options : false;
    let dateFormat = window?.appDateFormat || 'D M Y';
    let dateLocale = 'en';
    if (!isInt(dateNumber)) dateNumber = parseDate(dateNumber);
    const date = moment(dateNumber, 'X');
    if (typeof options === 'object') {
        doCapitalize = options.capitalize;
        toFormat = options.format;
        includeTime = options.includeTime;
        fullDay = options.day === 'full';
        shortDay = options.day === 'short';
        fullMonth = options.month === 'full';
        year = options.year != null ? options.year === 'full' : true;
        dateFormat = options.dateFormat || dateFormat;
        dateLocale = options.dateLocale || dateLocale;
        isRelative = options.isRelative;
    }
    if (year === 'not-current' && date.isSame(moment(), 'year')) year = false; // Don't display year if year is current year
    const commaAfterDay = dateLocale === 'nl' ? '' : ',';
    if (dateLocale !== 'en') date.locale(dateLocale);
    if (isRelative) {
        let res;
        if (dateLocale === 'nl') {
            if (date.isSame(moment().subtract(1, 'day'), 'day')) res = 'gisteren';
            else if (date.isSame(moment(), 'day')) res = 'vandaag';
            else if (date.isSame(moment().add(1, 'day'), 'day')) res = 'morgen';
            else if (date.isSame(moment(), 'isoWeek')) res = date.format('dddd');
            if (res) {
                if (doCapitalize) res = capitalize(res);
                if (includeTime) res += ` om ${formatTime(date)}`
                return res;
            }
        } else {
            if (date.isSame(moment().subtract(1, 'day'), 'day')) res = 'yesterday';
            else if (date.isSame(moment(), 'day')) res = 'today';
            else if (date.isSame(moment().add(1, 'day'), 'day')) res = 'tomorrow';
            else if (date.isSame(moment(), 'isoWeek')) res = date.format('dddd');
            if (res) {
                if (doCapitalize) res = capitalize(res);
                if (includeTime) res += ` at ${formatTime(date)}`
                return res;
            }
        }
    }
    if (toFormat) return date.format(toFormat);
    const shortMonthNotation = `MMM${date.format('MMM') === date.format('MMMM') || date.format('MMM').includes('.') ? '' : '.'}`;
    if (typeof options === 'string') return (options === 'moment' ? date : (options === 'date' ? date.toDate() : date.format(options)));
    else {
        let res = date.format(`${fullDay ? `dddd${commaAfterDay} ` : ''}${shortDay ? 'ddd, ' : ''}${dateFormat === 'M D, Y' ? `${fullMonth ? 'MMMM' : shortMonthNotation} D${year ? ',' : ''}` : `D ${fullMonth ? 'MMMM' : shortMonthNotation}`}${year ? ' yyyy' : ''}`);
        if (doCapitalize) res = capitalize(res);
        if (includeTime) res += ` at ${formatTime(date)}`
        return res;
    }
}

export function join(arr, lastJoin = 'and', singleVerb, pluralVerb, none = 'none') {
    // [1,2,3] => "1, 2 and 3"
    // with singleVerb/pluralVerb: 1 has / 1, 2 and 3 have
    if (!arr || !arr.length) return none;
    let str = arr.length > 1 ? `${arr.slice(0, -1).join(', ')} ${lastJoin} ${arr.slice(-1)[0]}` : arr[0];
    if (singleVerb && pluralVerb) str += ` ${arr.length === 1 ? singleVerb : pluralVerb}`;
    return str;
}

function parseDate(str, fromFormat = '') {
    if (typeof str === 'number') return str; // Already timestamp
    if (!str || str.length < 5) return null;
    let parsedDate;
    if (typeof str === 'object') {
        parsedDate = moment(str);
    } else if (fromFormat) {
        parsedDate = moment(str, fromFormat);
    } else {
        parsedDate = moment(new Date(str));
    }
    if (!parsedDate.isValid() && !fromFormat) {
        parsedDate = moment(str, 'D MMM');
    }
    if (!parsedDate.isValid()) {
        const word = str.toLowerCase();
        switch (word) {
            case 'gisteren':
            case 'yesterday':
                parsedDate = moment().startOf('day').subtract(1, 'day');
                break;
            case 'vandaag':
            case 'today':
                parsedDate = moment().startOf('day');
                break;
            case 'morgen':
            case 'tomorrow':
                parsedDate = moment().startOf('day').add(1, 'day');
                break;
            case 'overmorgen':
            case 'day after tomorrow':
                parsedDate = moment().startOf('day').add(2, 'days');
                break;
            default:
                return null;
        }
    }
    if (parsedDate.year() === 2001) {
        parsedDate.year(moment().year());
    }
    return parsedDate.unix();
}

// Obfuscate a plaintext string with a simple rotation algorithm similar to the rot13 cipher.
// Does not cipher characters, only letters (a-z and A-Z).
export function rot13(str) {
    return str.split('').map(char => {
        if ((char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z')) {
            return String.fromCharCode(char.charCodeAt(0) + (char.toLowerCase() < 'n' ? 13 : -13));
        } else {
            return char;
        }
    }).join('');
}

export function parseRoute(route, keyObj) {
    if (!route) return '';
    let routeKeys = route.split('/').filter(x => x.startsWith(':')).map(x => x.substring(1));
    for (const key of routeKeys) {
        route = route.split(':' + key).join(keyObj[key] || '');
    }
    routeKeys = route.split('=').filter(x => x.startsWith(':')).map(x => x.substring(1));
    for (const key of routeKeys) {
        route = route.split(':' + key).join(keyObj[key] || '');
    }
    return route;
}

export function timestamp({ time, isRandRecent, isStartOfDay, addMinutes, addHours, addDays } = {}) {
    const m = time ? moment(time, 'H:mm') : moment();
    if (isRandRecent) m.subtract(rand(1, 120), 'minutes');
    if (isStartOfDay) m.startOf('day');
    if (addMinutes) m.add(addMinutes, 'minutes');
    if (addHours) m.add(addHours, 'hours');
    if (addDays) m.add(addDays, 'days');
    return m.unix();
}

export function sortData(a, b, sortByField, sortDesc) {
    let aVal = a[sortByField.key];
    let bVal = b[sortByField.key];
    if (sortByField.calc) {
        aVal = sortByField.calc(aVal, a);
        bVal = sortByField.calc(bVal, b);
    }
    if (aVal && !bVal) return -1;
    if (!aVal && bVal) return 1;
    if (Array.isArray(aVal) && aVal.length && Array.isArray(bVal) && !bVal.length) return -1;
    if (Array.isArray(aVal) && !aVal.length && Array.isArray(bVal) && bVal.length) return 1;
    const alphabeticalTypes = ['text', 'email'];
    if ((!sortByField.type || alphabeticalTypes.includes(sortByField.type)) && aVal && bVal && aVal.localeCompare && bVal.localeCompare) {
        return sortDesc ? bVal.localeCompare(aVal) : aVal.localeCompare(bVal);
    } else {
        return sortDesc ? bVal - aVal : aVal - bVal;
    }
}

export function formatBytes(bytes, decimals = 0) {
    if (!bytes) return '0 bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function filterData(searchQuery, hideFields = [], fields = [], data = []) {
    if (searchQuery) {
        const searchWords = searchQuery.toLowerCase().split(' ');
        data = data.filter(dataObj => {
            const dataObjWithCalcValue = { ...dataObj };
            // Replace value with calculated value so we're searching in the field that the user sees.
            const calcValues = fields.filter(x => !!x.calc);
            for (const calcValue of calcValues) {
                dataObjWithCalcValue[calcValue.key] = calcValue.calc(dataObjWithCalcValue[calcValue.key], dataObjWithCalcValue);
            }
            // Replace arrays and objects with strings so we can search them.
            const jsonValues = fields.filter(x => x.searchInJson);
            for (const jsonValue of jsonValues) {
                dataObjWithCalcValue[jsonValue.key] = JSON.stringify(dataObjWithCalcValue[jsonValue.key]);
            }
            const keys = Object.keys(dataObjWithCalcValue);
            const fieldKeys = fields.map(h => h.key);
            const values = Object.values(dataObjWithCalcValue).filter((x, i) => typeof x === 'string' && fieldKeys.includes(keys[i]) && !hideFields.includes(keys[i]));
            return searchWords.every(searchWord => values.some(val => val.toLowerCase().includes(searchWord)));
        });
    }
    return data;
}

export function formatCurrency(number, round = false) {
    return _formatter(number, round, false, true);
}

export function formatNumber(number) {
    return _formatter(number, false, false, false);
}

export function parseCurrency(number) {
    return _formatter(number, false, true, true);
}

export function parseNumber(number) {
    return _formatter(number, false, true, false);
}

function _formatter(number, round, toInt, isCurrency) {
    // number could be 137050 or 1370,50 which should both result in 1.370,50
    if (number == null || number === '') return '';
    // Convert to int if necessary
    if (typeof number === 'string') {
        // Add missing cents if necessary
        if (isCurrency) {
            if (number[number.length - 1] === ',') number += '0';
            if (number[number.length - 2] === ',') number += '0';
        }
        // Strip cents if more than 2 numbers after comma
        const cents = number.split(',')[1];
        if (isCurrency && cents && cents.length > 2) number = number.split(',')[0] + ',' + cents.substring(0, 2);
        else if (!isCurrency) number = number.split(',')[0];
        if (number.includes(',')) number = parseInt(number.toString().split('.').join('').split(',').join(''));
        else if (isCurrency) number *= 100;
        if (toInt) return parseInt(number);
    }
    if (isCurrency) number = (number / 100).toFixed(2).replace('.', ','); // Add cents
    else number = number.toString().replace('.', ','); // Add cents
    number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Add thousands
    number = number.toString().replace(",", ",,"); // temp var for decimals
    number = number.toString().replace(".", ","); // set thousands
    number = number.toString().replace(",,", "."); // set decimals
    if (!number) return '';
    return round ? number.substring(0, number.length - 3) : number;
}

export function readFile(file, readerFunction = 'readAsText') {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader[readerFunction](file);
        } catch (e) {
            reject(e.message);
        }
    });
}

export function readExcel(file) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const rows = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1, raw: false })
                const keys = rows[0].map(x => x.toLowerCase());
                const rowObjects = [];
                for (let i = 1; i < rows.length; i++) {
                    const values = rows[i];
                    const obj = values.reduce((obj, x, i) => ({ ...obj, [keys[i]]: x }), {});
                    rowObjects.push(obj);
                }
                resolve(rowObjects);
            };
            reader.readAsArrayBuffer(file);
        } catch (e) {
            reject(e.message);
        }
    });
}

export async function readCSV(file, separator = ';') {
    const text = await readFile(file);
    const rows = text.split('\n');
    const first = rows[0];
    const keys = first.split(separator).map(x => x.split('"').join('').trim().toLowerCase());
    const rowObjects = [];
    for (let i = 1; i < text.split('\n').length; i++) {
        const line = rows[i];
        const values = line.split(separator).map(x => x.split('"').join(''));
        if (values.join('') === '') continue; // Line is empty (usually last line)
        const obj = values.reduce((obj, x, i) => ({ ...obj, [keys[i]]: x }), {});
        Object.keys(obj).forEach((key) => (obj[key] === '') && delete obj[key]); // Delete empty keys
        rowObjects.push(obj);
    }
    return rowObjects;
}

export function toggleInArray(value, array, forceBool) {
    const newArray = array ? [...array] : [];
    const index = newArray.indexOf(value);
    if (index === -1 && forceBool !== false) {
        newArray.push(value);
    } else if (forceBool !== true) {
        newArray.splice(index, 1);
    }
    return newArray;
}

export function updateObjectInArray(id, obj, array) {
    const newArray = array ? [...array] : [];
    const oldItemIndex = newArray.findIndex(x => x.id === id);
    newArray[oldItemIndex] = { ...newArray[oldItemIndex], ...obj };
    return newArray;
}

export function apostropheS(str) {
    if (!str) return '';
    if (str.endsWith('s')) return str + "'";
    else return str + "'s";
}

export function deleteObjectInArray(id, array) {
    const newArray = array ? [...array] : [];
    const itemToDeleteIndex = newArray.findIndex(x => x.id === id);
    newArray.splice(itemToDeleteIndex, 1);
    return newArray;
}

/*
export function getTimezone(key = '+0:00', onlyTime = false) {
    // Warning: getting a timezone by key usually will not work properly.
    // E.g. Amsterdam summer time will be +2:00 and therefore "Kaliningrad, South Africa" is returned.
    const timezones = [
        {key: '-12:00', name: '(GMT -12:00) Eniwetok, Kwajalein'},
        {key: '-11:00', name: '(GMT -11:00) Midway Island, Samoa'},
        {key: '-10:00', name: '(GMT -10:00) Hawaii'},
        {key: '-09:30', name: '(GMT -9:30) Taiohae'},
        {key: '-09:00', name: '(GMT -9:00) Alaska'},
        {key: '-08:00', name: '(GMT -8:00) Pacific Time (US & Canada)'},
        {key: '-07:00', name: '(GMT -7:00) Mountain Time (US &amp; Canada)'},
        {key: '-06:00', name: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City'},
        {key: '-05:00', name: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima'},
        {key: '-04:30', name: '(GMT -4:30) Caracas'},
        {key: '-04:00', name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'},
        {key: '-03:30', name: '(GMT -3:30) Newfoundland'},
        {key: '-03:00', name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown'},
        {key: '-02:00', name: '(GMT -2:00) Mid-Atlantic'},
        {key: '-01:00', name: '(GMT -1:00) Azores, Cape Verde Islands'},
        {key: '+00:00', name: '(GMT) Western Europe Time, London, Lisbon, Casablanca'},
        {key: '+01:00', name: '(GMT +1:00) Amsterdam, Brussels, Copenhagen, Madrid, Paris'},
        {key: '+02:00', name: '(GMT +2:00) Kaliningrad, South Africa'},
        {key: '+03:00', name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg'},
        {key: '+03:30', name: '(GMT +3:30) Tehran'},
        {key: '+04:00', name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'},
        {key: '+04:30', name: '(GMT +4:30) Kabul'},
        {key: '+05:00', name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'},
        {key: '+05:30', name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'},
        {key: '+05:45', name: '(GMT +5:45) Kathmandu, Pokhara'},
        {key: '+06:00', name: '(GMT +6:00) Almaty, Dhaka, Colombo'},
        {key: '+06:30', name: '(GMT +6:30) Yangon, Mandalay'},
        {key: '+07:00', name: '(GMT +7:00) Bangkok, Hanoi, Jakarta'},
        {key: '+08:00', name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'},
        {key: '+08:45', name: '(GMT +8:45) Eucla'},
        {key: '+09:00', name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'},
        {key: '+09:30', name: '(GMT +9:30) Adelaide, Darwin'},
        {key: '+10:00', name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok'},
        {key: '+10:30', name: '(GMT +10:30) Lord Howe Island'},
        {key: '+11:00', name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'},
        {key: '+11:30', name: '(GMT +11:30) Norfolk Island'},
        {key: '+12:00', name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka'},
        {key: '+12:45', name: '(GMT +12:45) Chatham Islands'},
        {key: '+13:00', name: '(GMT +13:00) Apia, Nukualofa'},
        {key: '+14:00', name: '(GMT +14:00) Line Islands, Tokelau'},
    ];
    const res = key !== 'all' ? (timezones.find(x => x.key === key) || {}).name : timezones;
    return key !== 'all' && onlyTime ? res.split('(')[1].split(')')[0] : res;
}
*/

export function parseShortcut(str) {
    const parts = str.split('-');
    const letter = parts[1] || parts[0].toLowerCase();
    const modifier = parts[1] ? parts[0].toLowerCase() : false;
    const isOption = modifier === 'opt';
    const isShift = modifier === 'shift';
    const isCmd = modifier === 'cmd';
    return { letter, isOption, isShift, isCmd };
}

export function getMetaKey() {
    const isMac = window.navigator.platform.toLowerCase().indexOf('mac') >= 0;
    return isMac ? 'Command' : 'Windows';
}

export function formatShortcut(str, returnArray = false) {
    let { letter, isOption, isShift, isCmd } = parseShortcut(str);
    const isMac = window.navigator.platform.toLowerCase().indexOf('mac') >= 0;
    if (letter === ' ') letter = 'spacebar';
    if (letter === 'ArrowLeft') letter = '←';
    if (letter === 'ArrowRight') letter = '→';
    if (letter === 'ArrowUp') letter = '↑';
    if (letter === 'ArrowDown') letter = '↓';
    if (letter.length === 1) letter = letter.toUpperCase();
    const chars = [];
    if (isCmd) chars.push(isMac ? '⌘' : 'Ctrl-');
    if (isShift) chars.push(isMac ? '⇧' : 'Shift-');
    if (isOption) chars.push(isMac ? '⌥' : 'Alt-');
    chars.push(letter.toUpperCase());
    return returnArray ? chars : chars.join('');
}

export function fName(fullName, args = {}) {
    const { withS, isCapitalized, before, after } = args;
    let str;
    if (!fullName) {
        return '';
    }
    str = fullName.split(' ')[0];
    if (withS) str = apostropheS(str);
    if (isCapitalized) str = capitalize(str);
    return `${before || ''}${str}${after || ''}`;
}

export function toggleInObject(key, object, value = null) {
    const newObject = { ...object };
    const exists = key in newObject;
    if (!exists) {
        newObject[key] = value;
    } else {
        delete newObject[key];
    }
    return newObject;
}

export function parseTemplate(str, fields, data) {
    const results = [];
    const variables = str && str.match(/\[(.*?)\]/g);
    if (Array.isArray(data)) {
        for (const oldDataItem of data) {
            const result = parseTemplateSingle(str, oldDataItem, variables, fields);
            results.push(result);
        }
    } else {
        return parseTemplateSingle(str, data, variables, fields);
    }
    return results;
}

function parseTemplateSingle(str, oldDataItem, variables, fields) {
    const dataItem = {};
    for (const key in oldDataItem) {
        // Convert keys to lowercase.
        if (oldDataItem.hasOwnProperty(key)) dataItem[key.toLowerCase()] = oldDataItem[key];
    }
    let thisStr = str;
    if (variables) for (const variable of variables) {
        let varStr = variable.substring(1).substring(0, variable.length - 2).toLowerCase();
        const field = fields.find(x => x.key.toLowerCase() === varStr || (x.name || '').toLowerCase() === varStr);
        if (!field) continue; // Keep variables that are not replaceable
        if (field.dataKey) field.key = field.dataKey; // Switch keys for parsing by formatData
        const value = formatData(dataItem, field, false).val;
        thisStr = thisStr.split(variable).join(value || ''); // Turn replaceable variables without value into an empty string
    }
    return thisStr;
}

export function secondsToTime(totalSeconds, displayInMinutes) {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    if ((displayInMinutes || hours > 0) && minutes.toString().length === 1) minutes = `0${minutes}`;
    let seconds = Math.floor(totalSeconds % 60);
    if (seconds.toString().length === 1) seconds = `0${seconds}`;
    return displayInMinutes ? `${hours}:${minutes}` : (hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`);
}

export function formatData(dataItem, field, returnVisual = false) {
    if (!dataItem || !field) return { rawVal: '', val: '' };
    const rawVal = dataItem[field.key] || field.defaultValue;
    let val = rawVal;
    if (typeof rawVal === 'string') val = capitalize(val);
    if (field.calc) val = field.calc(rawVal, dataItem);
    if (Array.isArray(val) && field.type !== 'labels') {
        val = val.join(', ');
    } else {
        // Default formatting
        if (field.type === 'currency') val = format('currency', val, field.format);
        else if (field.type === 'date') val = format('date', val, field.format);
        else if (field.type === 'number') val = parseInt(val);
        else if (field.type === 'time') val = format('time', val, field.format);
        else if (field.type === 'boolean' && val !== null) val = returnVisual ? <Icon padding={2} color={val ? 'green-600' : 'red-600'} colorDark={val ? 'green-500' : 'red-500'} icon={val ? 'check' : 'cross'} size={12} /> : (val ? '✓' : '✗');
        else if (field.type === 'array') val = null;
        else if (field.type === 'dropdown') {
            if (val) {
                const options = field.options || [];
                const index = typeof field.options[0] === 'object' ? (options.findIndex(x => x.key === val) || options.findIndex(x => x.key === val.toLowerCase())) : (options.indexOf(val) === -1 ? options.indexOf(val.toLowerCase()) : options.indexOf(val));
                const option = index > -1 ? options[index] : null;
                val = option && typeof option === 'object' ? capitalize(option.name || option.key) : capitalize(option);
            } else {
                val = null;
            }
        }
    }
    if (field.type === 'labels' && Array.isArray(val)) {
        if (returnVisual) {
            val = <Labels showDashIfEmpty align={field.align} labelIds={val} labels={field.labels} />;
        } else {
            val = val.map(labelId => {
                const label = field.labels.find(x => x.id === labelId);
                if (!label) return false;
                return label.name;
            }).filter(x => x).join(', ')
        }
    }
    return { rawVal, val };
}

export function stripCharacters(str) {
    // Strips out characters and spaces
    return str.replaceAll(/[^a-zA-Z0-9]/g, "");
}

export function getDayPart(lang = 'en') {
    const d = new Date();
    const time = d.getHours();
    if (lang === 'en') {
        if (time < 12) {
            return 'morning';
        } else if (time < 18) {
            return 'afternoon';
        } else {
            return 'evening';
        }
    } else if (lang === 'nl') {
        if (time < 12) {
            return 'ochtend';
        } else if (time < 18) {
            return 'middag';
        } else {
            return 'avond';
        }
    }
    return false;
}