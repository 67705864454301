import React from "react"
import {
  withData,
  withNotify,
  Button,
  Input,
  DemoBox,
  Loader,
  ButtonFile,
  getMetaKey,
  Dropdown,
  formatShortcut,
  Shortcut,
  PopoverCopyAs,
} from "../shared"
import ProjectFinding from "./ProjectFinding"
import { Route, Switch } from "react-router-dom"
import { getFindingFields, getQuoteFromTranscript } from "../functions"
import ProjectFindingsLabels from "./ProjectFindingsLabels"
import ProjectInterviewFindingsTranscript from "./ProjectInterviewFindingsTranscript"
import ProjectInterviewFindingsList from "./ProjectInterviewFindingsList"

class ProjectInterviewFindings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      languageCode: "en-US",
      isMarkOpen: false,
    }
  }
  render() {
    const langDropdown = (
      <Input
        value={this.state.languageCode}
        onBlur={(languageCode) => this.setState({ languageCode })}
        hasBorder
        type="dropdown"
        canClear={false}
        options={[
          { key: "", name: "Audio language", disabled: true },
          { key: "en-US", name: "English" },
          { key: "es-ES", name: "Spanish" },
          { key: "fr-FR", name: "French" },
          { key: "nl-NL", name: "Dutch" },
          { key: "de-DE", name: "German" },
        ]}
      />
    )
    const project = this.props.project
    const interview = this.props.interview
    let findings = this.props.findings.sort((a, b) => {
      if (a.time && !b.time) return 1
      if (!a.time && b.time) return -1
      if (a.time && b.time) return a.time - b.time
      return a.createdOn - b.createdOn
    })
    const findingsForCopy = findings.map((finding) => {
      const quote = getQuoteFromTranscript(
        interview.transcript,
        interview.transcriptEntities,
        finding,
        true,
      )
      return {
        ...finding,
        text: quote ? `${quote} ${finding.text || ""}` : finding.text || "",
      }
    })
    const isFocus = this.props.isFocus
    const labels = this.props.project.findingLabels || []
    const interviews = (this.props.interviews || [])
      .filter((x) => x.projectId === project.id)
      .map((x) => ({ ...x, duration: x.duration || project.duration || 60 }))
    const fields = getFindingFields(interviews, labels)
    const baseUrl = `/projects/${project.id}/interviews/${interview.id}/findings`
    const projectOwner = this.props.usersPublic.find(
      (x) => x.id === project.createdBy,
    )

    return (
      <div
        className={`animate-up flex h-full flex-1 flex-col overflow-hidden ${
          this.props.className || ""
        }`}
      >
        <Shortcut
          press="Escape"
          onPress={() => this.props.history.push(`/projects/${project.id}`)}
        />
        {this.props.isDemo && this.props.session.isOnboarded && (
          <DemoBox
            back={`/projects/${project.id}/emails/new`}
            step={7}
            backData={{
              toIds: ["demo-interview-4", "demo-interview-5"],
              subject: "Interview at [dateshort]",
              message:
                "Hi [firstname],\n\nThank you for applying to our coffee tasting! Based on your availability, I scheduled you on [date] at [time]. \n\nIf you have any questions, feel free to reply. See you then!\n\nDemo Denise\nResearcher, Lungo Forte",
            }}
            to="/projects/demo-project/findings"
            isRight
            primary="When the interview is done, we can transcribe and&nbsp;analyze it."
            secondary="The transcript you see here is automatically generated. You can freely edit it, label&nbsp;important findings, and add notes."
          />
        )}

        <Switch>
          <Route path="/projects/:projectId/interviews/:interviewId/findings/focus" />
          <Route path="/projects/:projectId/interviews/:interviewId/findings/timestamps" />
          <Route
            path="/projects/:projectId/interviews/:interviewId/findings/copy-as"
            render={(props) => (
              <PopoverCopyAs
                backTo={baseUrl}
                defaultFormat="[Finding] — [Name]"
                {...props}
                fields={fields}
                data={findingsForCopy}
                singular="finding"
                plural="findings"
              />
            )}
          />
          <Route
            path="/projects/:projectId/interviews/:interviewId/findings/labels"
            render={(props) => (
              <ProjectFindingsLabels backTo={baseUrl} {...props} />
            )}
          />
          <Route
            path="/projects/:projectId/interviews/:interviewId/findings/:findingId"
            render={(props) => <ProjectFinding {...props} isInterviewSingle />}
          />
        </Switch>
        <Route
          path="/projects/:projectId/interviews/:interviewId/findings/focus/copy-as"
          render={(props) => (
            <PopoverCopyAs
              backTo={`${baseUrl}/focus`}
              defaultFormat="[Finding] — [Name]"
              {...props}
              fields={fields}
              data={findingsForCopy}
              singular="finding"
              plural="findings"
            />
          )}
        />
        <Route
          path="/projects/:projectId/interviews/:interviewId/findings/focus/labels"
          render={(props) => (
            <ProjectFindingsLabels backTo={`${baseUrl}/focus`} {...props} />
          )}
        />

        {interview.transcriptProgressId || this.props.isLoading ? (
          <div className="animate-open flex flex-1 flex-col items-center justify-center">
            <Loader isColor />
            <h5
              className={`mt-8 ${
                interview.transcriptProgressId ? "mb-2" : "mb-1"
              }`}
            >
              {interview.transcriptProgressId
                ? "Transcribing..."
                : "Starting transcription..."}
            </h5>
            <p className="max-w-xs text-center text-gray-500">
              {interview.transcriptProgressId ? (
                <>
                  This may take a few minutes. You can close this interview in
                  the meantime.
                </>
              ) : (
                "Please hold on for a few seconds."
              )}
            </p>
          </div>
        ) : (
          <>
            <div
              className={`flex flex-none space-x-1 border-b py-2 px-8 ${
                isFocus ? "justify-center" : "justify-end"
              } border-light`}
            >
              {interview.transcript ? (
                <>
                  <Button
                    shortcut="cmd-b"
                    alsoWorksWhenInputInFocus
                    disabled={this.props.session.findingsForceFindingView}
                    onClick={() =>
                      this.setState({ isMarkOpen: !this.state.isMarkOpen })
                    }
                    isSmall
                    icon="edit"
                    title="Mark finding"
                  />
                  <Button
                    disabled={this.props.session.findingsForceFindingView}
                    onClick={() =>
                      this.props.setSession({
                        findingsHideMarkers:
                          !this.props.session.findingsHideMarkers,
                      })
                    }
                    isSmall
                    selected={
                      this.props.session.findingsHideMarkers &&
                      !this.props.session.findingsForceFindingView
                    }
                    icon="hide"
                    title="Hide finding marks"
                  />
                  <div className="px-1.5">
                    <div className="h-full border-l" />
                  </div>
                  <Button
                    isSmall
                    selected={!this.props.session.findingsForceFindingView}
                    onClick={() =>
                      this.props.setSession({ findingsForceFindingView: false })
                    }
                    icon="text"
                    title="Transcript view"
                  />
                  <Button
                    isSmall
                    selected={this.props.session.findingsForceFindingView}
                    onClick={() =>
                      this.props.setSession({ findingsForceFindingView: true })
                    }
                    icon="bullets"
                    title="Finding view"
                  />
                </>
              ) : (
                <>
                  <Dropdown
                    width={235}
                    arrowPosition="center"
                    approxHeight={280}
                    isInfo
                    button={
                      <Button
                        disabled={this.props.isUploading || this.props.isDemo}
                        isSmall
                        icon="add"
                        className="ml-auto"
                      >
                        Transcript
                      </Button>
                    }
                  >
                    <h6>Generate transcript</h6>
                    {project.createdBy === this.props.userId ? (
                      <>
                        {interview.mediaType ? (
                          interview.mediaType === "audio" ? (
                            <>
                              <p className="mt-2 mb-4 text-sm">
                                Use a microphone close to each speaker for best
                                results. Missing a language? You can request it
                                using the Help & feedback button.
                              </p>
                              {langDropdown}
                              <Button
                                className="mt-2 mb-5"
                                primary
                                full
                                disabled={!this.state.languageCode}
                                onClick={() =>
                                  this.props.onConfirm(this.state.languageCode)
                                }
                              >
                                Generate
                              </Button>
                            </>
                          ) : (
                            <>
                              <p className="mt-2 text-sm">
                                Transcription of video is currently not
                                supported. Please upload an audio file to
                                transcribe.
                              </p>
                              <p className="mb-4 mt-2 text-sm">
                                Use a microphone close to each speaker for best
                                results. Missing a language? You can request it
                                using the Help & feedback button.
                              </p>
                              {langDropdown}
                              <ButtonFile
                                className="mt-2 mb-5"
                                disabled={!this.state.languageCode}
                                accept="audio/mpeg,audio/wav,audio/mp4,audio/x-m4a"
                                primary
                                full
                                onChange={(e) =>
                                  this.props.onUpload(
                                    e,
                                    this.state.languageCode,
                                  )
                                }
                              >
                                Select audio & generate
                              </ButtonFile>
                            </>
                          )
                        ) : (
                          <>
                            <p className="mb-4 mt-2 text-sm">
                              Use a microphone close to each speaker for best
                              results. Missing a language? You can request it
                              using the Help & feedback button.
                            </p>
                            {langDropdown}
                            <ButtonFile
                              className="mt-2 mb-5"
                              disabled={!this.state.languageCode}
                              accept="audio/mpeg,audio/wav,audio/mp4,audio/x-m4a"
                              primary
                              full
                              onChange={(e) =>
                                this.props.onUpload(e, this.state.languageCode)
                              }
                            >
                              Select audio & generate
                            </ButtonFile>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="mb-4 mt-2 text-sm">
                          Only {projectOwner?.name || "the project owner"} can
                          generate a transcript. You can ask them to add one.
                        </p>
                      </>
                    )}
                    <h6>Add manually</h6>
                    <p className="mt-2 text-sm">
                      If you already have a transcript, you can also add it
                      manually.
                    </p>
                    <Button
                      onClick={() =>
                        this.props.update("interviews", interview.id, {
                          transcript: [],
                          transcriptEntities: {},
                        })
                      }
                      secondary
                      full
                      className="mt-3"
                    >
                      Add manually
                    </Button>
                  </Dropdown>
                </>
              )}
              <div className="px-1.5">
                <div className="h-full border-l" />
              </div>
              <Button
                isSmall
                icon="label"
                to={`${baseUrl}${isFocus ? "/focus" : ""}/labels`}
                title="Edit labels"
              />
              <Button
                disabled={!findings.length}
                isSmall
                to={`${baseUrl}${isFocus ? "/focus" : ""}/copy-as`}
                toData={{ selectedIds: findings.map((x) => x.id) }}
                icon="copy"
                title="Copy"
              />
              <Dropdown
                arrowPosition="right"
                width={240}
                approxHeight={250}
                button={<Button isSmall icon="help" />}
              >
                {interview.transcript &&
                !this.props.session.findingsForceFindingView ? (
                  <>
                    <h6 className="pf">Using transcript view</h6>
                    <ul className="pr-2 pb-2 text-sm">
                      <li>
                        Select text from the transcript and click the marker
                        icon in the toolbar to turn it into a finding, or simply
                        press {formatShortcut("cmd-b")}.
                      </li>
                      <li className="mt-2">
                        You can also quickly create a finding (without adding a
                        note) by right-clicking a text selection.
                      </li>
                      <li className="mt-2">
                        Labels of findings will show up on the right side. Click
                        on a label to view options.
                      </li>
                      {interview.mediaType && interview.transcriptTimes && (
                        <li className="mt-2">
                          Click a word in the transcript when holding the{" "}
                          {getMetaKey()} key to go there in the{" "}
                          {interview.mediaType}.
                        </li>
                      )}
                      {interview.mediaType && (
                        <li className="mt-2">
                          You can play {interview.mediaType} by pressing{" "}
                          {formatShortcut("cmd-p")}. Press{" "}
                          {formatShortcut("cmd-ArrowDown")} to go back a few
                          seconds, or {formatShortcut("cmd-ArrowUp")} to go
                          forward.
                        </li>
                      )}
                    </ul>
                  </>
                ) : (
                  <>
                    <h6 className="pf">Using finding view</h6>
                    <ul className="pr-2 pb-2 text-sm">
                      <li>Right-click on a finding to show more options.</li>
                      <li className="mt-2">
                        Mark a key finding by writing an asterisk (*) at the
                        start or end.
                      </li>
                      <li className="mt-2">
                        If you have your findings written elsewhere, just paste
                        them in the input field: they will be separated
                        automatically.
                      </li>
                      {interview.mediaType && (
                        <li className="mt-2">
                          You can play {interview.mediaType} by pressing{" "}
                          {formatShortcut("cmd-p")}. Press{" "}
                          {formatShortcut("cmd-ArrowDown")} to go back a few
                          seconds, or {formatShortcut("cmd-ArrowUp")} to go
                          forward.
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </Dropdown>
              {!isFocus && (
                <Button
                  isSmall
                  onClick={() => {
                    this.props.toggle(false) // Pause media before navigating to focus to present two sources playing.
                  }}
                  icon="expand"
                  title="Focus"
                  to={`${baseUrl}/focus`}
                />
              )}
            </div>
            {interview.transcript &&
              !this.props.session.findingsForceFindingView && (
                <ProjectInterviewFindingsTranscript
                  isMarkOpen={this.state.isMarkOpen}
                  closeMark={() => this.setState({ isMarkOpen: false })}
                  labels={project.findingLabels}
                  findings={findings}
                  baseUrl={baseUrl}
                  fields={fields}
                  mediaType={interview.mediaType}
                  transcript={interview.transcript}
                  transcriptEntities={interview.transcriptEntities}
                  transcriptTimes={interview.transcriptTimes}
                  mediaSeconds={this.props.mediaSeconds}
                  isFocus={isFocus}
                  setTime={this.props.setTime}
                />
              )}
            {(!interview.transcript ||
              this.props.session.findingsForceFindingView) && (
              <ProjectInterviewFindingsList
                labels={project.findingLabels}
                findings={findings}
                baseUrl={baseUrl}
                fields={fields}
                isFocus={isFocus}
                transcript={interview.transcript}
                transcriptEntities={interview.transcriptEntities}
                hasMedia={!!interview.mediaUrl}
                name={interview.name}
                mediaSeconds={this.props.mediaSeconds}
                setTime={this.props.setTime}
              />
            )}
          </>
        )}
      </div>
    )
  }
}

export default withData(withNotify(ProjectInterviewFindings))
