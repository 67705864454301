import React from "react"
import { Icon, Button, Input, Loader, withNotify } from "../shared"
import firebase from "firebase/app"
import "firebase/auth"

import { withRouter } from "react-router-dom"

class Action extends React.Component {
  constructor(props) {
    super(props)
    const urlParams = new URLSearchParams(this.props.location.search)
    this.mode = urlParams.get("mode")
    this.actionCode = urlParams.get("oobCode")
    this.state = {
      email: "",
      password: "",
      password2: "",
      isLoading: false,
      isInit: false,
    }
  }
  async componentDidMount() {
    if (this.mode === "resetPassword") {
      try {
        const email = await firebase
          .auth()
          .verifyPasswordResetCode(this.actionCode)
        this.setState({ email, isInit: true })
      } catch (e) {
        this.setState({ isInit: true })
      }
    } else {
      this.setState({ isInit: true })
    }
  }
  async finalize(e) {
    e.preventDefault()
    const { password, password2 } = this.state
    if (!password || !password2)
      return this.props.error("Please enter both passwords")
    if (password.length < 6)
      return this.props.error("Please use a longer password")
    if (password !== password2)
      return this.props.error("Passwords do not match")
    try {
      this.setState({ isLoading: true })
      await firebase.auth().confirmPasswordReset(this.actionCode, password)
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, password)
      this.props.success("Password changed!")
      this.props.history.push("/")
    } catch (e) {
      return this.props.error(e.message)
    }
    this.setState({ isLoading: false })
  }
  render() {
    if (!this.state.isInit) return <Loader fullScreen />
    return (
      <div className="flex h-full items-center justify-center">
        <form
          onSubmit={this.finalize.bind(this)}
          className={`w-full max-w-sm select-none ${
            this.state.isLoading ? "pointer-events-none" : ""
          }`}
        >
          {this.mode === "resetPassword" ? (
            this.state.email ? (
              <>
                <div className="mb-4 flex flex-col items-center">
                  <Icon icon="lock" color={window.appColor} />
                </div>
                <h3 className={`mb-10 text-center font-bold`}>
                  Set a new password
                </h3>
                <h6 className="mt-6 mb-2">Password</h6>
                <Input
                  hasBorder
                  autoFocus
                  type="password"
                  placeholder="••••••••••••"
                  value={this.state.password}
                  onChange={(password) => this.setState({ password })}
                />
                <h6 className="mt-6 mb-2">Password (again)</h6>
                <Input
                  hasBorder
                  type="password"
                  placeholder="••••••••••••"
                  value={this.state.password2}
                  onChange={(password2) => this.setState({ password2 })}
                />
                <Button
                  type="submit"
                  primary
                  loading={this.state.isLoading}
                  className="mt-8 w-full"
                >
                  Confirm
                </Button>
              </>
            ) : (
              <p>
                Something went wrong. Please contact us at
                support@interviewkit.co for help.
              </p>
            )
          ) : (
            <p>
              This is currently not supported. Please contact us at
              support@interviewkit.co for help.
            </p>
          )}
        </form>
      </div>
    )
  }
}

export default withNotify(withRouter(Action))
