import React from "react"
import {
  Page,
  fName,
  format,
  DemoBox,
  DropdownItem,
  Callout,
  withData,
  Button,
  Radio,
  withNotify,
  Input,
  RadioItem,
  InputGroup,
  Dropdown,
  DropdownDateFormat,
  parseTemplate,
  join,
} from "../shared"
import moment from "moment"
import { functions } from "../index"
import { Checkbox, toggleInArray } from "../shared"
import { withRouter } from "react-router-dom"

class ProjectEmailNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSendingTest: false,
      toIds: this.props.session.emailToIds || [],
      previewId: false,
      subject: this.props.session.emailSubject || "",
      message: this.props.session.emailMessage || "",
      sendWhen: this.props.session.emailSendWhen || "now",
      dateLocale: this.props.session.emailDateLocale || false,
      dateFormat: this.props.session.emailDateFormat || false,
      timeFormat: this.props.session.emailTimeFormat || false,
      addlabelIds: this.props.session.emailAddlabelIds || [],
      removelabelIds: this.props.session.emailRemovelabelIds || [],
      min: parseInt(this.props.session.emailMin) || 15,
      date: parseInt(this.props.session.emailDate) || null,
      time: this.props.session.emailTime || null,
      ...this.props.location.state,
    }
    this.messageRef = React.createRef()
  }
  add(interviews) {
    let toIds = [...this.state.toIds]
    for (const interview of interviews) {
      if (toIds.indexOf(interview.id) === -1) {
        toIds.push(interview.id)
      }
    }
    this.setState({ toIds })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.dateLocale !== this.state.dateLocale ||
      prevState.dateFormat !== this.state.dateFormat ||
      prevState.timeFormat !== this.state.timeFormat ||
      prevState.addlabelIds.join(",") !== this.state.addlabelIds.join(",") ||
      prevState.removelabelIds.join(",") !==
        this.state.removelabelIds.join(",") ||
      prevState.sendWhen !== this.state.sendWhen ||
      prevState.date !== this.state.date ||
      prevState.time !== this.state.time ||
      prevState.min !== this.state.min ||
      prevState.message !== this.state.message ||
      prevState.subject !== this.state.subject ||
      prevState.toIds.join(",") !== this.state.toIds.join(",")
    ) {
      this.props.setSession({
        emailToIds: this.state.toIds,
        emailSubject: this.state.subject,
        emailMessage: this.state.message,
        emailDateLocale: this.state.dateLocale,
        emailDateFormat: this.state.dateFormat,
        emailTimeFormat: this.state.timeFormat,
        emailAddlabelIds: this.state.addlabelIds,
        emailRemovelabelIds: this.state.removelabelIds,
        emailSendWhen: this.state.sendWhen,
        emailDate: this.state.date,
        emailTime: this.state.time,
        emailMin: this.state.min,
      })
    }
  }
  async clearSession() {
    return this.props.setSession({
      emailToIds: null,
      emailSubject: null,
      emailMessage: null,
      emailDateLocale: null,
      emailDateFormat: null,
      emailTimeFormat: null,
      emailAddlabelIds: null,
      emailRemovelabelIds: null,
      emailSendWhen: null,
      emailDate: null,
      emailTime: null,
      emailMin: null,
    })
  }
  async cancel(backUrl) {
    const { toIds, subject, message } = this.state
    const isEmpty = !(toIds.length || subject || message)
    const confirm =
      isEmpty ||
      (await this.props.warn(
        "Delete this draft?",
        "Your email will be lost.",
        "Delete",
      ))
    if (confirm) {
      await this.clearSession()
      this.props.history.push(backUrl)
    }
  }
  parse(toInterviews, project, forId = this.state.previewId, force) {
    if (force && !forId && toInterviews.length) forId = toInterviews[0].id
    const previewInterview = forId
      ? toInterviews.find((x) => x.id === forId) || {}
      : false
    const previewIndex = forId
      ? toInterviews.findIndex((x) => x.id === forId)
      : false
    const user = this.props.getUser()
    const fields = previewInterview
      ? [
          { key: "name" },
          { key: "firstname", defaultValue: fName(previewInterview.name) },
          {
            key: "date",
            type: "date",
            format: {
              day: "full",
              month: "full",
              year: false,
              dateFormat: this.state.dateFormat,
              dateLocale: this.state.dateLocale,
            },
          },
          {
            key: "dateshort",
            dataKey: "date",
            type: "date",
            format: {
              day: false,
              year: false,
              dateFormat: this.state.dateFormat,
              dateLocale: this.state.dateLocale,
            },
          },
          {
            key: "dateday",
            dataKey: "date",
            type: "date",
            format: { format: "dddd", dateLocale: this.state.dateLocale },
          },
          {
            key: "time",
            dataKey: "date",
            type: "time",
            format: this.state.timeFormat,
          },
          {
            key: "duration",
            type: "number",
            defaultValue: project.duration || 60,
          },
          { key: "myname", defaultValue: user.name },
          { key: "myfirstname", defaultValue: fName(user.name) },
          { key: "project", defaultValue: project.name },
        ]
      : false
    const parsed = previewInterview
      ? parseTemplate(this.state.message, fields, previewInterview)
      : false
    const parsedSubject = previewInterview
      ? parseTemplate(this.state.subject, fields, previewInterview)
      : false
    return { previewInterview, previewIndex, parsed, parsedSubject }
  }
  async setTemplate(template) {
    const isConfirm =
      this.state.subject || this.state.message
        ? await this.props.warn(
            "Overwrite message?",
            "Setting this template will overwrite your subject, message and date settings.",
            "Set template",
          )
        : true
    if (isConfirm) {
      this.setState({
        subject: template.subject || "",
        message: template.message || "",
        dateLocale: template.dateLocale || false,
        dateFormat: template.dateFormat || false,
        timeFormat: template.timeFormat || false,
      })
    }
  }
  async sendTest(toInterviews, project, email) {
    this.setState({ isSendingTest: true })
    const sendTestEmail = functions.httpsCallable("sendTestEmail")
    const { message } = this.state
    const interview = toInterviews[0] || {}
    const user = this.props.getUser()
    const { parsedSubject } = this.parse(
      toInterviews,
      project,
      interview.id,
      true,
    )
    const { data } = await sendTestEmail({
      projectId: project.id,
      subject: parsedSubject,
      message,
      substitutions: {
        name: interview.name,
        firstname: fName(interview.name),
        date: format("date", interview.date, {
          day: "full",
          month: "full",
          year: false,
          dateFormat: this.state.dateFormat,
          dateLocale: this.state.dateLocale,
        }),
        dateshort: format("date", interview.date, {
          day: false,
          year: false,
          dateFormat: this.state.dateFormat,
          dateLocale: this.state.dateLocale,
        }),
        dateday: format("date", interview.date, {
          format: "dddd",
          dateLocale: this.state.dateLocale,
        }),
        time: format("time", interview.date),
        duration: `${interview.duration || project.duration || 60}`,
        myname: user.name,
        myfirstname: fName(user.name),
        project: project.name,
      },
    })
    if (data && data.isSuccess) {
      this.props.success("Test email sent", `To you at ${email}`)
    } else {
      this.props.error("Could not send test email", (data || {}).message)
    }
    this.setState({ isSendingTest: false })
  }
  insert(str) {
    let message = this.state.message
    if (!message.endsWith(" ")) {
      message += " "
    }
    message += str
    this.setState({ message })
    this.messageRef.current.focus()
  }
  async sendNow(toInterviews, project) {
    // Ask for confirmation first
    const isScheduled = this.state.sendWhen !== "now"
    const confirm = await this.props.openModal({
      icon: "send",
      action: isScheduled ? "Schedule" : "Send now",
      title: `${isScheduled ? "Schedule" : "Send"} ${
        toInterviews.length
      } email${toInterviews.length === 1 ? "" : "s"}?`,
      desc: (
        <>
          This message will be sent{" "}
          {isScheduled
            ? this.state.sendWhen === "later"
              ? `${format("date", this.state.date, {
                  month: "full",
                  year: "not-current",
                  isRelative: true,
                })} at ${format("time", this.state.time)}`
              : `${this.state.min} minutes ${
                  this.state.sendWhen === "before-interview"
                    ? "before"
                    : "after"
                }`
            : "immediately"}{" "}
          {this.state.sendWhen.endsWith("-interview")
            ? `${toInterviews.length} interview${
                toInterviews.length === 1 ? "" : "s"
              }.`
            : `to ${toInterviews.length} interviewee${
                toInterviews.length === 1 ? "" : "s"
              }.`}
        </>
      ),
    })
    if (!confirm) return false

    // Send the email
    this.setState({ isSending: true })
    const { message } = this.state
    const personalizations = []
    const user = this.props.getUser()
    for (const interview of toInterviews) {
      const { parsedSubject, parsed } = this.parse(
        toInterviews,
        project,
        interview.id,
      )
      const obj = {
        projectId: project.id,
        to: {
          name: interview.name,
          email: interview.email,
        },
        messageParsed: parsed,
        subject: parsedSubject,
        substitutions: {
          name: interview.name,
          firstname: fName(interview.name),
          date: format("date", interview.date, {
            day: "full",
            month: "full",
            year: false,
            dateFormat: this.state.dateFormat,
            dateLocale: this.state.dateLocale,
          }),
          dateshort: format("date", interview.date, {
            day: false,
            year: false,
            dateFormat: this.state.dateFormat,
            dateLocale: this.state.dateLocale,
          }),
          dateday: format("date", interview.date, {
            format: "dddd",
            dateLocale: this.state.dateLocale,
          }),
          time: format("time", interview.date),
          duration: `${interview.duration || project.duration || 60}`,
          myname: user.name,
          myfirstname: fName(user.name),
          project: project.name,
        },
      }
      if (this.state.sendWhen === "later") {
        obj.scheduledAt = moment(this.state.date, "X")
          .hours(this.state.time.split(":")[0])
          .minutes(this.state.time.split(":")[1])
          .startOf("minute")
          .unix()
      } else if (this.state.sendWhen.endsWith("-interview")) {
        const isBefore = this.state.sendWhen === "before-interview"
        obj.scheduledAt = `${moment(interview.date, "X")
          .add(this.state.min * (isBefore ? -1 : 1), "minutes")
          .unix()}`
      }

      personalizations.push(obj)
    }

    for (const p of personalizations) {
      if (p.scheduledAt) {
        const m = moment(p.scheduledAt, "X")
        if (m.isSameOrBefore(moment().add(6, "minute")))
          return this.props.error(
            "Email scheduled too early",
            "Please schedule at least five minutes in the future.",
          )
      }
    }

    // Check limit
    const newMeter = (user.meterEmail || 0) + personalizations.length
    if (newMeter > user.maxEmail)
      return this.props.error("You have not enough emails left in your plan")

    const sendOrScheduleEmail = functions.httpsCallable("sendOrScheduleEmail")
    const { data } = await sendOrScheduleEmail({
      message,
      personalizations,
      subjectUnparsed: this.state.subject || "",
      projectId: project.id,
    })

    if (data && data.isSuccess) {
      // Update label ID's
      if (this.state.addlabelIds.length || this.state.removelabelIds.length) {
        const obj = {}
        for (const interview of toInterviews) {
          let labelIds = [...(interview.labelIds || [])]
          for (const labelId of this.state.addlabelIds) {
            labelIds = toggleInArray(labelId, labelIds, true)
          }
          for (const labelId of this.state.removelabelIds) {
            labelIds = toggleInArray(labelId, labelIds, false)
          }
          obj[interview.id] = { labelIds }
        }
        await this.props.updateMultiple("interviews", obj)
      }
      await this.props.fetch({
        collection: "emails",
        id: data.emailId,
        force: true,
      }) // Add force here to broadcast the new email to other members.
      this.props.success(
        `${toInterviews.length} email${toInterviews.length === 1 ? "" : "s"} ${
          this.state.sendWhen === "now" ? "sent" : "scheduled"
        }`,
      )
      await this.clearSession()
      this.props.history.push(
        `/projects/${this.props.match.params.projectId}/emails/${data.emailId}`,
      )
    } else {
      this.setState({ isSending: false })
      this.props.error("Could not send email", (data || {}).message)
    }
  }
  render() {
    const project = this.props.projects.find(
      (x) => x.id === this.props.match.params.projectId,
    )
    const interviews = this.props.interviews.filter(
      (x) => x.projectId === this.props.match.params.projectId,
    )
    if (interviews.length === 0) return null
    const user = this.props.getUser()
    const templates = project.emailTemplates || []

    const MAIL_INTERVAL = 15 // Keep this in sync with server
    const prev = interviews.sort((a, b) => a.createdOn - b.createdOn)[0] // First interview used as preview
    const isScheduled = this.state.sendWhen !== "now"
    const scheduledMoment =
      this.state.sendWhen === "later" && this.state.date && this.state.time
        ? moment(this.state.date, "X")
            .hours(this.state.time.split(":")[0])
            .minutes(this.state.time.split(":")[1])
            .startOf("minute")
        : false
    let isScheduledBeforeNow =
      isScheduled &&
      scheduledMoment &&
      scheduledMoment.isSameOrBefore(
        moment().add(MAIL_INTERVAL, "minutes"),
        "minute",
      )
    let isScheduledAfter3Months =
      isScheduled &&
      scheduledMoment &&
      scheduledMoment.isSameOrAfter(moment().add(3, "months"), "hour")

    const toInterviews = this.state.toIds.map((id) =>
      interviews.find((x) => x.id === id),
    )
    const isSomeWithoutEmailError = toInterviews.some((x) => !x.email)
    const namesWithoutEmail = isSomeWithoutEmailError
      ? toInterviews.filter((x) => !x.email).map((x) => x.name || "Unnamed")
      : null
    const isSomeNotScheduled = toInterviews.some((x) => !x.date)
    const isSendLaterAndSomeNotScheduledError =
      isSomeNotScheduled && this.state.sendWhen.includes("-interview")
    const isVariableAndSomeNotScheduledError =
      isSomeNotScheduled &&
      ["[date]", "[dateshort]", "[dateday]", "[time]"].some((x) =>
        this.state.message.includes(x),
      )
    const namesUnscheduled =
      isSendLaterAndSomeNotScheduledError || isVariableAndSomeNotScheduledError
        ? toInterviews.filter((x) => !x.date).map((x) => x.name)
        : null

    const ivs = toInterviews
      .filter((x) => x.date)
      .sort((a, b) => a.date - b.date)
    if (this.state.sendWhen.includes("-interview")) {
      const isBefore = this.state.sendWhen === "before-interview"
      const firstInterview = ivs[0]
      const lastInterview = ivs[ivs.length - 1]
      isScheduledBeforeNow =
        firstInterview &&
        moment(firstInterview.date, "X")
          .add(this.state.min * (isBefore ? -1 : 1), "minutes")
          .isSameOrBefore(moment().add(6, "minutes"), "minute")
      isScheduledAfter3Months =
        lastInterview &&
        moment(lastInterview.date, "X")
          .add(this.state.min * (isBefore ? -1 : 1), "minutes")
          .isSameOrAfter(moment().add(3, "months"), "hour")
    }

    const interviewsScheduled = interviews.filter((x) => x.date)
    const interviewsUnscheduled = interviews.filter((x) => !x.date)
    const interviewsEmailed = interviews.filter((interview) =>
      (this.props.emails || []).some((email) =>
        email.sends.map((s) => s.to).some((to) => to.email === interview.email),
      ),
    )
    const interviewsNotEmailed = interviews.filter(
      (interview) => !interviewsEmailed.map((x) => x.id).includes(interview.id),
    )
    const isLaterWithoutDateOrTime =
      (this.state.sendWhen.includes("-interview") && !this.state.min) ||
      (this.state.sendWhen === "later" &&
        (!this.state.date || !this.state.time))
    const toEmails = toInterviews.map((x) => x.email)
    const doubleEmailAddress = toEmails.find(
      (email, index) => toEmails.indexOf(email) !== index,
    )

    const isFieldMissing = !(
      this.state.toIds.length &&
      this.state.subject &&
      this.state.message
    )

    const { previewInterview, previewIndex, parsed, parsedSubject } =
      this.parse(toInterviews, project)

    const isSendDisabled =
      this.props.isDemo ||
      isFieldMissing ||
      doubleEmailAddress ||
      isScheduledBeforeNow ||
      isScheduledAfter3Months ||
      isSomeWithoutEmailError ||
      isLaterWithoutDateOrTime ||
      isSendLaterAndSomeNotScheduledError ||
      isVariableAndSomeNotScheduledError
    const backUrl = `/projects/${project.id}/emails${
      this.props.match.params.emailId
        ? `/${this.props.match.params.emailId}`
        : ""
    }`

    const more = (
      <Dropdown
        width={230}
        button={<Button isLoading={this.state.isSendingTest} icon="more" />}
      >
        <DropdownItem
          disabled={!toInterviews.length}
          icon="view"
          onClick={() => this.setState({ previewId: this.state.toIds[0] })}
        >
          Preview
        </DropdownItem>
        <DropdownItem
          disabled={isFieldMissing || this.props.isDemo}
          icon="send"
          onClick={() => this.sendTest(toInterviews, project, user.email)}
        >
          Send me a test email
        </DropdownItem>
        {(!toInterviews.length || isFieldMissing) && (
          <p className="sub p mt-1 mb-2">
            {!toInterviews.length
              ? 'Add people to the "to" field to preview and send a test email.'
              : isFieldMissing
              ? "Add a subject and message to send a test email."
              : ""}
          </p>
        )}
        <h6 className="p">Date settings</h6>
        <DropdownDateFormat
          doc={this.state}
          onBlur={(data) => this.setState(data)}
        />
        <p className="sub p pt-3 pb-1">
          Variables will be displayed in the selected format and language.
        </p>
      </Dropdown>
    )

    return (
      <Page
        isPopup
        singular="draft"
        width="3xl"
        title="New email"
        height={650}
        backTo={backUrl}
        hasPadding
        bar={
          <>
            <Dropdown button={<Button title="Variables" icon="add" />}>
              <h6 className="pf">Insert variable</h6>
              <DropdownItem
                onClick={() => this.insert("[name]")}
                desc={prev.name}
              >
                Interviewee name
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[firstname]")}
                desc={fName(prev.name)}
              >
                Interviewee first name
              </DropdownItem>
              <DropdownItem
                borderTop
                onClick={() => this.insert("[date]")}
                desc={format("date", prev.date || "today", {
                  day: "full",
                  month: "full",
                  year: false,
                  dateLocale: this.state.dateLocale,
                  dateFormat: this.state.dateFormat,
                })}
              >
                Full interview date
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[dateshort]")}
                desc={format("date", prev.date || "today", {
                  month: "full",
                  year: false,
                  dateLocale: this.state.dateLocale,
                  dateFormat: this.state.dateFormat,
                })}
              >
                Short interview date
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[dateday]")}
                desc={format("date", prev.date || "today", {
                  format: "dddd",
                  dateLocale: this.state.dateLocale,
                })}
              >
                Interview day
              </DropdownItem>
              <DropdownItem
                borderTop
                onClick={() => this.insert("[time]")}
                desc={format("time", prev.date || "123000", {
                  to: this.state.timeFormat,
                })}
              >
                Interview time
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[duration]")}
                desc={prev.duration || project.duration || 60}
              >
                Interview duration (min.)
              </DropdownItem>
              <DropdownItem
                borderTop
                onClick={() => this.insert("[project]")}
                desc={project.name}
              >
                Project name
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[myname]")}
                desc={user.name || "Jim Example"}
              >
                Your name
              </DropdownItem>
              <DropdownItem
                onClick={() => this.insert("[myfirstname]")}
                desc={fName(user.name) || "Jim"}
              >
                Your first name
              </DropdownItem>
            </Dropdown>
            <Dropdown
              width={templates.length ? 260 : 200}
              button={<Button title="Templates" icon="folder" />}
            >
              <h6 className="pf">Use template</h6>
              {templates.length ? (
                templates.map((template) => (
                  <DropdownItem
                    key={template.id}
                    onClick={() => this.setTemplate(template)}
                    desc={template.message.substring(0, 80)}
                  >
                    {template.subject || "No subject"}
                  </DropdownItem>
                ))
              ) : (
                <DropdownItem disabled>No templates</DropdownItem>
              )}
              <DropdownItem borderTop to={`${backUrl}/new/templates`}>
                Manage templates
              </DropdownItem>
            </Dropdown>
            <Dropdown
              width={220}
              button={<Button title="Schedule" icon="clock" />}
            >
              <h6 className="pf">Send</h6>
              <Radio
                value={this.state.sendWhen}
                onChange={(sendWhen) => this.setState({ sendWhen })}
              >
                <RadioItem value="now">Now</RadioItem>
                <RadioItem value="later">Later</RadioItem>
                <RadioItem value="before-interview">Before interview</RadioItem>
                <RadioItem value="after-interview">After interview</RadioItem>
              </Radio>
              {this.state.sendWhen === "later" ? (
                <>
                  <h6 className="p">Schedule</h6>
                  <InputGroup
                    doc={this.state}
                    beforeWidth={40}
                    className="p mb-2"
                    onBlur={(data) => this.setState(data)}
                    fields={[
                      {
                        key: "date",
                        type: "date",
                        placeholder: "Set date...",
                        format: { day: "full", year: false },
                      },
                      { key: "time", type: "time", placeholder: "Set time..." },
                    ]}
                  />
                </>
              ) : this.state.sendWhen.includes("-interview") ? (
                <>
                  <h6 className="p">Schedule</h6>
                  <Input
                    defaultValue={this.state.min}
                    onBlur={(min) => this.setState({ min })}
                    className="mx-3 mb-2"
                    type="number"
                    after={`min. ${
                      this.state.sendWhen === "before-interview"
                        ? "before"
                        : "after"
                    } interview`}
                  />
                </>
              ) : null}
            </Dropdown>
            {project.interviewLabels && project.interviewLabels.length ? (
              <Dropdown
                width={240}
                button={<Button title="Labels" icon="label" />}
              >
                <h6 className="pf">Add label</h6>
                {project.interviewLabels.map((x) => {
                  return (
                    <Checkbox
                      isActive={this.state.addlabelIds.includes(x.id)}
                      key={x.id}
                      onChange={() => {
                        const addlabelIds = toggleInArray(
                          x.id,
                          this.state.addlabelIds,
                        )
                        const obj = { addlabelIds }
                        if (this.state.removelabelIds.includes(x.id))
                          obj.removelabelIds = toggleInArray(
                            x.id,
                            this.state.removelabelIds,
                          )
                        this.setState(obj)
                      }}
                    >
                      {x.name}
                    </Checkbox>
                  )
                })}
                <h6 className="p">Remove label</h6>
                {project.interviewLabels.map((x) => {
                  return (
                    <Checkbox
                      isActive={this.state.removelabelIds.includes(x.id)}
                      key={x.id}
                      onChange={() => {
                        const removelabelIds = toggleInArray(
                          x.id,
                          this.state.removelabelIds,
                        )
                        const obj = { removelabelIds }
                        if (this.state.addlabelIds.includes(x.id))
                          obj.addlabelIds = toggleInArray(
                            x.id,
                            this.state.addlabelIds,
                          )
                        this.setState(obj)
                      }}
                    >
                      {x.name}
                    </Checkbox>
                  )
                })}
                <p className="sub p pt-3 pb-1">
                  After you press send, these labels will be added to or removed
                  from these interviews.
                </p>
              </Dropdown>
            ) : null}
            {more}
          </>
        }
        barRight={
          <>
            <Button onClick={() => this.cancel(backUrl)}>Cancel</Button>
            <Button
              isLoading={this.state.isSending}
              onClick={() => this.sendNow(toInterviews, project)}
              primary
              disabled={isSendDisabled}
              className="ml-2"
            >
              {isScheduled ? "Schedule" : "Send"}
            </Button>
          </>
        }
      >
        {this.props.isDemo && this.props.session.isOnboarded && (
          <DemoBox
            step={6}
            to={`/projects/${project.id}/interviews/demo-interview-3/findings`}
            back="/projects/demo-project/interviews/schedule"
            backData={{
              selectedIds: [
                "demo-interview-4",
                "demo-interview-5",
                "demo-interview-7",
                "demo-interview-8",
              ],
            }}
            primary="To inform them of their new interview times, we can email them at once."
            secondary="With variables and templates, sending an email to all interviewees with their personal interview details takes about a minute."
          />
        )}
        <div className="flex h-full flex-col">
          {doubleEmailAddress ? (
            <Callout isWarning className="mb-5">
              Some interviewees share the same email address (
              {doubleEmailAddress}).
            </Callout>
          ) : isSomeWithoutEmailError ? (
            <Callout isWarning className="mb-5">
              {join(namesWithoutEmail, "and", "does", "do")} not have an email
              address.
            </Callout>
          ) : isVariableAndSomeNotScheduledError ? (
            <Callout isWarning className="mb-5">
              You included date and/or time variables, but the interview
              {namesUnscheduled.length === 1 ? "" : "s"} of{" "}
              {join(namesUnscheduled, "and", "does", "do")} not have a date and
              time yet.
            </Callout>
          ) : isScheduledBeforeNow ? (
            <Callout isWarning className="mb-5">
              Please schedule at least 15 minutes in the future.
            </Callout>
          ) : isScheduledAfter3Months ? (
            <Callout isWarning className="mb-5">
              You cannot schedule for more than 3 months in the future.
            </Callout>
          ) : isSendLaterAndSomeNotScheduledError ? (
            <Callout isWarning className="mb-5">
              You scheduled this email to be sent {this.state.min} minutes{" "}
              {this.state.sendWhen === "before-interview" ? "before" : "after"}{" "}
              each interview, but the interview
              {namesUnscheduled.length === 1 ? "" : "s"} of{" "}
              {join(namesUnscheduled, "and", "does", "do")} not have a date and
              time yet.
            </Callout>
          ) : null}
          <InputGroup
            doc={
              previewInterview
                ? { toIds: this.state.toIds, subject: parsedSubject }
                : this.state
            }
            onBlur={(data) => this.setState(data)}
            beforeWidth={70}
            disabled={!!this.state.previewId}
            fields={[
              {
                key: "toIds",
                before: "To",
                type: "document",
                multiple: true,
                defaultIds: this.state.toIds,
                options: interviews.map((x) => ({
                  name: x.name,
                  desc: x.email,
                  id: x.id,
                })),
                button: (
                  <Dropdown
                    arrowPosition="right"
                    width={220}
                    button={<Button icon="add" isSmall />}
                  >
                    <h6 className="pf">Quick add</h6>
                    <DropdownItem
                      onClick={() => this.add(interviews)}
                      disabled={interviews.length === 0}
                      sub={interviews.length}
                    >
                      All interviewees
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.add(interviewsScheduled)}
                      icon="clock"
                      disabled={interviewsScheduled.length === 0}
                      borderTop
                      sub={interviewsScheduled.length}
                    >
                      Scheduled
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.add(interviewsUnscheduled)}
                      icon="clock"
                      disabled={interviewsUnscheduled.length === 0}
                      sub={interviewsUnscheduled.length}
                    >
                      Unscheduled
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.add(interviewsEmailed)}
                      icon="envelope"
                      disabled={interviewsEmailed.length === 0}
                      borderTop
                      sub={interviewsEmailed.length}
                    >
                      Emailed before
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.add(interviewsNotEmailed)}
                      icon="envelope"
                      disabled={interviewsNotEmailed.length === 0}
                      sub={interviewsNotEmailed.length}
                    >
                      Not emailed before
                    </DropdownItem>
                    {project.interviewLabels ? (
                      <>
                        <h6 className="p">Labels</h6>
                        {project.interviewLabels.map((x, i) => {
                          const interviewsWithLabel = interviews.filter(
                            (y) => y.labelIds && y.labelIds.includes(x.id),
                          )
                          const interviewsWithoutLabel = interviews.filter(
                            (y) => !y.labelIds || !y.labelIds.includes(x.id),
                          )
                          return (
                            <React.Fragment key={x.id}>
                              <DropdownItem
                                onClick={() => this.add(interviewsWithLabel)}
                                disabled={interviewsWithLabel.length === 0}
                                icon="tag"
                                borderTop={i > 0}
                                sub={interviewsWithLabel.length}
                              >
                                {x.name || "New tag"}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => this.add(interviewsWithoutLabel)}
                                disabled={interviewsWithoutLabel.length === 0}
                                icon="tag"
                                sub={interviewsWithoutLabel.length}
                              >
                                Not{" "}
                                {(x.name || "new tag").charAt(0).toLowerCase() +
                                  (x.name || "new tag").slice(1)}
                              </DropdownItem>
                            </React.Fragment>
                          )
                        })}
                      </>
                    ) : null}
                  </Dropdown>
                ),
              },
              {
                key: "from",
                button: (
                  <Dropdown
                    width={235}
                    arrowPosition="right"
                    button={<Button icon="help" isSmall />}
                    isInfo
                  >
                    <h6>Help</h6>
                    <p className="mt-3 text-sm">
                      Emails will be sent from{" "}
                      <span className="font-medium">
                        noreply@interviewkit.co
                      </span>{" "}
                      to ensure the email gets delivered.
                    </p>
                    <p className="mt-3 text-sm">
                      Your name will be displayed as the sender, so people know
                      it's you. When they reply, they will reply directly to
                      your email address,{" "}
                      <span className="font-medium">{user.email}</span>.
                    </p>
                  </Dropdown>
                ),
                disabled: true,
                defaultValue: `${
                  this.props.getUser().name
                } <noreply@interviewkit.co>`,
              },
              { key: "subject" },
            ]}
          />
          <Input
            disabled={!!this.state.previewId}
            ref={this.messageRef}
            rows={1}
            value={this.state.previewId ? parsed : this.state.message}
            onChange={(x) =>
              !!this.state.message !== !!x
                ? this.setState({ message: x })
                : null
            }
            onBlur={(message) => this.setState({ message })}
            noStyling
            noInputStyling
            className="flex-1 pt-5"
            classNameInput="bg-transparent resize-none h-full leading-relaxed"
            autoFocus
          />
          {previewInterview && (
            <div className="no-select flex grid grid-cols-3 items-center rounded-full border p-4">
              <div className="col-span-2 flex items-center">
                <Button
                  onClick={() =>
                    this.setState({
                      previewId: toInterviews[previewIndex - 1].id,
                    })
                  }
                  disabled={previewIndex < 1}
                  icon="chevron-left"
                />
                <Button
                  onClick={() =>
                    this.setState({
                      previewId: toInterviews[previewIndex + 1].id,
                    })
                  }
                  disabled={previewIndex === toInterviews.length - 1}
                  icon="chevron-right"
                />
                <div className="ml-5 flex-1 select-none">
                  <h6>Preview as</h6>
                  <h5 className="truncate">{previewInterview.name}</h5>
                </div>
              </div>
              <Button
                secondary
                className="ml-10"
                onClick={() => this.setState({ previewId: false })}
              >
                Done
              </Button>
            </div>
          )}
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(withRouter(ProjectEmailNew)))
