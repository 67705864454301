import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import {
  Chart,
  Callout,
  Page,
  withData,
  withNotify,
  Sidebar,
  getHex,
  getColor,
  SidebarItem,
} from "../shared"

class ProjectInterviewsInsights extends React.Component {
  render() {
    const { project, interviews, backTo } = this.props
    const basePath = `/projects/:projectId/interviews/insights`
    const baseUrl = `/projects/${this.props.match.params.projectId}/interviews/insights`
    const hasOther = interviews.some((x) => x.gender === "other")

    const noAge = interviews.filter((x) => !x.age || x.age === 0).length
    const noGender = interviews.filter((x) => !x.gender).length

    return (
      <Page
        isPopup
        title="Insights"
        width="6xl"
        icon="zap"
        style={{ height: 500 }}
        hasDone
        hasPadding
        backTo={backTo}
      >
        <div
          className="grid h-full gap-10"
          style={{ gridTemplateColumns: "200px 1fr" }}
        >
          <Sidebar isInline>
            <h6 className="mb-2 mt-4">Sample</h6>
            <SidebarItem to={`${baseUrl}/age`}>Age distribution</SidebarItem>
            <SidebarItem to={`${baseUrl}/gender`}>
              Gender distribution
            </SidebarItem>
            <h6 className="mb-2 mt-6">Labels</h6>
            <SidebarItem to={`${baseUrl}/label`}>
              Interviews by label
            </SidebarItem>
          </Sidebar>
          <div className="py-2">
            <Switch>
              <Route
                path={`${basePath}/age`}
                render={(props) => (
                  <div className="flex h-full flex-col rounded-xl border p-4 pt-6">
                    <Chart
                      key="age"
                      singular="interview"
                      plural="interviews"
                      className="flex-1"
                      showPercentages
                      showXAxis
                      showXBorder
                      showYAxis
                      showYBorder
                      showYSide
                      type="bar"
                      labels={[
                        "0-9",
                        "10-19",
                        "20-29",
                        "30-39",
                        "40-49",
                        "50-59",
                        "60-69",
                        "70-79",
                        "80+",
                      ]}
                      datasets={[
                        {
                          backgroundColor: getColor(false, true),
                          data: [
                            interviews.filter(
                              (x) => x.age != null && x.age <= 9,
                            ).length,
                            interviews.filter((x) => x.age >= 10 && x.age <= 19)
                              .length,
                            interviews.filter((x) => x.age >= 20 && x.age <= 29)
                              .length,
                            interviews.filter((x) => x.age >= 30 && x.age <= 39)
                              .length,
                            interviews.filter((x) => x.age >= 40 && x.age <= 49)
                              .length,
                            interviews.filter((x) => x.age >= 50 && x.age <= 59)
                              .length,
                            interviews.filter((x) => x.age >= 60 && x.age <= 69)
                              .length,
                            interviews.filter((x) => x.age >= 70 && x.age <= 79)
                              .length,
                            interviews.filter((x) => x.age >= 80).length,
                          ],
                        },
                      ]}
                    />
                    {noAge > 0 && (
                      <Callout className="mt-1">
                        {noAge} interviewee{noAge === 1 ? " has" : "s have"} no
                        age specified and {noAge === 1 ? "is" : "are"} not
                        included in this chart.
                      </Callout>
                    )}
                  </div>
                )}
              />
              <Route
                path={`${basePath}/gender`}
                render={(props) => (
                  <div className="flex h-full flex-col rounded-xl border p-4">
                    <Chart
                      key="gender"
                      singular="interview"
                      plural="interviews"
                      showPercentages
                      type="polar"
                      labels={
                        hasOther
                          ? ["Male", "Female", "Other"]
                          : ["Male", "Female"]
                      }
                      datasets={[
                        {
                          backgroundColor: hasOther
                            ? ["#6366F1", "#EC4899", "#EAB308"]
                            : ["#6366F1", "#EC4899"],
                          data: hasOther
                            ? [
                                interviews.filter((x) => x.gender === "male")
                                  .length,
                                interviews.filter((x) => x.gender === "female")
                                  .length,
                                interviews.filter((x) => x.gender === "other")
                                  .length,
                              ]
                            : [
                                interviews.filter((x) => x.gender === "male")
                                  .length,
                                interviews.filter((x) => x.gender === "female")
                                  .length,
                              ],
                        },
                      ]}
                    />
                    {noGender > 0 && (
                      <Callout className="mt-6">
                        {noGender} interviewee
                        {noGender === 1 ? " has" : "s have"} no gender specified
                        and {noGender === 1 ? "is" : "are"} not included in this
                        chart.
                      </Callout>
                    )}
                  </div>
                )}
              />
              <Route
                path={`${basePath}/label`}
                render={(props) => (
                  <div className="h-full rounded-xl border p-4 pt-6">
                    {project.interviewLabels?.length > 0 ? (
                      <Chart
                        key="label"
                        singular="interview"
                        plural="interviews"
                        className="flex-1"
                        showPercentages
                        showXAxis
                        showXBorder
                        showYAxis
                        showYBorder
                        showYSide
                        startAtZero
                        type="bar"
                        labels={(project.interviewLabels || []).map(
                          (x) => x.name,
                        )}
                        datasets={[
                          {
                            backgroundColor: (
                              project.interviewLabels || []
                            ).map((label) => getHex(label.color)),
                            data: (project.interviewLabels || []).map((tag) =>
                              interviews.reduce(
                                (sum, x) =>
                                  (x.labelIds || []).includes(tag.id)
                                    ? sum + 1
                                    : sum,
                                0,
                              ),
                            ),
                          },
                        ]}
                      />
                    ) : (
                      <p className="sub flex h-full w-full items-center justify-center">
                        Add labels to interviews to view them here.
                      </p>
                    )}
                  </div>
                )}
              />
              <Redirect from={`${basePath}`} to={`${baseUrl}/age`} />
            </Switch>
          </div>
        </div>
      </Page>
    )
  }
}

export default withData(withNotify(ProjectInterviewsInsights))
