import React from "react"
import { Link } from "react-router-dom"
import { Button, Input, Page, withData, withNotify } from "../shared"

class SettingsProfileDelete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      isLoading: false,
    }
  }
  async confirmDelete() {
    try {
      this.setState({ isLoading: true })
      await this.props.deleteAccount(this.state.password)
      this.props.success("Your account was deleted. Take care! 👋")
      this.props.history.push("/")
    } catch (msg) {
      this.props.error(msg)
      this.setState({ isLoading: false })
    }
  }
  render() {
    return (
      <Page
        isPopup
        title="Delete your account"
        width="xl"
        hasPadding
        backTo="/settings/profile"
        footerGrid={2}
        footer={
          <>
            <Button
              onClick={() => this.confirmDelete()}
              isLoading={this.state.isLoading}
              disabled={!this.state.password.length}
              isRed
              primary
            >
              Delete account & all data
            </Button>
            <Button secondary to="/settings/profile">
              Cancel
            </Button>
          </>
        }
      >
        <p>
          Sorry to see you leave! If there is anything you don't like about
          InterviewKit, please email us at support@interviewkit.co.
        </p>
        <p className="mt-3">
          If you are sure you want to go, this is what will happen:
        </p>
        <ol className="my-3">
          <li>
            Your user data and any projects you are the owner of will be{" "}
            <span className="font-semibold text-red-500">
              immediately deleted
            </span>
            . Any subscriptions will be cancelled.
          </li>
          <li className="mt-2">
            Once confirmed, you{" "}
            <span className="font-semibold text-red-500">
              cannot restore your data
            </span>
            .
          </li>
        </ol>
        <p className="mb-3">
          You can export data from InterviewKit{" "}
          <Link className="link" to="/settings/export">
            on this page
          </Link>{" "}
          if you want to store it somewhere else.
        </p>
        <p className="mb-5">
          If you are 100% sure, please type your password below and confirm. All
          the best and thank you for using InterviewKit! 👋
        </p>
        <Input
          hasBorder
          placeholder="Your password..."
          value={this.state.password}
          onChange={(password) => this.setState({ password })}
          type="password"
        />
      </Page>
    )
  }
}

export default withData(withNotify(SettingsProfileDelete))
