import React from "react"
import Icon from "./Icon"
import Loader from "./Loader"

class Label extends React.Component {
  render() {
    const { color } = this.props
    return (
      <div
        onClick={this.props.onClick}
        title={this.props.title}
        className={`${
          color
            ? `border-transparent text-${color}-600 dark:text-${color}-200 bg-${color}-50 dark:bg-${color}-900`
            : "bg-white text-gray-800 dark:bg-gray-800 dark:text-gray-300"
        } ${this.props.onClick ? "pushable" : ""} border ${
          this.props.isSmall
            ? "rounded-lg px-1.5 py-0.5"
            : "h-8 rounded-full px-4"
        } relative inline-flex select-none items-center overflow-hidden whitespace-nowrap text-sm font-medium ${
          this.props.className || ""
        }`}
      >
        {this.props.icon ? (
          <Icon
            className={
              this.props.children
                ? this.props.isSmall
                  ? "mr-1.5"
                  : "mr-3"
                : ""
            }
            icon={this.props.icon}
            size={12}
            color={color}
          />
        ) : null}
        {this.props.isLoading && (
          <Loader
            size={18}
            color="gray-600"
            className={
              this.props.children
                ? this.props.isSmall
                  ? "-ml-2 mr-1.5"
                  : "-ml-2 mr-3"
                : "-mx-2"
            }
          />
        )}
        {this.props.children}
      </div>
    )
  }
}

Label.propTypes = {}

export default Label
