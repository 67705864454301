import React from "react"
import {
  Button,
  Input,
  Icon,
  Page,
  Label,
  withData,
  toPreview,
  withNotify,
  isEmail,
} from "../shared"
import { Link } from "react-router-dom"
import abn from "../assets/logos/logo-abn.svg"
import bnp from "../assets/logos/logo-bnp.svg"
import heineken from "../assets/logos/logo-heineken.svg"
import nn from "../assets/logos/logo-nn.svg"
import unilever from "../assets/logos/logo-unilever.svg"
import { functions } from "../index"
import { loadStripe } from "@stripe/stripe-js"
import queryString from "query-string"
import InputPlan from "../components/InputPlan"

class Buy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlanOpen: false,
      isLoadingCheckout: false,
      plan: this.props.session.buyPlan || "regular",
      email:
        (!this.props.isDemo && this.props.userEmail) ||
        this.props.session.buyEmail ||
        "",
    }
  }
  componentDidMount() {
    const isCancelled =
      queryString.parse(this.props.location.search).status === "cancelled"
    if (isCancelled) {
      this.props.error("Your purchase was cancelled")
      this.props.history.replace(this.props.location.pathname)
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Save email and selected plan in session storage, so that it's still filled in if the payment gets cancelled.
    if (prevState.plan !== this.state.plan) {
      this.props.setSession({ buyPlan: this.state.plan })
    }
    if (prevState.email !== this.state.email) {
      this.props.setSession({ buyEmail: this.state.email })
    }
  }
  async goToCheckout() {
    const { email, plan } = this.state
    if (!isEmail(email)) {
      return this.props.error("Please enter a valid email address")
    }
    this.setState({ isLoadingCheckout: true })
    try {
      window.sa_event?.("open_checkout")
      // Handle
      const getNewCheckoutSessionId = functions.httpsCallable(
        "getNewCheckoutSessionId",
      )
      const { data } = await getNewCheckoutSessionId({ email, plan })
      if (data.sessionId) {
        const stripe = await loadStripe(data.publicKey)
        stripe.redirectToCheckout({ sessionId: data.sessionId })
      } else {
        this.props.error("Could not reach payment server", true)
        this.setState({ isLoadingCheckout: false })
      }
    } catch (e) {
      if (e.code === "already-exists") {
        this.props.error(
          "This email address already exists",
          "Try signing in or resetting your password.",
        )
      } else if (e.code === "invalid-argument") {
        this.props.error("Please enter a valid email address")
      } else {
        this.props.error("Could not reach payment server", true)
      }
      this.setState({ isLoadingCheckout: false })
    }
  }
  render() {
    const { isPlanOpen, email } = this.state
    return (
      <Page
        noBar
        isGray
        backTo={toPreview()}
        isPopup
        width="6xl"
        title="Get InterviewKit"
        classNameChild="flex justify-center items-center"
      >
        <div>
          <div className="relative z-10 grid w-full max-w-screen-lg grid-cols-3 rounded-3xl bg-white shadow-lg dark:bg-gray-800">
            <div className="relative flex flex-col p-10">
              <div
                className={`absolute inset-0 z-30 rounded-l-3xl bg-white dark:bg-gray-800 ${
                  isPlanOpen
                    ? "opacity-70 transition-opacity"
                    : "pointer-events-none opacity-0"
                }`}
                onClick={() => this.setState({ isPlanOpen: false })}
              />
              <Label icon="smiley" color="pink" className="mb-4 self-start">
                Happiness guarantee
              </Label>
              <h2 className="mb-5 leading-snug">
                Try InterviewKit <br />7 days for free.
              </h2>
              <div className="my-auto">
                <div className="flex items-start">
                  <Icon
                    icon="check"
                    size={12}
                    padding={2}
                    color="green-600"
                    colorDark="green-400"
                    bg="green-100"
                    className="mr-3 flex-none"
                    bgDark="green-900"
                  />
                  <p className="mt-0.5">
                    Access all features, video & audio storage and services.{" "}
                    <Link
                      to={toPreview("sign-up-details")}
                      className="link-color"
                    >
                      Details
                    </Link>
                  </p>
                </div>
                <div className="mt-2 flex items-start">
                  <Icon
                    icon="check"
                    size={12}
                    padding={2}
                    color="green-600"
                    colorDark="green-400"
                    bg="green-100"
                    className="mr-3 flex-none"
                    bgDark="green-900"
                  />
                  <p className="mt-0.5">
                    Get up to{" "}
                    <span className="font-medium text-black dark:text-white">
                      15% off
                    </span>{" "}
                    by selecting annual invoicing in the next step.
                  </p>
                </div>
                <div className="mt-2 flex items-start">
                  <Icon
                    icon="check"
                    size={12}
                    padding={2}
                    color="green-600"
                    colorDark="green-400"
                    bg="green-100"
                    className="mr-3 flex-none"
                    bgDark="green-900"
                  />
                  <p className="mt-0.5">
                    You can cancel your trial at any time and you won't be
                    charged.
                  </p>
                </div>
              </div>
              <InputPlan
                className="mt-6 mb-2"
                plan={this.state.plan}
                setPlan={(plan) => this.setState({ plan })}
                isPlanOpen={this.state.isPlanOpen}
                setPlanOpen={(isPlanOpen) => this.setState({ isPlanOpen })}
              />
              <Input
                onEnter={this.goToCheckout.bind(this)}
                disabled={!this.props.isDemo && this.props.userEmail}
                value={email}
                onChange={(email) => this.setState({ email })}
                autoFocus
                hasBorder
                placeholder="Your email address..."
              />
              <Button
                full
                primary
                isLoading={this.state.isLoadingCheckout}
                onClick={this.goToCheckout.bind(this)}
                className="mt-6 flex-none"
              >
                Continue
              </Button>
              <a
                href="//interviewkit.co/pricing"
                target="_blank"
                rel="noopener noreferrer"
                className="tr mt-3 flex cursor-pointer no-underline items-center justify-center rounded-lg p-1 px-2"
              >
                <Icon icon="help" size={12} color="gray-500" />
                <p className="ml-2 text-sm text-gray-500">
                  Questions? Please see our{" "}
                  <span className="font-medium">pricing page</span>.
                </p>
              </a>
            </div>
            <div
              className="relative col-span-2 flex flex-col rounded-r-3xl bg-gray-800 bg-cover bg-center p-10 pb-9"
              onClick={() => this.setState({ isPlanOpen: false })}
              style={{ backgroundImage: "url(/assets/m1.png)" }}
            >
              <div
                className="absolute inset-0 z-10"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(30,41,59,0.7) 0%, rgba(30,41,59,0.5) 20%, rgba(30,41,59,0) 40%)",
                }}
              />
              <div className="relative z-20 mt-auto flex justify-between space-x-12 opacity-80">
                <div className="flex items-center justify-center">
                  <img
                    alt=""
                    style={{
                      maxWidth: "8rem",
                      maxHeight: "4rem",
                      position: "relative",
                      top: 7,
                    }}
                    src={abn}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img
                    alt=""
                    style={{ maxWidth: "8rem", maxHeight: "4rem" }}
                    src={bnp}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img
                    alt=""
                    style={{ maxWidth: "8rem", maxHeight: "4rem" }}
                    src={heineken}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img
                    alt=""
                    style={{ maxWidth: "8rem", maxHeight: "4rem" }}
                    src={nn}
                  />
                </div>
                <div className="flex w-16 flex-none items-center justify-center">
                  <img
                    alt=""
                    style={{
                      maxWidth: "3rem",
                      maxHeight: "4rem",
                      position: "relative",
                      top: 2,
                    }}
                    src={unilever}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

export default withNotify(withData(Buy))
