import React from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import {checkIfDark, getSemiUniqueKey} from "../functions";
import Shortcut from "./Shortcut";

class Audio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
        this.id = 'audio-' + getSemiUniqueKey();
    }
    componentDidMount() {
        const audioColor = this.props.isDark || checkIfDark() ? '#374151' : '#D1D5DB';
        this.props.audioRef.current = WaveSurfer.create({
            container: `#${this.id}`,
            partialRender: true,
            height: this.props.height,
            barRadius: 3,
            barWidth: 2,
            cursorColor: audioColor,
            progressColor: audioColor,
            normalize: true,
            waveColor: window.appColorHex,
        });
        this.props.audioRef.current.setTime = this.setTime.bind(this);
        const peaks = this.props.peaks ? this.props.peaks.data : false;
        if(peaks) {
            this.props.audioRef.current.backend.setPeaks(peaks);
            this.props.audioRef.current.drawBuffer();
            this.props.audioRef.current.getArrayBuffer(this.props.url, data => this.props.audioRef.current.loadArrayBuffer(data));
        } else {
            this.props.audioRef.current.load(this.props.url);
        }
        this.props.audioRef.current.on('ready', () => {
            const time = this.props.time;
            if(time) this.setTime(time);
            this.setState({isLoaded: true});
            if(this.props.autoplay) this.props.audioRef.current.play();
        });
        if(this.props.onTime) {
            this.props.audioRef.current.on('audioprocess', () => {
                if(this.props.audioRef.current) this.props.onTime(this.props.audioRef.current.getCurrentTime());
            });
            this.props.audioRef.current.on('seek', () => {
                if(this.props.audioRef.current) this.props.onTime(this.props.audioRef.current.getCurrentTime());
            });
        }
        if(this.props.onToggle) {
            this.props.audioRef.current.on("play", () => {
                this.props.onToggle(true);
            });
            this.props.audioRef.current.on("pause", () => {
                this.props.onToggle(false);
            });
        }
    }
    componentWillUnmount() {
        if(this.props.audioRef.current) this.props.audioRef.current.destroy();
    }
    setTime(seconds, playWhenPaused) {
        if(typeof seconds === 'string' && (seconds.startsWith('+') || seconds.startsWith('-'))) {
            return this.setTimeRelative(seconds);
        }
        if(this.props.audioRef.current) {
            const duration = this.props.audioRef.current.getDuration();
            if(duration) this.props.audioRef.current.seekTo(Math.max(0, Math.min(seconds / duration, 1)));
            if(playWhenPaused && !this.props.audioRef.current.isPlaying()) this.props.audioRef.current.play();
        }
    }
    setTimeRelative(seconds) {
        if(typeof seconds === 'string' && seconds.startsWith('+')) seconds = parseInt(seconds.substring(1));
        if(typeof seconds === 'string' && seconds.startsWith('-')) seconds = parseInt(seconds);
        if(this.props.audioRef.current) this.setTime(this.props.audioRef.current.getCurrentTime() + seconds);
    }
    toggle() {
        if(this.props.audioRef.current) this.props.audioRef.current.playPause();
    }
    render() {
        return (
            <div className={`${this.props.peaks ? (this.state.isLoaded ? '' : 'animate-pulse') : (this.state.isLoaded ? 'animate-fade-in' : 'opacity-0')} ${this.props.className || ''}`}>
                {this.state.isLoaded && this.props.enableShortcuts ? <>
                    <Shortcut press=' ' onPress={this.toggle.bind(this)} />
                    <Shortcut press='shift-ArrowLeft' onPress={() => this.setTimeRelative(-1)} />
                    <Shortcut press='shift-ArrowRight' onPress={() => this.setTimeRelative(1)} />
                    <Shortcut press='ArrowLeft' onPress={() => this.setTimeRelative(-5)} />
                    <Shortcut press='ArrowRight' onPress={() => this.setTimeRelative(5)} />
                </> : null}
                <div className={this.props.peaks ? (this.state.isLoaded ? 'transition-all duration-300' : 'opacity-60 cursor-not-allowed') : ''}>
                    <div id={this.id} className={this.props.peaks && !this.state.isLoaded ? ' pointer-events-none' : ''} />
                </div>
            </div>
        );
    }
}

/*
<WaveSurfer onMount={(x) => {
                    this.props.audioRef.current = x;
                    this.props.audioRef.current.setTime = this.setTime.bind(this);
                    if(this.props.audioRef.current) {
                        this.props.audioRef.current.on("ready", () => {
                            console.log('ready');
                            const time = this.props.time;
                            this.setState({isLoaded: true});
                            if(time) this.setTime(time);
                            if(this.props.autoplay) this.props.audioRef.current.play();
                        });
                        if(this.props.onTime) {
                            this.props.audioRef.current.on('audioprocess', () => {
                                if(this.props.audioRef.current) this.props.onTime(this.props.audioRef.current.getCurrentTime());
                            });
                            this.props.audioRef.current.on('seek', () => {
                                if(this.props.audioRef.current) this.props.onTime(this.props.audioRef.current.getCurrentTime());
                            });
                        }
                        if(this.props.onToggle) {
                            this.props.audioRef.current.on("play", () => {
                                this.props.onToggle(true);
                            });
                            this.props.audioRef.current.on("pause", () => {
                                this.props.onToggle(false);
                            });
                        }
                        this.props.audioRef.current.load(this.props.url, [
                            0.0218, 0.0183, 0.0165, 0.0198, 0.2137, 0.2888, 0.2313, 0.15, 0.2542, 0.2538, 0.2358, 0.1195, 0.1591, 0.2599, 0.2742, 0.1447, 0.2328, 0.1878, 0.1988, 0.1645, 0.1218, 0.2005, 0.2828, 0.2051, 0.1664, 0.1181, 0.1621, 0.2966, 0.189, 0.246, 0.2445, 0.1621, 0.1618, 0.189, 0.2354, 0.1561, 0.1638, 0.2799, 0.0923, 0.1659, 0.1675, 0.1268, 0.0984, 0.0997, 0.1248, 0.1495, 0.1431, 0.1236, 0.1755, 0.1183, 0.1349, 0.1018, 0.1109, 0.1833, 0.1813, 0.1422, 0.0961, 0.1191, 0.0791, 0.0631, 0.0315, 0.0157, 0.0166, 0.0108
                        ]);
                    }
                }}>
                    <WaveForm id={`waveform-${this.id}`} partialRender height={this.props.height} barRadius={3} barWidth={2} cursorColor={audioColor} progressColor={audioColor} normalize waveColor={window.appColorHex} />
                </WaveSurfer>
 */

Audio.propTypes = {
    autoplay: PropTypes.bool,
    onToggle: PropTypes.func,
    url: PropTypes.string.isRequired,
    time: PropTypes.number,
}

Audio.defaultProps = {
    height: 84,
}

export default React.forwardRef((props, ref) => <Audio {...props} audioRef={ref} />);