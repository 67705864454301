import React from "react"
import {
  Button,
  Input,
  Page,
  withData,
  withNotify,
  Dropdown,
  format,
} from "../shared"
import { functions } from "../index"

class SettingsBillingEdit extends React.Component {
  constructor(props) {
    super(props)
    const user = this.props.getUser()
    this.state = {
      hasPlan: !!this.props.plan,
      audio: parseInt(user.maxAudio / 1073741824) || 1,
      video: parseInt(user.maxVideo / 3600) || 5,
      isLoading: false,
    }
    this.currentOptions = {
      audio: parseInt(user.maxAudio / 1073741824) || 1,
      video: parseInt(user.maxVideo / 3600) || 5,
    }
    this.backTo = `/settings/billing`
    this.audioOptions = [
      { amount: 5, price: 0 },
      { amount: 25, price: 1900 },
      { amount: 50, price: 2900 },
      { amount: 100, price: 4900 },
    ]
    this.videoOptions = [
      { amount: 5, price: 0 },
      { amount: 25, price: 2200 },
      { amount: 50, price: 3900 },
      { amount: 100, price: 6900 },
    ]
  }
  async editResources() {
    this.setState({ isLoading: true })
    const editResources = functions.httpsCallable("editResources")

    const audio = Math.max(
      0,
      this.audioOptions.findIndex((x) => x.amount === this.state.audio),
    )
    const video = Math.max(
      0,
      this.videoOptions.findIndex((x) => x.amount === this.state.video),
    )

    try {
      const { data } = await editResources({ audio, video })
      // Update new limits only locally, will be propagated with stripeWebhook later but we want to get
      // immediate feedback that limits have been updated.
      await this.props.update(
        "usersPrivate",
        this.props.userId,
        data.newLimits,
        true,
      )
      this.props.success("Storage edited")
      this.props.history.push(this.backTo)
    } catch (e) {
      if (e.code === "unimplemented") {
        this.props.error(
          "Please contact us to upgrade",
          "Upgrading storage on an annual plan requires manual adjustment.",
        )
      } else if (e.code === "permission-denied") {
        this.props.error(
          e.message,
          "Your current storage exceeds your requested limit.",
        )
      } else {
        this.props.error(
          "Could not edit storage",
          e.message || "Please try again or contact us for help.",
        )
      }
      this.setState({ isLoading: false })
    }
  }
  render() {
    const { audio, video } = this.state
    const startedFromZero =
      this.currentOptions.audio === 5 && this.currentOptions.video === 5
    const hasNotChanged =
      this.currentOptions.audio === audio && this.currentOptions.video === video

    // Calculate total
    let total = 0
    total +=
      (this.audioOptions.find((x) => x.amount === audio) || {}).price || 0
    total +=
      (this.videoOptions.find((x) => x.amount === video) || {}).price || 0

    return (
      <Page
        isPopup
        width="2xl"
        icon={
          startedFromZero
            ? "add-circle"
            : "Setting.InterfaceSettingSliderVertical"
        }
        title={startedFromZero ? "Add storage" : "Edit storage"}
        hasPadding
        backTo={this.backTo}
        footerGrid={2}
        footer={
          <>
            <Button
              onClick={() => this.editResources()}
              disabled={hasNotChanged}
              isLoading={this.state.isLoading}
              primary
            >
              {startedFromZero ? "Add storage" : "Edit storage"}
            </Button>
            <Button disabled={this.state.isLoading} secondary to={this.backTo}>
              Cancel
            </Button>
          </>
        }
      >
        <div className="-mt-px flex h-full flex-col pb-4">
          <p className="mb-5 text-gray-500">
            You can edit your audio and video storage at any time, to easily
            scale InterviewKit to your projects and only pay for what you need.
          </p>
          <div className="mt-3 grid grid-cols-2 gap-5">
            <div>
              <h6 className="mb-2">Video storage</h6>
              <Input
                readOnly={this.state.isLoading}
                hasBorder
                value={video}
                onBlur={(video) => this.setState({ video: parseInt(video) })}
                type="dropdown"
                canClear={false}
                options={this.videoOptions.map((option) => ({
                  key: option.amount,
                  name: `${option.amount} hours ${
                    option.price === 0
                      ? ""
                      : `($${format("currency", option.price, true)})`
                  }`,
                }))}
              />
            </div>
            <div>
              <h6 className="mb-2">Audio storage</h6>
              <Input
                readOnly={this.state.isLoading}
                hasBorder
                value={audio}
                onBlur={(audio) => this.setState({ audio: parseInt(audio) })}
                type="dropdown"
                canClear={false}
                options={this.audioOptions.map((option) => ({
                  key: option.amount,
                  name: `${option.amount} GB ${
                    option.price === 0
                      ? ""
                      : `($${format("currency", option.price, true)})`
                  }`,
                }))}
              />
            </div>
          </div>
          <h6 className="mt-8 mb-2">Storage costs</h6>
          <h4>${format("currency", total, true)}/month</h4>
          <div className="flex h-8">
            <p className="sub mt-1.5">
              {total === 0 ? (
                "Included with your subscription."
              ) : (
                <>Excl. taxes &middot; Due today (prorated)</>
              )}
            </p>
            {(total > 0 || !hasNotChanged) && (
              <Dropdown
                isInfo
                width={280}
                button={
                  <Button
                    className="ml-1 mt-0.5"
                    color="gray-500"
                    isSmall
                    title=""
                    icon="help"
                  />
                }
              >
                <h6 className="mb-3">About storage billing</h6>
                <p className="text-sm">
                  You can edit your storage at any time. The costs will be
                  prorated and charged from your credit card.
                </p>
                <p className="mt-3 text-sm">
                  For example, if you used a bigger storage capacity for half a
                  month, you will only be billed 50%. Any credited amount will
                  be subtracted from your next invoice.
                </p>
              </Dropdown>
            )}
          </div>
        </div>
      </Page>
    )
  }
}

SettingsBillingEdit.propTypes = {}

export default withData(withNotify(SettingsBillingEdit))
