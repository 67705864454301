import React from "react"
import {
  format,
  Button,
  Page,
  withData,
  withNotify,
  downloadFile,
} from "../shared"
import { functions } from "../index"
import { getQuoteFromTranscript } from "../functions"
import exportImg from "../assets/export.png"

const esc = (str) => {
  if (typeof str === "string") {
    return str?.split(";").join(",") || ""
  } else {
    return str || ""
  }
}

class SettingsExport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTakeoutLoading: false,
    }
  }
  async requestTakeout() {
    const confirm = await this.props.openModal({
      title: "Request takeout?",
      desc: `Please allow up to a few hours for your takeout to complete. Once completed, you will receive a download link at ${this.props.userEmail}.`,
      action: "Request",
      icon: "download",
    })
    if (confirm) {
      try {
        this.setState({ isTakeoutLoading: true })
        const requestTakeout = functions.httpsCallable("requestTakeout")
        await requestTakeout()
        await this.props.fetch({
          collection: "usersPrivate",
          id: this.props.userId,
          force: true,
        })
        this.props.success(
          "Takeout requested",
          "You will receive an email soon.",
        )
      } catch (e) {
        this.props.error("Could not request takeout", true)
      } finally {
        this.setState({ isTakeoutLoading: false })
      }
    }
  }
  exportInterviewsAsCSV() {
    let csvContent = "data:text/csv;charset=utf-8,"
    // Add head
    csvContent +=
      "Project;Name;Age;Gender;Email;Phone;Education;Job;Interview date;Notes;Labels;Transcript\n"
    csvContent += this.props.interviews
      .map((x) => {
        const project =
          this.props.projects.find((y) => y.id === x.projectId) || {}
        const labels = x.labelIds
          ? x.labelIds
              .map((labelId) =>
                project.interviewLabels.find((x) => x.id === labelId),
              )
              .filter((x) => x)
              .map((x) => esc(x.name))
              .join(", ")
          : ""
        const transcript = x.transcript?.map((x) => esc(x.text)).join(" /// ")
        return `${esc(project.name)};${esc(x.name)};${x.age || ""};${
          x.gender || ""
        };${esc(x.email)};${esc(x.phone)};${esc(x.education)};${esc(x.job)};${
          x.date ? format("date", x.date, { includeTime: true }) : ""
        };${esc(x.notes)?.split("\n").join(" /// ")};${labels};${
          transcript || ""
        }`
      })
      .join("\n")
    downloadFile("interviews-all.csv", csvContent)
  }
  exportFindingsAsCSV() {
    let csvContent = "data:text/csv;charset=utf-8,"
    // Add head
    csvContent +=
      "Project;Finding;Quote in transcript;Starred;Interviewee;Labels\n"
    csvContent += this.props.findings
      .map((x) => {
        const project =
          this.props.projects.find((y) => y.id === x.projectId) || {}
        const interview =
          this.props.interviews.find((y) => y.id === x.interviewId) || {}
        const sentence = getQuoteFromTranscript(
          interview.transcript,
          interview.transcriptEntities,
          x,
        )
        const labels = x.labelIds
          ? x.labelIds
              .map((labelId) =>
                project.findingLabels.find((x) => x.id === labelId),
              )
              .filter((x) => x)
              .map((x) => esc(x.name))
              .join(", ")
          : ""
        return `${esc(project.name)};${esc(x.text)};${esc(sentence)};${
          x.isStarred ? "Yes" : ""
        };${esc(interview.name)};${labels}`
      })
      .join("\n")
    downloadFile("findings-all.csv", csvContent)
  }
  exportResponsesAsCSV() {
    let csvContent = "data:text/csv;charset=utf-8,"
    // Add head
    const csvHead = [
      "Project",
      "Form",
      "Name",
      "Age",
      "Gender",
      "Email",
      "Phone",
      "Education",
      "Job",
      "Availability",
      "Submitted on",
    ]
    this.props.formResponses.forEach((x) => {
      // Add titles of other questions to head
      if (x.questions)
        for (const question of x.questions) {
          const index = csvHead.indexOf(esc(question.title))
          if (index === -1) csvHead.push(esc(question.title))
        }
    })
    csvContent += csvHead.join(";") + "\n"
    // Add body
    csvContent += this.props.formResponses
      .map((x) => {
        const project =
          this.props.projects.find((y) => y.id === x.projectId) || {}
        const form = this.props.forms.find((y) => y.id === x.formId) || {}
        const availability = x.availability
          ?.map?.(
            (x) =>
              `${format("date", x.from)} from ${format(
                "time",
                x.from,
              )} until ${format("time", x.until)}`,
          )
          .join(", ")
        const fields = [
          `${esc(project.name)}`,
          `${esc(form.title)}`,
          `${esc(x.name)}`,
          `${x.age || ""}`,
          `${x.gender || ""}`,
          `${esc(x.email)}`,
          `${esc(x.phone)}`,
          `${esc(x.education)}`,
          `${esc(x.job)}`,
          availability || "",
          `${
            x.createdOn
              ? format("date", x.createdOn, { includeTime: true })
              : ""
          }`,
        ]
        while (fields.length < csvHead.length) {
          fields.push("")
        }
        if (x.questions)
          for (const question of x.questions) {
            let answer = Array.isArray(question.answer)
              ? question.answer.join(", ")
              : question.answer
            const index = csvHead.indexOf(esc(question.title))
            if (index > -1) {
              // not really necessary, index should always be bigger than -1
              fields[index] = esc(answer)
            }
          }
        return fields.join(";")
      })
      .join("\n")
    downloadFile("form-responses-all.csv", csvContent)
  }
  render() {
    const projects = this.props.projects || []
    const isTakeoutRequested = this.props.getUser().takeoutRequestedOn > 0
    return (
      <Page title="Export" isNarrow icon="download" hasPadding>
        <div className="flex">
          <div>
            <h5 className="mb-2">Export from one project</h5>
            <p>
              You can export interviews, findings and form responses from any
              project by clicking "Export to CSV" in the More menu (in the top
              right corner).
            </p>
          </div>
          <div
            className="relative ml-4 flex-none overflow-hidden rounded-xl bg-cover bg-no-repeat"
            style={{
              backgroundPosition: "center -160px",
              backgroundImage: `url(${exportImg})`,
              width: 268,
            }}
          >
            <div className="absolute inset-x-0 top-0 h-10 bg-gradient-to-b from-white" />
            <div className="absolute inset-x-0 bottom-0 h-10 bg-gradient-to-t from-white" />
          </div>
        </div>
        <h5 className="mt-10 mb-2">Export from all projects</h5>
        <p className="mb-3">
          You can also export combined data of all projects you have access to
          below. These exports contain CSV files that you can read in
          spreadsheet apps like Excel, Numbers and Google Sheets. It will
          include the following projects:
        </p>
        {projects.length ? (
          <ul>
            {projects.map((x) => (
              <li key={x.id}>{x.name}</li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No projects</p>
        )}
        <div className="flex space-x-2">
          <Button
            isSmall
            disabled={!projects.length || !this.props.interviews?.length}
            className="mt-4"
            onClick={this.exportInterviewsAsCSV.bind(this)}
            secondary
            icon="download"
          >
            All interviews
          </Button>
          <Button
            isSmall
            disabled={!projects.length || !this.props.findings?.length}
            className="mt-4"
            onClick={this.exportFindingsAsCSV.bind(this)}
            secondary
            icon="download"
          >
            All findings
          </Button>
          <Button
            isSmall
            disabled={!projects.length || !this.props.formResponses?.length}
            className="mt-4"
            onClick={this.exportResponsesAsCSV.bind(this)}
            secondary
            icon="download"
          >
            All form responses
          </Button>
        </div>
        <h5 className="mt-11 mb-2">Export everything</h5>
        <p className="mb-2">
          To get all of your data, e.g. when you're deleting your account, you
          can request a full takeout. Takeouts include all project and user
          data, and any audio and video you uploaded. You will receive an email
          with a download link (valid for 24 hours) when it's ready.
        </p>
        <Button
          isLoading={this.state.isTakeoutLoading}
          isSmall
          className="mt-1"
          disabled={isTakeoutRequested}
          onClick={this.requestTakeout.bind(this)}
          secondary
        >
          {isTakeoutRequested ? "✓ Takeout requested" : "Request takeout"}
        </Button>
        <p className="sub mt-3">
          Please allow up to a few hours for your takeout to complete.
        </p>
      </Page>
    )
  }
}

export default withData(withNotify(SettingsExport))
