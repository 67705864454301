import React from "react"
import Icon from "./Icon"
import Loader from "./Loader"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import Button from "./Button"
import Label from "./Label"
import Input from "./Input"
import Dropdown from "./Dropdown"
import { withRouter } from "react-router-dom"
import { withData } from "../data"
import { withNotify } from "../notify"
import Shortcut from "./Shortcut"
import ErrorPage from "./Error"
import FileDrop from "./FileDrop"
import { checkIfDark } from "../functions"
import { motion } from "framer-motion"

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: !!props.needsData,
      isError: false,
      errorCode: false,
      errorMessage: false,
      isErrorNoPermission: false,
      isScrolled: false,
      isScrolledToBottom: false,
      isClosing: false,
      hasScroll: false,
      isSearching: false,
    }
    this.depth = this.props.match.path.split("/").length
    this.onScroll = this.onScroll.bind(this)
  }
  async componentDidMount() {
    if (this.ref && this.ref.scrollHeight > this.ref.clientHeight) {
      this.setState({ hasScroll: true })
    }
    if (this.ref) this.ref.addEventListener("scroll", this.onScroll)
    if (this.props.needsData) {
      return this.loadData()
    }
  }
  async loadData() {
    if (JSON.stringify(this.props.needsData).includes("undefined")) return false // Wait for prop to update.
    try {
      await this.props.fetch(this.props.needsData)
      this.setState({ isLoading: false })
    } catch (e) {
      console.error(e)
      this.setState({
        isLoading: false,
        isError: true,
        errorCode: e.code,
        errorMessage: e.message,
        isErrorNoPermission:
          e.code === "permission-denied" || e.code === "unauthenticated",
      })
    }
  }
  async componentDidUpdate(prevProps, prevState, snapshot) {
    /* if(this.state.isLoading && JSON.stringify(prevProps.needsData) !== JSON.stringify(this.props.needsData)) {
            this.loadData();
        }
        if(!prevState.isLoading && this.state.isLoading && this.ref) {
            if(this.ref.scrollHeight > this.ref.clientHeight) {
                this.setState({hasScroll: true});
            }
            this.ref.addEventListener('scroll', this.onScroll);
        } */
    const hasScroll = this.ref && this.ref.scrollHeight > this.ref.clientHeight
    if (this.state.hasScroll !== hasScroll) {
      this.setState({ hasScroll })
    }
    if (
      !prevState.isClosing &&
      this.state.isClosing &&
      !this.missingRequiredVariables
    ) {
      const timeout = window.setTimeout(() => {
        this.props.history.push(this.props.backTo)
      }, 200)
      this.setState({ timeout })
    }
  }
  componentWillUnmount() {
    /* this.setState({isMounted: false}); */
    if (this.state.timeout) window.clearTimeout(this.state.timeout)
    if (this.ref) this.ref.removeEventListener("scroll", this.onScroll)
  }
  onScroll() {
    if (this.ref) {
      let isScrolled = this.ref.scrollTop >= 10
      let isScrolledToBottom =
        this.ref.scrollTop + this.ref.offsetHeight >= this.ref.scrollHeight - 10
      if (
        isScrolled !== this.state.isScrolled ||
        isScrolledToBottom !== this.state.isScrolledToBottom
      ) {
        this.setState({ isScrolled, isScrolledToBottom })
      }
    }
  }
  async closePopup() {
    if (this.missingRequiredVariables) return false
    const confirm =
      !this.props.warnBeforeClose ||
      (await this.props.warn(
        `Close this ${this.props.singular || "window"}?`,
        `Your changes will be lost.`,
        "Discard",
      ))
    if (confirm) {
      if (this.props.backTo) this.setState({ isClosing: true })
    }
  }
  handleTitleEdit(title) {
    this.props.titleOnUpdate(title)
    this.setState({ isEditingTitle: false })
  }
  closeCompactSearch() {
    this.props.onSearch("")
    this.setState({ isSearching: false })
  }
  render() {
    const { isSidebar, isFullScreen, noBar } = this.props
    const appTitle = window.appName ? ` · ${window.appName}` : ""
    const isPopupOrChild = this.props.isPopup || this.props.isPopupChild
    const minBarHeight =
      isPopupOrChild && this.props.position === "center" ? "5rem" : "4rem"
    const isSmallPadding =
      (isPopupOrChild && this.props.position !== "center") || isSidebar
    const paddingL = isSmallPadding ? "pl-6" : "pl-10"
    const paddingR = isSmallPadding ? "pr-6" : "pr-10"
    const padding = isSmallPadding ? "p-6" : "p-10"
    const isLoading = this.props.isLoading || this.state.isLoading
    const isError = this.state.isError || this.props.showError
    const title = isError ? "Error" : this.props.title
    const isDark = checkIfDark()
    const style = this.props.isPopup
      ? {
        maxHeight: "90vh",
        height: this.props.height,
        ...(this.props.style || {}),
      }
      : this.props.style
    const isBackButton = this.props.backTo && !isPopupOrChild && !isFullScreen
    const hasBarRight =
      this.props.barRight ||
      this.props.onSearch ||
      this.props.more ||
      isFullScreen ||
      this.props.hasDone ||
      this.props.hasCancel
    const canEditTitle = !!this.props.titleOnUpdate

    const loadScreen = (
      <div className="flex h-full flex-col">
        <Helmet title={`Loading...${appTitle}`} />
        {noBar ? null : (
          <div
            className={`flex items-center`}
            style={{ minHeight: minBarHeight }}
          >
            <div
              className={`h-10 w-64 animate-pulse rounded-3xl bg-gray-50 dark:bg-gray-700`}
            />
            {hasBarRight && (
              <div className="ml-auto flex items-center space-x-1">
                {this.props.barRight &&
                  React.Children.map(
                    this.props.barRight.props?.children || this.props.barRight,
                    () => (
                      <div
                        style={{ width: 44 }}
                        className="h-10 animate-pulse rounded-3xl bg-gray-50 dark:bg-gray-700"
                      />
                    ),
                  )}
                {this.props.more && (
                  <div
                    style={{ width: 44 }}
                    className="h-10 animate-pulse rounded-3xl bg-gray-50 dark:bg-gray-700"
                  />
                )}
              </div>
            )}
          </div>
        )}
        <Loader color="gray-200" colorDark="gray-600" fullScreen />
      </div>
    )

    const props = {
      className: `overflow-hidden ${this.props.isPopup
        ? `${this.props.position.startsWith("top")
          ? "animate-open-top"
          : this.props.position.startsWith("bottom")
            ? "animate-open-bottom"
            : `animate-pop`
        } relative tr ${this.state.isClosing ? "opacity-0 transform scale-95" : ""
        } w-full rounded-3xl max-w-${this.props.width} ${this.props.noBg ? "" : "bg-white dark:bg-gray-800"
        }`
        : `${isFullScreen
          ? `${this.state.isClosing ? "opacity-0 transform scale-95" : ""
          } animate-pop transform tr ${this.props.noBg ? "" : "bg-white dark:bg-gray-800"
          } w-screen h-screen fixed inset-0 z-30`
          : "relative h-full w-full"
        }`
        } min-w-screen-sm flex flex-col ${this.props.disabled ? "pointer-events-none" : "pointer-events-auto"
        } ${this.props.center ? "flex items-center justify-center" : ""} ${isSidebar ? "border-r border-light dark:border-gray-700" : ""
        } ${this.props.className || ""}`,
      style,
    }

    if (this.props.required) {
      for (const key of Object.keys(this.props.required)) {
        const val = this.props.required[key]
        if (
          !val ||
          (typeof val === "object" && Object.keys(val).length === 0)
        ) {
          this.missingRequiredVariables = key
          break
        } else {
          this.missingRequiredVariables = false
        }
      }
    }

    const zIndex =
      this.props.zIndex != null ? this.props.zIndex : 40 + this.depth * 10

    const pageContent = (
      <>
        {this.props.titleHead || this.props.title ? (
          <Helmet
            title={`${this.props.titleHead || this.props.title || ""
              }${appTitle}`}
          />
        ) : null}
        {noBar ||
          isError ||
          this.missingRequiredVariables ||
          isLoading ? null : (
          <motion.div
            className={`${isBackButton && !this.state.isSearching ? "pl-3" : paddingL
              } ${paddingR} ${minBarHeight === "5rem" ? "pt-2" : ""
              } flex-none items-center ${this.props.isGray
                ? "bg-gray-50 dark:bg-gray-900"
                : "bg-white dark:bg-gray-800"
              } relative z-30 flex ${this.state.isScrolled ? "shadow-sm" : ""} ${this.props.classNameBar || ""
              }`}
            style={{
              ...this.props.barStyle,
              transition: "box-shadow 150ms ease-out",
              minHeight: minBarHeight,
            }}
          >
            {this.props.isSidebar && this.state.isSearching ? null : (
              <>
                {isBackButton ? (
                  <Button
                    title="Back"
                    icon="chevron-left"
                    className="mr-3"
                    to={
                      this.missingRequiredVariables ? false : this.props.backTo
                    }
                  />
                ) : null}
                {isFullScreen ? (
                  <>
                    <div className="flex flex-1 items-center">
                      {this.props.bar}
                    </div>
                    <div className="flex-1 overflow-hidden">
                      {this.props.titleTop && (
                        <h6 className="-mb-1 text-center">
                          {this.props.titleTop}
                        </h6>
                      )}
                      {this.state.isEditingTitle ? (
                        <Input
                          autoSelect
                          hasBorder
                          classNameInput="h4 text-center"
                          onEnter={this.handleTitleEdit.bind(this)}
                          className={`flex-1 ${this.props.bar ? "mr-10" : ""
                            } mr-2 truncate rounded-xl leading-snug`}
                          style={{ marginTop: 2 }}
                          defaultValue={title}
                          onBlur={this.handleTitleEdit.bind(this)}
                        />
                      ) : (
                        <h4
                          className={`h5 flex-1 truncate text-center leading-snug ${canEditTitle ? "cursor-pointer" : ""
                            }`}
                          onClick={
                            !!this.props.titleOnUpdate
                              ? () => this.setState({ isEditingTitle: true })
                              : null
                          }
                          style={{ marginTop: 2 }}
                        >
                          {title}
                        </h4>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`${this.props.titleIsSmall ? "mr-auto" : "flex-1"
                        } ${isSidebar ? "pl-4" : ""
                        } flex items-center overflow-hidden`}
                    >
                      {this.props.icon && (
                        <Icon
                          className="mr-4"
                          icon={this.props.icon}
                          size={24}
                          color={window.appColor}
                          colorDark={window.appColorDark}
                        />
                      )}
                      {this.props.image && (
                        <div
                          className="mr-4 h-10 w-10 rounded-full bg-cover bg-center"
                          style={{
                            backgroundImage: `url(${this.props.image})`,
                          }}
                        />
                      )}
                      {this.state.isEditingTitle ? (
                        <Input
                          autoSelect
                          hasBorder
                          classNameInput={`h4 ${isSidebar ? "ml-4" : ""}`}
                          onEnter={this.handleTitleEdit.bind(this)}
                          className={`flex-1 ${this.props.bar ? "mr-10" : ""
                            } mr-2 truncate rounded-xl leading-snug`}
                          style={{ marginTop: 2 }}
                          defaultValue={title}
                          onBlur={this.handleTitleEdit.bind(this)}
                        />
                      ) : (
                        <h4
                          className={`mr-4 truncate leading-snug ${canEditTitle ? "cursor-pointer" : ""
                            }`}
                          onClick={
                            !!this.props.titleOnUpdate
                              ? () => this.setState({ isEditingTitle: true })
                              : null
                          }
                          style={{ marginTop: 2 }}
                        >
                          {title}
                        </h4>
                      )}
                      {this.props.filteredTo && (
                        <Label
                          onClick={() =>
                            this.props.history.push(this.props.filteredTo)
                          }
                          className="pushable ml-2"
                          color="gray"
                          icon="filter"
                        >
                          Filtered
                        </Label>
                      )}
                      {this.props.filteredTo && this.props.onFilterClear && (
                        <div
                          className="pushable ml-1 cursor-pointer p-2"
                          onClick={() => this.props.onFilterClear()}
                        >
                          <Icon icon="cross" color="gray-500" size={8} />
                        </div>
                      )}
                    </div>
                    {this.props.bar}
                  </>
                )}
              </>
            )}
            {this.state.isSearching ? (
              <div className={`animate-pop flex flex-1 justify-end`}>
                <div
                  className="flex w-full"
                  style={
                    this.props.isSidebar
                      ? null
                      : { maxWidth: isSidebar ? 360 : 320 }
                  }
                >
                  <Input
                    autoFocus
                    hasBorder
                    onEscape={this.closeCompactSearch.bind(this)}
                    defaultValue={this.props.searchValue}
                    className="relative mr-2 flex-1"
                    onChange={(x) => this.props.onSearch(x)}
                    icon="search"
                    placeholder="Find..."
                  />
                  <Button
                    secondary
                    onClick={this.closeCompactSearch.bind(this)}
                  >
                    Done
                  </Button>
                </div>
              </div>
            ) : (
              <>
                {hasBarRight ? (
                  <div className="flex flex-1 items-center justify-end space-x-1">
                    {this.props.barRight}
                    {this.props.onSearch && !this.state.isSearching ? (
                      <Button
                        disabled={this.props.searchDisabled}
                        title="Search"
                        icon="search"
                        onClick={() => this.setState({ isSearching: true })}
                      />
                    ) : null}
                    {this.props.more ? (
                      <Dropdown
                        width={this.props.moreWidth}
                        arrowPosition="right"
                        button={
                          <Button
                            isLoading={this.props.moreIsLoading}
                            disabled={this.props.moreDisabled}
                            icon="more"
                            title="More"
                          />
                        }
                      >
                        {this.props.more}
                      </Dropdown>
                    ) : null}
                    {this.props.hasCancel ? (
                      <Button onClick={() => this.closePopup()}>Cancel</Button>
                    ) : null}
                    {this.props.hasDone ? (
                      <Button secondary onClick={() => this.closePopup()}>
                        Done
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </>
            )}
          </motion.div>
        )}
        <div
          ref={(x) => (this.ref = x)}
          className={`flex-1 overflow-scroll ${this.props.isFlexCol ? "flex flex-col" : ""
            } ${this.props.noHFull ? "" : "h-full"} ${this.props.isGray
              ? "bg-gray-50 dark:bg-gray-900"
              : this.props.noBg
                ? ""
                : "bg-white dark:bg-gray-800"
            } w-full ${this.props.hasPadding ||
              isLoading ||
              isError ||
              this.missingRequiredVariables
              ? `${padding} ${this.props.footer && !isError ? "pb-px" : "pb-6"
              } ${noBar
                ? null
                : isError
                  ? ""
                  : `${isPopupOrChild ? "pt-0" : "pt-4"}`
              }`
              : ""
            } ${this.props.isGrid ? `grid items-start gap-${this.props.gap}` : ""
            } ${this.props.classNameChild || ""}`}
        >
          {isLoading ? (
            loadScreen
          ) : this.missingRequiredVariables ? (
            <ErrorPage
              type="noPermission"
              singular={this.missingRequiredVariables}
            />
          ) : isError ? (
            <ErrorPage
              singular={this.props.singular}
              code={this.state.errorCode}
              message={this.state.errorMessage || this.props.showError}
              showBack={!!this.props.showError}
              isNoPermission={this.state.isErrorNoPermission}
              showContact={!this.state.isErrorNoPermission}
            />
          ) : this.props.isNarrow ? (
            <div className="max-w-xl">{this.props.children}</div>
          ) : (
            this.props.children
          )}
        </div>
        {this.props.footer && !isError && !this.missingRequiredVariables ? (
          <div
            className={`${(this.state.hasScroll && !this.state.isScrolledToBottom) ||
              this.props.footerBorder
              ? "border-light"
              : "border-transparent"
              } flex-none border-t py-6 ${paddingL} ${paddingR} items-center ${this.props.footerGrid
                ? `grid gap-5 grid-cols-${this.props.footerGrid || 2}`
                : ""
              } ${this.props.footerClassName}`}
            style={{ transition: "border 150ms ease-out" }}
          >
            {this.props.footer}
          </div>
        ) : null}
      </>
    )

    const page = this.props.onFileDrop ? (
      <FileDrop
        {...props}
        onChange={this.props.onFileDrop}
        accept={this.props.fileDropAccept}
        multiple={this.props.fileDropMultiple}
        title={this.props.fileDropTitle}
        icon={this.props.fileDropIcon}
      >
        {pageContent}
      </FileDrop>
    ) : (
      <motion.div {...props}>{pageContent}</motion.div>
    )
    return this.props.isPopup ? (
      <>
        {!this.props.noCloseOnEscape &&
          this.depth === this.props.location.pathname.split("/").length ? (
          <Shortcut press="Escape" onPress={this.closePopup.bind(this)} />
        ) : null}
        <div
          onClick={this.closePopup.bind(this)}
          className={`animate-fade-in tr fixed ${this.state.isClosing ? "opacity-0" : ""
            } inset-0`}
          style={{
            zIndex,
            backdropFilter:
              this.props.backTo && !this.props.noBg ? null : "blur(6px)",
            WebkitBackdropFilter:
              this.props.backTo && !this.props.noBg ? null : "blur(6px)",
            background: isDark
              ? "rgba(17, 24, 39,0.35)"
              : "rgba(107,114,128,0.08)",
          }}
        />
        <motion.div
          className={`pointer-events-none fixed inset-0 z-50 flex p-10 ${this.props.position === "center"
            ? "items-center justify-center"
            : "pt-20"
            } ${this.props.position.startsWith("top") ? "items-start" : ""} ${this.props.position.startsWith("bottom") ? "items-end" : ""
            } ${this.props.position.startsWith("center") ? "items-center" : ""} ${this.props.position.endsWith("Center") ? "justify-center" : ""
            } ${this.props.position.endsWith("Right") ? "justify-end" : ""} ${this.props.position.endsWith("Left") ? "justify-start" : ""
            }`}
          style={{
            zIndex: zIndex + 10,
            left:
              this.props.position === "center"
                ? 0
                : this.props.position.endsWith("Left") && this.props.offset
                  ? 440
                  : 240,
            right:
              this.props.position.endsWith("Right") && this.props.offset
                ? 200
                : null,
          }}
        >
          {page}
        </motion.div>
      </>
    ) : (
      page
    )
  }
}

Page.propTypes = {
  className: PropTypes.string,
  center: PropTypes.bool, // Center align content within page
  noCloseOnEscape: PropTypes.bool,
  desc: PropTypes.string,
  offset: PropTypes.bool, // Moves box closer to center with left and right alignment
  onClose: PropTypes.func,
  width: PropTypes.string,
  title: PropTypes.string,
  position: PropTypes.string,
  isCompactSearch: PropTypes.bool,
  searchDisabled: PropTypes.bool,
  isGrid: PropTypes.bool,
  gap: PropTypes.number,
  backTo: (props) => {
    if (props.isPopup && props.backTo == null) {
      return new Error(
        "A backTo prop is required for popovers. To disable, set the backTo prop to false.",
      )
    }
  },
  height: PropTypes.number,
  showErrorIf: PropTypes.bool,
}

Page.defaultProps = {
  width: "xs",
  position: "center",
  gap: 10,
}

export default withRouter(withData(withNotify(Page)))
