import React from "react"
import {
  Button,
  Sheet,
  getSemiUniqueKey,
  deleteObjectInArray,
  parse,
} from "../shared"
import moment from "moment"

class AvailabilitySheet extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getStateOnUpdate()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.availability && this.props.availability) {
      this.setState(this.getStateOnUpdate())
    }
  }
  getStateOnUpdate() {
    let availability = JSON.parse(
      JSON.stringify(
        (this.props.availability || []).map((x) => ({
          id: x.id,
          date: x.from,
          from: parse("time", x.from),
          until: parse("time", x.until),
        })),
      ),
    )
    const wasEmpty = availability.length === 0
    if (wasEmpty && !!this.props.onUpdate) {
      availability = [
        {
          date: null,
          from: "",
          until: "",
          id: getSemiUniqueKey(),
        },
      ]
    }
    return {
      isLocked: !wasEmpty,
      availability,
    }
  }
  addAvailability() {
    this.setState({
      availability: [
        ...this.state.availability,
        {
          date: "",
          from: "",
          until: "",
          id: getSemiUniqueKey(),
        },
      ],
    })
  }
  render() {
    const someEmpty = this.state.availability.some(
      (x) => !x.date || !x.from || !x.until,
    )
    return (
      <>
        <Sheet
          emptyMessage="No availability"
          className={this.props.className}
          readOnly={this.state.isLocked || !this.props.onUpdate}
          canSort={false}
          singular="availability"
          fields={[
            {
              key: "date",
              type: "date",
              name: this.props.firstColName || "Availability",
              placeholder: "Set date...",
              format: { day: "full", year: "not-current", capitalize: true },
            },
            {
              key: "from",
              type: "time",
              size: "0.5fr",
              placeholder: "Set from...",
            },
            {
              key: "until",
              type: "time",
              size: this.state.isLocked ? "0.5fr" : "0.6fr",
              placeholder: "Set until...",
            },
          ]}
          showDays
          onCreate={this.addAvailability.bind(this)}
          onUpdate={(availability) => this.setState({ availability })}
          buttons={(x) =>
            this.state.isLocked || !this.props.onUpdate ? null : (
              <Button
                icon="cross"
                isSmall
                onClick={(e) =>
                  this.setState({
                    availability: deleteObjectInArray(
                      x.id,
                      this.state.availability,
                    ),
                  })
                }
              />
            )
          }
          icon="clock"
          data={this.state.availability}
        />
        {!!this.props.onUpdate && (
          <div className="mt-4 flex">
            {this.state.isLocked && (
              <Button
                isSmall
                className="ml-auto"
                onClick={() => this.setState({ isLocked: false })}
              >
                Edit availability
              </Button>
            )}
            {this.state.isLocked && !!this.props.onDelete && (
              <Button
                onClick={this.props.onDelete}
                isSmall
                icon="delete"
                className="ml-1"
                title="Delete"
              />
            )}
            {!this.state.isLocked && (
              <Button
                isSmall
                primary
                className="mr-2"
                disabled={someEmpty}
                onClick={() => {
                  this.setState({ isLocked: true })
                  this.props.onUpdate(
                    this.state.availability
                      .map((x) => ({
                        id: x.id,
                        from: moment(x.date, "X")
                          .hours(x.from.split(":")[0])
                          .minutes(x.from.split(":")[1])
                          .unix(),
                        until: moment(x.date, "X")
                          .hours(x.until.split(":")[0])
                          .minutes(x.until.split(":")[1])
                          .unix(),
                      }))
                      .sort((a, b) => {
                        return a.from - b.from
                      }),
                  )
                  this.forceUpdate()
                }}
              >
                Save
              </Button>
            )}
            {!this.state.isLocked && (
              <Button
                isSmall
                onClick={() => {
                  this.setState({ isLocked: true })
                  this.setState({
                    availability: JSON.parse(
                      JSON.stringify(
                        (this.props.availability || []).map((x) => ({
                          ...x,
                          date: x.from,
                        })),
                      ),
                    ),
                  })
                }}
              >
                Cancel
              </Button>
            )}
            {!this.state.isLocked && (
              <Button
                isSmall
                className="ml-auto"
                secondary
                onClick={this.addAvailability.bind(this)}
              >
                + Add row
              </Button>
            )}
          </div>
        )}
      </>
    )
  }
}

export default AvailabilitySheet
